.corpPromo{
  *{
    box-sizing: border-box;
  }
  .b-travel-how-buy__column_text{
    max-width: 160px !important;
  }
  .form-group{
    margin-bottom: 15px;
    .control-label{
      font-weight: normal;
      text-transform: uppercase;
      color: grey;
      font-size: 12px;
    }
    .form-control{
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    }
    .error{
      border-color: red;
    }
  }
  .error-text{
    position: relative;
    top: -10px;
    color: red;
    font-size: 12px;
  }
  .btn-success{
    border-radius: 50px;
    background: #37a465;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    color: #fff;
  }
  p:last-child{
    margin-bottom: 30px;
  }
  form{
    margin: 40px 0;
    width: 100%;
    max-width: 400px;
  }
  .popap-over{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #21A038;
    z-index: 100;
    display: none;
    .popap-close{
      position: absolute;
      top: 20px;
      right: 20px;
      background: url('../i/new/i-popup-close.png') no-repeat center;
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
    .popap-wrp{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 101;
      padding: 50px 100px;
      max-width: 600px;
      background: #fff;
      border-radius: 5px;
      font-size: 36px;
      text-align: center;
      line-height: 1.2
    }
  }
}