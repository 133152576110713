.u-clear-fix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html{
  height: 100%;

  &.overflow{
    overflow: hidden;
    height: 100%;
  }
}
sup{
  font-size: 70%;
}
body{
  position: relative;
  font-family: $sbFont !important;
  font-size: $regularFontSize;
  line-height: $regularFontLineHeight;
  color: $regularColor;
  min-height: 100%;
  background: $bgColor;
  min-width: $minWidth;

  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;

  &.overflow{
    overflow: hidden;
    height: 100%;
  }
}
.hidden {
  display: none !important;
}
.b-rub{
  font-family: $sbFont !important;
  font-size: 90%;
}
.b-nowrap{
  white-space: nowrap;
}
a{
  //color: $linkColor;
  //border-bottom: 1px solid $linkColorBorder;
  transition: border-color .25s, color .25s;
  text-decoration: none;


  &:hover .b-link{
    color: $linkColorHover;

    .b-link__text{
      border-bottom-color: $linkColorHoverBorder;
    }
  }

  &:hover {
    color: $linkColorHover;
    border-bottom-color: $linkColorHoverBorder;
  }
  &:active{
    color: $linkColor;
    border-bottom-color: $linkColorBorder;
  }
}
.b-link {
  color: $linkColor;
  transition: color .25s;
  font-size: 50%;
  border-bottom: none !important;

  &.b-link__not-line{
    .b-link__text{
      border-bottom: none;
    }
  }

  .b-link__text{
    font-size: 200%;
    padding: 0.3em 0 0;
    border-bottom: 1px solid $linkColorBorder;
  }

  &:hover{
    color: $linkColorHover;
    .b-link__text{
      border-bottom-color: $linkColorHoverBorder;
    }
  }
  &:active{
    color: $linkColor;
    .b-link__text{
      border-bottom-color: $linkColorBorder;
    }
  }
}

.b-button__box{
  position: relative;

  &.b-button__box_show{
    text-align: center;

    .b-button{
      min-width: 250px;
    }
  }
}
.b-button{
  display: inline-block;
  vertical-align: top;
  border: none;
  background: none;
  outline: none;
  position: relative;
  box-sizing: border-box;
  height: $buttonHeight;
  border-radius: 28px;
  font-size: $biggerFontSize;
  line-height: $biggerFontLineHeight;
  font-family: $sbFont !important;
  padding: 12px 21px 15px;
  color: #fff;
  border: 1px solid $linkColor;
  background: $linkColor;
  transition: background .25s, border-color .25s, color .25s;
  cursor: pointer;
  box-shadow: $boxShadow2;

  &:hover{
    box-shadow: none;
    color: #fff;
  }

  &.b-button_border{
    background: transparent;
    color: $linkColor;
    box-shadow: none;

    &:hover{
      background: transparent;
      color: $linkColorHover;
    }

    &.b-button_white{
      background: transparent;
      border-color: rgba(255,255,255, .3);
      color: #fff;

      &:hover{
        background: #fff;
        border-color: #fff;
        color: $linkColorHover;
      }
    }
  }

  &.b-button_white{
    border-color: #fff;
    background: #fff;
    color: $linkColor;
    transition: background .25s, border-color .25s, color .25s, box-shadow .25s;

    &:hover{
      border-color: rgba(255,255,255, .8);
      background: rgba(255,255,255, .8);
      color: $linkColorHover;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
    }
  }

  &.b-button_yellow{
    border-color: $yellowColor;
    background: $yellowColor;
    color: $regularColor;

    &:hover{
      border-color: rgba(255,202,74, .8);
      background: rgba(255,202,74, .8);
    }
  }

  &.b-button_small{
    height: $buttonSmallHeight;
    padding: 7px 15px 10px;
  }

  &.b-button_radius{
    border-radius: $inputBorderRadius;
  }

  &[disabled="disabled"]{
    opacity: .3;
    cursor: default;
  }

}
.b-wrapper{
  width: 1050px;
  margin: auto;
  position: relative;
}
h1,h2,h3,h4,h5,h6{

  font-family: $sbFontBold;
  font-weight: bold;
  position: relative;
  margin-bottom: 19px;
}

h1{
  font-size: 55px;
  line-height: 65px;
}
h2{
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 35px;
}
h3{
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 29px;
}
h4{
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 16px;
}
h5{
  font-size: 25px;
  line-height: 35px;
}
h6{
  font-size: 19px;
  line-height: 29px;
  margin-bottom: 10px;
}
.b-text__wrapper + .b-video-block{
  margin-top: 40px;
}

.b-video-block{
  position: relative;
  z-index: 1;
  box-shadow: $boxShadow;
  border-radius: $inputBorderRadius;

  &.played{
    .b-video-block__overlay{
      opacity: 0;
    }
    iframe{
      opacity: 1;
    }
  }

  &+.b-text__wrapper{
    margin-top: 45px;
  }

  .b-video-block__overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $inputBorderRadius;
    z-index: 1;
    transition: opacity .25s;
  }

  .b-video-block__play{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: -45px 0 0 -45px;
    background: $linkColor;
    box-shadow: $boxShadow2;
    border: none;
    transition: background .25s;

    &:hover{
      background: $linkColorHover;
    }

    &:before{
      content: "";
      position: absolute;
      left: 55%;
      top: 50%;
      width: 23px;
      height: 31px;
      margin: -15px 0 0 -12px;
      background: url("../i/new/i-video-play.svg") no-repeat;
    }
  }

  iframe{
    width: 100%;
    height: 395px;
    opacity: 1;
    transition: opacity .25s;
    display: block;
    z-index: 2;
    position: relative;
  }
}

.b-main-title__i-list{
  position: relative;
  margin-right: -10%;
  padding-top: 25px;

  li{
    float: left;
    vertical-align: top;
    position: relative;
    width: 55%;
    box-sizing: border-box;
    padding: 10px 10% 10px 100px;
    margin-bottom: 17px;
    min-height: 74px;

    &:nth-child(even){
      width: 45%;
      padding-right: 0;
    }

    .b-main-title__i-list_text{
      height: 54px;
      display: table-cell;
      vertical-align: middle;

      span{
        display: inline-block;
        vertical-align: top;
      }
    }

    .b-main-title__i-list_pic{
      width: 74px;
      height: 74px;
      position: absolute;
      left: 0;
      top: 0;
    }

    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.b-text__wrapper{
  margin-left: 70px;
  position: relative;

  .b-news-article-contents{
    padding-top: 20px;
  }

  .b-accordion__text{
    .b-text__img{
      margin-left: 0;
    }
  }

  .b-text__img{
    position: relative;
    text-align: center;
    margin: 20px 0 20px -70px;

    .b-text__img_zoom{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 88px;
      height: 88px;
      margin: -44px 0 0 -44px;
      border-radius: 50%;
      background: $linkColor;
      box-shadow: $boxShadow2;
      display: none;

      &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 47px;
        height: 46px;
        margin: -23px 0 0 -23px;
        background: url("../i/new/i-pic-zoom.png") no-repeat;
      }
    }

    img{
      display: inline-block;
      vertical-align: top;
      margin: 0;
    }
  }

  img{
    display: block;
    max-width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .b-table{
    margin-left: -70px;
    position: relative;
    border-radius: $inputBorderRadius;
    box-shadow: 0 0 5px rgba(0,0,0, .05);
    padding: 0 30px;
    background: #fff;
    margin-top: -21px;
  }

  h3{
    margin-bottom: 3px;
    margin-top: 30px;

    &:first-child{
      margin-top: 0;
    }
  }

  table{
    //table-layout: fixed;
    width: 100%;

    thead{
      font-size: $biggerFontSize;
      font-weight: bold;
      font-family: $sbFontBold;

      td{
        border-bottom: $border;
        padding-bottom: 21px !important;
      }
    }

    tr{

      &:first-child{
        td{
          font-size: $biggerFontSize;
          font-weight: bold;
          font-family: $sbFontBold;
          border-bottom: $border;
          padding-bottom: 21px !important;
        }
      }

      &.b-table__border{
        td{
          border-bottom: $border;
          padding-bottom: 21px;
        }
      }
      &:last-child{
        td{
          padding-bottom: 47px;
        }
      }
    }

    td{
      text-align: right;
      padding:  21px 0 5px 25px;

      &:first-child{
        text-align: left;
        padding-left: 0;
      }
    }
  }

  b,strong{
    font-weight: bold;
    font-family: $sbFontBold;
  }

  ul{
    padding-top: 7px;
    margin-bottom: 30px;

    &:last-child{
      margin-bottom: 0;
    }
    &.b-ul{
      li{
        list-style: none;
        margin-left: 0;
        padding-left: 39px;

        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: -1px;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: url("../i/new/i-ul-text.svg") no-repeat center;
          background-size: 15px 10px;
          font-size: 19px;
          line-height: 27px;
          text-align: center;
          display: block;
        }
      }
    }

    &.b-docs__list{
      padding-top: 0;

      li{
        &:before{
          display: none;
        }
      }
    }

    li{
      position: relative;
      margin-bottom: 17px !important;
      list-style: none;
      margin-left: 0;
      padding-left: 39px;

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: -6px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: url("../i/new/i-ul-text.svg") no-repeat center;
        background-size: 15px 10px;
        font-size: 19px;
        line-height: 27px;
        text-align: center;
        display: block;
      }
      &.noBefore{
        &:before{
          display: none !important;
        }
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
    // old for bulit black dot

    //li{
    //  position: relative;
    //  margin-bottom: 13px;
    //  list-style: disc;
    //  padding-left: 8px;
    //  margin-left: 20px;
    //
    //  &:before{
    //    display: none;
    //  }
    //
    //  &:last-child{
    //    margin-bottom: 0;
    //  }
    //}
  }

  p{
    margin-bottom: 13px;
    text-align: left;

    &:last-child{
      margin-bottom: 0;
    }
  }

  u{
    text-decoration: none;
  }

  ol{
    counter-reset: ol-list;
    padding-top: 7px;
    margin-bottom: 30px;

    ul{
      margin-top: 20px;
      font-size: $regularFontSize;
      line-height: $regularFontLineHeight;

       li{
         padding-left: 39px;
         padding-bottom: 17px;

         &:before{
           content: "";
           top: -1px;
           counter-increment: none;
         }
       }
    }

    li{
      padding-left: 39px;
      padding-bottom: 26px;
      position: relative;

      &:last-child{
        padding-bottom: 0;
      }

      &:before{
        counter-increment: ol-list;
        content: counters(ol-list,".");
        position: absolute;
        left: 0;
        top: 1px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: $border;
        font-size: $biggerFontSize;
        line-height: 27px;
        text-align: center;
      }
    }
  }
}
.b-input__box{
  position: relative;
}
.b-input{
  display: inline-block;
  vertical-align: top;
  border: none;
  background: #fff;
  padding: 13px 18px;
  width: 100%;
  height: $inputsHeight;
  border-radius: $inputBorderRadius;
  box-shadow:  0 0 5px rgba(0, 0, 0, 0.05);
  font-family: $sbFont !important;
  font-size: $regularFontSize;
  line-height: $regularFontLineHeight;
  color: $regularColor;
  font-weight: normal;
  box-sizing: border-box;
  outline: none;
  z-index: 3;
  position: relative;
  -webkit-appearance: none;
  appearance: none;

  &.unactive{
    opacity: .8;
  }
}
textarea{
  resize: none;
}

.b-content{
  position: relative;


  &.z-index{
    z-index: 22;
  }

  .b-first-section{
    position: relative;
    padding-top: 165px;

    .b-main-title{
      h1{
        margin-bottom: 29px;
      }
    }
  }
}
.b-text__bigger{
  font-size: $biggerFontSize;
  line-height: $biggerFontLineHeight;
}
.b-main-title{
  margin-bottom: 19px;
}
.b-bg{
  position: relative;
  background: $bgPicColor;
  color: #fff;

  .b-bg__top_overflow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  .b-bg{
    background: rgba(61,75,70, .5);
    overflow: visible;

  }

  .b-calc__result{
    background: rgba(58,172,106, .85);

    .b-calc__result_pic{
      display: none;
    }
  }

  .b-bg__pic{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover;
    background-position: center;
    opacity: .6;
  }

  .b-text__wrapper{
    ul{
      li:before{
        background-image: url("../i/new/i-ul-text-white.svg");
      }
    }
  }
}
.b-careers.b-text__wrapper{
  ul{
    li{
      padding-left: 39px !important;
    }
  }
}

.b-main-tabs__section{
  position: relative;

  .b-main-tabs__tabs{
    position: relative;
    border-bottom: $border;
    margin-top: -20px;
    font-size: 0;
    line-height: 0;
    //padding-right: 335px;
    //display: table;
    width: 100%;

    li{
      display: inline-block;
      vertical-align: top;
      padding-right: 30px;
      height: 100%;

      &:last-child{
        padding-right: 0;
      }
    }
    .b-main-tabs__tabs_link{
      display: inline-block;
      vertical-align: top;
      border-bottom: none;
      padding: 20px 0;
      height: 100%;
      position: relative;
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;

      &.b-custom-tab{
        padding-left: 70px;
        font-size: 23px;
        line-height: 35px;
        padding-bottom: 40px;

        &:hover{
          i{
            background: $linkColorHover;
          }
        }

        &.b-custom-tab_sbs{
          i:before{
            width: 23px;
            height: 29px;
            background-image: url("../i/new/i-activation-sbs.png");
          }
        }
        &.b-custom-tab_sbsg{
          i:before{
            width: 28px;
            height: 23px;
            top: 52%;
            left: 52%;
            background-image: url("../i/new/i-activation-sbsg.png");
          }
        }

        i{
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -42px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: $linkColor;
          box-shadow: -5px 20px 30px rgba(0,0,0, .2);
          transition: background .25s, box-shadow .25s;

          &:before{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }

        &.active{
          i{
            background: rgba(0,0,0, .1);
            box-shadow: none;

            &:before{
              background-position: -50px 0;
            }
          }
        }
      }

      &.active{
        cursor: default;
        color: $regularColor;
        &:before{
          opacity: 1;
        }
      }

      &:before{
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        height: 5px;
        background: $linkColor;
        transition: opacity .25s;
        opacity: 0;
      }
    }

    .b-main-tabs__tabs_arrow{
      display: none;
      width: 36px;
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      bottom: 0;
      background: transparent url(../i/new/i-accordion.svg) no-repeat center;
      background-size: 16px 7px;
      transform: rotate(180deg);
      border: none;
      transition: transform .25s;
    }
  }

  .b-main-tabs__result_box{
    position: relative;
    padding-top: 30px;

    .b-main-tabs__result{
      position: relative;
      display: none;

      &.active{
        display: block;
      }
    }
  }
}

.b-bg,
.b-product-section__insurance{
  .b-accordion{
    &.b-accordion__list{
      .b-accordion__item{
        &:before{
          border-color: rgba(255,255,255, .3);
        }
      }
      .b-accordion__link {
        color: #fff;

        &:hover{
          color: rgba(255,255,255, .5);
        }
        &:after {
          border-color: rgba(255,255,255, .3);
          background: transparent url("../i/new/i-accordion-white.svg") no-repeat center 45%;
          background-size: 16px 7px;
        }
      }
    }
  }
}
.b-text__wrapper{
  .b-accordion{
    padding-top: 0;

    li:before{
      display: none;
    }
  }
}
.b-accordion{
  position: relative;

  &.b-accordion__questions{
    .b-accordion__link{
      padding-left: 35px;

      &:hover{
        &:before{
          opacity: .8;
        }
      }

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 25px;
        height: 25px;
        background: url("../i/new/i-question.svg") no-repeat;
        opacity: .3;
        transition: opacity .25s;
      }
    }
    .b-accordion__text{
      padding-left: 35px;
    }
  }

  &.b-accordion__list{
    counter-reset: accord-list;
    position: relative;

    .b-accordion__link{
      color: $linkColor;

      &:hover{
        color: $linkColorHover;
      }
    }

    .b-accordion__link,
    .b-accordion__text{
      padding-left: 42px;
      margin-left: -42px;
    }


    .b-accordion__item{
      padding-left: 57px;
      &:before{
        counter-increment: accord-list;
        content: counters(accord-list,".");
        width: 30px;
        height: 30px;
        border: $border;
        border-radius: 50%;
        line-height: 28px;
        text-align: center;
        position: absolute;
        left: 15px;
        top: 10px;
        box-sizing: border-box;
        transition: border-color .25s;
        display: block;
        background: none;
        color: $linkColor;
      }
      &.active,
      &.first-active{
        &:before{
          color: $linkColor;
          border-color: $linkColorBorder;
        }
      }
    }

    .b-accordion__link{
      padding-right: 50px;

      &:after{
        opacity: 1;
        width: 30px;
        height: 30px;
        border: $border;
        border-radius: 50%;
        box-sizing: border-box;
        top: -1px;
        background: transparent url("../i/new/i-accordion.svg") no-repeat center 45%;
        background-size: 16px 7px;
        transition: border-color .25s, backgorund .25s, transform .25s;
      }
    }
  }

  &.b-ul{
    margin-left: -15px;

    .b-accordion__item{
      padding-left: 54px;

      &:before{
        content: "";
        position: absolute;
        left: 15px;
        top: 12px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: url("../i/new/i-ul-text.svg") no-repeat center;
        background-size: 15px 10px;
        font-size: 19px;
        line-height: 27px;
        text-align: center;
        display: block;
      }
    }
  }

  .b-accordion__item{
    list-style: none;
    margin-left: -15px;
    margin-top: -8px;
    margin-bottom: 13px;
    padding: 13px 20px 0 15px;
    border-radius: $inputBorderRadius;
    background: transparent;
    transition: background .25s, box-shadow .25s;

    &.active,
    &.first-active{
      background: #fff;
      box-shadow: $boxShadow;

      .b-accordion__link:after{
        transform: rotate(0deg);
        opacity: 1;
      }
    }

    &.first-active{
      .b-accordion__text{
        display: block;
      }
    }

    .b-accordion__text{
      ul{
        li{
          &:before{
            display: block;
          }
        }
      }
    }
  }

  .b-accordion__text{
    display: none;
    padding-top: 13px;
    padding-bottom: 18px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .b-accordion__link{
    display: block;
    border: none;
    position: relative;
    padding-right: 30px;
    cursor: pointer;
    color: $linkColor;

    &:hover{
      color: $linkColorHover;
      &:after{
        opacity: 1;
      }
    }

    &:after{
      content: "";
      position: absolute;
      right: 0;
      top: 10px;
      width: 16px;
      height: 7px;
      background: url("../i/new/i-accordion.svg") no-repeat;
      transform: rotate(180deg);
      opacity: 0;
      transition: transform .25s, opacity .25s;
    }
  }
}

.b-main-tabs__result{
  .b-text__wrapper{
    padding-top: 18px;
  }
}

.b-footer-products__who{
  border-bottom: $border;
  font-size: $smallerFontSize;
  line-height: $smallerFontLineHeight;
  padding: 26px 0 26px 37px;
  margin-bottom: 37px;
  margin-top: 24px;
  position: relative;
  color: $greyColor;

  &:before{
    content: "";
    position: absolute;
    left: 0;
    top: 26px;
    width: 25px;
    height: 25px;
    background: url("../i/new/sber-whois-product.svg") no-repeat;
  }
}

.b-footer-products__section{
  position: relative;
}

.b-footer-products__carousel{
  position: relative;
  margin: 0 -9px 45px;
  padding-bottom: 12px;

  .slick-list{
    padding: 5px 5px 40px;
    margin-left: -5px;
    margin-right: -5px;
  }

}
.b-footer-products{
  height: 495px;
  float: left;
  vertical-align: top;
  margin: 0 5px;
  position: relative;
  padding: 42px 32px 0;
  border: $border;
  background: transparent;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 7px;
  outline: none;
  overflow: hidden;
  opacity: 0;
  transition: border-color .25s, background .25s, box-shadow .25s, opacity .25s;

  &.slick-active{
    opacity: 1;
  }

  &:hover{
    background: #fff;
    border-color: #fff;
    box-shadow: $boxShadow;

    .b-button__box{
      opacity: 1;
    }

    .b-footer-products__bottom_pic{
      opacity: 1;
      transform: scale(1);
    }

    .b-footer-products__price{
      color: #fff;
    }
  }

  .b-link{
    .b-link__text{
      border-bottom: none;
    }

    &:hover{
      .b-link__text{
        border-bottom-color: transparent;
      }
    }
  }

  .b-footer-products__wrapper{
    overflow: hidden;
    max-height: 286px;
  }

  .b-footer-products__text{
    opacity: .5;
    text-overflow: ellipsis;
    width: 90%;

    ul{
      margin-bottom: 15px;
      li{
        position: relative;
        margin-bottom: 13px;
        padding-left: 15px;

        &:last-child{
          margin-bottom: 0;
        }

        &:before{
          content: "-";
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &:last-child{
        margin-bottom: 0;
      }
    }

    p{
      margin-bottom: 13px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .b-footer-products__bottom{
    position: absolute;
    left: 35px;
    right: 35px;
    bottom: 38px;
  }
  .b-footer-products__bottom_pic{
    width: 220px;
    height: 220px;
    position: absolute;
    top: -62px;
    right: -97px;
    border-radius: 50%;
    background: $linkColor;
    overflow: hidden;
    transform: scale(0);
    opacity: 0;
    transform-origin: right bottom;
    transition: opacity .25s, transform .25s;


    span{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: right bottom;
      background-size: cover;
      opacity: .6;
    }
  }
  .b-button__box{
    float: left;
    vertical-align: top;
    opacity: 0;
    margin-top: -16px;
    margin-left: -15px;
    transition: opacity .25s;

    .b-button{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .b-footer-products__price{
    padding-top: 8px;
    float: right;
    vertical-align: top;
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;
    font-weight: bold;
    font-family: $sbFontBold;
    position: relative;
    transition: color .25s;

    .b-rub{
      font-weight: normal;
    }
  }
}

.slick-slider{
  .slick-dots{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0;
    line-height: 0;
    text-align: center;

    li{
      display: inline-block;
      vertical-align: top;
      margin: 0 3px;

      &.slick-active{
        button{
          cursor: default;
          background: $borderGreyColor;
        }
      }

      button{
        background: $linkColor;
        width: 10px;
        height: 10px;
        margin: 0;
        padding: 0;
        color: transparent;
        outline: none;
        border: none;
        border-radius: 50%;
        transition: background .25s;
        cursor: pointer;

        &:hover{
          background: $linkColorHover;
        }
      }
    }
  }

  .slick-arrow{
    width: 54px;
    height: 54px;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    color: transparent;
    background: $linkColor;
    border: none;
    outline: none;
    transition: background .25s;
    box-shadow: $boxShadow;
    z-index: 10;

    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 12px;
      height: 25px;
      background: url("../i/new/i-slick-white.svg") no-repeat;
      opacity: .7;
      transition: opacity .25s;
    }

    &:hover{
      background: #fff;
      &:before{
        background: url("../i/new/i-slick-green.svg") no-repeat;
      }
    }

    &.slick-prev{
      left: -62px;
    }
    &.slick-next{
      right: -62px;

      &:before{
        transform: translate(-50%,-50%) rotate(180deg);
      }
    }
  }
}

.b-information__section{
  position: relative;
  margin-bottom: 42px;

  .b-information__tel{
    float: left;
    vertical-align: top;
    margin-right: 55px;

    h2{
      margin-bottom: 0;
    }

    span{
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $sbFontBold;
      display: block;
      margin-bottom: 2px;
    }

    a{
      font-family: $sbFontBold;
      border: none;
      color: #fff;
    }
  }

  .b-social__list{
    float: left;
    vertical-align: top;
    padding-top: 32px;
    padding-left: 10px;
  }
}

.b-social__list{
  position: relative;

  .b-social__item{
    float: left;
    vertical-align: top;
    margin-right: 10px;

    .b-social{
      width: 40px;
      height: 40px;
      border: none;
      display: block;
      background: url("../i/new/social-sprite.svg") no-repeat 100% 100%;
       background-size: 200px 40px;
      transition: opacity .25s;

      &:hover{
        opacity: .7;
      }

      &.b-social_fb{
        background-position: 0 0;
      }
      &.b-social_tw{
        background-position: -50px 0;
      }
      &.b-social_vk{
        background-position: -100px 0;
      }
      &.b-social_ins{
        background-position: -150px 0;
      }
    }

    &:last-child{
      margin-right: 0;
    }
  }
}

.b-footer{
  position: relative;
  background: $linkColor;
  padding: 55px 0 0;
  font-size: $smallerFontSize;
  line-height: $smallerFontLineHeight;
  color: rgba(255,255,255, .6);

  &.time-with-benefits{
    display: none;
  }

  &-black{
    background: #171a1e;
  }

  a{
    color: rgba(255,255,255, .8);
    border-bottom-color: rgba(255,255,255, .33);

    &:hover{
      color: #fff;
      border-bottom-color: rgba(255,255,255, .6);
    }
  }

  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 95px;
    opacity: .1;

    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#002619+0,002619+100&1+0,0+100 */
    background: -moz-linear-gradient(top, rgba(0,38,25,1) 0%, rgba(0,38,25,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,38,25,1) 0%,rgba(0,38,25,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,38,25,1) 0%,rgba(0,38,25,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002619', endColorstr='#00002619',GradientType=0 ); /* IE6-9 */
  }

  .b-footer__columns_box{
    position: relative;
    padding-bottom: 45px;
  }
  .b-footer__columns{
    float: left;
    vertical-align: top;
    width: 260px;
    position: relative;
    box-sizing: border-box;
    padding-right: 20px;

    .b-footer__title{
      padding-top: 1px;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $sbFontBold;
      display: block;
      margin-bottom: 18px;
    }

    ul{
      margin-bottom: 34px;

      &:last-child{
        margin-bottom: 0;
      }

      li{
        margin-bottom: 19px;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  .b-licence{
    float: right;
    vertical-align: top;
    width: 215px;
    font-size: 12px;
    line-height: 15px;
    padding-top: 3px;

    li{
      margin-bottom: 38px;

      &:last-child{
        margin-bottom: 0;
      }
    }
    .b-licence__title{
      line-height: 19px;
      color: rgba(255,255,255, .5);
      display: block;
      margin-bottom: 18px;
      font-size: $smallerFontSize;
    }
  }

  .b-footer__agreement{
    margin-bottom: 27px;

    .b-footer__agreement_link{
      float: left;
      vertical-align: top;
      margin-right: 30px;
      margin-bottom: 10px;
      padding-left: 29px;
      position: relative;

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 15px;
        height: 17px;
        background: url("/static/i/new/i-footer-agreement.png") no-repeat;
      }

      .b-link__text{
        border-bottom-color: rgba(255,255,255, .33);
      }

      &:hover{
        .b-link__text{
          border-bottom-color: rgba(255,255,255, 1);
        }
      }


      &:last-child{
        margin-right: 0;
      }
    }
    .b-footer__agreement_link2{
      float: none;
      display: block;
      width: 100%;
      vertical-align: top;
      margin-right: 30px;
      margin-bottom: 10px;
      padding-left: 29px;
      position: relative;
      cursor: pointer;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 15px;
        height: 17px;
        background: url("/static/i/new/i-amount-plus.png") no-repeat;
        background-position-x: -50px;
      }

      .b-link_dn{
        position: relative;
        display: none;
        &:before{
          content: "";
          position: absolute;
          left: -29px;
          top: 5px;
          width: 15px;
          height: 17px;
          background: url("/static/i/new/i-footer-agreement.png") no-repeat;
        }
      }

      &.active{
        .b-link_dn{
          display: inline-block;
        }
      }

      &.b-link__text{
        color: #fff;
      }


      &:hover{
        .b-link__text{
          border-bottom-color: rgba(255,255,255, 1);
        }
      }


      &:last-child{
        margin-right: 0;
      }
    }
  }

  .b-footer__bottom{
    position: relative;
    border-top: 1px solid rgba(255,255,255, .3);
    padding:  23px 0 27px;
    text-align: center;

    .b-footer__bottom_copy{
      float: left;
      vertical-align: top;
      color: #fff;
      text-align: left;
    }
    .b-footer__bottom_made{
      float: right;
      vertical-align: top;
      text-align: left;

      .b-footer__bottom_made-BA{
        display: block;
        font-size: 13px;
        margin-top: 5px;

        a{
          font-size: 15px;
        }
      }
    }
    .b-footer__bottom_middle{
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 15px;
      padding-top: 6px;
    }
  }
}

.b-refer-manual{
  position: relative;

  a{
    display: inline-block;
    vertical-align: top;
    height: 43px;
    font-size: 16px;
    line-height: 23px;
    padding: 10px 17px 10px 13px;
    background: #fff;
    border-radius: $inputBorderRadius;
    border-bottom-left-radius: 0;
    color: $linkColor;
    transition: color .25s;
    position: relative;
    white-space: nowrap;

    &:before{
      content: "";
      position: absolute;
      right: 100%;
      bottom: -1px;
      width: 6px;
      height: 10px;
      background: url("../i/new/i-footer-button.png") no-repeat;
    }

    &:hover{
      color: $linkColorHover;
    }
  }
}

.b-text__wrapper .b-docs__list{
  position: relative;
  margin-top: -24px;

  li{
    float: left;
    vertical-align: top;
    width: 47%;
    box-sizing: border-box;
    margin-right: 6%;
    margin-top: 24px;
    margin-left: 0;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    &:nth-child(even){
      margin-right: 0;
    }

    a{
      display: block;
      border: none;
      min-height: 50px;
      padding: 1px 0 0 58px;
      position: relative;

      span{
        display: block;
        color: $greyColor;
        font-size: 13px;
        line-height: 16px;
        margin-top: 5px;
      }

      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 41px;
        height: 50px;
        background: url("../i/new/i-pdf.svg") no-repeat;
        box-shadow: 0 0 10px rgba(0,0,0, .08);
      }
    }
  }
}
.b-ins-case__tab-box{
  position: relative;
  padding-top: 25px;
  padding-bottom: 60px;

  .b-ins-case__tab-box_result{
    float: left;
    vertical-align: top;
    width: 73%;
    margin-top: 10px;
    padding-top: 1px;
    padding-right: 380px;

    .b-ins-case__tab-box_result-error{
      padding: 30px 0 0 45px;
    }

    .b-ins-case__tab-box_text{
      display: none;
      padding-left: 45px;
      border-left: $border;
      min-height: 240px;
      box-sizing: border-box;
    }

    ul{
      li{
        padding-top: 12px;

        &:first-child{
          padding-top: 0;
        }
      }
      a{
        border-bottom: none;
      }
    }
  }

  .b-header-main__nav{
    float: left;
    vertical-align: top;
    width: 27%;
    margin: 0;
    padding: 0;

    &:before,
    &:after{
      display: none;
    }
  }

  .b-header-main__link {
    border-bottom: none;
    display: block;
    position: relative;

    &.active{
      color: $regularColor;
      cursor: default;

      &:after{
        opacity: 1;
      }
    }

    &:after{
      content: "";
      position: absolute;
      right: -3px;
      width: 5px;
      height: 35px;
      top: 50%;
      transform: translate(0, -50%);
      background: $linkColor;
      opacity: 0;
    }
  }
}

.b-ins-case__second{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  display: none;

  .b-ins-case__second_button{
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -140px;
    width: 280px;
    height: 280px;
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    font-family: $sbFontBold;
    padding: 112px 50px 0;
    box-sizing: border-box;
    background: $linkColor;
    color: #fff;
    border: none;
    border-radius: 50%;
    transition: background .25s;

    &:before{
      content: "";
      position: absolute;
      left: 50%;
      top: 25px;
      margin-left: -36px;
      width: 72px;
      height: 82px;
      background: url(../i/new/i-ins-case.svg) no-repeat;
      background-size: cover;
    }

    &:hover{
      background: $linkColorHover;
    }
  }

  h2{
    &:after{
      display: none;
    }
  }

  .b-bg__pic{
    position: fixed;
    transform: translate(0,0) !important;
    -webkit-transform: translateZ(0) !important;
  }
}

.b-activation__list{
  font-size: $biggerFontSize;
  line-height: $biggerFontLineHeight;
  width: 625px;

  li{
    margin-bottom: 13px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  a{
    border-bottom: none;
  }
}
.b-activation__first{
  padding-top: 45px;
  padding-bottom: 60px;
  position: relative;

  .b-button__box{
    margin-bottom: 18px;
  }

  .b-button{
    padding-left: 63px;

    &:before{
      content: "";
      position: absolute;
      left: 22px;
      top: 50%;
      width: 29px;
      height: 30px;
      margin-top: -15px;
      background: url("../i/new/i-activate-button.png") no-repeat;
    }
  }

  .b-wrapper{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    z-index: 3;
  }

  .b-text__wrapper{
    margin: 0 auto;
    padding-bottom: 50px;
    width: 670px;

    &.b-activation__first_text{
      p {
        margin-left: 85px;
      }
    }

    ul{
      margin-bottom: 40px;
    }
    .column{
      li{
        float: left;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        padding-right: 5%;
      }
    }

    h3{
      margin-bottom: 20px;
    }
  }
  .b-activation__first_faq{
    position: absolute;
    top: 50%;
    right: 13%;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background: $linkColor;
    color: #fff;
    text-align: center;
    margin-top: -140px;
    box-sizing: border-box;
    padding: 130px 20px 0;
    border: none;
    transition: background .25s;
    z-index: 4;
    -webkit-transform: translateZ(0);


    &:hover{
      background: $linkColorHover;
    }

    &:before{
      content: "";
      position: absolute;
      top: 42px;
      left: 50%;
      margin-left: -51px;
      width: 103px;
      height: 74px;
      background: url("../i/new/i-activation-first.png") no-repeat;
    }
  }
}
.b-activation__tabs{
  position: relative;
  padding-top: 10px;
  z-index: 1;
  .b-main-tabs__result_box{
    background: #fff;
    padding-bottom: 10px;
    margin-bottom: 42px;
    box-shadow: 0 10px 30px rgba(0,0,0, .1);
  }
  .b-main-tabs__section .b-main-tabs__tabs{
    border-bottom: none;

    li{
      padding-right: 50px;

      &:last-child{
        padding-right: 0;
      }
    }
  }
}
.b-activation__second{
  display: none;

  .b-activation__back{
    position: absolute;
    top: 50px;
    left: -100px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: $linkColor;
    transition: background .25s;

    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 27px;
      height: 26px;
      margin: -13px 0 0 -13px;
      background: url("../i/new/i-back-step.png") no-repeat;
    }

    &:hover{
      background: $linkColorHover;
    }
  }

  .b-activation__title{
    margin-right: 290px;

    p{
      width: 80%;
    }
  }
  .b-activation__form{
    position: relative;
    margin-top: 24px;

    .b-button__box{
      position: absolute;
      left: 100%;
      top: -110px;
      margin-left: 35px;

      .b-button{
        width: 280px;
        height: 280px;
        border-radius: 50%;
        text-align: center;
        font-size: 25px;
        line-height: 35px;
        font-weight: bold;
        font-family: $sbFontBold;
        box-sizing: border-box;
        padding-top: 62px;

        &:before{
          content: "";
          position: absolute;
          width: 68px;
          height: 66px;
          background: url("../i/new/i-activation-button.png") no-repeat;
          top: 70px;
          left: 50%;
          margin-left: -34px;
        }
      }
    }

    .b-checkbox__section{
      margin: 40px 0;
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;

      .b-checkbox__label{
        padding-right: 70px;
        padding-left: 51px;

        .b-checkbox__icon{
          left: 17px;
        }
        &:before{
          top: -10px;
          bottom: -12px;
          opacity: 1;
        }
      }

      .b-checkbox__faq-box_call{
        position: absolute;
        right: 20px;
        top: 16px;
        width: 25px;
        height: 25px;
        background: url(../i/new/i-question.svg) no-repeat;
        border: none;
        opacity: .5;
        display: block;
        transition: opacity .25s;

        &:hover{
          opacity: 1;
        }
      }
    }

    .b-label{
      display: block;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      opacity: .5;
      margin-bottom: 7px;
      font-weight: bold;
      font-family: $sbFontBold;
    }

    .b-input__box{
      width: 162px;
      float: left;
      vertical-align: top;
      margin-right: 15px;

      &:last-child{
        margin-right: 0;
      }

      .b-input {
        background: none;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.1);

        &:focus{
          background: #fff;
          border-color: #fff;
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
        }
      }

      &:first-child{
        width: 135px;
      }
    }
  }
}

.b-form-agreement{
  position: relative;
  background: #fff;
  border-radius: $inputBorderRadius;
  box-shadow: 0 0 5px rgba(0,0,0, .05);
  padding: 6px 18px 13px;
  margin-top: 15px;

  .b-checkbox__section{
    .b-checkbox__label{
      color: #000;

      &:before{
        display: none;
      }
    }
  }
}

.b-popup__overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  background: rgba(58,172,106, .95);
  overflow-y: scroll;
  display: none;

  &.b-popup__overlay_feedback,
  &.b-popup__overlay_activation,
  &.b-popup__overlay_ins,
  &.b-popup__overlay_refer-manual{
    background: $bgColor;

    .b-popup__close{
      background: url(../i/new/i-popup-close-green.svg) no-repeat;
    }
  }

  &.b-popup__overlay_activation{
    .b-wrapper{
      padding-top: 45px;
      padding-bottom: 60px;
    }

    &.second{
      .b-activation__first{
        display: none;
      }
      .b-activation__second{
        display: block;
      }
    }

  }

  &.b-popup__overlay_ins{

    .b-ins-case__side{
      position: absolute;
      right: 0;
      top: 0;

      .b-contacts__box{
        width: 320px;
        padding: 26px;
        box-shadow: 0 0 30px rgba(0,0,0, .1);

        h5{
          font-size: 14px;
        }
        h4{
          margin-bottom: 4px;
        }
        p{
          padding-top: 3px;
        }

        &:before{
          display: none;
        }

        .b-contacts__column{
          float: none;
          padding-right: 0;
          width: 100%;
          border-right: none;
          padding-left: 0;

          &:first-child{
            border-bottom: $border;
            padding-bottom: 34px;
            margin-bottom: 24px;
          }
        }
      }
    }

    .b-popup__close{
      position: fixed;
    }

    &.second{
      .b-popup__close{
        background: url("../i/new/i-popup-close.svg") no-repeat;
      }
      .b-ins-case__first{
        display: none;
      }
    }

    .b-wrapper{
      padding-top: 45px;
    }
  }

  .b-popup__close{
    position: fixed;
    top: 30px;
    right: 35px;
    width: 58px;
    height: 58px;
    background: url("../i/new/i-popup-close.svg") no-repeat;
    border: none;
    transition: transform .25s;
    z-index: 5;
    -webkit-transform: translateZ(0);


    &:hover{
      transform: scale(.75);
    }
  }

  &.show{
    .b-popup{
      transform: translate(-50%, 0) scale(1);
      opacity: 1;

      &.b-popup__feedback,
      &.b-popup__refer-manual{
        transform: translate(-50%, 0) scale(1);
      }
    }
  }
}

.b-popup__overlay_agreement{
  z-index: 200;

  .b-popup__close{
    right: auto;
    left: 35px;
    width: 48px;
    height: 49px;
    background: url("/static/i/new/i-back-agreement.png") no-repeat;
  }
}
.b-popup__overlay.show .b-popup__agreement{
  transform: scale(1);
}
.b-popup.b-popup__agreement{
  position: relative;
  width: 640px;
  margin: 120px auto;
  top: 0;
  left: 0;

  p{
    margin-bottom: 13px;

    &:last-child{
      margin-bottom: 0;
    }
  }
}

.b-feedback-form,
.b-question-form{
  position: relative;

  .b-feedback-form__column,
  .b-question-form__column{
    .b-input__box{
      float: left;
      vertical-align: top;
      width: 47%;

      &:first-child{
        margin-right: 6%;
      }
    }
  }

  textarea{
    height: 150px;
  }

  .b-feedback-form__checkboxs{
    .b-checkbox__section{
      float: left;
      vertical-align: top;
      width: 50%;
      margin: 0;
      padding: 0;
      border-bottom: none;

      &:first-child{
        .b-checkbox__label{
          border-top-left-radius: $inputBorderRadius;
          border-bottom-left-radius: $inputBorderRadius;
        }

        &+.b-checkbox__section{
          .b-checkbox__label{
            border-left: none;
          }
        }
      }

      &:last-child{
        .b-checkbox__label{
          border-top-right-radius: $inputBorderRadius;
          border-bottom-right-radius: $inputBorderRadius;
        }
      }

      input{
        &:checked + .b-checkbox__label{
          background: #fff;
          cursor: default;
          color: $linkColor;
          border-color: #fff;
          box-shadow: $boxShadow;
        }
      }

      .b-checkbox__label{
        margin: 0;
        display: block;
        padding: 13px 0;
        height: 53px;
        box-sizing: border-box;
        text-align: center;
        border: $border;
        color: $greyColor;
        transition: background .25s, box-shadow .25s, color .25s, border-color .25s;

        &:hover{
          color: $linkColor;
        }

        &:before{
          display: none !important;
        }

        .b-checkbox__icon{
          display: none;
        }
      }
    }
  }
}



.b-popup{
  background: $bgColor;
  border-radius: $inputBorderRadius;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0) scale(0);
  padding: 51px 55px 60px;
  box-sizing: border-box;
  box-shadow: $boxShadow;
  opacity: 0;
  margin: 100px 0;
  transition: transform .25s, opacity .25s;

  h2{
    font-size: 55px;
    line-height: 65px;
  }

  &.b-popup__feedback,
  &.b-popup__refer-manual{
    top: 0;
    padding: 50px 300px 60px 0;
    box-shadow: none;
    width: 800px;
    position: relative;
    transform: translate(-50%, 0) scale(0);

    .b-feedback-form__faq{
      position: absolute;
      right: -300px;
      top: 100px;
      width: 210px;
      color: $greyColor;

      p{
        margin-bottom: 25px;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }

  &.b-popup__question{
    width: 560px;
  }

  .b-order-form__error{
    margin-top: 20px;
  }

  &.b-popup__order{
    width: 360px;
  }

  .b-order-form{
    p{
      //margin-bottom: ;
    }
  }

  .b-button__box{
    margin-top: 30px;

    .b-button{
      width: 100%;
    }
  }

  .b-input__box{
    margin-top: 15px;

    .b-label{
      display: block;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      opacity: .5;
      margin-bottom: 7px;
      font-weight: bold;
      font-family: $sbFontBold;
    }

    .b-input{
      background: none;
      box-shadow: none;
      border: $border;

      &:focus{
        background: #fff;
        border-color: #fff;
        box-shadow: $boxShadow;
      }
    }
  }
}
.b-calc__result_box.alone + .b-promo-code__box{
  width: 280px;
  height: 280px;

  .b-promo-code__close{
    top: 0;
    right: 15px;
  }
}
.b-promo-code__box{
  position: absolute;
  right: 0;
  top: 0;
  width: 355px;
  height: 355px;
  visibility: hidden;
  z-index: 9;
  transition: visibility .5s .0s;

  &.open{
    visibility: visible;
    transition: visibility .0s .0s;
    z-index: 11;

    .b-promo-code{
      transform: rotateY(0deg);
      //opacity: 1;
      transition: opacity .5s, transform .5s;
    }
  }

}
.b-promo-code{
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 30px rgba(0,0,0, .2);
  display: table;
  text-align: center;
  transform: rotateY(90deg);
  //opacity: 0;
  transition: opacity .25s .25s, transform .5s;
  color: $regularColor;


  .b-promo-code__close{
    position: absolute;
    right: 45px;
    top: 6px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: $linkColor;
    transition: background .25s;
    box-shadow: 0 0 30px rgba(0,0,0, .2);

    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 17px;
      margin: -8px 0 0 -12px;
      background: url("../i/new/i-promo-code-back.png") no-repeat;
    }

    &:hover{
      background: $linkColorHover;
    }
  }

  .b-promo-code__table-cell{
    display: table-cell;
    vertical-align: middle;
  }
  .b-promo-code__wrapper{
    display: inline-block;
    width: 55%;
  }
  .b-promo-code__title{
    display: block;
    width: 60%;
    margin: 0 auto 25px;
  }
  .b-input{
    box-shadow: none;
    border: $border;
  }
  .b-button__box{
    margin-top: 33px;

    .b-button{
      width: 100%;
    }
  }
}

.b-top__section{
  padding-top: 165px;
}

.b-common-sort{
  position: relative;
  border-top: $border;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 25px;

  .b-common-sort__item{
    float: left;
    vertical-align: top;
    margin-right: 25px;

    &:last-child{
      margin-right: 0;
    }

    a{
      display: block;
      padding-top: 19px;
      color: $regularColor;
      opacity: .5;
      transition: opacity .25s;
      position: relative;
      border: none;
      z-index: 2;

      &.active{
        opacity: 1;
        cursor: default;

        &:before{
          opacity: 1;
        }
      }

      &:before{
        content: "";
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        height: 5px;
        background: $yellowColor;
        opacity: 0;
        transition: opacity .25s;
      }

      &:hover{
        opacity: 1;
      }
    }
  }
}

.b-suggest{
  position: relative;
  padding-top: 180px;
  text-align: center;
  margin-bottom: 110px;

  .b-wrapper{
    padding-top: 125px;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 91px;
      height: 102px;
      margin-left: -45px;
      background: url("../i/new/i-suggest.svg") no-repeat;
    }
  }

  h2{
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
  }
  p{
    max-width: 550px;
    margin: auto;
    opacity: .5;
  }
}

.b-text__section{
  padding-top: 165px;
  padding-bottom: 20px;
  position: relative;
  width: 775px;
  margin: auto;

  .b-text__wrapper{
    width: 100%;
    margin: auto;
  }

  .b-video-block{
    width: 915px;
    margin-top: 53px;
    margin-bottom: 63px;

    iframe{
      width: 915px;
      height: 515px;
    }
  }

  .b-gallery{
    width: 915px;
    margin-left: -140px;
    margin-top: 53px;
    margin-bottom: 30px;
    padding-bottom: 45px;

    .slick-dots{
      li{
        padding-left: 0;
      }
    }

    .slick-arrow{
      background: $linkColor;
      &:before{
        background: url("../i/new/i-slick-white.svg") no-repeat;
      }

      &:hover{
        background: #fff;
        &:before{
          background: url("../i/new/i-slick-green.svg") no-repeat;
        }
      }
    }

    .b-gallery__item{
      height: 610px;
      position: relative;
      outline: none;
      border: none;
      overflow: hidden;
      border-radius: $inputBorderRadius;

      img{
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: auto;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: $inputBorderRadius;
      }
    }
  }
}

.b-tags{
  position: relative;
  font-size: $biggerFontSize;
  line-height: $biggerFontLineHeight;
  margin-bottom: 40px;

  li{
    float: left;
    vertical-align: top;
    margin-right: 30px;

    &:last-child{
      margin-right: 0;
    }
  }
}

.b-voting__box{
  position: relative;
  width: 760px;
  margin: 60px auto;
  background: #fff;
  border-radius: $inputBorderRadius;
  padding: 60px 62px 40px 60px;
  box-sizing: border-box;
  box-shadow: $boxShadow;

  &.voted{
    .b-voting__list{
      .b-voting__item{
        label{
          color: $regularColor !important;

          b{
            display: inline;
          }

          &:before{
            display: none;
          }
        }

        input{
          &:checked + label{
            &:before{
              display: block;
              background-position: -50px 0;
            }
          }
        }

      }
    }
  }

  .b-voting__error{
    margin: 23px 0 0 39px;
  }

  .b-voting__text{
    opacity: .5;
    margin: 23px 0 0 39px;
    font-size: $smallerFontSize;
    line-height: $smallerFontLineHeight;
  }

  .b-voting__list{
    position: relative;
    overflow: hidden;

    .b-voting__item{
      border-bottom: $border;
      padding-left: 39px;
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;

      &:first-child{
        border-top: $border;
      }

      input{
        position: absolute;
        top: -100%;
        left: -100%;
        width: 0;
        height: 0;
        opacity: 0;
      }

      .b-voting__progress{
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 3px;
        background: $linkColor;
      }

      label{
        color: $linkColor;
        display: block;
        padding: 12px 0 10px;
        cursor: pointer;
        transition: color .25s;
        position: relative;

        &:before{
          content: "";
          position: absolute;
          left: -39px;
          top: 19px;
          width: 21px;
          height: 13px;
          background: url("../i/new/i-voting.png") no-repeat 0 0;
        }

        b{
          display: none;
          font-weight: bold;
          font-family: $sbFontBold;

          &:before{
            content: "- ";
          }
        }

        &:hover{
          color: $linkColorHover;
        }
      }
    }
  }
}

.b-social-section{
  text-align: center;
  margin-top: 60px;

  .b-wrapper{
    border-top: $border;
    padding-top: 60px;
    padding-bottom: 72px;
  }

  .b-social-section__label{
    display: inline-block;
    vertical-align: top;
    opacity: .5;
    margin-right: 10px;
    margin-top: 8px;
    font-size: $smallerFontSize;
  }
  .b-social__list{
    display: inline-block;
    vertical-align: top;

    .b-social__item .b-social{
      background-image: url("../i/new/social-sprite-green.svg");
    }
  }

}

.b-input__box{
  &.b-input__box_file{
    margin: 20px 0;
    .b-label{
      display: inline-block;
      vertical-align: top;
      margin: 12px 20px 0 0;
    }
  }
}

.b-input-file{
  position: relative;
  display: inline-block;
  vertical-align: top;

  .b-input-file__text{
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 5px;
    margin-top: 9px;

    &:before{
      content: "-";
      display: inline-block;
      vertical-align: top;
      padding-right: 3px;
    }
  }

  .b-button{
    font-size: 15px;
  }

  input{
    opacity: 0;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.b-popup__ins-case{
  width: 630px;

  textarea{
    min-height: 53px;
  }

  .b-ins-case__form__column{
    .b-input__box{
      width: 55%;
      float: left;
      vertical-align: top;

      &:last-child{
        width: 41%;
        margin-left: 4%;
      }
    }
  }
}

.b-popup__search{
  background: $bgColor;
  z-index: 23;

  .b-search-append{
    padding-top: 132px;

    .b-products__section_wrapper{
      margin-bottom: 0;
    }

    .b-product{
      &.b-product__big{
        .b-product__pic div{
          border-radius: 0;
        }
      }
      .b-product__pic div{
        border-radius: 50%;
        -webkit-transform: translateZ(0);
      }
    }
  }
  .b-search-form__error{
    text-align: center;
    padding-top: 130px;
  }
}
.b-search__dropdown{
  .b-link .b-link__text{
    border-bottom: none;
  }
  h6{
    &:last-child{
      position: relative;
      overflow: hidden;
      a{
        display: inline-block;
        vertical-align: top;
        position: relative;
        border: none;

        &:before{
          content: "";
          position: absolute;
          left: 100%;
          top: 50%;
          margin-left: 9px;
          width: 200px;
          height: 1px;
          background: $borderGreyColor;
        }
        &:after{
          content: "";
          position: absolute;
          left: 100%;
          margin-left: 205px;
          top: 50%;
          width: 6px;
          height: 8px;
          margin-top: -4px;
          background: url("../i/new/i-search-arrow.png") no-repeat;
          opacity: .1;
        }
      }

    }
  }
}
.b-search-form{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: $bgColor;
  border-bottom: $border;
  -webkit-transform: translateZ(0);

  .b-search-form__faq{
    position: absolute;
    top: 100%;
    margin-top: 30px;
    left: 50px;
    width: 300px;
    color: $greyColor;
  }

  .b-wrapper{
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -15px;
      width: 30px;
      height: 30px;
      background: url("../i/new/header/i-search-black.svg") no-repeat;
      background-size: cover;
      opacity: .1;
    }
    .b-popup__search_close{
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -16px;
      width: 32px;
      height: 32px;
      background: url("../i/new/i-popup-close-green.svg") no-repeat;
      background-size: cover;
      border: none;
    }
  }

  input{
    display: block;
    margin-left: 50px;
    margin-right: 60px;
    background: none;
    border: none;
    outline: none;
    height: 83px;
    font-size: 35px;
    line-height: 82px;
    font-weight: bold;
    font-family: $sbFontBold;
    width: 90%;
  }
}

.b-popup__img-block{
  overflow: scroll;
  display: table-cell;
  vertical-align: middle;
  text-align: center;

  img{
    display: inline-block;
  }
}

.b-popup.b-popup__refer-manual{

  h2{
    white-space: nowrap;
  }

  .b-feedback-form__faq{
    width: 320px;
    right: -410px;
  }

}

.b-question__dop{
  margin-top: 40px;
  margin-left: 55px;
  background: #fff;
  border-radius: $inputBorderRadius;
  box-shadow: -5px 20px 30px rgba(0,0,0, .1);
  padding: 30px 23px 30px;

  .b-button__box{
    margin-top: 25px;
  }

  .b-text__wrapper{
    margin-left: 0;
  }
}


@include placeholder($borderGreyColor, '.b-input');

@media only screen and (min-width: 1200px){
  .b-footer-products{
    width: 340px !important;
  }

  .b-main-tabs__section .b-main-tabs__tabs li{
    max-width: none !important;
  }
}
@media only screen and (min-width: 1600px){
  @import "variables_media_1600";

  body{
    font-size: $regularFontSize;
    line-height: $regularFontLineHeight;
    min-width: $minWidth;
  }

  .b-wrapper{
    width: 1400px;
  }
  .b-main-title{
    margin-bottom: 29px;
  }
  h1{
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 35px;
  }
  h2{
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 29px;
  }
  h3{
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 29px;
  }
  h4{
    font-size: 35px;
    line-height: 45px;
  }
  .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box h4{
    font-size: 25px;
    line-height: 35px;
  }
  .b-text__bigger{
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;
  }

  .b-input{
    font-size: $regularFontSize;
  }
  .b-footer-products__carousel{
    margin-left: 0;
    margin-right: 0;
  }
  .b-footer-products{
    width: 456px !important;
  }
  .b-footer{
    font-size: 18px;
    line-height: 30px;

    .b-footer__bottom .b-footer__bottom_middle{
      font-size: 14px;
      padding-top: 10px;
    }

    .b-licence{
      font-size: 14px;
      line-height: 18px;

      .b-licence__title{
        font-size: 18px;
        line-height: 26px;
      }
    }

    .b-footer__columns{
      width: 330px;

      .b-footer__title{
        font-size: 16px;
        line-height: 30px;
        padding-top: 0;
      }
    }
  }
  .b-activation__second .b-activation__form .b-button__box{
    margin-left: 265px;
    .b-button{
      width: 400px;
      height: 400px;

      &:before{
        top: 120px;
      }
    }
  }
  .b-content .b-first-section .b-main-title h1{
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 1199px){
  @import "variables_media_tablet";

  .b-question__dop{
    margin-left: -15px;
  }

  .b-activation__list{
    margin-right: 250px;
    width: auto;
  }

  .b-activation__first{

    .b-activation__first_faq{
      width: 200px;
      height: 200px;
      font-size: $smallerFontSize;
      line-height: $smallerFontLineHeight;
      padding-top: 80px;

      &:before{
        top: 10px;
        transform: scale(.7);
      }
    }
    .b-text__wrapper{
      width: auto;
    }
  }

  .b-search-form{
    input{
      width: 85%;
    }
    .b-wrapper{
      &:before{
        left: 60px;
      }
      .b-popup__search_close{
        right: 20px;
      }
    }
    .b-search-form__faq{
      left: 110px;
    }
  }

  .b-activation__second{

    .b-activation__back{
      width: 45px;
      height: 45px;
      left: 8px;
      top: 15px;

      &:before{
        transform: scale(.8);
      }
    }
    .b-activation__title{
      margin-right: 200px;
    }
    .b-activation__form{

      .b-input__box{
        width: 147px;

        &:first-child{
          width: 129px;
        }
      }

      .b-checkbox__section{
        border-bottom: none;

        .b-checkbox__label{
          margin-right: 0;
          &:before{
            display: block;
          }
        }
      }
      .b-button__box{
        top: -60px;
        margin-left: 30px;
        .b-button{
          width: 200px;
          height: 200px;
          font-size: 20px;

          &:before{
            top: 40px;
          }
        }
      }
    }
  }

  .b-popup{
    &.b-popup__refer-manual,
    &.b-popup__feedback{
      width: 700px;
      padding-right: 200px;

      .b-feedback-form__faq{
        right: -200px;
        width: 180px;
      }
    }
    &.b-popup__refer-manual{
      h2{
        white-space: normal;
      }
    }
  }


  .b-ins-case__second .b-ins-case__second_button{
    width: 200px;
    height: 200px;
    margin-top: -100px;
    padding: 90px 0 0;
    right: 25px !important;
    font-size: 20px;
    line-height: 26px;

    &:before{
      margin-left: -27px;
      width: 55px;
      height: 60px;
    }
  }

  .b-input__box.b-input__box_file .b-label{
    margin-top: 12px;
  }
  .b-input-file .b-input-file__text{
    margin-top: 11px;
  }

  .b-text__section{
    width: auto;
    padding-left: 60px;
    padding-right: 25px;

    .b-text__wrapper{
      width: auto;
      margin: 0 70px;
    }

    .b-video-block{
      width: auto;
      margin-left: -70px;
      margin-right: -70px;

      iframe{
        width: 100%;
        height: 420px;
      }
    }

    .b-gallery{
      width: auto;
      margin-left: -70px;
      margin-right: -35px;

      .slick-arrow{
        background: $linkColor;
        &:before{
          background: url("../i/new/i-slick-white.svg") no-repeat;
        }

        &:hover{
          background: $linkColor;
          &:before{
            background: url("../i/new/i-slick-white.svg") no-repeat;
          }
        }

        &.slick-prev{
          left: -58px;
        }
        &.slick-next{
          right: -58px;
        }
      }

      .b-gallery__item{
        height: 420px;
      }
    }
  }

  .b-voting__box{
    width: 100%;
  }

  .b-footer-products__carousel{
    .b-product{
      margin: 0 9px;
    }
  }

  h1{
    font-size: 40px;
    line-height: 50px;
  }
  h2{
    font-size: 31px;
    line-height: 45px;
    margin-bottom: 19px;
  }
  h3{
    font-size: 25px;
    line-height: 25px;
  }
  h4{
    font-size: 16px;
    line-height: 24px;
  }
  h5{
    font-size: 21px;
    line-height: 30px;
  }
  h6{
    font-size: 18px;
    line-height: 28px;
  }
  .b-video-block{
    iframe{
      height: 295px;
    }
  }
  body{
    font-size: $regularFontSize;
    line-height: $regularFontLineHeight;
    min-width: 0;
  }
  .b-text__bigger{
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;
  }
  .b-input{
    font-size: $regularFontSize;
  }
  .b-wrapper{
    width: 100%;
    box-sizing: border-box;
    padding: 0 25px 0 60px;
  }
  .b-main-tabs__section .b-main-tabs__tabs li{

    .b-main-tabs__tabs_link{
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;

      &.b-custom-tab{
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .b-accordion{
    &.b-accordion__list{
      .b-accordion__link{
        &:before,
        &:after{
          top: -3px;
          opacity: 1 !important;
        }
      }
    }

    .b-accordion__link{
      &:hover:after{
        opacity: 0;
      }
    }
  }
  .b-text__wrapper{
    margin-left: 0;

    .b-text__img{
      margin-left: 0;
    }

    .b-table{
      margin-left: -30px;

      table tr:first-child td{
        font-size: $regularFontSize;
      }
    }
  }
  .b-footer-products__section{
    .b-wrapper{
      padding: 0 60px;
    }
  }
  .slick-slider .slick-arrow{
    transform: scale(.82) translate(0, -50%);

    &.slick-prev{
      left: -49px;
    }
    &.slick-next{
      right: -49px;
    }

    &.slick-disabled{
      cursor: default;
      opacity: .5;
    }
  }

  .b-information__section{
    .b-information__tel{
      margin-right: 20px;
      span{
        font-size: 10px;
        line-height: 15px;
      }
      h2{
        font-size: 31px;
      }
    }
    .b-social__list{
      padding-top: 15px;
      margin-right: -20px;
    }
  }

  .b-footer{
    font-size: $smallerFontSize;
    line-height: $smallerFontLineHeight;

    .b-wrapper{
      padding: 0 45px;
    }

    .b-footer__columns{
      width: 25%;
      padding-right: 5%;

    }

    .b-licence{
      float: left;
      width: 25%;
    }

    .b-footer__bottom{
      .b-footer__bottom_copy{
        width: 120px;
      }
      .b-footer__bottom_middle{
        width: 35%;
      }
      .b-footer__bottom_made{
        width: 210px;
        text-align: right;

        .b-footer__bottom_made-BA{
          display: block;
          font-size: $smallerFontSize;
          margin-top: 5px;

          a{
            font-size: $smallerFontSize;
          }
        }
      }
    }
  }

  .b-button{
    font-size: $regularFontSize;
    height: 46px;
    padding: 7px 15px 10px;
  }

  .b-ins-case__tab-box .b-ins-case__tab-box_result{
    padding-right: 0;
  }
  .b-popup__overlay.b-popup__overlay_ins{
    .b-ins-case__side{
      position: relative;
      float: left;
      width: 100%;
      margin-top: 30px;

      .b-contacts__box{
        width: 100%;

        &:before{
          display: block;
        }

        .b-contacts__column{
          float: left;
          width: 50%;

          &:first-child{
            padding-bottom: 0;
            margin-bottom: 0;
            padding-right: 30px;
            border-bottom: none;
          }

          &:last-child{
            padding-left: 30px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px){
  @import "variables_media_mobile";

  .b-question__dop{
    margin-left: 0px;
    padding: 7% 5%;

    h4{
      font-size: 20px;
    }
  }

  .b-popup__overlay.b-popup__overlay_ins{
    .b-ins-case__side{
      .b-contacts__box{
        padding: 5%;

        &:before{
          display: none;
        }

        .b-contacts__column{
          float: none;
          width: 100%;
          padding-right: 0;
          padding-left: 0;

          &:first-child{
            padding-bottom: 20px !important;
            margin-bottom: 20px;
            border-bottom: $border;
          }
        }
      }
    }
  }

  .b-activation__list{
    margin-right: 0;
  }

  .b-activation__first{

    .b-text__wrapper .column li{
      float: none;
      width: 100%;
      padding-right: 0;
    }

    .b-text__wrapper.b-activation__first_text p{
      margin-left: 0;
    }

    .b-main-tabs__section .b-main-tabs__tabs{
      padding-right: 0;
    }

    .b-activation__tabs{

      .b-main-tabs__section .b-main-tabs__tabs li{
        padding-right: 1.5%;
      }
    }

    .b-main-tabs__result{
      .b-text__wrapper{
        margin-top: 0;
      }
    }

    .b-activation__first_faq{
      position: relative;
      width: auto;
      height: auto;
      border-radius: 0;
      top: auto;
      display: block;
      right: auto !important;
      font-size: $regularFontSize;
      line-height: $regularFontLineHeight;
      padding: 27px 5.5% 30px 100px;
      text-align: left;
      margin-top: 0;

      &:before{
        left: 0;
        margin-left: 0;
        top: 46%;
        margin-top: -37px;
        transform: scale(.7);
      }
    }
    .b-text__wrapper{
      margin-top: 0;
      padding-bottom: 30px;
      margin-right: 0;
      width: auto;
    }
  }

  .b-popup__search{
    z-index: 30;

    .b-search-append{
      padding-top: 61px;
      padding-bottom: 0;
    }

    .b-search-form__error{
      padding-top: 20px;
    }
  }
  .b-search-form{
    .b-search-form__faq{
      left: 40px;
      width: 190px;
    }
    .b-wrapper{
      &:before{
        left: 0;
      }
      .b-popup__search_close{
        width: 26px;
        height: 26px;
        margin-top: -13px;
        right: 0;
      }
    }
    input{
      height: 59px;
      line-height: 57px;
      font-size: 19px;
      margin-left: 40px;
      margin-right: 50px;
    }
  }

  .b-popup__overlay.show{
    .b-popup{
      &.b-popup__feedback,
      &.b-popup__refer-manual{
        transform: scale(1);
      }
    }
  }

  .b-popup{
    &.b-popup__feedback,
    &.b-popup__refer-manual{
      transform: scale(0);

      .b-feedback-form__faq{
        position: relative;
        right: auto;
        top: auto;
        width: 100%;
      }

      .phone{
        width: 100%;
      }
    }
  }

  .b-voting__box{
    width: auto;
    margin: 50px -5.5%;
    border-radius: 0;
    padding: 30px 5.5%;

    .b-voting__text{
      font-size: $regularFontSize;
      line-height: $regularFontLineHeight;
      margin-left: 0;
    }

    .b-voting__list .b-voting__item{
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;
    }
  }

  .b-social-section{
    .b-social-section__label{
      font-size: 14px;
    }
    .b-wrapper{
      padding-top: 44px;
      padding-bottom: 45px;
    }
  }

  .b-text__section{
    padding-top: 125px;
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    margin: auto;

    .b-text__wrapper{
      margin: 0;
    }

    .b-video-block{
      margin:  30px -5.5%;
      border-radius: 0;
      box-shadow: none;

      .b-video-block__overlay{
        border-radius: 0;
      }

      iframe{
        height: 320px;
      }
    }

    .b-gallery{
      margin:  30px -5.5%;
      padding-bottom: 0;
      border-radius: 0;

      .slick-dots{
        left: 0;
        right: 0;
        top: auto;
        bottom: -28px;
        text-align: center;

        li{
          float: none;
          width: auto;
          margin: 0 3px;

          &.slick-active{
            button{
              background: rgba(0, 0, 0, 0.1);
            }
          }

          button{
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: $linkColor;
          }
        }
      }

      .slick-arrow{
        &.slick-prev{
          left: 2.5%;
        }
        &.slick-next{
          right: 2.5%;
        }
      }

      .b-gallery__item{
        height: 220px;
        border-radius: 0;

        img{
          border-radius: 0;
        }
      }
    }
  }

  .b-tags{
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;
    margin-bottom: 20px;
  }

  .b-suggest{
    padding-top: 120px;
  }

  .b-top__section{
    padding-top: 125px;
  }

  .b-common-sort{
    border: $border;
    border-radius: $inputBorderRadius;
    overflow: hidden;
    font-size: 15px;
    line-height: 24px;

    .b-common-sort__item{
      float: none;
      margin: 0;
      border-bottom: $border;

      a{
        display: block;
        box-sizing: border-box;
        padding: 12px 14px;
        opacity: 1;

        &.active{
          background: $yellowColor;
        }

        &:before{
          display: none;
        }
      }

      &:last-child{
        border: none;
      }
    }
  }

  .b-main-title__i-list{
    margin-right: 0;

    li{
      float: none;
      width: 100% !important;
    }
  }

  .b-text__wrapper{
    .b-table{
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      margin-top: -10px;
      background: none;
      box-shadow: none;
      font-size: $smallerFontSize;
      line-height: $smallerFontLineHeight;

      thead{
        font-size: $smallerFontSize;
        td{
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
      td{
        padding: 10px 0 0px 10px;
      }

      tr.b-table__border td{
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .b-text__img{
      .b-text__img_zoom{
        display: block;
      }
    }
  }
  .b-main-title__icon-box{
    margin-top: -40px;
    padding-bottom: 30px;
  }
  .b-main-tabs__section{
    .b-main-tabs__tabs{
      padding-right: 0;
    }
  }

  .b-wrapper{
    width: 90%;
    padding: 0;
  }

  .b-main-title{
    margin-bottom: 14px;
  }
  .b-video-block{
    iframe{
      height: 250px;
    }
    .b-video-block__play{
      width: 60px;
      height: 60px;
      margin: -30px 0 0 -30px;
    }
  }

  h1{
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 14px;
  }
  h2{
    font-size: 23px;
    line-height: 27px;
    margin-bottom: 14px;
  }
  h3{
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  h4{
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 14px;
  }
  h5{
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 9px;
  }
  h6{
    margin-bottom: 9px;
  }

  .b-first-section{

    .b-wrapper{
      &.absolute{
        padding-bottom: 181px;

        .b-calc__result_box{
          position: absolute;
          top: auto !important;
          bottom: 0;
        }
        .b-calc__result{
          //position: absolute;
          //top: auto !important;
          //bottom: 0;
        }
      }
    }

    &.b-first-section__first-capital{
      padding-bottom: 0;

      .b-main-title{
        padding-bottom: 40px;
      }
    }

    &.b-first-section__mites-protection{
      .b-main-title{
        padding-bottom: 40px;
      }
    }
  }

  .b-footer,
  .b-footer-products__section{
    z-index: 11;
  }

  .b-content .b-first-section{
    padding-top: 123px;
    z-index: 5;

    &.z-index{
      z-index: 120;
    }
  }

  .slick-slider{
    .slick-arrow{
      &:hover{
        background: #fff;

        &:before{
          background: url(../i/new/i-slick-green.svg) no-repeat;
        }
      }
    }

    .slick-dots{
      position: absolute;
      //top:0;
      bottom: auto;
      left: -5.5%;
      right: -5.5%;

      li{
        float: left;
        vertical-align: top;
        //width: 33.333%;
        margin: 0;

        &.slick-active{
          button{
            background: $linkColor;
          }
        }

        button{
          height: 3px;
          width: 100%;
          border-radius: 0;
          background: transparent;
          transition: background .25s;
        }
      }
    }
  }

  .b-main-tabs__section{
    .b-main-tabs__result_box{
      padding-top: 15px;
    }
    .b-main-tabs__tabs{
      margin-top: 25px;

      &.open{
        li{
          &:first-child{
            .b-main-tabs__tabs_arrow{
              display: block !important;
              transform: rotate(0deg);
            }
          }
          .b-main-tabs__tabs_link{
            display: block;

            &.active{
              color: #000;

              &+.b-main-tabs__tabs_arrow{
                display: none;
              }
            }
          }
        }
      }

      li{
        display: block;
        width: 100%;
        max-width: none !important;
        margin-right: 0;
        padding-right: 0;
        position: relative;

        .b-main-tabs__tabs_link{
          font-size: $regularFontSize;
          line-height: $regularFontLineHeight;
          display: none;
          padding: 20px 60px 20px 0;
          box-sizing: border-box;
          border-top: $border;

          &.active{
            display: block;
            color: $linkColor;

            &+.b-main-tabs__tabs_arrow{
              display: block;
            }
          }

          &:before{
            display: none !important;
          }

          &.b-custom-tab{
            padding-left: 0;
            padding-bottom: 20px;
            font-size: $regularFontSize;
            line-height: $regularFontLineHeight;

            i{
              display: none;
            }
          }

          span{
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  .b-accordion{
    &.b-accordion__questions{
      .b-accordion__item{
        padding-bottom: 14px;
        margin-bottom: 7px;
        border-bottom: $border;

        &:last-child{
          border-bottom: none;
        }

        &.first-active,
        &.active{
          border-bottom: none;
        }
      }
      .b-accordion__text{
        padding-left: 0;
      }
    }
  }

  .b-text__wrapper .b-docs__list{
    margin-top: 7px;

    li{
      margin-right: 0;
      width: 100%;
      float: none;
      padding-bottom: 8px;
      border-bottom: $border;
      margin-bottom: 10px;
      margin-top: 0;

      &:last-child{
        border-bottom: none;
      }

      a{
        padding-left: 37px;
        min-height: 33px;
        padding-top: 5px;
        &:before{
          width: 27px;
          height: 33px;
          background-size: cover;
        }
      }
    }
  }

  .b-footer-products__who{
    margin-bottom: 20px;
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 25px;
    font-size: $smallerFontSize;
    line-height: $smallerFontLineHeight;

    &:before{
      top: 18px;
      width: 20px;
      height: 20px;
      background-size: cover;
    }

    &+h2{
      top: 98px;
    }
  }

  .b-footer-products__section{
    padding-top: 0;

    .b-wrapper{
      padding: 0;
      width: 90%;
    }

    h2{
      position: absolute;
      top: 36px;
      left: 0;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 15px;
      white-space: nowrap;
      opacity: .3;
    }
  }
  .b-footer-products__carousel{
    margin: 0 -5.5%;
    padding-bottom: 0;
    overflow: hidden;
    padding-top: 85px;

    .slick-list{
      margin: 0;
      padding: 0;
    }

    &.slick-slider {
      .slick-arrow{
        transform: scale(0.82);
        top: 16px;
        background: $linkColor;

        &:before{
          background: url("../i/new/i-slick-white.svg") no-repeat;
        }

        &.slick-prev{
          right: 50px;
          left: auto;
        }
        &.slick-next{
          right: 0;
        }
      }
    }

    .b-product{
      margin: 0;
      border-bottom: none;
    }
  }

  .b-footer-products{
    border: none;
    padding: 77px 15px 40px;
    margin: 0;
    height: 450px;
    border-radius: 0;
    background: none !important;
    box-shadow: none;
    background: #fff;

    .b-footer-products__wrapper{
      max-height: 235px;
    }

    .b-button__box{
      opacity: 1;
      margin-left: 15px;
    }

    .b-footer-products__bottom_pic{
      transform: scale(1);
      opacity: 1;
    }

    .b-footer-products__price{
      color: #fff;
    }

    .b-footer-products__bottom{
      left: 0;
      right: 0;
    }

  }

  .b-information__section{
    margin-bottom: 43px;

    .b-social__list{
      text-align: left;
      padding: 20px 0 0;
    }

    .b-information__tel{
      float: none !important;
      margin-top: 17px;

      &:first-child{
        margin-top: 0;
      }

      span{
        font-size: 10px;
        line-height: 15px;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block;
      }

      h2{
        font-size: 23px;
        line-height: 27px;
        font-weight: bold;
        font-family: $sbFontBold;
        opacity: 1;
        position: relative;
        top: 0;
      }
    }
  }

  .b-footer{
    padding-top: 30px;
    font-size: 15px;

    .b-wrapper{
      padding: 0;
    }

    .b-footer__bottom{
      border-top-color: rgba(255,255,255, .1);
      font-size: 11px;
      line-height: 17px;
      text-align: left;
      padding-top: 17px;
      padding-bottom: 15px;

      .b-footer__bottom_copy{
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
      }

      .b-footer__bottom_made{
        width: 46%;
        text-align: right;

        .b-footer__bottom_made-BA{
          display: block;
          font-size: 11px;
          margin-top: 5px;

          a{
            font-size: 11px;
          }
        }

        span{
          display: block;
        }
      }
      .b-footer__bottom_middle{
        float: left;
        width: 46%;
        text-align: left;
        vertical-align: top;
        padding-top: 0;
        margin-top: 0;
      }
    }

    .b-footer__columns_box{
      padding-bottom: 11px;
    }

    .b-licence{
      float: none;
      display: inline-block;
      width: 100%;
      //margin-left: 8%;
      font-size: 11px;
      line-height: 17px;
      margin-top: 0;

      .b-licence__title{
        font-size: 10px;
        line-height: 15px;
        font-weight: normal;
        margin-bottom: 13px;
      }

      li{
        float: none;
        width: 100%;
        margin-bottom: 26px;
      }
    }

    .b-footer__columns{
      display: inline-block;
      vertical-align: top;
      float: none;
      width: 45%;
      padding-right: 0;
      margin-bottom: 40px;

      .b-footer__title{
        font-size: 10px;
        line-height: 15px;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 9px;
      }

      ul {
        margin-bottom: 38px;

        li{
          margin-bottom: 15px;
        }
      }

      &:nth-child(even){
        margin-left: 8%;
      }
    }
  }

  .b-popup__overlay{

    .b-popup__close{
      position: absolute;
      width: 29px;
      height: 29px;
      background-size: cover !important;
      top: 10px;
      left: 50%;
      margin-left: -15px;
    }

    &.show{
      .b-popup{
        transform: scale(1);
      }
    }

    .b-popup{
      position: relative;
      margin: 60px auto 40px;
      transform: scale(0);
      left: 0;
      top: 0;
      width: 90%;
      padding: 20px 5% 25px;

      .b-input__box .b-label{
        font-size: 12px;
        line-height: 17px;
      }

      h2, h4{
        font-size: 23px;
        line-height: 27px;
      }

      &.b-popup__feedback,
      &.b-popup__refer-manual{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
      }
    }
  }

  .b-feedback-form,
  .b-question-form{
    .b-feedback-form__column,
    .b-question-form__column{
      .b-input__box{
        float: none;
        width: 100%;
        margin-right: 0;
        margin-top: 15px;

        &:first-child{
          margin-top: 0;
        }
      }
    }
    .b-feedback-form__column{
      .b-input__box{
        &:first-child{
          margin-top: 15px;
        }
      }
    }
  }


  .b-input__box.b-input__box_file{
    margin-top: 15px;
    margin-bottom: 20px;

    .b-label{
      margin: 0 0 7px;
      display: block;
    }
  }

  .b-popup__overlay{
    &.b-popup__overlay_ins,
    &.b-popup__overlay_activation{
      .b-wrapper{
        padding-top: 65px;
      }
    }
  }

  .b-activation__list{
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;
  }

  .b-activation__second{
    .b-activation__back{
      top: 5px;
      left: -3%;
    }
    .b-activation__title{
      margin-right: 0;

      p{
        width: 100%;
      }
    }
    .b-product__wrapper{
      margin-right: 0;
    }
    .b-activation__form{
      .b-button__box{
        position: relative;
        left: 0;
        top: 0;
        margin-left: 0;
        text-align: center;

        .b-button{
          width: 100%;
          height: auto;
          padding-top: 7px;
          border-radius: 28px;
          font-size: 15px;
          line-height: 29px;

          &:before{
            display: none;
          }
        }
      }
      .b-input__box{
        margin-top: 15px;
        float: none;
        margin-right: 0;
        width: 100% !important;

        .b-label{
          font-size: 12px;
          line-height: 17px;
        }
      }
      .b-checkbox__section{
        font-size: $regularFontSize;
        line-height: $regularFontLineHeight;
      }
    }
  }

  .b-ins-case__tab-box{
    padding-top: 0;
    padding-bottom: 40px;

    .b-header-main__nav{
      float: none;
      width: 100%;

      .b-ins-case__tab-box_text{
        display: none;
        margin-left: -5.5%;
        margin-right: -5.5%;
        padding: 17px 5.5% 0;
        box-shadow: 0 0 100px rgba(0, 0, 0, 0.15);

        ul{
          a{
            border-bottom: none;
            display: block;
            padding: 11px 0 9px;
          }
          li{
            border-top: $border;
          }
        }
      }

      .b-header-main__link{
        border-bottom: $border;
        padding-top: 12px;
        padding-bottom: 14px;

        &.active{
          border-bottom-color: transparent;
        }

        &:after{
          right: 0;
          width: 16px;
          height: 7px;
          border: none;
          background: url(../i/new/i-accordion.svg) no-repeat;
        }

        &:before{
          transform: translate(0,10px);
        }
      }

      li{
        margin: 0;

        &:last-child{
          .b-header-main__link{
            border-bottom: none;
          }
        }
      }
    }
  }

  .b-ins-case__second{
    &.b-product-section__insurance{
      overflow-y: scroll;

      .b-wrapper{
        padding-bottom: 30px;
      }
    }

    .b-ins-case__second_button{
      left: 0;
      right: 0 !important;
      bottom: 0;
      top: auto;
      margin-top: 0;
      margin-left: 0;
      width: auto;
      height: auto;
      border-radius: 0;
      padding: 13px 40% 13px 5%;
      font-size: 17px;
      line-height: 22px;
      text-align: left;

      &:before{
        width: 40px;
        height: 43px;
        left: auto;
        right: 20px;
        top: 50%;
        margin-top: -25px;
        margin-left: 0;
      }
    }
  }
  .b-popup__ins-case .b-ins-case__form__column .b-input__box{
    float: none;
    width: 100% !important;
    margin-left: 0 !important;
  }

  .b-popup__overlay_pic{

    .b-popup__close{
      position: fixed;
      width: 50px;
      height: 50px;
      background: $linkColor;
      border-radius: 50%;
      margin-left: -25px;
      box-shadow: $boxShadow2;

      &:before{
        content: "";
        position: absolute;
        width: 28px;
        height: 28px;
        background-size: cover !important;
        top: 50%;
        left: 50%;
        margin: -14px 0 0 -14px;
        background: url(../i/new/i-popup-close.svg) no-repeat;
      }
    }

  }
  .b-content .b-first-section .b-main-title h1{
    margin-bottom: 19px;
    margin-left: -7px;
    color: #fff;

    span{
      background: $linkColor;
      padding: 0 7px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }

  .b-bg{
    &.b-bg__top{
      .b-bg__top_overflow{
        position: fixed;

        &.bottom{
          top: auto;
          position: absolute;
        }
      }
    }

  }

  .b-text__wrapper .b-table table tr:first-child td{
    font-size: 11px;
    line-height: 16px;
  }
  .b-refer-manual{
    margin-top: 100px;
  }
  .b-footer .b-footer__agreement{
    margin-top: 25px;
  }

  .b-bg{
    &.b-bg__top_rp{
      .b-bg__pic{
        background-position: 90% center;
      }
    }
  }

}

@media only screen and (min-width: 768px){
  .b-main-tabs__section{
    &.b-main-tabs__section_bar{
      overflow: hidden;
      margin-right: 335px;

      .b-text__wrapper .b-docs__list li{
        float: none;
        margin-right: 0;
        width: 100%;
      }

      .b-main-tabs__tabs{
        float: left;
        vertical-align: top;
        width: 215px;
        border-bottom: none;
        z-index: 2;
        margin-top: 0;
        border-right: 1px solid #dfdfdf;
        margin-right: -1px;

        li{
          max-width: none !important;
          padding-right: 0;
          margin: 15px 0;
          display: block;

          &:first-child{
            margin-top: 0;
          }

          &:last-child{
            margin-bottom: 0;
          }

          .b-main-tabs__tabs_link{
            padding: 0 25px 0 0;
            font-size: $regularFontSize;
            line-height: 24px;
            min-height: 0 !important;
            display: block;

            &:before{
              right: -3px;
              top: 0;
              width: 5px;
              height: auto;
              bottom: 0;
              left: auto;
            }
          }
        }
      }

      .b-main-tabs__result_box{
        float: left;
        vertical-align: top;
        width: 500px;
        margin-right: 0;
        border-left: $border;
        padding-top: 0;

        .b-text__wrapper{
          padding-top: 0;
        }
      }
    }
  }


}
@media only screen and (min-width: 768px) and (max-width: 1199px){
  @import "variables_media_tablet";

  .b-main-tabs__section {
    &.b-main-tabs__section_bar {
      margin-right: 230px;

      .b-main-tabs__tabs{
        width: 183px;
        li{
          .b-main-tabs__tabs_link{
            font-size: $regularFontSize;
            line-height: $regularFontLineHeight;
          }
        }
      }
      .b-main-tabs__result_box{
        width: 270px;

        .b-text__wrapper{
          padding-left: 35px;
        }
      }
    }
  }
}

