.timeWithBenefits {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1920px;
  line-height: 1.2;
  font-family: $sbFont !important;



  .cnt {
    //max-width: 1366px;
    //margin: 0 auto;
    //padding: 0 50px;
    //@media screen and (max-width: 767px) {
    //  padding: 0 24px;
    //}
  }

  .xs {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .hd {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .header {
    background-color: #21a038;

    .cnt {
      height: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        height: 80px;
        img {
          max-width: 30%;
        }
      }
    }

    a {
      text-decoration: none;
      @media screen and (max-width: 767px) {
        max-width: 65%;
        flex-shrink: 0;
        img {
          max-width: 100% !important;
        }
      }
    }
  }

  .newBanner {
    background: url("https://ticket.sberbank-insurance.ru/img/header_bg2.png") no-repeat center;
    background-size: cover;
    //height: 400px;
    padding-bottom: 30px;
    padding-top: 20px;
    @media screen and (max-width: 767px) {
      background: url("../../i/timeWithBenefits/bg-xs.png") no-repeat center;
      background-size: cover;
      height: auto;
      padding-bottom: 50px;

    }

    .logo {
      padding-bottom: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        height: 50px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 590px;
        img {
          width: 100%;
        }
      }
    }

    .head {
      text-align: center;
      font-size: 60px;
      color: #fff;
      font-family: $sbFontBold;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 36px;
      }
      @media screen and (max-width: 359px) {
        font-size: 30px;
      }
    }

    .subhead {
      padding-top: 20px;
      font-size: 26px;
      color: #fff;
      text-align: center;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }

    }

    .btn_white {
      margin-top: 30px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      height: 60px;
      width: 100%;
      max-width: 355px;
      border-radius: 60px;
      border: 1px solid #fff;
      background: #fff;
      color: #21a038;
      font-size: 24px;
      cursor: pointer;
      transition: .5s;
      font-family: $sbFontBold;
      font-weight: 600;

      @media screen and (max-width: 767px) {
        display: none;

      }

      &:hover {
        background: transparent;
        color: #fff;
      }

      &:disabled {
        cursor: default;
        opacity: .5;
      }
    }
  }

  .wrap {
    background: url("/i/timeWithBenefits/bg.png") no-repeat top center;
    background-size: cover;
    @media screen and (max-width: 1024px) {
      background-size: 1920px;
      background-repeat: repeat-y;
    }
    @media screen and (max-width: 767px) {
      background: #e3e3e3;
    }


    .ul {
      position: relative;
      width: 100%;
      padding-bottom: 0px;
      padding-top: 40px;
      @media screen and (max-width: 767px) {
        padding: 0;
        top: -25px;
      }

      &_img {
        position: absolute;
        z-index: 1;
        bottom: -60px;
        right: -115px;
        height: 510px;
        @media screen and (max-width: 1024px) {
          display: none !important;
        }
      }

      .li {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 60px;
        width: calc(100% - 230px);
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          display: flex !important;
          flex-wrap: wrap;
          align-items: center;
          background: #fff;
          border-radius: 20px;
          padding: 30px 20px;
        }

        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(2) {
          width: calc(100% - 230px);
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
        }

        &_icon {
          width: 103px;
          flex-shrink: 0;
          margin-right: 20px;
          @media screen and (max-width: 767px) {
            width: 80px;
          }
        }

        &_head {
          font-size: 36px;
          color: #21a038;
          margin-bottom: 10px;
          font-family: $sbFontBold;
          font-weight: 600;
          @media screen and (max-width: 767px) {
            font-size: 24px;
            width: calc(100% - 100px);


          }
        }

        &_text {
          font-size: 24px;
          color: #000;
          @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-top: 30px;
          }

          small {
            font-size: 21px;
            color: rgba(#000, .5);
            @media screen and (max-width: 767px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .where {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    left: 123px;
    margin-bottom: -180px;
    @media screen and (max-width: 1024px) {
      left: 0;
      flex-direction: column-reverse;
    }

    .left {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        background: #fff;
        width: calc(100% + 100px);
        position: relative;
        left: 50%;
        transform: translate(-50%);
        border-radius: 0;
        padding: 0 20px;
      }
      @media screen and (max-width: 767px) {
        width: calc(100% + 48px);
      }

      &_head {
        font-size: 32px;
        font-family: $sbFontBold;
        font-weight: 600;
        line-height: 1.2;
        color: #21a038;
        margin-bottom: 10px;
        text-align: center;
        @media screen and (max-width: 1024px) {
          text-align: center;
          font-size: 32px;
          line-height: 1.2;
          color: #000;
        }

      }

      &_text {
        font-size: 18px;
        text-align: center;
        b {
          font-weight: normal;
        }

        @media screen and (max-width: 1024px) {
          text-align: center;
          font-size: 20px;
        }
        @media screen and (max-width: 767px) {
          text-align: center;
          b {
            font-weight: bold;
            font-family: $sbFontBold;
          }
        }
      }

      .phone {
        @media screen and (max-width: 1024px) {

          height: 430px;
          object-fit: cover;
          width: 351px;
          object-position: top;
        }
      }
    }

    .right {
      margin-left: 100px;
      width: 100%;
      background: #21a038;
      border-radius: 33px;
      padding: 20px 50px;
      margin-bottom: 20px;
      @media screen and (max-width: 1440px) {
        margin-right: 70px;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        background: #fff;
        width: calc(100% + 100px);
        position: relative;
        left: 50%;
        transform: translate(-50%);
        border-radius: 0;
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        padding: 20px;
        width: calc(100% + 48px);
      }

      &_head {
        text-align: center;
        font-size: 43px;
        color: #fff;
        margin-bottom: 15px;
        font-family: $sbFontBold;
        font-weight: 600;
        @media screen and (max-width: 1024px) {
          color: #21a038;
        }
        @media screen and (max-width: 767px) {
          font-size: 32px;
        }
      }

      &_li {
        font-size: 26px;
        color: #fff;
        margin-bottom: 50px;
        padding-left: 80px;
        position: relative;
        &:last-child{
          margin-bottom: 30px;
        }
        @media screen and (max-width: 1024px) {
          color: #000;
        }
        @media screen and (max-width: 767px) {
          font-size: 20px;
          padding-left: 70px;
        }

        &:before {
          content: attr(data-number);
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          line-height: 26px;
          color: #21a038;
          @media screen and (max-width: 1024px) {
            background: #e3e3e3;
          }
        }

        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 25px;
          top: 0px;
          transform: translate(-50%, 0);
          width: 5px;
          height: calc(100% + 70px);
          background: #fff;
          @media screen and (max-width: 1024px) {
            background: url("../../i/timeWithBenefits/after_dots.png") repeat-y center;
            background-size: 5px
          }
        }

        &:last-child {
          &:after {
            display: none;

          }
        }
      }
    }
  }

  .form {
    background: #faed00;
    padding: 35px 0;
    position: relative;
    z-index: 3;

    &_flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &_head {
      font-size: 36px;
      line-height: 1.2;
      color: #000;
      text-align: center;
      font-family: $sbFontBold;
      font-weight: 600;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }

      span {
        font-size: 24px;
        font-family: $sbFont;
        font-weight: 400;
        color: #000;
        @media screen and (max-width: 767px) {
          font-size: 20px;
        }
      }
    }

    &_inputs {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 50px;
      margin-bottom: 60px;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      .inputBlock {
        width: 100%;
        max-width: 455px;
        margin-right: 40px;
        position: relative;
        @media screen and (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 40px;
        }
        &.select {
          input {
            padding-right: 30px;
          }
          &:before {
            content: '';
            position: absolute;
            z-index: 3;
            bottom: 18px;
            right: 10px;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid #000;
            border-right: 2px solid #000;
            transform: rotate(45deg);
            transition: .5s;
            transform-origin: center;
          }
          &.active{
            &:before{
              bottom: 14px;
              transform: rotate(225deg);
            }
          }
        }


        &:last-child {
          margin-right: 0;
          @media screen and (max-width: 767px) {
            margin-bottom: 0;
          }
        }

        label {
          font-weight: 600;
          font-family: $sbFontBold;
          font-size: 24px;
          color: #000;
        }

        input {
          margin-top: 15px;
          width: 100%;
          background: rgba(#fff, 1);
          border: 0;
          outline: 0;
          height: 45px;
          font-size: 20px;
          color: #000;
          border-radius: 8px;
          padding: 0 10px;
          box-sizing: border-box;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
          &::placeholder {
            color: #000;
          }
        }

        .option{
          display: none;
          position: absolute;
          z-index: 5;
          bottom: 0px;
          left: 0;
          transform: translate(0, 100%);
          background: rgba(#fff, 1);
          border-radius: 8px;
          padding: 10px 0;

          &__el{
            font-size: 20px;
            color: #000;
            padding: 10px;
            cursor: pointer;
            transition: .5s;
            &.selected,
            &:hover{
              background: #B1B1B1;
            }
          }

        }
      }
    }

    .errorDiv {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(0, 100%);
      color: red;

      &.success {
        color: #21a038;
      }
    }

    &_btn {
      height: 60px;
      width: 100%;
      max-width: 355px;

      border-radius: 60px;
      background: #21a038;
      border: 1px solid #21a038;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
      transition: .5s;
      font-weight: 600;
      font-family: $sbFontBold;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }

      &:hover {
        background: transparent;
        color: #000;
      }

      &:disabled {
        cursor: default;
        opacity: .5;
      }
    }
  }

  .footer {
    background-color: #21a038;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      opacity: .1;
      background: linear-gradient(0deg,#002619 0,rgba(0,38,25,0));
    }
    .cnt {
      height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 767px) {
        height: 80px;
      }
    }

    a {
      font-size: 20px;
      color: #EBEBEB;
      text-decoration: none;
      border-bottom: 1px solid rgba(#EBEBEB, 0);
      transition: .5s;
      padding-left: 20px;
      position: relative;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 15px;
        height: 17px;
        background: url(/static/i/new/i-footer-agreement.png) no-repeat;
      }

      &:hover {
        border-bottom: 1px solid rgba(#EBEBEB, 1);
      }
    }
  }
}