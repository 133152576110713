.wrapper {
	max-width: 1360px;
    padding: 0 50px;
    margin: 0 auto;
    box-sizing: border-box;

	@media screen and (max-width: 767px) {
		padding: 0 20px;
	}
}
