/* reset styles */
/* line 5, ../sass/reset.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
/* line 30, ../sass/reset.scss */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

/* line 35, ../sass/reset.scss */
body {
  line-height: 1;
}

/* line 39, ../sass/reset.scss */
ol, ul {
  list-style: none;
}

/* line 43, ../sass/reset.scss */
blockquote, q {
  quotes: none;
}

/* line 47, ../sass/reset.scss */
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* line 53, ../sass/reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 58, ../sass/reset.scss */
td {
  vertical-align: top;
}

/* line 62, ../sass/reset.scss */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

/* line 68, ../sass/reset.scss */
small {
  font-size: 85%;
}

/* line 70, ../sass/reset.scss */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

/* line 71, ../sass/reset.scss */
sup {
  top: -0.5em;
}

/* line 72, ../sass/reset.scss */
sub {
  bottom: -0.25em;
}

/* line 74, ../sass/reset.scss */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin-left: 0;
  *width: 13px;
  *height: 13px;
}

/* line 82, ../sass/reset.scss */
select {
  margin: 0;
}

/* line 86, ../sass/reset.scss */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

/* line 93, ../sass/reset.scss */
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

/* line 97, ../sass/reset.scss */
button, input {
  line-height: normal;
}

/* line 101, ../sass/reset.scss */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: default;
  -webkit-appearance: button;
  *overflow: visible;
}

/* line 107, ../sass/reset.scss */
button[disabled], input[disabled] {
  cursor: default;
}

/* line 111, ../sass/reset.scss */
.clear {
  clear: both;
  height: 1px;
}

/* line 116, ../sass/reset.scss */
.cl:after, .radio:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  padding: 0;
  margin: 0;
  font-size: 0;
}

/* line 128, ../sass/reset.scss */
.cl {
  display: inline-block;
}

/* line 132, ../sass/reset.scss */
.rel {
  position: relative;
}

/* line 136, ../sass/reset.scss */
.fl {
  float: left;
}

/* line 140, ../sass/reset.scss */
.ov {
  overflow: hidden;
}

/* line 145, ../sass/reset.scss */
.side_left {
  float: left;
}

/* line 149, ../sass/reset.scss */
.side_right {
  float: right;
}

/* line 153, ../sass/reset.scss */
.inline {
  display: inline-block;
}

/* line 157, ../sass/reset.scss */
html[xmlns] .cl, html[xmlns] .radio {
  display: block;
}

/* line 161, ../sass/reset.scss */
* html .cl, * html .radio {
  height: 1%;
}

/* line 165, ../sass/reset.scss */
.hidden {
  display: none;
}

/* line 169, ../sass/reset.scss */
button::-moz-focus-inner {
  padding: 0;
  border: 0;
  -moz-box-sizing: content-box;
}

/* line 175, ../sass/reset.scss */
a:active, a:focus, button:active, button:focus {
  outline: none;
}

/* line 179, ../sass/reset.scss */
::-webkit-validation-bubble-message {
  display: none;
}
