.b-header.white,
.b-header.green-day {
  a {
    color: #fff !important;
  }
  .b-header__search_i:before {
    background: url(../i/new/header/i-search-white.svg) no-repeat 0 0;
    @media screen and (max-width: 767px) {
      background: url(../i/new/header/i-search.svg) no-repeat 0 0;
    }
  }
  .b-header__logo {
    background: url(../i/new/header/logo-white.svg) no-repeat 0;
  }
  .b-header-main__wrapper a {
    color: inherit !important;
  }
  .b-top-menu a {
    @media screen and (max-width: 767px) {
      color: inherit !important;
    }
  }
}

.actionDiv {
  width: 100%;
  overflow: hidden;
  &-img {
    padding-top: 70px;
    background: #191b18;
    position: relative;
    img {
      width: 100%;
      height: auto;
      @media screen and (max-width: 767px) {
        margin-left: 20px;
        width: 600px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
      }
    }
    &-text {
      width: 100%;
      max-width: 1115px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0-50%, -50%);
      span {
        font-size: 40px;
        display: inline-block;
        color: #fff;
        border: none;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background: #21A038;
        padding: 5px 13px 5px 10px;
        font-weight: 600;
        font-family: $sbFontBold;
        transition: background .25s;
        box-shadow: -10px 34px 45px rgba(0, 0, 0, .5);
      }
    }
  }
  &-content{
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
  }

}

