.infoPhoneHelper{
  position: relative;
  top: -10px;
  .infoIcon{
    cursor: pointer;
  }
  &:hover{
    .infoPopap{
      display: block;
    }
  }
  .infoPopap{
    display: none;
    position: absolute;
    z-index: 222;
    top: -15px;
    left: -20px;
    transform: translate(0, -100%);
    font-size: 16px;
    line-height: 1.2 !important;
    font-weight: normal;
    color: #2b2b2b;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #009942;
    padding: 10px;
    width: 400px;

    &:before{
      content: '';
      position: absolute;
      bottom: -1px;
      left: 25px;
      width: 20px;
      height: 20px;
      border-top: 1px solid #009942;
      border-right: 1px solid #009942;
      background: #fff;
      transform: translate(0, 50%) rotate(135deg);
    }
  }
  &.down{
    top: 0;
    .infoPopap{
      top: auto;
      bottom: 5px;
      transform: translate(0, 100%);
      &:before{
        bottom: auto;
        top: -1px;
        transform: translate(0, -50%) rotate(-45deg);
      }
    }
  }
}