@charset "UTF-8";
/* reset styles */
/* line 5, ../sass/reset.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* HTML5 display-role reset for older browsers */
/* line 30, ../sass/reset.scss */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 35, ../sass/reset.scss */
body {
  line-height: 1; }

/* line 39, ../sass/reset.scss */
ol, ul {
  list-style: none; }

/* line 43, ../sass/reset.scss */
blockquote, q {
  quotes: none; }

/* line 47, ../sass/reset.scss */
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

/* line 53, ../sass/reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 58, ../sass/reset.scss */
td {
  vertical-align: top; }

/* line 62, ../sass/reset.scss */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle; }

/* line 68, ../sass/reset.scss */
small {
  font-size: 85%; }

/* line 70, ../sass/reset.scss */
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 71, ../sass/reset.scss */
sup {
  top: -0.5em; }

/* line 72, ../sass/reset.scss */
sub {
  bottom: -0.25em; }

/* line 74, ../sass/reset.scss */
input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  margin-left: 0;
  *width: 13px;
  *height: 13px; }

/* line 82, ../sass/reset.scss */
select {
  margin: 0; }

/* line 86, ../sass/reset.scss */
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

/* line 93, ../sass/reset.scss */
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

/* line 97, ../sass/reset.scss */
button, input {
  line-height: normal; }

/* line 101, ../sass/reset.scss */
button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: default;
  -webkit-appearance: button;
  *overflow: visible; }

/* line 107, ../sass/reset.scss */
button[disabled], input[disabled] {
  cursor: default; }

/* line 111, ../sass/reset.scss */
.clear {
  clear: both;
  height: 1px; }

/* line 116, ../sass/reset.scss */
.cl:after, .radio:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  padding: 0;
  margin: 0;
  font-size: 0; }

/* line 128, ../sass/reset.scss */
.cl {
  display: inline-block; }

/* line 132, ../sass/reset.scss */
.rel {
  position: relative; }

/* line 136, ../sass/reset.scss */
.fl {
  float: left; }

/* line 140, ../sass/reset.scss */
.ov {
  overflow: hidden; }

/* line 145, ../sass/reset.scss */
.side_left {
  float: left; }

/* line 149, ../sass/reset.scss */
.side_right {
  float: right; }

/* line 153, ../sass/reset.scss */
.inline {
  display: inline-block; }

/* line 157, ../sass/reset.scss */
html[xmlns] .cl, html[xmlns] .radio {
  display: block; }

/* line 161, ../sass/reset.scss */
* html .cl, * html .radio {
  height: 1%; }

/* line 165, ../sass/reset.scss */
.hidden {
  display: none; }

/* line 169, ../sass/reset.scss */
button::-moz-focus-inner {
  padding: 0;
  border: 0;
  -moz-box-sizing: content-box; }

/* line 175, ../sass/reset.scss */
a:active, a:focus, button:active, button:focus {
  outline: none; }

/* line 179, ../sass/reset.scss */
::-webkit-validation-bubble-message {
  display: none; }

@font-face {
  font-family: 'als_rublregular';
  src: url("../fonts/rouble-webfont.woff2") format("woff2"), url("../fonts/rouble-webfont.woff") format("woff"), url("../fonts/rouble-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-LightLF.eot");
  src: url("../fonts/FedraSansPro-LightLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-LightLF.woff") format("woff"), url("../fonts/FedraSansPro-LightLF.ttf") format("truetype"), url("../fonts/FedraSansPro-LightLF.svg#FedraSansPro-LightLF") format("svg"); }

@font-face {
  font-weight: 300;
  font-style: italic;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-LightItalicLF.eot");
  src: url("../fonts/FedraSansPro-LightItalicLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-LightItalicLF.woff") format("woff"), url("../fonts/FedraSansPro-LightItalicLF.ttf") format("truetype"), url("../fonts/FedraSansPro-LightItalicLF.svg#FedraSansPro-LightItalicLF") format("svg"); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-BookLF.eot");
  src: url("../fonts/FedraSansPro-BookLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-BookLF.woff") format("woff"), url("../fonts/FedraSansPro-BookLF.ttf") format("truetype"), url("../fonts/FedraSansPro-BookLF.svg#FedraSansPro-BookLF") format("svg"); }

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-BookItalicLF.eot");
  src: url("../fonts/FedraSansPro-BookItalicLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-BookItalicLF.woff") format("woff"), url("../fonts/FedraSansPro-BookItalicLF.ttf") format("truetype"), url("../fonts/FedraSansPro-BookItalicLF.svg#FedraSansPro-BookItalicLF") format("svg"); }

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-DemiLF.eot");
  src: url("../fonts/FedraSansPro-DemiLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-DemiLF.woff") format("woff"), url("../fonts/FedraSansPro-DemiLF.ttf") format("truetype"), url("../fonts/FedraSansPro-DemiLF.svg#FedraSansPro-DemiLF") format("svg"); }

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-DemiItalicLF.eot");
  src: url("../fonts/FedraSansPro-DemiItalicLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-DemiItalicLF.woff") format("woff"), url("../fonts/FedraSansPro-DemiItalicLF.ttf") format("truetype"), url("../fonts/FedraSansPro-DemiItalicLF.svg#FedraSansPro-DemiItalicLF") format("svg"); }

@font-face {
  font-weight: 600;
  font-style: normal;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-MediumLF.eot");
  src: url("../fonts/FedraSansPro-MediumLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-MediumLF.woff") format("woff"), url("../fonts/FedraSansPro-MediumLF.ttf") format("truetype"), url("../fonts/FedraSansPro-MediumLF.svg#FedraSansPro-MediumLF") format("svg"); }

@font-face {
  font-weight: 600;
  font-style: italic;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-MediumItalicLF.eot");
  src: url("../fonts/FedraSansPro-MediumItalicLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-MediumItalicLF.woff") format("woff"), url("../fonts/FedraSansPro-MediumItalicLF.ttf") format("truetype"), url("../fonts/FedraSansPro-MediumItalicLF.svg#FedraSansPro-MediumItalicLF") format("svg"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'Fedra Sans';
  src: url("../fonts/FedraSansPro-BoldLF.eot");
  src: url("../fonts/FedraSansPro-BoldLF.eot?#iefix") format("embedded-opentype"), url("../fonts/FedraSansPro-BoldLF.woff") format("woff"), url("../fonts/FedraSansPro-BoldLF.ttf") format("truetype"), url("../fonts/FedraSansPro-BoldLF.svg#FedraSansPro-BoldLF") format("svg"); }

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: 'SB Sans Display';
  src: url("../fonts/SB Sans Display.eot");
  src: url("../fonts/SB Sans Display.eot?#iefix") format("embedded-opentype"), url("../fonts/SB Sans Display.woff") format("woff"), url("../fonts/SB Sans Display.ttf") format("truetype"), url("../fonts/SB Sans Display.svg#SB Sans Display") format("svg"); }

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: 'SB Sans Display Bold';
  src: url("../fonts/SB Sans Display Bold.eot");
  src: url("../fonts/SB Sans Display Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/SB Sans Display Bold.woff") format("woff"), url("../fonts/SB Sans Display Bold.ttf") format("truetype"); }

.b-dropdown {
  font-size: 17px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
  color: #21A038;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  overflow: hidden; }
  .b-dropdown.b-dropdown__scroll .b-dropdown__text {
    height: 53px; }
  .b-dropdown.b-dropdown__scroll .b-dropdown__sub {
    max-height: 260px;
    overflow-y: scroll;
    margin-top: 1px; }
    .b-dropdown.b-dropdown__scroll .b-dropdown__sub li:first-child {
      border-top: none; }
  .b-dropdown.b-dropdown_open {
    z-index: 2; }
    .b-dropdown.b-dropdown_open .b-dropdown__text {
      background: rgba(0, 0, 0, 0.05); }
    .b-dropdown.b-dropdown_open.first-open .b-dropdown__sub {
      opacity: 1;
      visibility: visible;
      height: auto;
      border-radius: 6px; }

.b-dropdown_open .b-dropdown__sub {
  opacity: 1;
  visibility: visible;
  height: auto; }

.b-dropdown__sub {
  position: relative;
  overflow: auto;
  min-width: 100%;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background: #fff; }

.b-dropdown__item.active {
  background: rgba(0, 0, 0, 0.1); }

.b-dropdown__item,
.b-dropdown__text {
  display: block;
  padding: 13px 18px;
  background: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #21A038;
  transition: background .2s;
  cursor: pointer; }

.b-feedback__item .b-dropdown__item,
.b-feedback__item .b-dropdown__text {
  padding: 11px 18px; }

.b-dropdown__text {
  border: none;
  border-radius: 5px 5px 0 0;
  height: 53px;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative; }
  .b-dropdown__text:hover {
    color: #21A038; }

.b-dropdown > .b-dropdown__text {
  overflow: hidden;
  padding-right: 18px;
  text-overflow: ellipsis;
  display: none; }

.b-dropdown__item:first-child {
  border-radius: 5px 5px 0 0; }

.b-dropdown__item:last-child {
  border-radius: 0 0 5px 5px; }

.b-dropdown__item:hover,
.b-dropdown_open .b-dropdown__text:hover {
  color: #21A038;
  background: rgba(0, 0, 0, 0.05); }

.b-dropdown_deployed.b-dropdown_open .b-dropdown__arrow {
  display: none; }

@media only screen and (min-width: 1600px) {
  .b-dropdown {
    font-size: 19px; } }

@media only screen and (max-width: 1199px) {
  .b-dropdown {
    font-size: 15px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.u-clear-fix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

html {
  height: 100%; }
  html.overflow {
    overflow: hidden;
    height: 100%; }

sup {
  font-size: 70%; }

body {
  position: relative;
  font-family: "SB Sans Display" !important;
  font-size: 17px;
  line-height: 27px;
  color: #000;
  min-height: 100%;
  background: #f9f9f9;
  min-width: 1200px;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased; }
  body.overflow {
    overflow: hidden;
    height: 100%; }

.hidden {
  display: none !important; }

.b-rub {
  font-family: "SB Sans Display" !important;
  font-size: 90%; }

.b-nowrap {
  white-space: nowrap; }

a {
  transition: border-color .25s, color .25s;
  text-decoration: none; }
  a:hover .b-link {
    color: #21A038; }
    a:hover .b-link .b-link__text {
      border-bottom-color: rgba(33, 160, 56, 0.33); }
  a:hover {
    color: #21A038;
    border-bottom-color: rgba(33, 160, 56, 0.33); }
  a:active {
    color: #21A038;
    border-bottom-color: rgba(33, 160, 56, 0.33); }

.b-link {
  color: #21A038;
  transition: color .25s;
  font-size: 50%;
  border-bottom: none !important; }
  .b-link.b-link__not-line .b-link__text {
    border-bottom: none; }
  .b-link .b-link__text {
    font-size: 200%;
    padding: 0.3em 0 0;
    border-bottom: 1px solid rgba(33, 160, 56, 0.33); }
  .b-link:hover {
    color: #21A038; }
    .b-link:hover .b-link__text {
      border-bottom-color: rgba(33, 160, 56, 0.33); }
  .b-link:active {
    color: #21A038; }
    .b-link:active .b-link__text {
      border-bottom-color: rgba(33, 160, 56, 0.33); }

.b-button__box {
  position: relative; }
  .b-button__box.b-button__box_show {
    text-align: center; }
    .b-button__box.b-button__box_show .b-button {
      min-width: 250px; }

.b-button {
  display: inline-block;
  vertical-align: top;
  border: none;
  background: none;
  outline: none;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  border-radius: 28px;
  font-size: 19px;
  line-height: 29px;
  font-family: "SB Sans Display" !important;
  padding: 12px 21px 15px;
  color: #fff;
  border: 1px solid #21A038;
  background: #21A038;
  transition: background .25s, border-color .25s, color .25s;
  cursor: pointer;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
  .b-button:hover {
    box-shadow: none;
    color: #fff; }
  .b-button.b-button_border {
    background: transparent;
    color: #21A038;
    box-shadow: none; }
    .b-button.b-button_border:hover {
      background: transparent;
      color: #21A038; }
    .b-button.b-button_border.b-button_white {
      background: transparent;
      border-color: rgba(255, 255, 255, 0.3);
      color: #fff; }
      .b-button.b-button_border.b-button_white:hover {
        background: #fff;
        border-color: #fff;
        color: #21A038; }
  .b-button.b-button_white {
    border-color: #fff;
    background: #fff;
    color: #21A038;
    transition: background .25s, border-color .25s, color .25s, box-shadow .25s; }
    .b-button.b-button_white:hover {
      border-color: rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.8);
      color: #21A038;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.3); }
  .b-button.b-button_yellow {
    border-color: #FAED00;
    background: #FAED00;
    color: #000; }
    .b-button.b-button_yellow:hover {
      border-color: rgba(255, 202, 74, 0.8);
      background: rgba(255, 202, 74, 0.8); }
  .b-button.b-button_small {
    height: 46px;
    padding: 7px 15px 10px; }
  .b-button.b-button_radius {
    border-radius: 5px; }
  .b-button[disabled="disabled"] {
    opacity: .3;
    cursor: default; }

.b-wrapper {
  width: 1050px;
  margin: auto;
  position: relative; }

h1, h2, h3, h4, h5, h6 {
  font-family: "SB Sans Display Bold";
  font-weight: bold;
  position: relative;
  margin-bottom: 19px; }

h1 {
  font-size: 55px;
  line-height: 65px; }

h2 {
  font-size: 45px;
  line-height: 55px;
  margin-bottom: 35px; }

h3 {
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 29px; }

h4 {
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 16px; }

h5 {
  font-size: 25px;
  line-height: 35px; }

h6 {
  font-size: 19px;
  line-height: 29px;
  margin-bottom: 10px; }

.b-text__wrapper + .b-video-block {
  margin-top: 40px; }

.b-video-block {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 5px; }
  .b-video-block.played .b-video-block__overlay {
    opacity: 0; }
  .b-video-block.played iframe {
    opacity: 1; }
  .b-video-block + .b-text__wrapper {
    margin-top: 45px; }
  .b-video-block .b-video-block__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    z-index: 1;
    transition: opacity .25s; }
  .b-video-block .b-video-block__play {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: -45px 0 0 -45px;
    background: #21A038;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border: none;
    transition: background .25s; }
    .b-video-block .b-video-block__play:hover {
      background: #21A038; }
    .b-video-block .b-video-block__play:before {
      content: "";
      position: absolute;
      left: 55%;
      top: 50%;
      width: 23px;
      height: 31px;
      margin: -15px 0 0 -12px;
      background: url("../i/new/i-video-play.svg") no-repeat; }
  .b-video-block iframe {
    width: 100%;
    height: 395px;
    opacity: 1;
    transition: opacity .25s;
    display: block;
    z-index: 2;
    position: relative; }

.b-main-title__i-list {
  position: relative;
  margin-right: -10%;
  padding-top: 25px; }
  .b-main-title__i-list li {
    float: left;
    vertical-align: top;
    position: relative;
    width: 55%;
    box-sizing: border-box;
    padding: 10px 10% 10px 100px;
    margin-bottom: 17px;
    min-height: 74px; }
    .b-main-title__i-list li:nth-child(even) {
      width: 45%;
      padding-right: 0; }
    .b-main-title__i-list li .b-main-title__i-list_text {
      height: 54px;
      display: table-cell;
      vertical-align: middle; }
      .b-main-title__i-list li .b-main-title__i-list_text span {
        display: inline-block;
        vertical-align: top; }
    .b-main-title__i-list li .b-main-title__i-list_pic {
      width: 74px;
      height: 74px;
      position: absolute;
      left: 0;
      top: 0; }
    .b-main-title__i-list li img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.b-text__wrapper {
  margin-left: 70px;
  position: relative; }
  .b-text__wrapper .b-news-article-contents {
    padding-top: 20px; }
  .b-text__wrapper .b-accordion__text .b-text__img {
    margin-left: 0; }
  .b-text__wrapper .b-text__img {
    position: relative;
    text-align: center;
    margin: 20px 0 20px -70px; }
    .b-text__wrapper .b-text__img .b-text__img_zoom {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 88px;
      height: 88px;
      margin: -44px 0 0 -44px;
      border-radius: 50%;
      background: #21A038;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
      display: none; }
      .b-text__wrapper .b-text__img .b-text__img_zoom:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 47px;
        height: 46px;
        margin: -23px 0 0 -23px;
        background: url("../i/new/i-pic-zoom.png") no-repeat; }
    .b-text__wrapper .b-text__img img {
      display: inline-block;
      vertical-align: top;
      margin: 0; }
  .b-text__wrapper img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 20px 0; }
  .b-text__wrapper .b-table {
    margin-left: -70px;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    padding: 0 30px;
    background: #fff;
    margin-top: -21px; }
  .b-text__wrapper h3 {
    margin-bottom: 3px;
    margin-top: 30px; }
    .b-text__wrapper h3:first-child {
      margin-top: 0; }
  .b-text__wrapper table {
    width: 100%; }
    .b-text__wrapper table thead {
      font-size: 19px;
      font-weight: bold;
      font-family: "SB Sans Display Bold"; }
      .b-text__wrapper table thead td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 21px !important; }
    .b-text__wrapper table tr:first-child td {
      font-size: 19px;
      font-weight: bold;
      font-family: "SB Sans Display Bold";
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 21px !important; }
    .b-text__wrapper table tr.b-table__border td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 21px; }
    .b-text__wrapper table tr:last-child td {
      padding-bottom: 47px; }
    .b-text__wrapper table td {
      text-align: right;
      padding: 21px 0 5px 25px; }
      .b-text__wrapper table td:first-child {
        text-align: left;
        padding-left: 0; }
  .b-text__wrapper b, .b-text__wrapper strong {
    font-weight: bold;
    font-family: "SB Sans Display Bold"; }
  .b-text__wrapper ul {
    padding-top: 7px;
    margin-bottom: 30px; }
    .b-text__wrapper ul:last-child {
      margin-bottom: 0; }
    .b-text__wrapper ul.b-ul li {
      list-style: none;
      margin-left: 0;
      padding-left: 39px; }
      .b-text__wrapper ul.b-ul li:before {
        content: "";
        position: absolute;
        left: 0;
        top: -1px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: url("../i/new/i-ul-text.svg") no-repeat center;
        background-size: 15px 10px;
        font-size: 19px;
        line-height: 27px;
        text-align: center;
        display: block; }
    .b-text__wrapper ul.b-docs__list {
      padding-top: 0; }
      .b-text__wrapper ul.b-docs__list li:before {
        display: none; }
    .b-text__wrapper ul li {
      position: relative;
      margin-bottom: 17px !important;
      list-style: none;
      margin-left: 0;
      padding-left: 39px; }
      .b-text__wrapper ul li:before {
        content: "";
        position: absolute;
        left: 0;
        top: -6px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: url("../i/new/i-ul-text.svg") no-repeat center;
        background-size: 15px 10px;
        font-size: 19px;
        line-height: 27px;
        text-align: center;
        display: block; }
      .b-text__wrapper ul li.noBefore:before {
        display: none !important; }
      .b-text__wrapper ul li:last-child {
        margin-bottom: 0; }
  .b-text__wrapper p {
    margin-bottom: 13px;
    text-align: left; }
    .b-text__wrapper p:last-child {
      margin-bottom: 0; }
  .b-text__wrapper u {
    text-decoration: none; }
  .b-text__wrapper ol {
    counter-reset: ol-list;
    padding-top: 7px;
    margin-bottom: 30px; }
    .b-text__wrapper ol ul {
      margin-top: 20px;
      font-size: 17px;
      line-height: 27px; }
      .b-text__wrapper ol ul li {
        padding-left: 39px;
        padding-bottom: 17px; }
        .b-text__wrapper ol ul li:before {
          content: "";
          top: -1px;
          counter-increment: none; }
    .b-text__wrapper ol li {
      padding-left: 39px;
      padding-bottom: 26px;
      position: relative; }
      .b-text__wrapper ol li:last-child {
        padding-bottom: 0; }
      .b-text__wrapper ol li:before {
        counter-increment: ol-list;
        content: counters(ol-list, ".");
        position: absolute;
        left: 0;
        top: 1px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 19px;
        line-height: 27px;
        text-align: center; }

.b-input__box {
  position: relative; }

.b-input {
  display: inline-block;
  vertical-align: top;
  border: none;
  background: #fff;
  padding: 13px 18px;
  width: 100%;
  height: 53px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  font-family: "SB Sans Display" !important;
  font-size: 17px;
  line-height: 27px;
  color: #000;
  font-weight: normal;
  box-sizing: border-box;
  outline: none;
  z-index: 3;
  position: relative;
  -webkit-appearance: none;
  appearance: none; }
  .b-input.unactive {
    opacity: .8; }

textarea {
  resize: none; }

.b-content {
  position: relative; }
  .b-content.z-index {
    z-index: 22; }
  .b-content .b-first-section {
    position: relative;
    padding-top: 165px; }
    .b-content .b-first-section .b-main-title h1 {
      margin-bottom: 29px; }

.b-text__bigger {
  font-size: 19px;
  line-height: 29px; }

.b-main-title {
  margin-bottom: 19px; }

.b-bg {
  position: relative;
  background: #3d4b46;
  color: #fff; }
  .b-bg .b-bg__top_overflow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden; }
  .b-bg .b-bg {
    background: rgba(61, 75, 70, 0.5);
    overflow: visible; }
  .b-bg .b-calc__result {
    background: rgba(58, 172, 106, 0.85); }
    .b-bg .b-calc__result .b-calc__result_pic {
      display: none; }
  .b-bg .b-bg__pic {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-size: cover;
    background-position: center;
    opacity: .6; }
  .b-bg .b-text__wrapper ul li:before {
    background-image: url("../i/new/i-ul-text-white.svg"); }

.b-careers.b-text__wrapper ul li {
  padding-left: 39px !important; }

.b-main-tabs__section {
  position: relative; }
  .b-main-tabs__section .b-main-tabs__tabs {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: -20px;
    font-size: 0;
    line-height: 0;
    width: 100%; }
    .b-main-tabs__section .b-main-tabs__tabs li {
      display: inline-block;
      vertical-align: top;
      padding-right: 30px;
      height: 100%; }
      .b-main-tabs__section .b-main-tabs__tabs li:last-child {
        padding-right: 0; }
    .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link {
      display: inline-block;
      vertical-align: top;
      border-bottom: none;
      padding: 20px 0;
      height: 100%;
      position: relative;
      font-size: 19px;
      line-height: 29px; }
      .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab {
        padding-left: 70px;
        font-size: 23px;
        line-height: 35px;
        padding-bottom: 40px; }
        .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab:hover i {
          background: #21A038; }
        .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab.b-custom-tab_sbs i:before {
          width: 23px;
          height: 29px;
          background-image: url("../i/new/i-activation-sbs.png"); }
        .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab.b-custom-tab_sbsg i:before {
          width: 28px;
          height: 23px;
          top: 52%;
          left: 52%;
          background-image: url("../i/new/i-activation-sbsg.png"); }
        .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab i {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -42px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #21A038;
          box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.2);
          transition: background .25s, box-shadow .25s; }
          .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab i:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-position: 0 0; }
        .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab.active i {
          background: rgba(0, 0, 0, 0.1);
          box-shadow: none; }
          .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.b-custom-tab.active i:before {
            background-position: -50px 0; }
      .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.active {
        cursor: default;
        color: #000; }
        .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link.active:before {
          opacity: 1; }
      .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link:before {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        height: 5px;
        background: #21A038;
        transition: opacity .25s;
        opacity: 0; }
    .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_arrow {
      display: none;
      width: 36px;
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      bottom: 0;
      background: transparent url(../i/new/i-accordion.svg) no-repeat center;
      background-size: 16px 7px;
      transform: rotate(180deg);
      border: none;
      transition: transform .25s; }
  .b-main-tabs__section .b-main-tabs__result_box {
    position: relative;
    padding-top: 30px; }
    .b-main-tabs__section .b-main-tabs__result_box .b-main-tabs__result {
      position: relative;
      display: none; }
      .b-main-tabs__section .b-main-tabs__result_box .b-main-tabs__result.active {
        display: block; }

.b-bg .b-accordion.b-accordion__list .b-accordion__item:before,
.b-product-section__insurance .b-accordion.b-accordion__list .b-accordion__item:before {
  border-color: rgba(255, 255, 255, 0.3); }

.b-bg .b-accordion.b-accordion__list .b-accordion__link,
.b-product-section__insurance .b-accordion.b-accordion__list .b-accordion__link {
  color: #fff; }
  .b-bg .b-accordion.b-accordion__list .b-accordion__link:hover,
  .b-product-section__insurance .b-accordion.b-accordion__list .b-accordion__link:hover {
    color: rgba(255, 255, 255, 0.5); }
  .b-bg .b-accordion.b-accordion__list .b-accordion__link:after,
  .b-product-section__insurance .b-accordion.b-accordion__list .b-accordion__link:after {
    border-color: rgba(255, 255, 255, 0.3);
    background: transparent url("../i/new/i-accordion-white.svg") no-repeat center 45%;
    background-size: 16px 7px; }

.b-text__wrapper .b-accordion {
  padding-top: 0; }
  .b-text__wrapper .b-accordion li:before {
    display: none; }

.b-accordion {
  position: relative; }
  .b-accordion.b-accordion__questions .b-accordion__link {
    padding-left: 35px; }
    .b-accordion.b-accordion__questions .b-accordion__link:hover:before {
      opacity: .8; }
    .b-accordion.b-accordion__questions .b-accordion__link:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      background: url("../i/new/i-question.svg") no-repeat;
      opacity: .3;
      transition: opacity .25s; }
  .b-accordion.b-accordion__questions .b-accordion__text {
    padding-left: 35px; }
  .b-accordion.b-accordion__list {
    counter-reset: accord-list;
    position: relative; }
    .b-accordion.b-accordion__list .b-accordion__link {
      color: #21A038; }
      .b-accordion.b-accordion__list .b-accordion__link:hover {
        color: #21A038; }
    .b-accordion.b-accordion__list .b-accordion__link,
    .b-accordion.b-accordion__list .b-accordion__text {
      padding-left: 42px;
      margin-left: -42px; }
    .b-accordion.b-accordion__list .b-accordion__item {
      padding-left: 57px; }
      .b-accordion.b-accordion__list .b-accordion__item:before {
        counter-increment: accord-list;
        content: counters(accord-list, ".");
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        line-height: 28px;
        text-align: center;
        position: absolute;
        left: 15px;
        top: 10px;
        box-sizing: border-box;
        transition: border-color .25s;
        display: block;
        background: none;
        color: #21A038; }
      .b-accordion.b-accordion__list .b-accordion__item.active:before, .b-accordion.b-accordion__list .b-accordion__item.first-active:before {
        color: #21A038;
        border-color: rgba(33, 160, 56, 0.33); }
    .b-accordion.b-accordion__list .b-accordion__link {
      padding-right: 50px; }
      .b-accordion.b-accordion__list .b-accordion__link:after {
        opacity: 1;
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        box-sizing: border-box;
        top: -1px;
        background: transparent url("../i/new/i-accordion.svg") no-repeat center 45%;
        background-size: 16px 7px;
        transition: border-color .25s, backgorund .25s, transform .25s; }
  .b-accordion.b-ul {
    margin-left: -15px; }
    .b-accordion.b-ul .b-accordion__item {
      padding-left: 54px; }
      .b-accordion.b-ul .b-accordion__item:before {
        content: "";
        position: absolute;
        left: 15px;
        top: 12px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: url("../i/new/i-ul-text.svg") no-repeat center;
        background-size: 15px 10px;
        font-size: 19px;
        line-height: 27px;
        text-align: center;
        display: block; }
  .b-accordion .b-accordion__item {
    list-style: none;
    margin-left: -15px;
    margin-top: -8px;
    margin-bottom: 13px;
    padding: 13px 20px 0 15px;
    border-radius: 5px;
    background: transparent;
    transition: background .25s, box-shadow .25s; }
    .b-accordion .b-accordion__item.active, .b-accordion .b-accordion__item.first-active {
      background: #fff;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
      .b-accordion .b-accordion__item.active .b-accordion__link:after, .b-accordion .b-accordion__item.first-active .b-accordion__link:after {
        transform: rotate(0deg);
        opacity: 1; }
    .b-accordion .b-accordion__item.first-active .b-accordion__text {
      display: block; }
    .b-accordion .b-accordion__item .b-accordion__text ul li:before {
      display: block; }
  .b-accordion .b-accordion__text {
    display: none;
    padding-top: 13px;
    padding-bottom: 18px; }
    .b-accordion .b-accordion__text img {
      display: block;
      max-width: 100%;
      height: auto; }
  .b-accordion .b-accordion__link {
    display: block;
    border: none;
    position: relative;
    padding-right: 30px;
    cursor: pointer;
    color: #21A038; }
    .b-accordion .b-accordion__link:hover {
      color: #21A038; }
      .b-accordion .b-accordion__link:hover:after {
        opacity: 1; }
    .b-accordion .b-accordion__link:after {
      content: "";
      position: absolute;
      right: 0;
      top: 10px;
      width: 16px;
      height: 7px;
      background: url("../i/new/i-accordion.svg") no-repeat;
      transform: rotate(180deg);
      opacity: 0;
      transition: transform .25s, opacity .25s; }

.b-main-tabs__result .b-text__wrapper {
  padding-top: 18px; }

.b-footer-products__who {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 16px;
  line-height: 25px;
  padding: 26px 0 26px 37px;
  margin-bottom: 37px;
  margin-top: 24px;
  position: relative;
  color: rgba(0, 0, 0, 0.4); }
  .b-footer-products__who:before {
    content: "";
    position: absolute;
    left: 0;
    top: 26px;
    width: 25px;
    height: 25px;
    background: url("../i/new/sber-whois-product.svg") no-repeat; }

.b-footer-products__section {
  position: relative; }

.b-footer-products__carousel {
  position: relative;
  margin: 0 -9px 45px;
  padding-bottom: 12px; }
  .b-footer-products__carousel .slick-list {
    padding: 5px 5px 40px;
    margin-left: -5px;
    margin-right: -5px; }

.b-footer-products {
  height: 495px;
  float: left;
  vertical-align: top;
  margin: 0 5px;
  position: relative;
  padding: 42px 32px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: transparent;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 7px;
  outline: none;
  overflow: hidden;
  opacity: 0;
  transition: border-color .25s, background .25s, box-shadow .25s, opacity .25s; }
  .b-footer-products.slick-active {
    opacity: 1; }
  .b-footer-products:hover {
    background: #fff;
    border-color: #fff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
    .b-footer-products:hover .b-button__box {
      opacity: 1; }
    .b-footer-products:hover .b-footer-products__bottom_pic {
      opacity: 1;
      transform: scale(1); }
    .b-footer-products:hover .b-footer-products__price {
      color: #fff; }
  .b-footer-products .b-link .b-link__text {
    border-bottom: none; }
  .b-footer-products .b-link:hover .b-link__text {
    border-bottom-color: transparent; }
  .b-footer-products .b-footer-products__wrapper {
    overflow: hidden;
    max-height: 286px; }
  .b-footer-products .b-footer-products__text {
    opacity: .5;
    text-overflow: ellipsis;
    width: 90%; }
    .b-footer-products .b-footer-products__text ul {
      margin-bottom: 15px; }
      .b-footer-products .b-footer-products__text ul li {
        position: relative;
        margin-bottom: 13px;
        padding-left: 15px; }
        .b-footer-products .b-footer-products__text ul li:last-child {
          margin-bottom: 0; }
        .b-footer-products .b-footer-products__text ul li:before {
          content: "-";
          position: absolute;
          left: 0;
          top: 0; }
      .b-footer-products .b-footer-products__text ul:last-child {
        margin-bottom: 0; }
    .b-footer-products .b-footer-products__text p {
      margin-bottom: 13px; }
      .b-footer-products .b-footer-products__text p:last-child {
        margin-bottom: 0; }
  .b-footer-products .b-footer-products__bottom {
    position: absolute;
    left: 35px;
    right: 35px;
    bottom: 38px; }
  .b-footer-products .b-footer-products__bottom_pic {
    width: 220px;
    height: 220px;
    position: absolute;
    top: -62px;
    right: -97px;
    border-radius: 50%;
    background: #21A038;
    overflow: hidden;
    transform: scale(0);
    opacity: 0;
    transform-origin: right bottom;
    transition: opacity .25s, transform .25s; }
    .b-footer-products .b-footer-products__bottom_pic span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: right bottom;
      background-size: cover;
      opacity: .6; }
  .b-footer-products .b-button__box {
    float: left;
    vertical-align: top;
    opacity: 0;
    margin-top: -16px;
    margin-left: -15px;
    transition: opacity .25s; }
    .b-footer-products .b-button__box .b-button {
      padding-left: 15px;
      padding-right: 15px; }
  .b-footer-products .b-footer-products__price {
    padding-top: 8px;
    float: right;
    vertical-align: top;
    font-size: 19px;
    line-height: 29px;
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    position: relative;
    transition: color .25s; }
    .b-footer-products .b-footer-products__price .b-rub {
      font-weight: normal; }

.slick-slider .slick-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 0;
  line-height: 0;
  text-align: center; }
  .slick-slider .slick-dots li {
    display: inline-block;
    vertical-align: top;
    margin: 0 3px; }
    .slick-slider .slick-dots li.slick-active button {
      cursor: default;
      background: rgba(0, 0, 0, 0.1); }
    .slick-slider .slick-dots li button {
      background: #21A038;
      width: 10px;
      height: 10px;
      margin: 0;
      padding: 0;
      color: transparent;
      outline: none;
      border: none;
      border-radius: 50%;
      transition: background .25s;
      cursor: pointer; }
      .slick-slider .slick-dots li button:hover {
        background: #21A038; }

.slick-slider .slick-arrow {
  width: 54px;
  height: 54px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  color: transparent;
  background: #21A038;
  border: none;
  outline: none;
  transition: background .25s;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  z-index: 10; }
  .slick-slider .slick-arrow:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 25px;
    background: url("../i/new/i-slick-white.svg") no-repeat;
    opacity: .7;
    transition: opacity .25s; }
  .slick-slider .slick-arrow:hover {
    background: #fff; }
    .slick-slider .slick-arrow:hover:before {
      background: url("../i/new/i-slick-green.svg") no-repeat; }
  .slick-slider .slick-arrow.slick-prev {
    left: -62px; }
  .slick-slider .slick-arrow.slick-next {
    right: -62px; }
    .slick-slider .slick-arrow.slick-next:before {
      transform: translate(-50%, -50%) rotate(180deg); }

.b-information__section {
  position: relative;
  margin-bottom: 42px; }
  .b-information__section .b-information__tel {
    float: left;
    vertical-align: top;
    margin-right: 55px; }
    .b-information__section .b-information__tel h2 {
      margin-bottom: 0; }
    .b-information__section .b-information__tel span {
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: "SB Sans Display Bold";
      display: block;
      margin-bottom: 2px; }
    .b-information__section .b-information__tel a {
      font-family: "SB Sans Display Bold";
      border: none;
      color: #fff; }
  .b-information__section .b-social__list {
    float: left;
    vertical-align: top;
    padding-top: 32px;
    padding-left: 10px; }

.b-social__list {
  position: relative; }
  .b-social__list .b-social__item {
    float: left;
    vertical-align: top;
    margin-right: 10px; }
    .b-social__list .b-social__item .b-social {
      width: 40px;
      height: 40px;
      border: none;
      display: block;
      background: url("../i/new/social-sprite.svg") no-repeat 100% 100%;
      background-size: 200px 40px;
      transition: opacity .25s; }
      .b-social__list .b-social__item .b-social:hover {
        opacity: .7; }
      .b-social__list .b-social__item .b-social.b-social_fb {
        background-position: 0 0; }
      .b-social__list .b-social__item .b-social.b-social_tw {
        background-position: -50px 0; }
      .b-social__list .b-social__item .b-social.b-social_vk {
        background-position: -100px 0; }
      .b-social__list .b-social__item .b-social.b-social_ins {
        background-position: -150px 0; }
    .b-social__list .b-social__item:last-child {
      margin-right: 0; }

.b-footer {
  position: relative;
  background: #21A038;
  padding: 55px 0 0;
  font-size: 16px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.6); }
  .b-footer.time-with-benefits {
    display: none; }
  .b-footer-black {
    background: #171a1e; }
  .b-footer a {
    color: rgba(255, 255, 255, 0.8);
    border-bottom-color: rgba(255, 255, 255, 0.33); }
    .b-footer a:hover {
      color: #fff;
      border-bottom-color: rgba(255, 255, 255, 0.6); }
  .b-footer:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 95px;
    opacity: .1;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#002619+0,002619+100&1+0,0+100 */
    background: -moz-linear-gradient(top, #002619 0%, rgba(0, 38, 25, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #002619 0%, rgba(0, 38, 25, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #002619 0%, rgba(0, 38, 25, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002619', endColorstr='#00002619',GradientType=0 );
    /* IE6-9 */ }
  .b-footer .b-footer__columns_box {
    position: relative;
    padding-bottom: 45px; }
  .b-footer .b-footer__columns {
    float: left;
    vertical-align: top;
    width: 260px;
    position: relative;
    box-sizing: border-box;
    padding-right: 20px; }
    .b-footer .b-footer__columns .b-footer__title {
      padding-top: 1px;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      font-weight: bold;
      font-family: "SB Sans Display Bold";
      display: block;
      margin-bottom: 18px; }
    .b-footer .b-footer__columns ul {
      margin-bottom: 34px; }
      .b-footer .b-footer__columns ul:last-child {
        margin-bottom: 0; }
      .b-footer .b-footer__columns ul li {
        margin-bottom: 19px; }
        .b-footer .b-footer__columns ul li:last-child {
          margin-bottom: 0; }
  .b-footer .b-licence {
    float: right;
    vertical-align: top;
    width: 215px;
    font-size: 12px;
    line-height: 15px;
    padding-top: 3px; }
    .b-footer .b-licence li {
      margin-bottom: 38px; }
      .b-footer .b-licence li:last-child {
        margin-bottom: 0; }
    .b-footer .b-licence .b-licence__title {
      line-height: 19px;
      color: rgba(255, 255, 255, 0.5);
      display: block;
      margin-bottom: 18px;
      font-size: 16px; }
  .b-footer .b-footer__agreement {
    margin-bottom: 27px; }
    .b-footer .b-footer__agreement .b-footer__agreement_link {
      float: left;
      vertical-align: top;
      margin-right: 30px;
      margin-bottom: 10px;
      padding-left: 29px;
      position: relative; }
      .b-footer .b-footer__agreement .b-footer__agreement_link:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 15px;
        height: 17px;
        background: url("/static/i/new/i-footer-agreement.png") no-repeat; }
      .b-footer .b-footer__agreement .b-footer__agreement_link .b-link__text {
        border-bottom-color: rgba(255, 255, 255, 0.33); }
      .b-footer .b-footer__agreement .b-footer__agreement_link:hover .b-link__text {
        border-bottom-color: white; }
      .b-footer .b-footer__agreement .b-footer__agreement_link:last-child {
        margin-right: 0; }
    .b-footer .b-footer__agreement .b-footer__agreement_link2 {
      float: none;
      display: block;
      width: 100%;
      vertical-align: top;
      margin-right: 30px;
      margin-bottom: 10px;
      padding-left: 29px;
      position: relative;
      cursor: pointer; }
      .b-footer .b-footer__agreement .b-footer__agreement_link2:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 15px;
        height: 17px;
        background: url("/static/i/new/i-amount-plus.png") no-repeat;
        background-position-x: -50px; }
      .b-footer .b-footer__agreement .b-footer__agreement_link2 .b-link_dn {
        position: relative;
        display: none; }
        .b-footer .b-footer__agreement .b-footer__agreement_link2 .b-link_dn:before {
          content: "";
          position: absolute;
          left: -29px;
          top: 5px;
          width: 15px;
          height: 17px;
          background: url("/static/i/new/i-footer-agreement.png") no-repeat; }
      .b-footer .b-footer__agreement .b-footer__agreement_link2.active .b-link_dn {
        display: inline-block; }
      .b-footer .b-footer__agreement .b-footer__agreement_link2.b-link__text {
        color: #fff; }
      .b-footer .b-footer__agreement .b-footer__agreement_link2:hover .b-link__text {
        border-bottom-color: white; }
      .b-footer .b-footer__agreement .b-footer__agreement_link2:last-child {
        margin-right: 0; }
  .b-footer .b-footer__bottom {
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 23px 0 27px;
    text-align: center; }
    .b-footer .b-footer__bottom .b-footer__bottom_copy {
      float: left;
      vertical-align: top;
      color: #fff;
      text-align: left; }
    .b-footer .b-footer__bottom .b-footer__bottom_made {
      float: right;
      vertical-align: top;
      text-align: left; }
      .b-footer .b-footer__bottom .b-footer__bottom_made .b-footer__bottom_made-BA {
        display: block;
        font-size: 13px;
        margin-top: 5px; }
        .b-footer .b-footer__bottom .b-footer__bottom_made .b-footer__bottom_made-BA a {
          font-size: 15px; }
    .b-footer .b-footer__bottom .b-footer__bottom_middle {
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 15px;
      padding-top: 6px; }

.b-refer-manual {
  position: relative; }
  .b-refer-manual a {
    display: inline-block;
    vertical-align: top;
    height: 43px;
    font-size: 16px;
    line-height: 23px;
    padding: 10px 17px 10px 13px;
    background: #fff;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    color: #21A038;
    transition: color .25s;
    position: relative;
    white-space: nowrap; }
    .b-refer-manual a:before {
      content: "";
      position: absolute;
      right: 100%;
      bottom: -1px;
      width: 6px;
      height: 10px;
      background: url("../i/new/i-footer-button.png") no-repeat; }
    .b-refer-manual a:hover {
      color: #21A038; }

.b-text__wrapper .b-docs__list {
  position: relative;
  margin-top: -24px; }
  .b-text__wrapper .b-docs__list li {
    float: left;
    vertical-align: top;
    width: 47%;
    box-sizing: border-box;
    margin-right: 6%;
    margin-top: 24px;
    margin-left: 0;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
    .b-text__wrapper .b-docs__list li:nth-child(even) {
      margin-right: 0; }
    .b-text__wrapper .b-docs__list li a {
      display: block;
      border: none;
      min-height: 50px;
      padding: 1px 0 0 58px;
      position: relative; }
      .b-text__wrapper .b-docs__list li a span {
        display: block;
        color: rgba(0, 0, 0, 0.4);
        font-size: 13px;
        line-height: 16px;
        margin-top: 5px; }
      .b-text__wrapper .b-docs__list li a:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 41px;
        height: 50px;
        background: url("../i/new/i-pdf.svg") no-repeat;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.08); }

.b-ins-case__tab-box {
  position: relative;
  padding-top: 25px;
  padding-bottom: 60px; }
  .b-ins-case__tab-box .b-ins-case__tab-box_result {
    float: left;
    vertical-align: top;
    width: 73%;
    margin-top: 10px;
    padding-top: 1px;
    padding-right: 380px; }
    .b-ins-case__tab-box .b-ins-case__tab-box_result .b-ins-case__tab-box_result-error {
      padding: 30px 0 0 45px; }
    .b-ins-case__tab-box .b-ins-case__tab-box_result .b-ins-case__tab-box_text {
      display: none;
      padding-left: 45px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      min-height: 240px;
      box-sizing: border-box; }
    .b-ins-case__tab-box .b-ins-case__tab-box_result ul li {
      padding-top: 12px; }
      .b-ins-case__tab-box .b-ins-case__tab-box_result ul li:first-child {
        padding-top: 0; }
    .b-ins-case__tab-box .b-ins-case__tab-box_result ul a {
      border-bottom: none; }
  .b-ins-case__tab-box .b-header-main__nav {
    float: left;
    vertical-align: top;
    width: 27%;
    margin: 0;
    padding: 0; }
    .b-ins-case__tab-box .b-header-main__nav:before, .b-ins-case__tab-box .b-header-main__nav:after {
      display: none; }
  .b-ins-case__tab-box .b-header-main__link {
    border-bottom: none;
    display: block;
    position: relative; }
    .b-ins-case__tab-box .b-header-main__link.active {
      color: #000;
      cursor: default; }
      .b-ins-case__tab-box .b-header-main__link.active:after {
        opacity: 1; }
    .b-ins-case__tab-box .b-header-main__link:after {
      content: "";
      position: absolute;
      right: -3px;
      width: 5px;
      height: 35px;
      top: 50%;
      transform: translate(0, -50%);
      background: #21A038;
      opacity: 0; }

.b-ins-case__second {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  display: none; }
  .b-ins-case__second .b-ins-case__second_button {
    position: fixed;
    right: 0;
    top: 50%;
    margin-top: -140px;
    width: 280px;
    height: 280px;
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    padding: 112px 50px 0;
    box-sizing: border-box;
    background: #21A038;
    color: #fff;
    border: none;
    border-radius: 50%;
    transition: background .25s; }
    .b-ins-case__second .b-ins-case__second_button:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 25px;
      margin-left: -36px;
      width: 72px;
      height: 82px;
      background: url(../i/new/i-ins-case.svg) no-repeat;
      background-size: cover; }
    .b-ins-case__second .b-ins-case__second_button:hover {
      background: #21A038; }
  .b-ins-case__second h2:after {
    display: none; }
  .b-ins-case__second .b-bg__pic {
    position: fixed;
    transform: translate(0, 0) !important;
    -webkit-transform: translateZ(0) !important; }

.b-activation__list {
  font-size: 19px;
  line-height: 29px;
  width: 625px; }
  .b-activation__list li {
    margin-bottom: 13px; }
    .b-activation__list li:last-child {
      margin-bottom: 0; }
  .b-activation__list a {
    border-bottom: none; }

.b-activation__first {
  padding-top: 45px;
  padding-bottom: 60px;
  position: relative; }
  .b-activation__first .b-button__box {
    margin-bottom: 18px; }
  .b-activation__first .b-button {
    padding-left: 63px; }
    .b-activation__first .b-button:before {
      content: "";
      position: absolute;
      left: 22px;
      top: 50%;
      width: 29px;
      height: 30px;
      margin-top: -15px;
      background: url("../i/new/i-activate-button.png") no-repeat; }
  .b-activation__first .b-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    z-index: 3; }
  .b-activation__first .b-text__wrapper {
    margin: 0 auto;
    padding-bottom: 50px;
    width: 670px; }
    .b-activation__first .b-text__wrapper.b-activation__first_text p {
      margin-left: 85px; }
    .b-activation__first .b-text__wrapper ul {
      margin-bottom: 40px; }
    .b-activation__first .b-text__wrapper .column li {
      float: left;
      vertical-align: top;
      width: 50%;
      box-sizing: border-box;
      padding-right: 5%; }
    .b-activation__first .b-text__wrapper h3 {
      margin-bottom: 20px; }
  .b-activation__first .b-activation__first_faq {
    position: absolute;
    top: 50%;
    right: 13%;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background: #21A038;
    color: #fff;
    text-align: center;
    margin-top: -140px;
    box-sizing: border-box;
    padding: 130px 20px 0;
    border: none;
    transition: background .25s;
    z-index: 4;
    -webkit-transform: translateZ(0); }
    .b-activation__first .b-activation__first_faq:hover {
      background: #21A038; }
    .b-activation__first .b-activation__first_faq:before {
      content: "";
      position: absolute;
      top: 42px;
      left: 50%;
      margin-left: -51px;
      width: 103px;
      height: 74px;
      background: url("../i/new/i-activation-first.png") no-repeat; }

.b-activation__tabs {
  position: relative;
  padding-top: 10px;
  z-index: 1; }
  .b-activation__tabs .b-main-tabs__result_box {
    background: #fff;
    padding-bottom: 10px;
    margin-bottom: 42px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); }
  .b-activation__tabs .b-main-tabs__section .b-main-tabs__tabs {
    border-bottom: none; }
    .b-activation__tabs .b-main-tabs__section .b-main-tabs__tabs li {
      padding-right: 50px; }
      .b-activation__tabs .b-main-tabs__section .b-main-tabs__tabs li:last-child {
        padding-right: 0; }

.b-activation__second {
  display: none; }
  .b-activation__second .b-activation__back {
    position: absolute;
    top: 50px;
    left: -100px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #21A038;
    transition: background .25s; }
    .b-activation__second .b-activation__back:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 27px;
      height: 26px;
      margin: -13px 0 0 -13px;
      background: url("../i/new/i-back-step.png") no-repeat; }
    .b-activation__second .b-activation__back:hover {
      background: #21A038; }
  .b-activation__second .b-activation__title {
    margin-right: 290px; }
    .b-activation__second .b-activation__title p {
      width: 80%; }
  .b-activation__second .b-activation__form {
    position: relative;
    margin-top: 24px; }
    .b-activation__second .b-activation__form .b-button__box {
      position: absolute;
      left: 100%;
      top: -110px;
      margin-left: 35px; }
      .b-activation__second .b-activation__form .b-button__box .b-button {
        width: 280px;
        height: 280px;
        border-radius: 50%;
        text-align: center;
        font-size: 25px;
        line-height: 35px;
        font-weight: bold;
        font-family: "SB Sans Display Bold";
        box-sizing: border-box;
        padding-top: 62px; }
        .b-activation__second .b-activation__form .b-button__box .b-button:before {
          content: "";
          position: absolute;
          width: 68px;
          height: 66px;
          background: url("../i/new/i-activation-button.png") no-repeat;
          top: 70px;
          left: 50%;
          margin-left: -34px; }
    .b-activation__second .b-activation__form .b-checkbox__section {
      margin: 40px 0;
      font-size: 19px;
      line-height: 29px; }
      .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__label {
        padding-right: 70px;
        padding-left: 51px; }
        .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__label .b-checkbox__icon {
          left: 17px; }
        .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__label:before {
          top: -10px;
          bottom: -12px;
          opacity: 1; }
      .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__faq-box_call {
        position: absolute;
        right: 20px;
        top: 16px;
        width: 25px;
        height: 25px;
        background: url(../i/new/i-question.svg) no-repeat;
        border: none;
        opacity: .5;
        display: block;
        transition: opacity .25s; }
        .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__faq-box_call:hover {
          opacity: 1; }
    .b-activation__second .b-activation__form .b-label {
      display: block;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      opacity: .5;
      margin-bottom: 7px;
      font-weight: bold;
      font-family: "SB Sans Display Bold"; }
    .b-activation__second .b-activation__form .b-input__box {
      width: 162px;
      float: left;
      vertical-align: top;
      margin-right: 15px; }
      .b-activation__second .b-activation__form .b-input__box:last-child {
        margin-right: 0; }
      .b-activation__second .b-activation__form .b-input__box .b-input {
        background: none;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.1); }
        .b-activation__second .b-activation__form .b-input__box .b-input:focus {
          background: #fff;
          border-color: #fff;
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
      .b-activation__second .b-activation__form .b-input__box:first-child {
        width: 135px; }

.b-form-agreement {
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  padding: 6px 18px 13px;
  margin-top: 15px; }
  .b-form-agreement .b-checkbox__section .b-checkbox__label {
    color: #000; }
    .b-form-agreement .b-checkbox__section .b-checkbox__label:before {
      display: none; }

.b-popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
  background: rgba(58, 172, 106, 0.95);
  overflow-y: scroll;
  display: none; }
  .b-popup__overlay.b-popup__overlay_feedback, .b-popup__overlay.b-popup__overlay_activation, .b-popup__overlay.b-popup__overlay_ins, .b-popup__overlay.b-popup__overlay_refer-manual {
    background: #f9f9f9; }
    .b-popup__overlay.b-popup__overlay_feedback .b-popup__close, .b-popup__overlay.b-popup__overlay_activation .b-popup__close, .b-popup__overlay.b-popup__overlay_ins .b-popup__close, .b-popup__overlay.b-popup__overlay_refer-manual .b-popup__close {
      background: url(../i/new/i-popup-close-green.svg) no-repeat; }
  .b-popup__overlay.b-popup__overlay_activation .b-wrapper {
    padding-top: 45px;
    padding-bottom: 60px; }
  .b-popup__overlay.b-popup__overlay_activation.second .b-activation__first {
    display: none; }
  .b-popup__overlay.b-popup__overlay_activation.second .b-activation__second {
    display: block; }
  .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side {
    position: absolute;
    right: 0;
    top: 0; }
    .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box {
      width: 320px;
      padding: 26px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box h5 {
        font-size: 14px; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box h4 {
        margin-bottom: 4px; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box p {
        padding-top: 3px; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box:before {
        display: none; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column {
        float: none;
        padding-right: 0;
        width: 100%;
        border-right: none;
        padding-left: 0; }
        .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column:first-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 34px;
          margin-bottom: 24px; }
  .b-popup__overlay.b-popup__overlay_ins .b-popup__close {
    position: fixed; }
  .b-popup__overlay.b-popup__overlay_ins.second .b-popup__close {
    background: url("../i/new/i-popup-close.svg") no-repeat; }
  .b-popup__overlay.b-popup__overlay_ins.second .b-ins-case__first {
    display: none; }
  .b-popup__overlay.b-popup__overlay_ins .b-wrapper {
    padding-top: 45px; }
  .b-popup__overlay .b-popup__close {
    position: fixed;
    top: 30px;
    right: 35px;
    width: 58px;
    height: 58px;
    background: url("../i/new/i-popup-close.svg") no-repeat;
    border: none;
    transition: transform .25s;
    z-index: 5;
    -webkit-transform: translateZ(0); }
    .b-popup__overlay .b-popup__close:hover {
      transform: scale(0.75); }
  .b-popup__overlay.show .b-popup {
    transform: translate(-50%, 0) scale(1);
    opacity: 1; }
    .b-popup__overlay.show .b-popup.b-popup__feedback, .b-popup__overlay.show .b-popup.b-popup__refer-manual {
      transform: translate(-50%, 0) scale(1); }

.b-popup__overlay_agreement {
  z-index: 200; }
  .b-popup__overlay_agreement .b-popup__close {
    right: auto;
    left: 35px;
    width: 48px;
    height: 49px;
    background: url("/static/i/new/i-back-agreement.png") no-repeat; }

.b-popup__overlay.show .b-popup__agreement {
  transform: scale(1); }

.b-popup.b-popup__agreement {
  position: relative;
  width: 640px;
  margin: 120px auto;
  top: 0;
  left: 0; }
  .b-popup.b-popup__agreement p {
    margin-bottom: 13px; }
    .b-popup.b-popup__agreement p:last-child {
      margin-bottom: 0; }

.b-feedback-form,
.b-question-form {
  position: relative; }
  .b-feedback-form .b-feedback-form__column .b-input__box,
  .b-feedback-form .b-question-form__column .b-input__box,
  .b-question-form .b-feedback-form__column .b-input__box,
  .b-question-form .b-question-form__column .b-input__box {
    float: left;
    vertical-align: top;
    width: 47%; }
    .b-feedback-form .b-feedback-form__column .b-input__box:first-child,
    .b-feedback-form .b-question-form__column .b-input__box:first-child,
    .b-question-form .b-feedback-form__column .b-input__box:first-child,
    .b-question-form .b-question-form__column .b-input__box:first-child {
      margin-right: 6%; }
  .b-feedback-form textarea,
  .b-question-form textarea {
    height: 150px; }
  .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section,
  .b-question-form .b-feedback-form__checkboxs .b-checkbox__section {
    float: left;
    vertical-align: top;
    width: 50%;
    margin: 0;
    padding: 0;
    border-bottom: none; }
    .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section:first-child .b-checkbox__label,
    .b-question-form .b-feedback-form__checkboxs .b-checkbox__section:first-child .b-checkbox__label {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section:first-child + .b-checkbox__section .b-checkbox__label,
    .b-question-form .b-feedback-form__checkboxs .b-checkbox__section:first-child + .b-checkbox__section .b-checkbox__label {
      border-left: none; }
    .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section:last-child .b-checkbox__label,
    .b-question-form .b-feedback-form__checkboxs .b-checkbox__section:last-child .b-checkbox__label {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section input:checked + .b-checkbox__label,
    .b-question-form .b-feedback-form__checkboxs .b-checkbox__section input:checked + .b-checkbox__label {
      background: #fff;
      cursor: default;
      color: #21A038;
      border-color: #fff;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
    .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label,
    .b-question-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label {
      margin: 0;
      display: block;
      padding: 13px 0;
      height: 53px;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.4);
      transition: background .25s, box-shadow .25s, color .25s, border-color .25s; }
      .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label:hover,
      .b-question-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label:hover {
        color: #21A038; }
      .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label:before,
      .b-question-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label:before {
        display: none !important; }
      .b-feedback-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label .b-checkbox__icon,
      .b-question-form .b-feedback-form__checkboxs .b-checkbox__section .b-checkbox__label .b-checkbox__icon {
        display: none; }

.b-popup {
  background: #f9f9f9;
  border-radius: 5px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0) scale(0);
  padding: 51px 55px 60px;
  box-sizing: border-box;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  opacity: 0;
  margin: 100px 0;
  transition: transform .25s, opacity .25s; }
  .b-popup h2 {
    font-size: 55px;
    line-height: 65px; }
  .b-popup.b-popup__feedback, .b-popup.b-popup__refer-manual {
    top: 0;
    padding: 50px 300px 60px 0;
    box-shadow: none;
    width: 800px;
    position: relative;
    transform: translate(-50%, 0) scale(0); }
    .b-popup.b-popup__feedback .b-feedback-form__faq, .b-popup.b-popup__refer-manual .b-feedback-form__faq {
      position: absolute;
      right: -300px;
      top: 100px;
      width: 210px;
      color: rgba(0, 0, 0, 0.4); }
      .b-popup.b-popup__feedback .b-feedback-form__faq p, .b-popup.b-popup__refer-manual .b-feedback-form__faq p {
        margin-bottom: 25px; }
        .b-popup.b-popup__feedback .b-feedback-form__faq p:last-child, .b-popup.b-popup__refer-manual .b-feedback-form__faq p:last-child {
          margin-bottom: 0; }
  .b-popup.b-popup__question {
    width: 560px; }
  .b-popup .b-order-form__error {
    margin-top: 20px; }
  .b-popup.b-popup__order {
    width: 360px; }
  .b-popup .b-button__box {
    margin-top: 30px; }
    .b-popup .b-button__box .b-button {
      width: 100%; }
  .b-popup .b-input__box {
    margin-top: 15px; }
    .b-popup .b-input__box .b-label {
      display: block;
      font-size: 14px;
      line-height: 25px;
      text-transform: uppercase;
      opacity: .5;
      margin-bottom: 7px;
      font-weight: bold;
      font-family: "SB Sans Display Bold"; }
    .b-popup .b-input__box .b-input {
      background: none;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1); }
      .b-popup .b-input__box .b-input:focus {
        background: #fff;
        border-color: #fff;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }

.b-calc__result_box.alone + .b-promo-code__box {
  width: 280px;
  height: 280px; }
  .b-calc__result_box.alone + .b-promo-code__box .b-promo-code__close {
    top: 0;
    right: 15px; }

.b-promo-code__box {
  position: absolute;
  right: 0;
  top: 0;
  width: 355px;
  height: 355px;
  visibility: hidden;
  z-index: 9;
  transition: visibility .5s .0s; }
  .b-promo-code__box.open {
    visibility: visible;
    transition: visibility .0s .0s;
    z-index: 11; }
    .b-promo-code__box.open .b-promo-code {
      transform: rotateY(0deg);
      transition: opacity .5s, transform .5s; }

.b-promo-code {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  display: table;
  text-align: center;
  transform: rotateY(90deg);
  transition: opacity .25s .25s, transform .5s;
  color: #000; }
  .b-promo-code .b-promo-code__close {
    position: absolute;
    right: 45px;
    top: 6px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #21A038;
    transition: background .25s;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
    .b-promo-code .b-promo-code__close:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 17px;
      margin: -8px 0 0 -12px;
      background: url("../i/new/i-promo-code-back.png") no-repeat; }
    .b-promo-code .b-promo-code__close:hover {
      background: #21A038; }
  .b-promo-code .b-promo-code__table-cell {
    display: table-cell;
    vertical-align: middle; }
  .b-promo-code .b-promo-code__wrapper {
    display: inline-block;
    width: 55%; }
  .b-promo-code .b-promo-code__title {
    display: block;
    width: 60%;
    margin: 0 auto 25px; }
  .b-promo-code .b-input {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .b-promo-code .b-button__box {
    margin-top: 33px; }
    .b-promo-code .b-button__box .b-button {
      width: 100%; }

.b-top__section {
  padding-top: 165px; }

.b-common-sort {
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 25px; }
  .b-common-sort .b-common-sort__item {
    float: left;
    vertical-align: top;
    margin-right: 25px; }
    .b-common-sort .b-common-sort__item:last-child {
      margin-right: 0; }
    .b-common-sort .b-common-sort__item a {
      display: block;
      padding-top: 19px;
      color: #000;
      opacity: .5;
      transition: opacity .25s;
      position: relative;
      border: none;
      z-index: 2; }
      .b-common-sort .b-common-sort__item a.active {
        opacity: 1;
        cursor: default; }
        .b-common-sort .b-common-sort__item a.active:before {
          opacity: 1; }
      .b-common-sort .b-common-sort__item a:before {
        content: "";
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        height: 5px;
        background: #FAED00;
        opacity: 0;
        transition: opacity .25s; }
      .b-common-sort .b-common-sort__item a:hover {
        opacity: 1; }

.b-suggest {
  position: relative;
  padding-top: 180px;
  text-align: center;
  margin-bottom: 110px; }
  .b-suggest .b-wrapper {
    padding-top: 125px; }
    .b-suggest .b-wrapper:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 91px;
      height: 102px;
      margin-left: -45px;
      background: url("../i/new/i-suggest.svg") no-repeat; }
  .b-suggest h2 {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto; }
  .b-suggest p {
    max-width: 550px;
    margin: auto;
    opacity: .5; }

.b-text__section {
  padding-top: 165px;
  padding-bottom: 20px;
  position: relative;
  width: 775px;
  margin: auto; }
  .b-text__section .b-text__wrapper {
    width: 100%;
    margin: auto; }
  .b-text__section .b-video-block {
    width: 915px;
    margin-top: 53px;
    margin-bottom: 63px; }
    .b-text__section .b-video-block iframe {
      width: 915px;
      height: 515px; }
  .b-text__section .b-gallery {
    width: 915px;
    margin-left: -140px;
    margin-top: 53px;
    margin-bottom: 30px;
    padding-bottom: 45px; }
    .b-text__section .b-gallery .slick-dots li {
      padding-left: 0; }
    .b-text__section .b-gallery .slick-arrow {
      background: #21A038; }
      .b-text__section .b-gallery .slick-arrow:before {
        background: url("../i/new/i-slick-white.svg") no-repeat; }
      .b-text__section .b-gallery .slick-arrow:hover {
        background: #fff; }
        .b-text__section .b-gallery .slick-arrow:hover:before {
          background: url("../i/new/i-slick-green.svg") no-repeat; }
    .b-text__section .b-gallery .b-gallery__item {
      height: 610px;
      position: relative;
      outline: none;
      border: none;
      overflow: hidden;
      border-radius: 5px; }
      .b-text__section .b-gallery .b-gallery__item img {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: auto;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 5px; }

.b-tags {
  position: relative;
  font-size: 19px;
  line-height: 29px;
  margin-bottom: 40px; }
  .b-tags li {
    float: left;
    vertical-align: top;
    margin-right: 30px; }
    .b-tags li:last-child {
      margin-right: 0; }

.b-voting__box {
  position: relative;
  width: 760px;
  margin: 60px auto;
  background: #fff;
  border-radius: 5px;
  padding: 60px 62px 40px 60px;
  box-sizing: border-box;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
  .b-voting__box.voted .b-voting__list .b-voting__item label {
    color: #000 !important; }
    .b-voting__box.voted .b-voting__list .b-voting__item label b {
      display: inline; }
    .b-voting__box.voted .b-voting__list .b-voting__item label:before {
      display: none; }
  .b-voting__box.voted .b-voting__list .b-voting__item input:checked + label:before {
    display: block;
    background-position: -50px 0; }
  .b-voting__box .b-voting__error {
    margin: 23px 0 0 39px; }
  .b-voting__box .b-voting__text {
    opacity: .5;
    margin: 23px 0 0 39px;
    font-size: 16px;
    line-height: 25px; }
  .b-voting__box .b-voting__list {
    position: relative;
    overflow: hidden; }
    .b-voting__box .b-voting__list .b-voting__item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-left: 39px;
      font-size: 19px;
      line-height: 29px; }
      .b-voting__box .b-voting__list .b-voting__item:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1); }
      .b-voting__box .b-voting__list .b-voting__item input {
        position: absolute;
        top: -100%;
        left: -100%;
        width: 0;
        height: 0;
        opacity: 0; }
      .b-voting__box .b-voting__list .b-voting__item .b-voting__progress {
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 3px;
        background: #21A038; }
      .b-voting__box .b-voting__list .b-voting__item label {
        color: #21A038;
        display: block;
        padding: 12px 0 10px;
        cursor: pointer;
        transition: color .25s;
        position: relative; }
        .b-voting__box .b-voting__list .b-voting__item label:before {
          content: "";
          position: absolute;
          left: -39px;
          top: 19px;
          width: 21px;
          height: 13px;
          background: url("../i/new/i-voting.png") no-repeat 0 0; }
        .b-voting__box .b-voting__list .b-voting__item label b {
          display: none;
          font-weight: bold;
          font-family: "SB Sans Display Bold"; }
          .b-voting__box .b-voting__list .b-voting__item label b:before {
            content: "- "; }
        .b-voting__box .b-voting__list .b-voting__item label:hover {
          color: #21A038; }

.b-social-section {
  text-align: center;
  margin-top: 60px; }
  .b-social-section .b-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 60px;
    padding-bottom: 72px; }
  .b-social-section .b-social-section__label {
    display: inline-block;
    vertical-align: top;
    opacity: .5;
    margin-right: 10px;
    margin-top: 8px;
    font-size: 16px; }
  .b-social-section .b-social__list {
    display: inline-block;
    vertical-align: top; }
    .b-social-section .b-social__list .b-social__item .b-social {
      background-image: url("../i/new/social-sprite-green.svg"); }

.b-input__box.b-input__box_file {
  margin: 20px 0; }
  .b-input__box.b-input__box_file .b-label {
    display: inline-block;
    vertical-align: top;
    margin: 12px 20px 0 0; }

.b-input-file {
  position: relative;
  display: inline-block;
  vertical-align: top; }
  .b-input-file .b-input-file__text {
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin-left: 5px;
    margin-top: 9px; }
    .b-input-file .b-input-file__text:before {
      content: "-";
      display: inline-block;
      vertical-align: top;
      padding-right: 3px; }
  .b-input-file .b-button {
    font-size: 15px; }
  .b-input-file input {
    opacity: 0;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.b-popup__ins-case {
  width: 630px; }
  .b-popup__ins-case textarea {
    min-height: 53px; }
  .b-popup__ins-case .b-ins-case__form__column .b-input__box {
    width: 55%;
    float: left;
    vertical-align: top; }
    .b-popup__ins-case .b-ins-case__form__column .b-input__box:last-child {
      width: 41%;
      margin-left: 4%; }

.b-popup__search {
  background: #f9f9f9;
  z-index: 23; }
  .b-popup__search .b-search-append {
    padding-top: 132px; }
    .b-popup__search .b-search-append .b-products__section_wrapper {
      margin-bottom: 0; }
    .b-popup__search .b-search-append .b-product.b-product__big .b-product__pic div {
      border-radius: 0; }
    .b-popup__search .b-search-append .b-product .b-product__pic div {
      border-radius: 50%;
      -webkit-transform: translateZ(0); }
  .b-popup__search .b-search-form__error {
    text-align: center;
    padding-top: 130px; }

.b-search__dropdown .b-link .b-link__text {
  border-bottom: none; }

.b-search__dropdown h6:last-child {
  position: relative;
  overflow: hidden; }
  .b-search__dropdown h6:last-child a {
    display: inline-block;
    vertical-align: top;
    position: relative;
    border: none; }
    .b-search__dropdown h6:last-child a:before {
      content: "";
      position: absolute;
      left: 100%;
      top: 50%;
      margin-left: 9px;
      width: 200px;
      height: 1px;
      background: rgba(0, 0, 0, 0.1); }
    .b-search__dropdown h6:last-child a:after {
      content: "";
      position: absolute;
      left: 100%;
      margin-left: 205px;
      top: 50%;
      width: 6px;
      height: 8px;
      margin-top: -4px;
      background: url("../i/new/i-search-arrow.png") no-repeat;
      opacity: .1; }

.b-search-form {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #f9f9f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translateZ(0); }
  .b-search-form .b-search-form__faq {
    position: absolute;
    top: 100%;
    margin-top: 30px;
    left: 50px;
    width: 300px;
    color: rgba(0, 0, 0, 0.4); }
  .b-search-form .b-wrapper:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    background: url("../i/new/header/i-search-black.svg") no-repeat;
    background-size: cover;
    opacity: .1; }
  .b-search-form .b-wrapper .b-popup__search_close {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -16px;
    width: 32px;
    height: 32px;
    background: url("../i/new/i-popup-close-green.svg") no-repeat;
    background-size: cover;
    border: none; }
  .b-search-form input {
    display: block;
    margin-left: 50px;
    margin-right: 60px;
    background: none;
    border: none;
    outline: none;
    height: 83px;
    font-size: 35px;
    line-height: 82px;
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    width: 90%; }

.b-popup__img-block {
  overflow: scroll;
  display: table-cell;
  vertical-align: middle;
  text-align: center; }
  .b-popup__img-block img {
    display: inline-block; }

.b-popup.b-popup__refer-manual h2 {
  white-space: nowrap; }

.b-popup.b-popup__refer-manual .b-feedback-form__faq {
  width: 320px;
  right: -410px; }

.b-question__dop {
  margin-top: 40px;
  margin-left: 55px;
  background: #fff;
  border-radius: 5px;
  box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.1);
  padding: 30px 23px 30px; }
  .b-question__dop .b-button__box {
    margin-top: 25px; }
  .b-question__dop .b-text__wrapper {
    margin-left: 0; }

.b-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.1); }

.b-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.1); }

.b-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.1); }

.b-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.1); }

@media only screen and (min-width: 1200px) {
  .b-footer-products {
    width: 340px !important; }
  .b-main-tabs__section .b-main-tabs__tabs li {
    max-width: none !important; } }

@media only screen and (min-width: 1600px) {
  body {
    font-size: 19px;
    line-height: 30px;
    min-width: 1600px; }
  .b-wrapper {
    width: 1400px; }
  .b-main-title {
    margin-bottom: 29px; }
  h1 {
    font-size: 70px;
    line-height: 80px;
    margin-bottom: 35px; }
  h2 {
    font-size: 55px;
    line-height: 65px;
    margin-bottom: 29px; }
  h3 {
    font-size: 45px;
    line-height: 55px;
    margin-bottom: 29px; }
  h4 {
    font-size: 35px;
    line-height: 45px; }
  .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box h4 {
    font-size: 25px;
    line-height: 35px; }
  .b-text__bigger {
    font-size: 21px;
    line-height: 30px; }
  .b-input {
    font-size: 19px; }
  .b-footer-products__carousel {
    margin-left: 0;
    margin-right: 0; }
  .b-footer-products {
    width: 456px !important; }
  .b-footer {
    font-size: 18px;
    line-height: 30px; }
    .b-footer .b-footer__bottom .b-footer__bottom_middle {
      font-size: 14px;
      padding-top: 10px; }
    .b-footer .b-licence {
      font-size: 14px;
      line-height: 18px; }
      .b-footer .b-licence .b-licence__title {
        font-size: 18px;
        line-height: 26px; }
    .b-footer .b-footer__columns {
      width: 330px; }
      .b-footer .b-footer__columns .b-footer__title {
        font-size: 16px;
        line-height: 30px;
        padding-top: 0; }
  .b-activation__second .b-activation__form .b-button__box {
    margin-left: 265px; }
    .b-activation__second .b-activation__form .b-button__box .b-button {
      width: 400px;
      height: 400px; }
      .b-activation__second .b-activation__form .b-button__box .b-button:before {
        top: 120px; }
  .b-content .b-first-section .b-main-title h1 {
    margin-bottom: 35px; } }

@media only screen and (max-width: 1199px) {
  .b-question__dop {
    margin-left: -15px; }
  .b-activation__list {
    margin-right: 250px;
    width: auto; }
  .b-activation__first .b-activation__first_faq {
    width: 200px;
    height: 200px;
    font-size: 13px;
    line-height: 24px;
    padding-top: 80px; }
    .b-activation__first .b-activation__first_faq:before {
      top: 10px;
      transform: scale(0.7); }
  .b-activation__first .b-text__wrapper {
    width: auto; }
  .b-search-form input {
    width: 85%; }
  .b-search-form .b-wrapper:before {
    left: 60px; }
  .b-search-form .b-wrapper .b-popup__search_close {
    right: 20px; }
  .b-search-form .b-search-form__faq {
    left: 110px; }
  .b-activation__second .b-activation__back {
    width: 45px;
    height: 45px;
    left: 8px;
    top: 15px; }
    .b-activation__second .b-activation__back:before {
      transform: scale(0.8); }
  .b-activation__second .b-activation__title {
    margin-right: 200px; }
  .b-activation__second .b-activation__form .b-input__box {
    width: 147px; }
    .b-activation__second .b-activation__form .b-input__box:first-child {
      width: 129px; }
  .b-activation__second .b-activation__form .b-checkbox__section {
    border-bottom: none; }
    .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__label {
      margin-right: 0; }
      .b-activation__second .b-activation__form .b-checkbox__section .b-checkbox__label:before {
        display: block; }
  .b-activation__second .b-activation__form .b-button__box {
    top: -60px;
    margin-left: 30px; }
    .b-activation__second .b-activation__form .b-button__box .b-button {
      width: 200px;
      height: 200px;
      font-size: 20px; }
      .b-activation__second .b-activation__form .b-button__box .b-button:before {
        top: 40px; }
  .b-popup.b-popup__refer-manual, .b-popup.b-popup__feedback {
    width: 700px;
    padding-right: 200px; }
    .b-popup.b-popup__refer-manual .b-feedback-form__faq, .b-popup.b-popup__feedback .b-feedback-form__faq {
      right: -200px;
      width: 180px; }
  .b-popup.b-popup__refer-manual h2 {
    white-space: normal; }
  .b-ins-case__second .b-ins-case__second_button {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    padding: 90px 0 0;
    right: 25px !important;
    font-size: 20px;
    line-height: 26px; }
    .b-ins-case__second .b-ins-case__second_button:before {
      margin-left: -27px;
      width: 55px;
      height: 60px; }
  .b-input__box.b-input__box_file .b-label {
    margin-top: 12px; }
  .b-input-file .b-input-file__text {
    margin-top: 11px; }
  .b-text__section {
    width: auto;
    padding-left: 60px;
    padding-right: 25px; }
    .b-text__section .b-text__wrapper {
      width: auto;
      margin: 0 70px; }
    .b-text__section .b-video-block {
      width: auto;
      margin-left: -70px;
      margin-right: -70px; }
      .b-text__section .b-video-block iframe {
        width: 100%;
        height: 420px; }
    .b-text__section .b-gallery {
      width: auto;
      margin-left: -70px;
      margin-right: -35px; }
      .b-text__section .b-gallery .slick-arrow {
        background: #21A038; }
        .b-text__section .b-gallery .slick-arrow:before {
          background: url("../i/new/i-slick-white.svg") no-repeat; }
        .b-text__section .b-gallery .slick-arrow:hover {
          background: #21A038; }
          .b-text__section .b-gallery .slick-arrow:hover:before {
            background: url("../i/new/i-slick-white.svg") no-repeat; }
        .b-text__section .b-gallery .slick-arrow.slick-prev {
          left: -58px; }
        .b-text__section .b-gallery .slick-arrow.slick-next {
          right: -58px; }
      .b-text__section .b-gallery .b-gallery__item {
        height: 420px; }
  .b-voting__box {
    width: 100%; }
  .b-footer-products__carousel .b-product {
    margin: 0 9px; }
  h1 {
    font-size: 40px;
    line-height: 50px; }
  h2 {
    font-size: 31px;
    line-height: 45px;
    margin-bottom: 19px; }
  h3 {
    font-size: 25px;
    line-height: 25px; }
  h4 {
    font-size: 16px;
    line-height: 24px; }
  h5 {
    font-size: 21px;
    line-height: 30px; }
  h6 {
    font-size: 18px;
    line-height: 28px; }
  .b-video-block iframe {
    height: 295px; }
  body {
    font-size: 15px;
    line-height: 24px;
    min-width: 0; }
  .b-text__bigger {
    font-size: 17px;
    line-height: 24px; }
  .b-input {
    font-size: 15px; }
  .b-wrapper {
    width: 100%;
    box-sizing: border-box;
    padding: 0 25px 0 60px; }
  .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link {
    font-size: 17px;
    line-height: 24px; }
    .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.b-custom-tab {
      font-size: 20px;
      line-height: 30px; }
  .b-accordion.b-accordion__list .b-accordion__link:before, .b-accordion.b-accordion__list .b-accordion__link:after {
    top: -3px;
    opacity: 1 !important; }
  .b-accordion .b-accordion__link:hover:after {
    opacity: 0; }
  .b-text__wrapper {
    margin-left: 0; }
    .b-text__wrapper .b-text__img {
      margin-left: 0; }
    .b-text__wrapper .b-table {
      margin-left: -30px; }
      .b-text__wrapper .b-table table tr:first-child td {
        font-size: 15px; }
  .b-footer-products__section .b-wrapper {
    padding: 0 60px; }
  .slick-slider .slick-arrow {
    transform: scale(0.82) translate(0, -50%); }
    .slick-slider .slick-arrow.slick-prev {
      left: -49px; }
    .slick-slider .slick-arrow.slick-next {
      right: -49px; }
    .slick-slider .slick-arrow.slick-disabled {
      cursor: default;
      opacity: .5; }
  .b-information__section .b-information__tel {
    margin-right: 20px; }
    .b-information__section .b-information__tel span {
      font-size: 10px;
      line-height: 15px; }
    .b-information__section .b-information__tel h2 {
      font-size: 31px; }
  .b-information__section .b-social__list {
    padding-top: 15px;
    margin-right: -20px; }
  .b-footer {
    font-size: 13px;
    line-height: 24px; }
    .b-footer .b-wrapper {
      padding: 0 45px; }
    .b-footer .b-footer__columns {
      width: 25%;
      padding-right: 5%; }
    .b-footer .b-licence {
      float: left;
      width: 25%; }
    .b-footer .b-footer__bottom .b-footer__bottom_copy {
      width: 120px; }
    .b-footer .b-footer__bottom .b-footer__bottom_middle {
      width: 35%; }
    .b-footer .b-footer__bottom .b-footer__bottom_made {
      width: 210px;
      text-align: right; }
      .b-footer .b-footer__bottom .b-footer__bottom_made .b-footer__bottom_made-BA {
        display: block;
        font-size: 13px;
        margin-top: 5px; }
        .b-footer .b-footer__bottom .b-footer__bottom_made .b-footer__bottom_made-BA a {
          font-size: 13px; }
  .b-button {
    font-size: 15px;
    height: 46px;
    padding: 7px 15px 10px; }
  .b-ins-case__tab-box .b-ins-case__tab-box_result {
    padding-right: 0; }
  .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side {
    position: relative;
    float: left;
    width: 100%;
    margin-top: 30px; }
    .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box {
      width: 100%; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box:before {
        display: block; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column {
        float: left;
        width: 50%; }
        .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column:first-child {
          padding-bottom: 0;
          margin-bottom: 0;
          padding-right: 30px;
          border-bottom: none; }
        .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column:last-child {
          padding-left: 30px; } }

@media only screen and (max-width: 767px) {
  .b-question__dop {
    margin-left: 0px;
    padding: 7% 5%; }
    .b-question__dop h4 {
      font-size: 20px; }
  .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box {
    padding: 5%; }
    .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box:before {
      display: none; }
    .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column {
      float: none;
      width: 100%;
      padding-right: 0;
      padding-left: 0; }
      .b-popup__overlay.b-popup__overlay_ins .b-ins-case__side .b-contacts__box .b-contacts__column:first-child {
        padding-bottom: 20px !important;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .b-activation__list {
    margin-right: 0; }
  .b-activation__first .b-text__wrapper .column li {
    float: none;
    width: 100%;
    padding-right: 0; }
  .b-activation__first .b-text__wrapper.b-activation__first_text p {
    margin-left: 0; }
  .b-activation__first .b-main-tabs__section .b-main-tabs__tabs {
    padding-right: 0; }
  .b-activation__first .b-activation__tabs .b-main-tabs__section .b-main-tabs__tabs li {
    padding-right: 1.5%; }
  .b-activation__first .b-main-tabs__result .b-text__wrapper {
    margin-top: 0; }
  .b-activation__first .b-activation__first_faq {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 0;
    top: auto;
    display: block;
    right: auto !important;
    font-size: 15px;
    line-height: 24px;
    padding: 27px 5.5% 30px 100px;
    text-align: left;
    margin-top: 0; }
    .b-activation__first .b-activation__first_faq:before {
      left: 0;
      margin-left: 0;
      top: 46%;
      margin-top: -37px;
      transform: scale(0.7); }
  .b-activation__first .b-text__wrapper {
    margin-top: 0;
    padding-bottom: 30px;
    margin-right: 0;
    width: auto; }
  .b-popup__search {
    z-index: 30; }
    .b-popup__search .b-search-append {
      padding-top: 61px;
      padding-bottom: 0; }
    .b-popup__search .b-search-form__error {
      padding-top: 20px; }
  .b-search-form .b-search-form__faq {
    left: 40px;
    width: 190px; }
  .b-search-form .b-wrapper:before {
    left: 0; }
  .b-search-form .b-wrapper .b-popup__search_close {
    width: 26px;
    height: 26px;
    margin-top: -13px;
    right: 0; }
  .b-search-form input {
    height: 59px;
    line-height: 57px;
    font-size: 19px;
    margin-left: 40px;
    margin-right: 50px; }
  .b-popup__overlay.show .b-popup.b-popup__feedback, .b-popup__overlay.show .b-popup.b-popup__refer-manual {
    transform: scale(1); }
  .b-popup.b-popup__feedback, .b-popup.b-popup__refer-manual {
    transform: scale(0); }
    .b-popup.b-popup__feedback .b-feedback-form__faq, .b-popup.b-popup__refer-manual .b-feedback-form__faq {
      position: relative;
      right: auto;
      top: auto;
      width: 100%; }
    .b-popup.b-popup__feedback .phone, .b-popup.b-popup__refer-manual .phone {
      width: 100%; }
  .b-voting__box {
    width: auto;
    margin: 50px -5.5%;
    border-radius: 0;
    padding: 30px 5.5%; }
    .b-voting__box .b-voting__text {
      font-size: 15px;
      line-height: 24px;
      margin-left: 0; }
    .b-voting__box .b-voting__list .b-voting__item {
      font-size: 15px;
      line-height: 24px; }
  .b-social-section .b-social-section__label {
    font-size: 14px; }
  .b-social-section .b-wrapper {
    padding-top: 44px;
    padding-bottom: 45px; }
  .b-text__section {
    padding-top: 125px;
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    margin: auto; }
    .b-text__section .b-text__wrapper {
      margin: 0; }
    .b-text__section .b-video-block {
      margin: 30px -5.5%;
      border-radius: 0;
      box-shadow: none; }
      .b-text__section .b-video-block .b-video-block__overlay {
        border-radius: 0; }
      .b-text__section .b-video-block iframe {
        height: 320px; }
    .b-text__section .b-gallery {
      margin: 30px -5.5%;
      padding-bottom: 0;
      border-radius: 0; }
      .b-text__section .b-gallery .slick-dots {
        left: 0;
        right: 0;
        top: auto;
        bottom: -28px;
        text-align: center; }
        .b-text__section .b-gallery .slick-dots li {
          float: none;
          width: auto;
          margin: 0 3px; }
          .b-text__section .b-gallery .slick-dots li.slick-active button {
            background: rgba(0, 0, 0, 0.1); }
          .b-text__section .b-gallery .slick-dots li button {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: #21A038; }
      .b-text__section .b-gallery .slick-arrow.slick-prev {
        left: 2.5%; }
      .b-text__section .b-gallery .slick-arrow.slick-next {
        right: 2.5%; }
      .b-text__section .b-gallery .b-gallery__item {
        height: 220px;
        border-radius: 0; }
        .b-text__section .b-gallery .b-gallery__item img {
          border-radius: 0; }
  .b-tags {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px; }
  .b-suggest {
    padding-top: 120px; }
  .b-top__section {
    padding-top: 125px; }
  .b-common-sort {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    font-size: 15px;
    line-height: 24px; }
    .b-common-sort .b-common-sort__item {
      float: none;
      margin: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .b-common-sort .b-common-sort__item a {
        display: block;
        box-sizing: border-box;
        padding: 12px 14px;
        opacity: 1; }
        .b-common-sort .b-common-sort__item a.active {
          background: #FAED00; }
        .b-common-sort .b-common-sort__item a:before {
          display: none; }
      .b-common-sort .b-common-sort__item:last-child {
        border: none; }
  .b-main-title__i-list {
    margin-right: 0; }
    .b-main-title__i-list li {
      float: none;
      width: 100% !important; }
  .b-text__wrapper .b-table {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: -10px;
    background: none;
    box-shadow: none;
    font-size: 11px;
    line-height: 16px; }
    .b-text__wrapper .b-table thead {
      font-size: 11px; }
      .b-text__wrapper .b-table thead td {
        padding-top: 10px;
        padding-bottom: 10px; }
    .b-text__wrapper .b-table td {
      padding: 10px 0 0px 10px; }
    .b-text__wrapper .b-table tr.b-table__border td {
      padding-top: 10px;
      padding-bottom: 10px; }
  .b-text__wrapper .b-text__img .b-text__img_zoom {
    display: block; }
  .b-main-title__icon-box {
    margin-top: -40px;
    padding-bottom: 30px; }
  .b-main-tabs__section .b-main-tabs__tabs {
    padding-right: 0; }
  .b-wrapper {
    width: 90%;
    padding: 0; }
  .b-main-title {
    margin-bottom: 14px; }
  .b-video-block iframe {
    height: 250px; }
  .b-video-block .b-video-block__play {
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px; }
  h1 {
    font-size: 27px;
    line-height: 33px;
    margin-bottom: 14px; }
  h2 {
    font-size: 23px;
    line-height: 27px;
    margin-bottom: 14px; }
  h3 {
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 14px; }
  h4 {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 14px; }
  h5 {
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 9px; }
  h6 {
    margin-bottom: 9px; }
  .b-first-section .b-wrapper.absolute {
    padding-bottom: 181px; }
    .b-first-section .b-wrapper.absolute .b-calc__result_box {
      position: absolute;
      top: auto !important;
      bottom: 0; }
  .b-first-section.b-first-section__first-capital {
    padding-bottom: 0; }
    .b-first-section.b-first-section__first-capital .b-main-title {
      padding-bottom: 40px; }
  .b-first-section.b-first-section__mites-protection .b-main-title {
    padding-bottom: 40px; }
  .b-footer,
  .b-footer-products__section {
    z-index: 11; }
  .b-content .b-first-section {
    padding-top: 123px;
    z-index: 5; }
    .b-content .b-first-section.z-index {
      z-index: 120; }
  .slick-slider .slick-arrow:hover {
    background: #fff; }
    .slick-slider .slick-arrow:hover:before {
      background: url(../i/new/i-slick-green.svg) no-repeat; }
  .slick-slider .slick-dots {
    position: absolute;
    bottom: auto;
    left: -5.5%;
    right: -5.5%; }
    .slick-slider .slick-dots li {
      float: left;
      vertical-align: top;
      margin: 0; }
      .slick-slider .slick-dots li.slick-active button {
        background: #21A038; }
      .slick-slider .slick-dots li button {
        height: 3px;
        width: 100%;
        border-radius: 0;
        background: transparent;
        transition: background .25s; }
  .b-main-tabs__section .b-main-tabs__result_box {
    padding-top: 15px; }
  .b-main-tabs__section .b-main-tabs__tabs {
    margin-top: 25px; }
    .b-main-tabs__section .b-main-tabs__tabs.open li:first-child .b-main-tabs__tabs_arrow {
      display: block !important;
      transform: rotate(0deg); }
    .b-main-tabs__section .b-main-tabs__tabs.open li .b-main-tabs__tabs_link {
      display: block; }
      .b-main-tabs__section .b-main-tabs__tabs.open li .b-main-tabs__tabs_link.active {
        color: #000; }
        .b-main-tabs__section .b-main-tabs__tabs.open li .b-main-tabs__tabs_link.active + .b-main-tabs__tabs_arrow {
          display: none; }
    .b-main-tabs__section .b-main-tabs__tabs li {
      display: block;
      width: 100%;
      max-width: none !important;
      margin-right: 0;
      padding-right: 0;
      position: relative; }
      .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link {
        font-size: 15px;
        line-height: 24px;
        display: none;
        padding: 20px 60px 20px 0;
        box-sizing: border-box;
        border-top: 1px solid rgba(0, 0, 0, 0.1); }
        .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.active {
          display: block;
          color: #21A038; }
          .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.active + .b-main-tabs__tabs_arrow {
            display: block; }
        .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link:before {
          display: none !important; }
        .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.b-custom-tab {
          padding-left: 0;
          padding-bottom: 20px;
          font-size: 15px;
          line-height: 24px; }
          .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.b-custom-tab i {
            display: none; }
        .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link span {
          display: block;
          text-overflow: ellipsis;
          overflow: hidden; }
  .b-accordion.b-accordion__questions .b-accordion__item {
    padding-bottom: 14px;
    margin-bottom: 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .b-accordion.b-accordion__questions .b-accordion__item:last-child {
      border-bottom: none; }
    .b-accordion.b-accordion__questions .b-accordion__item.first-active, .b-accordion.b-accordion__questions .b-accordion__item.active {
      border-bottom: none; }
  .b-accordion.b-accordion__questions .b-accordion__text {
    padding-left: 0; }
  .b-text__wrapper .b-docs__list {
    margin-top: 7px; }
    .b-text__wrapper .b-docs__list li {
      margin-right: 0;
      width: 100%;
      float: none;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      margin-top: 0; }
      .b-text__wrapper .b-docs__list li:last-child {
        border-bottom: none; }
      .b-text__wrapper .b-docs__list li a {
        padding-left: 37px;
        min-height: 33px;
        padding-top: 5px; }
        .b-text__wrapper .b-docs__list li a:before {
          width: 27px;
          height: 33px;
          background-size: cover; }
  .b-footer-products__who {
    margin-bottom: 20px;
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 25px;
    font-size: 11px;
    line-height: 16px; }
    .b-footer-products__who:before {
      top: 18px;
      width: 20px;
      height: 20px;
      background-size: cover; }
    .b-footer-products__who + h2 {
      top: 98px; }
  .b-footer-products__section {
    padding-top: 0; }
    .b-footer-products__section .b-wrapper {
      padding: 0;
      width: 90%; }
    .b-footer-products__section h2 {
      position: absolute;
      top: 36px;
      left: 0;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 15px;
      white-space: nowrap;
      opacity: .3; }
  .b-footer-products__carousel {
    margin: 0 -5.5%;
    padding-bottom: 0;
    overflow: hidden;
    padding-top: 85px; }
    .b-footer-products__carousel .slick-list {
      margin: 0;
      padding: 0; }
    .b-footer-products__carousel.slick-slider .slick-arrow {
      transform: scale(0.82);
      top: 16px;
      background: #21A038; }
      .b-footer-products__carousel.slick-slider .slick-arrow:before {
        background: url("../i/new/i-slick-white.svg") no-repeat; }
      .b-footer-products__carousel.slick-slider .slick-arrow.slick-prev {
        right: 50px;
        left: auto; }
      .b-footer-products__carousel.slick-slider .slick-arrow.slick-next {
        right: 0; }
    .b-footer-products__carousel .b-product {
      margin: 0;
      border-bottom: none; }
  .b-footer-products {
    border: none;
    padding: 77px 15px 40px;
    margin: 0;
    height: 450px;
    border-radius: 0;
    background: none !important;
    box-shadow: none;
    background: #fff; }
    .b-footer-products .b-footer-products__wrapper {
      max-height: 235px; }
    .b-footer-products .b-button__box {
      opacity: 1;
      margin-left: 15px; }
    .b-footer-products .b-footer-products__bottom_pic {
      transform: scale(1);
      opacity: 1; }
    .b-footer-products .b-footer-products__price {
      color: #fff; }
    .b-footer-products .b-footer-products__bottom {
      left: 0;
      right: 0; }
  .b-information__section {
    margin-bottom: 43px; }
    .b-information__section .b-social__list {
      text-align: left;
      padding: 20px 0 0; }
    .b-information__section .b-information__tel {
      float: none !important;
      margin-top: 17px; }
      .b-information__section .b-information__tel:first-child {
        margin-top: 0; }
      .b-information__section .b-information__tel span {
        font-size: 10px;
        line-height: 15px;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: block; }
      .b-information__section .b-information__tel h2 {
        font-size: 23px;
        line-height: 27px;
        font-weight: bold;
        font-family: "SB Sans Display Bold";
        opacity: 1;
        position: relative;
        top: 0; }
  .b-footer {
    padding-top: 30px;
    font-size: 15px; }
    .b-footer .b-wrapper {
      padding: 0; }
    .b-footer .b-footer__bottom {
      border-top-color: rgba(255, 255, 255, 0.1);
      font-size: 11px;
      line-height: 17px;
      text-align: left;
      padding-top: 17px;
      padding-bottom: 15px; }
      .b-footer .b-footer__bottom .b-footer__bottom_copy {
        float: none;
        width: 100%;
        text-align: center;
        margin-bottom: 12px; }
      .b-footer .b-footer__bottom .b-footer__bottom_made {
        width: 46%;
        text-align: right; }
        .b-footer .b-footer__bottom .b-footer__bottom_made .b-footer__bottom_made-BA {
          display: block;
          font-size: 11px;
          margin-top: 5px; }
          .b-footer .b-footer__bottom .b-footer__bottom_made .b-footer__bottom_made-BA a {
            font-size: 11px; }
        .b-footer .b-footer__bottom .b-footer__bottom_made span {
          display: block; }
      .b-footer .b-footer__bottom .b-footer__bottom_middle {
        float: left;
        width: 46%;
        text-align: left;
        vertical-align: top;
        padding-top: 0;
        margin-top: 0; }
    .b-footer .b-footer__columns_box {
      padding-bottom: 11px; }
    .b-footer .b-licence {
      float: none;
      display: inline-block;
      width: 100%;
      font-size: 11px;
      line-height: 17px;
      margin-top: 0; }
      .b-footer .b-licence .b-licence__title {
        font-size: 10px;
        line-height: 15px;
        font-weight: normal;
        margin-bottom: 13px; }
      .b-footer .b-licence li {
        float: none;
        width: 100%;
        margin-bottom: 26px; }
    .b-footer .b-footer__columns {
      display: inline-block;
      vertical-align: top;
      float: none;
      width: 45%;
      padding-right: 0;
      margin-bottom: 40px; }
      .b-footer .b-footer__columns .b-footer__title {
        font-size: 10px;
        line-height: 15px;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 9px; }
      .b-footer .b-footer__columns ul {
        margin-bottom: 38px; }
        .b-footer .b-footer__columns ul li {
          margin-bottom: 15px; }
      .b-footer .b-footer__columns:nth-child(even) {
        margin-left: 8%; }
  .b-popup__overlay .b-popup__close {
    position: absolute;
    width: 29px;
    height: 29px;
    background-size: cover !important;
    top: 10px;
    left: 50%;
    margin-left: -15px; }
  .b-popup__overlay.show .b-popup {
    transform: scale(1); }
  .b-popup__overlay .b-popup {
    position: relative;
    margin: 60px auto 40px;
    transform: scale(0);
    left: 0;
    top: 0;
    width: 90%;
    padding: 20px 5% 25px; }
    .b-popup__overlay .b-popup .b-input__box .b-label {
      font-size: 12px;
      line-height: 17px; }
    .b-popup__overlay .b-popup h2, .b-popup__overlay .b-popup h4 {
      font-size: 23px;
      line-height: 27px; }
    .b-popup__overlay .b-popup.b-popup__feedback, .b-popup__overlay .b-popup.b-popup__refer-manual {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0; }
  .b-feedback-form .b-feedback-form__column .b-input__box,
  .b-feedback-form .b-question-form__column .b-input__box,
  .b-question-form .b-feedback-form__column .b-input__box,
  .b-question-form .b-question-form__column .b-input__box {
    float: none;
    width: 100%;
    margin-right: 0;
    margin-top: 15px; }
    .b-feedback-form .b-feedback-form__column .b-input__box:first-child,
    .b-feedback-form .b-question-form__column .b-input__box:first-child,
    .b-question-form .b-feedback-form__column .b-input__box:first-child,
    .b-question-form .b-question-form__column .b-input__box:first-child {
      margin-top: 0; }
  .b-feedback-form .b-feedback-form__column .b-input__box:first-child,
  .b-question-form .b-feedback-form__column .b-input__box:first-child {
    margin-top: 15px; }
  .b-input__box.b-input__box_file {
    margin-top: 15px;
    margin-bottom: 20px; }
    .b-input__box.b-input__box_file .b-label {
      margin: 0 0 7px;
      display: block; }
  .b-popup__overlay.b-popup__overlay_ins .b-wrapper, .b-popup__overlay.b-popup__overlay_activation .b-wrapper {
    padding-top: 65px; }
  .b-activation__list {
    font-size: 15px;
    line-height: 24px; }
  .b-activation__second .b-activation__back {
    top: 5px;
    left: -3%; }
  .b-activation__second .b-activation__title {
    margin-right: 0; }
    .b-activation__second .b-activation__title p {
      width: 100%; }
  .b-activation__second .b-product__wrapper {
    margin-right: 0; }
  .b-activation__second .b-activation__form .b-button__box {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 0;
    text-align: center; }
    .b-activation__second .b-activation__form .b-button__box .b-button {
      width: 100%;
      height: auto;
      padding-top: 7px;
      border-radius: 28px;
      font-size: 15px;
      line-height: 29px; }
      .b-activation__second .b-activation__form .b-button__box .b-button:before {
        display: none; }
  .b-activation__second .b-activation__form .b-input__box {
    margin-top: 15px;
    float: none;
    margin-right: 0;
    width: 100% !important; }
    .b-activation__second .b-activation__form .b-input__box .b-label {
      font-size: 12px;
      line-height: 17px; }
  .b-activation__second .b-activation__form .b-checkbox__section {
    font-size: 15px;
    line-height: 24px; }
  .b-ins-case__tab-box {
    padding-top: 0;
    padding-bottom: 40px; }
    .b-ins-case__tab-box .b-header-main__nav {
      float: none;
      width: 100%; }
      .b-ins-case__tab-box .b-header-main__nav .b-ins-case__tab-box_text {
        display: none;
        margin-left: -5.5%;
        margin-right: -5.5%;
        padding: 17px 5.5% 0;
        box-shadow: 0 0 100px rgba(0, 0, 0, 0.15); }
        .b-ins-case__tab-box .b-header-main__nav .b-ins-case__tab-box_text ul a {
          border-bottom: none;
          display: block;
          padding: 11px 0 9px; }
        .b-ins-case__tab-box .b-header-main__nav .b-ins-case__tab-box_text ul li {
          border-top: 1px solid rgba(0, 0, 0, 0.1); }
      .b-ins-case__tab-box .b-header-main__nav .b-header-main__link {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 12px;
        padding-bottom: 14px; }
        .b-ins-case__tab-box .b-header-main__nav .b-header-main__link.active {
          border-bottom-color: transparent; }
        .b-ins-case__tab-box .b-header-main__nav .b-header-main__link:after {
          right: 0;
          width: 16px;
          height: 7px;
          border: none;
          background: url(../i/new/i-accordion.svg) no-repeat; }
        .b-ins-case__tab-box .b-header-main__nav .b-header-main__link:before {
          transform: translate(0, 10px); }
      .b-ins-case__tab-box .b-header-main__nav li {
        margin: 0; }
        .b-ins-case__tab-box .b-header-main__nav li:last-child .b-header-main__link {
          border-bottom: none; }
  .b-ins-case__second.b-product-section__insurance {
    overflow-y: scroll; }
    .b-ins-case__second.b-product-section__insurance .b-wrapper {
      padding-bottom: 30px; }
  .b-ins-case__second .b-ins-case__second_button {
    left: 0;
    right: 0 !important;
    bottom: 0;
    top: auto;
    margin-top: 0;
    margin-left: 0;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 13px 40% 13px 5%;
    font-size: 17px;
    line-height: 22px;
    text-align: left; }
    .b-ins-case__second .b-ins-case__second_button:before {
      width: 40px;
      height: 43px;
      left: auto;
      right: 20px;
      top: 50%;
      margin-top: -25px;
      margin-left: 0; }
  .b-popup__ins-case .b-ins-case__form__column .b-input__box {
    float: none;
    width: 100% !important;
    margin-left: 0 !important; }
  .b-popup__overlay_pic .b-popup__close {
    position: fixed;
    width: 50px;
    height: 50px;
    background: #21A038;
    border-radius: 50%;
    margin-left: -25px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
    .b-popup__overlay_pic .b-popup__close:before {
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      background-size: cover !important;
      top: 50%;
      left: 50%;
      margin: -14px 0 0 -14px;
      background: url(../i/new/i-popup-close.svg) no-repeat; }
  .b-content .b-first-section .b-main-title h1 {
    margin-bottom: 19px;
    margin-left: -7px;
    color: #fff; }
    .b-content .b-first-section .b-main-title h1 span {
      background: #21A038;
      padding: 0 7px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone; }
  .b-bg.b-bg__top .b-bg__top_overflow {
    position: fixed; }
    .b-bg.b-bg__top .b-bg__top_overflow.bottom {
      top: auto;
      position: absolute; }
  .b-text__wrapper .b-table table tr:first-child td {
    font-size: 11px;
    line-height: 16px; }
  .b-refer-manual {
    margin-top: 100px; }
  .b-footer .b-footer__agreement {
    margin-top: 25px; }
  .b-bg.b-bg__top_rp .b-bg__pic {
    background-position: 90% center; } }

@media only screen and (min-width: 768px) {
  .b-main-tabs__section.b-main-tabs__section_bar {
    overflow: hidden;
    margin-right: 335px; }
    .b-main-tabs__section.b-main-tabs__section_bar .b-text__wrapper .b-docs__list li {
      float: none;
      margin-right: 0;
      width: 100%; }
    .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs {
      float: left;
      vertical-align: top;
      width: 215px;
      border-bottom: none;
      z-index: 2;
      margin-top: 0;
      border-right: 1px solid #dfdfdf;
      margin-right: -1px; }
      .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs li {
        max-width: none !important;
        padding-right: 0;
        margin: 15px 0;
        display: block; }
        .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs li:first-child {
          margin-top: 0; }
        .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs li:last-child {
          margin-bottom: 0; }
        .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs li .b-main-tabs__tabs_link {
          padding: 0 25px 0 0;
          font-size: 17px;
          line-height: 24px;
          min-height: 0 !important;
          display: block; }
          .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs li .b-main-tabs__tabs_link:before {
            right: -3px;
            top: 0;
            width: 5px;
            height: auto;
            bottom: 0;
            left: auto; }
    .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__result_box {
      float: left;
      vertical-align: top;
      width: 500px;
      margin-right: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 0; }
      .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__result_box .b-text__wrapper {
        padding-top: 0; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .b-main-tabs__section.b-main-tabs__section_bar {
    margin-right: 230px; }
    .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs {
      width: 183px; }
      .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__tabs li .b-main-tabs__tabs_link {
        font-size: 15px;
        line-height: 24px; }
    .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__result_box {
      width: 270px; }
      .b-main-tabs__section.b-main-tabs__section_bar .b-main-tabs__result_box .b-text__wrapper {
        padding-left: 35px; } }

.b-menu-toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 54px;
  left: 0 !important;
  margin-left: 0 !important;
  top: 0 !important;
  margin-top: 0 !important;
  margin-right: 20px;
  border-radius: 50%;
  background: #21A038;
  border: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  z-index: 21;
  transition: background .25s;
  flex-shrink: 0; }
  .b-menu-toggle.time-with-benefits {
    display: none; }
  .b-menu-toggle.z-index {
    z-index: 24; }
  .b-menu-toggle.open {
    z-index: 23; }
  .b-menu-toggle.fixed {
    position: fixed;
    top: 40px; }
  .b-menu-toggle .b-menu-toggle__line {
    position: absolute;
    left: 50%;
    margin-left: -13px;
    height: 2px;
    width: 26px;
    background: #fff;
    border-radius: 2px;
    transition: opacity .25s, transform .25s; }
    .b-menu-toggle .b-menu-toggle__line.b-menu-toggle__line_1 {
      top: 18px;
      transform-origin: 4px 5px; }
    .b-menu-toggle .b-menu-toggle__line.b-menu-toggle__line_2 {
      top: 50%;
      margin-top: -1px; }
    .b-menu-toggle .b-menu-toggle__line.b-menu-toggle__line_3 {
      bottom: 18px;
      transform-origin: 3px -4px; }
  .b-menu-toggle:hover {
    background: #21A038; }

.b-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  min-width: 1200px; }
  .b-header.time-with-benefits {
    display: none; }
  .b-header.b-header__white .b-header__login .b-header__login_icon {
    background: url("../i/new/header/i-login-blank-white.svg") no-repeat; }
  .b-header.b-header__white .b-header__search .b-header__search_i:before {
    background: url("../i/new/header/i-search-white.svg") no-repeat 0 0;
    transition: opacity .25s; }
  .b-header.b-header__white .b-header__search .b-header__search_i:hover:before {
    background-position: 0 0;
    opacity: .5; }
  .b-header.b-header__white .b-header__logo {
    background: url("../i/new/header/logo-white.svg") no-repeat left center; }
  .b-header.b-header__white .b-header__line {
    background: rgba(255, 255, 255, 0.1); }
  .b-header.b-header__white .b-top-menu li:first-child:before {
    border-color: rgba(255, 255, 255, 0.1); }
  .b-header.b-header__white .b-top-menu li:before, .b-header.b-header__white .b-top-menu li:after {
    border-color: rgba(255, 255, 255, 0.1); }
  .b-header.b-header__white .b-top-menu-no-border li:first-child:before {
    border: none; }
  .b-header.b-header__white .b-top-menu-no-border li:before, .b-header.b-header__white .b-top-menu-no-border li:after {
    border: none; }
  .b-header.b-header__white .b-bottom-menu li a {
    color: rgba(255, 255, 255, 0.6); }
    .b-header.b-header__white .b-bottom-menu li a:hover, .b-header.b-header__white .b-bottom-menu li a.active {
      color: #fff; }
  .b-header.b-header__white .b-header__wrapper a {
    color: #fff; }
    .b-header.b-header__white .b-header__wrapper a:hover {
      color: rgba(255, 255, 255, 0.6); }
  .b-header.fixed {
    position: fixed;
    opacity: 0;
    visibility: hidden; }
    .b-header.fixed.transition {
      transition: opacity .35s, visibility .35s; }
    .b-header.fixed .b-header__top {
      opacity: 0;
      transition: opacity .35s; }
    .b-header.fixed .b-header__bottom {
      opacity: 0;
      visibility: hidden;
      display: none; }
  .b-header.open {
    opacity: 1;
    visibility: visible;
    z-index: 23;
    background: #f7f7f7; }
    .b-header.open:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50%;
      opacity: .1;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, black 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
      /* IE6-9 */ }
    .b-header.open .b-header__line {
      display: none; }
    .b-header.open .b-header__bottom {
      opacity: 0;
      visibility: hidden; }
    .b-header.open .b-header-main {
      visibility: visible;
      opacity: 1; }
    .b-header.open .b-header__top {
      opacity: 1; }
    .b-header.open + .b-menu-toggle .b-menu-toggle__line_2 {
      opacity: 0; }
    .b-header.open + .b-menu-toggle .b-menu-toggle__line_1 {
      transform: rotate(45deg); }
    .b-header.open + .b-menu-toggle .b-menu-toggle__line_3 {
      transform: rotate(-45deg); }
  .b-header .b-header__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 81px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .b-header .b-header__top .left,
    .b-header .b-header__top .right {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
    .b-header .b-header__top .right {
      justify-content: flex-end;
      flex-direction: row-reverse; }
    .b-header .b-header__top:after {
      display: none; }
  .b-header .b-header__line {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background: rgba(0, 0, 0, 0.1);
    height: 1px;
    bottom: 0;
    width: 100%;
    min-width: 1125px; }
  .b-header .b-header__bottom {
    position: relative;
    transition: opacity .35s, visibility .35s;
    overflow: hidden !important;
    top: -3px;
    padding-top: 3px;
    width: 100%; }
    .b-header .b-header__bottom .mCustomScrollBox,
    .b-header .b-header__bottom .mCSB_container {
      overflow: visible; }
    .b-header .b-header__bottom .mCSB_scrollTools {
      opacity: 0 !important; }
  .b-header .b-bottom-menu {
    position: relative;
    margin-bottom: 0; }
    .b-header .b-bottom-menu li {
      float: left;
      vertical-align: top;
      margin-right: 20px; }
      .b-header .b-bottom-menu li:last-child {
        margin-right: 0; }
      .b-header .b-bottom-menu li a {
        display: block;
        font-size: 14px;
        line-height: 25px;
        border: none;
        box-sizing: border-box;
        padding: 16px 3px;
        color: rgba(0, 0, 0, 0.4);
        position: relative; }
        .b-header .b-bottom-menu li a:hover {
          color: #000; }
        .b-header .b-bottom-menu li a.active {
          color: #000; }
          .b-header .b-bottom-menu li a.active:before {
            opacity: 1; }
        .b-header .b-bottom-menu li a:before {
          content: "";
          position: absolute;
          top: -3px;
          left: 0;
          right: 0;
          height: 5px;
          background: #21A038;
          opacity: 0; }
  .b-header .b-header__logo {
    float: left;
    vertical-align: top;
    width: 285px;
    background: url("../i/new/header/logo.svg") no-repeat left center;
    border: none;
    height: 100%; }
  .b-header.login-open {
    z-index: 22; }
    .b-header.login-open .b-header__login_box .b-header__login {
      z-index: 5; }
    .b-header.login-open .b-header__login_box .b-header__login_overlay {
      display: block; }
  .b-header .b-header__login_box {
    float: right;
    vertical-align: top;
    margin-left: 30px;
    position: relative; }
    .b-header .b-header__login_box .b-header__login_overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #002619;
      opacity: .5;
      z-index: 4;
      display: none; }
    .b-header .b-header__login_box .b-header__login-popup {
      position: absolute;
      background: #fff;
      border-radius: 5px;
      right: -20px;
      top: 100%;
      z-index: 5;
      width: 400px;
      padding: 30px 26px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
      display: none; }
      .b-header .b-header__login_box .b-header__login-popup:before {
        content: "";
        position: absolute;
        right: 22px;
        top: -15px;
        border-bottom: 15px solid #fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent; }
      .b-header .b-header__login_box .b-header__login-popup a {
        position: relative;
        color: #21A038;
        border-bottom: none; }
        .b-header .b-header__login_box .b-header__login-popup a:hover {
          color: #21A038; }
        .b-header .b-header__login_box .b-header__login-popup a:after {
          content: "";
          position: absolute;
          width: 9px;
          height: 9px;
          background: url("../i/new/header/i-login-header-arrows.png") no-repeat;
          left: 100%;
          margin-left: 2px;
          bottom: 70%; }
      .b-header .b-header__login_box .b-header__login-popup li {
        margin-bottom: 15px; }
        .b-header .b-header__login_box .b-header__login-popup li:last-child {
          margin-bottom: 0; }
  .b-header .b-header__login {
    display: block;
    border-bottom: none;
    padding-top: 27px;
    padding-bottom: 24px;
    padding-right: 48px;
    position: relative;
    box-sizing: border-box; }
    .b-header .b-header__login .b-header__login_icon {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -17px;
      width: 34px;
      height: 34px;
      background: url("../i/new/header/i-login-blank.svg") no-repeat; }
  .b-header .b-top-menu {
    float: right;
    vertical-align: top;
    position: relative;
    margin-bottom: 0; }
    .b-header .b-top-menu li {
      float: left;
      vertical-align: top;
      position: relative; }
      .b-header .b-top-menu li:after, .b-header .b-top-menu li:first-child:before {
        content: "";
        position: absolute;
        right: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        top: 7px;
        bottom: 7px; }
      .b-header .b-top-menu li:first-child:before {
        right: auto;
        left: 0; }
      .b-header .b-top-menu li a {
        display: block;
        border: none;
        padding-top: 27px;
        padding-bottom: 27px;
        padding-left: 10px;
        padding-right: 10px; }
  .b-header .b-top-menu-no-border li:first-child:before {
    border: none; }
  .b-header .b-top-menu-no-border li:before, .b-header .b-top-menu-no-border li:after {
    border: none; }
  .b-header .b-header__search {
    float: right;
    vertical-align: top;
    margin-right: 10px;
    height: 100%; }
    .b-header .b-header__search .b-header__search_i {
      display: block;
      border: none;
      height: 100%;
      width: 50px;
      position: relative; }
      .b-header .b-header__search .b-header__search_i:hover:before {
        background-position: -50px 0; }
      .b-header .b-header__search .b-header__search_i:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 29px;
        margin: -15px 0 0 -15px;
        background: url("../i/new/header/i-search.svg") no-repeat 0 0;
        background-size: 100px 29px; }
  .b-header .b-header__wrapper {
    z-index: 3; }

.b-header-main {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: opacity .35s, visibility .35s;
  padding-top: 81px;
  box-sizing: border-box; }
  .b-header-main .b-header-main__wrapper {
    height: 100%;
    background: #fff;
    overflow: hidden; }
  .b-header-main .b-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
    height: 100%; }
  .b-header-main .b-header-main__link {
    border-bottom: none;
    display: block;
    position: relative; }
    .b-header-main .b-header-main__link.active {
      color: #000; }
      .b-header-main .b-header-main__link.active:after {
        opacity: 1; }
    .b-header-main .b-header-main__link:after {
      content: "";
      position: absolute;
      right: -3px;
      width: 5px;
      height: 35px;
      top: 50%;
      transform: translate(0, -50%);
      background: #21A038;
      opacity: 0; }
  .b-header-main .b-header-main__circle {
    position: absolute;
    width: 545px;
    height: 545px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.03);
    top: -160px;
    right: -180px;
    transform: scale(0);
    transition: transform .25s; }
    .b-header-main .b-header-main__circle .b-header-main__circle_insert,
    .b-header-main .b-header-main__circle .b-header-main__circle_insert-pic {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%; }
    .b-header-main .b-header-main__circle .b-header-main__circle_insert {
      overflow: hidden;
      transform: scale(0);
      opacity: 0;
      transition: transform .25s, opacity .25s;
      background: #3d4b46; }
      .b-header-main .b-header-main__circle .b-header-main__circle_insert .b-header-main__circle_insert-pic {
        opacity: .45;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; }
      .b-header-main .b-header-main__circle .b-header-main__circle_insert .b-header-main__circle__text {
        color: #fff;
        font-size: 19px; }
        .b-header-main .b-header-main__circle .b-header-main__circle_insert .b-header-main__circle__text .b-price {
          display: block;
          font-weight: bold;
          font-family: "SB Sans Display Bold";
          margin-top: 25px; }
    .b-header-main .b-header-main__circle.show {
      transform: scale(1); }
      .b-header-main .b-header-main__circle.show .b-header-main__circle__text-main {
        opacity: 1; }
      .b-header-main .b-header-main__circle.show.open .b-header-main__circle__text-main {
        display: none; }
      .b-header-main .b-header-main__circle.show.open .b-header-main__circle_insert {
        transform: scale(1);
        opacity: 1; }
        .b-header-main .b-header-main__circle.show.open .b-header-main__circle_insert .b-header-main__circle__text {
          opacity: 1; }
    .b-header-main .b-header-main__circle .b-header-main__circle__text {
      width: 210px;
      margin: 226px 0 0 100px;
      position: relative;
      opacity: 0;
      transition: opacity .25s .25s; }
      .b-header-main .b-header-main__circle .b-header-main__circle__text.b-header-main__circle__text-main {
        color: rgba(0, 0, 0, 0.4);
        padding-top: 52px; }
        .b-header-main .b-header-main__circle .b-header-main__circle__text.b-header-main__circle__text-main:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 38px;
          background: url("../i/new/header/header-main-circle-arrow.svg") no-repeat; }
  .b-header-main .b-header-main__l,
  .b-header-main .b-header-main__r {
    float: left;
    vertical-align: top;
    padding-top: 35px;
    position: relative; }
  .b-header-main .b-header-main__l {
    width: 27%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
  .b-header-main .b-header-main__r {
    box-sizing: border-box;
    width: 73%;
    height: 100%;
    padding-left: 45px; }

.b-header-main__nav {
  position: relative;
  margin: 24px 0;
  padding: 16px 0 10px; }
  .b-header-main__nav ~ .b-header-main__link {
    margin-bottom: 13px; }
  .b-header-main__nav:before {
    top: 0; }
  .b-header-main__nav:after {
    bottom: 0; }
  .b-header-main__nav:before, .b-header-main__nav:after {
    content: "";
    position: absolute;
    left: 0;
    width: 75%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1); }
  .b-header-main__nav li {
    margin: 10px 0 10px; }
  .b-header-main__nav .b-header-main__link {
    padding-left: 32px; }
    .b-header-main__nav .b-header-main__link:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      opacity: .3;
      transition: opacity .25s; }
    .b-header-main__nav .b-header-main__link:hover:before {
      opacity: .7; }
    .b-header-main__nav .b-header-main__link.active:before {
      background-position: -50px 0;
      opacity: .15; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_travel:before {
      left: -2px;
      top: 1px;
      width: 20px;
      height: 20px;
      background: url("../i/new/header/menu-travel.svg") no-repeat 0 0;
      background-size: 100px 20px; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_health:before {
      left: -2px;
      top: 1px;
      width: 21px;
      height: 20px;
      background: url("../i/new/header/menu-health.svg") no-repeat 0 0;
      background-size: 100px 20px; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_family:before {
      left: 0;
      top: 1px;
      width: 16px;
      height: 23px;
      background: url("../i/new/header/menu-family.svg") no-repeat 0 0;
      background-size: 100px 23px; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_build:before {
      left: -5px;
      top: 3px;
      width: 25px;
      height: 19px;
      background: url("../i/new/header/menu-build.svg") no-repeat 0 0;
      background-size: 100px 20px; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_finance:before {
      left: -1px;
      top: 5px;
      width: 16px;
      height: 19px;
      background: url("../i/new/header/menu-finance.svg") no-repeat 0 0;
      background-size: 100px 20px; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_business:before {
      left: 2px;
      top: 1px;
      width: 12px;
      height: 25px;
      background: url("../i/new/header/menu-business.svg") no-repeat 0 0;
      background-size: 100px 25px; }
    .b-header-main__nav .b-header-main__link.b-header-main__link_investment:before {
      left: -3px;
      top: 2px;
      width: 24px;
      height: 23px;
      background: url("../i/new/header/menu-invest.svg") no-repeat 0 0;
      background-size: 100px 23px; }

.b-header-main__list {
  margin-bottom: 36px; }
  .b-header-main__list.b-header-main__list_online a:after {
    content: "онлайн";
    display: inline-block;
    vertical-align: top;
    margin-left: 2px; }
  .b-header-main__list.b-header-main__list_archive a:after {
    content: "архив";
    display: inline-block;
    vertical-align: top;
    margin-left: 2px; }
  .b-header-main__list:last-child {
    margin-bottom: 0; }
  .b-header-main__list li {
    max-width: 50%; }
    .b-header-main__list li a {
      padding: 5px 0;
      display: inline-block;
      vertical-align: top;
      position: relative;
      border: none;
      color: rgba(0, 0, 0, 0.4); }

.b-header-main__tab {
  position: relative;
  display: none; }
  .b-header-main__tab.active {
    display: block; }
  .b-header-main__tab.b-header-main__tab_main {
    margin-bottom: 0; }
    .b-header-main__tab.b-header-main__tab_main .b-header-main__list {
      padding-top: 15px; }
      .b-header-main__tab.b-header-main__tab_main .b-header-main__list li {
        box-sizing: border-box;
        padding-right: 5%;
        display: inline-block;
        vertical-align: top;
        width: 36%; }
  .b-header-main__tab .b-header-main__tel {
    position: relative;
    margin-bottom: 14px; }
    .b-header-main__tab .b-header-main__tel span {
      display: block;
      font-weight: bold;
      font-family: "SB Sans Display Bold";
      text-transform: uppercase;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: -5px; }
    .b-header-main__tab .b-header-main__tel a {
      border: none;
      color: #000;
      font-size: 45px;
      line-height: 55px;
      font-weight: bold;
      font-family: "SB Sans Display Bold"; }

.b-header-main__l_tel {
  display: none; }

#mCSB_2_container {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 767px) {
    #mCSB_2_container {
      left: 0 !important; } }

.online-helper__button {
  display: none;
  padding: 5px 18px;
  background: #FFFFFF;
  border-radius: 3px;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #47525A !important;
  text-decoration: none;
  margin-right: 20px;
  white-space: nowrap; }
  .online-helper__button:hover, .online-helper__button:active {
    text-decoration: none; }
  @media only screen and (max-width: 768px) {
    .online-helper__button {
      display: block; } }

.online-helper__menu {
  position: absolute;
  left: 0;
  top: 82px;
  background: #FFFFFF;
  border-radius: 0px 0px 8px 8px;
  transition: all .3s linear;
  opacity: 0;
  visibility: hidden;
  width: 100%; }
  .online-helper__menu.active {
    opacity: 1;
    visibility: visible; }
  .online-helper__menu > li {
    display: flex;
    align-items: center;
    padding: 35px 45px;
    border-top: 1px solid #ccc;
    width: 100%; }
    @media only screen and (max-width: 1024px) {
      .online-helper__menu > li {
        padding: 27px 15px; } }
    .online-helper__menu > li > svg {
      width: 46px;
      margin-right: 28px; }
    .online-helper__menu > li > a {
      width: calc(100% - 74px);
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      color: rgba(57, 69, 78, 0.8) !important;
      text-decoration: none;
      max-width: 500px !important; }
      .online-helper__menu > li > a:hover, .online-helper__menu > li > a:active {
        text-decoration: none; }
      @media only screen and (max-width: 1600px) {
        .online-helper__menu > li > a {
          font-size: 14px; } }
      @media only screen and (max-width: 1024px) {
        .online-helper__menu > li > a {
          width: calc(100% - 43px); } }
    .online-helper__menu > li:last-child {
      border-radius: 0px 0px 8px 8px; }

.online-helper__dropdown {
  border: none;
  outline: none;
  padding: 27px 45px;
  background: transparent;
  color: #FFF;
  transition: all .3s linear; }
  @media only screen and (max-width: 1024px) {
    .online-helper__dropdown {
      padding: 27px 15px; } }
  .online-helper__dropdown.active {
    background: #FFF;
    color: rgba(57, 69, 78, 0.8) !important; }

.scroll__menu {
  overflow-x: auto;
  display: flex;
  width: 100% !important; }

.scroll__menu {
  scrollbar-color: #47525A #56675f;
  -ms-overflow-style: auto;
  scrollbar-color: #47525A #56675f;
  scrollbar-width: thin; }

.scroll__menu::-webkit-scrollbar {
  height: 0px; }

.scroll__menu::-webkit-scrollbar-thumb {
  background: #47525A; }

.scroll__menu::-webkit-scrollbar-track {
  background: #56675f; }

@media only screen and (min-width: 1600px) {
  .b-header {
    font-size: 19px; }
    .b-header .b-bottom-menu li a {
      font-size: 16px; }
    .b-header .b-header__login_box .b-header__login-popup {
      width: 450px; }
  .b-header-main .b-header-main__circle .b-header-main__circle__text {
    width: 270px;
    font-size: 21px;
    line-height: 30px; } }

@media only screen and (min-width: 1200px) {
  .b-header .b-header__bottom .mCustomScrollBox {
    overflow: visible; } }

@media only screen and (min-width: 768px) {
  .b-header .b-header__bottom .mCustomScrollBox {
    max-width: none !important; } }

@media only screen and (max-width: 1199px) {
  .b-header {
    font-size: 15px;
    line-height: 20px;
    min-width: 0; }
    .b-header .b-header__search {
      margin-right: 0; }
      .b-header .b-header__search .b-header__search_i {
        width: 56px; }
        .b-header .b-header__search .b-header__search_i:before {
          width: 26px;
          height: 25px;
          background-size: cover !important;
          background-position: 0 0 !important;
          margin: -12px 0 0 -13px; }
    .b-header .b-header__line {
      width: auto !important;
      left: -30px;
      right: -12px;
      transform: none;
      min-width: 0; }
    .b-header .b-bottom-menu {
      white-space: nowrap; }
      .b-header .b-bottom-menu li {
        margin-right: 10px; }
        .b-header .b-bottom-menu li a {
          font-size: 13px;
          line-height: 25px; }
    .b-header .b-header__logo {
      width: 220px;
      background-size: contain; }
    .b-header .b-header__login_box {
      height: 100%;
      margin-left: 0 !important; }
      .b-header .b-header__login_box .b-header__login-popup {
        right: -10px; }
        .b-header .b-header__login_box .b-header__login-popup:before {
          right: 12px; }
      .b-header .b-header__login_box .b-header__login {
        height: 100%; }
        .b-header .b-header__login_box .b-header__login .b-header__login_text {
          display: none; }
    .b-header .b-top-menu li:nth-child(1) a {
      max-width: 200px; }
    .b-header .b-top-menu li:nth-child(2) a {
      max-width: 110px; }
    .b-header .b-top-menu li a {
      padding: 20px 15px;
      max-width: 131px; }
    .b-header .b-top-menu-no-border li:nth-child(2) a {
      max-width: 160px; }
  .b-menu-toggle {
    transform: scale(0.82);
    margin-left: -34px; }
  .b-header-main .b-header-main__l {
    width: 30%; }
  .b-header-main .b-header-main__r {
    width: 70%; }
  .b-header-main .b-header-main__circle {
    display: none; }
  .b-header-main__tab.b-header-main__tab_main .b-header-main__list li {
    width: 47%;
    float: left;
    vertical-align: top; }
  .b-header-main__tab .b-header-main__list li {
    max-width: none; }
  .b-header .b-bottom-menu {
    width: 800px; } }

@media only screen and (max-width: 850px) {
  .b-header .b-top-menu-no-border li {
    float: none; }
    .b-header .b-top-menu-no-border li a {
      padding: 10px; } }

@media only screen and (max-width: 767px) {
  .b-header-main__l_tel {
    display: block;
    text-align: center;
    padding-bottom: 7px; }
    .b-header-main__l_tel .b-header-main__tel {
      margin-bottom: 17px; }
      .b-header-main__l_tel .b-header-main__tel span {
        font-size: 10px;
        line-height: 15px;
        text-transform: uppercase;
        opacity: .3;
        margin-bottom: 10px;
        display: block; }
      .b-header-main__l_tel .b-header-main__tel a {
        display: inline-block;
        vertical-align: top;
        font-size: 23px;
        line-height: 27px;
        color: #21A038;
        border-bottom: none; }
  .b-header-main__list {
    margin-bottom: 0; }
    .b-header-main__list li:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .b-header-main__list li:last-child {
      border-bottom: none !important; }
    .b-header-main__list li a {
      padding: 11px 0 9px; }
      .b-header-main__list li a .b-link__text {
        border-bottom: none; }
  .b-header-main {
    padding-top: 70px;
    padding-bottom: 7px;
    position: relative;
    height: auto !important; }
    .b-header-main .b-header-main__link.b-header-main__link_last {
      padding: 14px 0 16px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0; }
    .b-header-main .b-header-main__link:after {
      right: 0;
      width: 16px;
      height: 7px;
      border: none;
      background: url("../i/new/i-accordion.svg") no-repeat; }
    .b-header-main .b-wrapper {
      padding: 0; }
    .b-header-main .b-header-main__l {
      width: 100%;
      padding-top: 0;
      border-right: none; }
      .b-header-main .b-header-main__l .b-header-main__nav {
        margin: 0 0 20px;
        padding: 0; }
        .b-header-main .b-header-main__l .b-header-main__nav .b-header-main__tab {
          margin-left: -5.5%;
          margin-right: -5.5%;
          padding: 17px 5.5% 0;
          box-shadow: 0 0 100px rgba(0, 0, 0, 0.15); }
          .b-header-main .b-header-main__l .b-header-main__nav .b-header-main__tab h6 {
            padding-top: 18px;
            border-top: 1px solid rgba(0, 0, 0, 0.1); }
            .b-header-main .b-header-main__l .b-header-main__nav .b-header-main__tab h6:first-child {
              padding-top: 0;
              border-top: none; }
        .b-header-main .b-header-main__l .b-header-main__nav:before, .b-header-main .b-header-main__l .b-header-main__nav:after {
          display: none; }
        .b-header-main .b-header-main__l .b-header-main__nav li {
          margin: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
          .b-header-main .b-header-main__l .b-header-main__nav li .b-header-main__link {
            padding-top: 14px;
            padding-bottom: 16px; }
            .b-header-main .b-header-main__l .b-header-main__nav li .b-header-main__link:before {
              transform: translate(0, 11px); }
      .b-header-main .b-header-main__l .b-header-main__link.b-header-main__link_first {
        display: none; }
    .b-header-main .b-header-main__r {
      display: none; }
  .b-menu-toggle {
    transition: top .25s; }
  .b-header {
    height: 111px; }
    .b-header.b-header__white.open .b-header__logo {
      background-image: url("../i/new/header/logo.svg"); }
    .b-header.b-header__white.open .b-header__login .b-header__login_icon {
      background-image: url("../i/new/header/i-login-blank.svg"); }
    .b-header.b-header__white .b-header__wrapper a {
      color: #21A038; }
    .b-header.b-header__white .b-header__search .b-header__search_i:before {
      background-image: url("../i/new/header/i-search.svg"); }
    .b-header.fixed + .b-menu-toggle {
      top: 29px; }
    .b-header.open {
      background: #fff;
      overflow: scroll;
      height: auto; }
      .b-header.open .b-top-menu {
        position: absolute;
        top: 110px;
        left: -52px;
        right: -6.5%;
        padding: 0 5%;
        font-size: 13px;
        line-height: 17px;
        text-align: center; } }
      @media only screen and (max-width: 767px) and (max-width: 500px) {
        .b-header.open .b-top-menu {
          top: 80px;
          left: -100px; } }

@media only screen and (max-width: 767px) {
        .b-header.open .b-top-menu:before {
          top: 0; }
        .b-header.open .b-top-menu:after {
          bottom: 0;
          visibility: visible; }
        .b-header.open .b-top-menu:before, .b-header.open .b-top-menu:after {
          content: "";
          position: absolute;
          left: 5%;
          right: 5%;
          height: 1px;
          background: rgba(0, 0, 0, 0.1); }
        .b-header.open .b-top-menu li {
          float: none;
          display: inline-block; }
          .b-header.open .b-top-menu li + li {
            margin-left: -3px; }
          .b-header.open .b-top-menu li a {
            padding: 16px 0 18px; }
          .b-header.open .b-top-menu li:nth-child(1):before {
            display: none; }
          .b-header.open .b-top-menu li:nth-child(1) a {
            width: 140px; }
          .b-header.open .b-top-menu li:nth-child(2) a {
            width: 90px; }
          .b-header.open .b-top-menu li:nth-child(3) a {
            width: 110px; }
          .b-header.open .b-top-menu li:nth-child(3):after {
            display: none; }
      .b-header.open .b-top-menu-no-border {
        display: none; }
      .b-header.open .b-header__search {
        float: left;
        vertical-align: top;
        width: 95%;
        height: 50px;
        display: block;
        text-align: center;
        margin-left: 5%;
        margin-top: -6px; }
        .b-header.open .b-header__search .b-header__search_i {
          display: inline-block;
          vertical-align: top; }
          .b-header.open .b-header__search .b-header__search_i:before {
            width: 30px;
            height: 29px;
            margin: -15px 0 0 -15px; }
    .b-header .b-header__wrapper {
      width: 100%;
      padding-right: 5%;
      padding-left: 5%; }
    .b-header .b-header__logo {
      width: 168px;
      background-size: contain !important; }
    .b-header .b-top-menu,
    .b-header .b-header__search {
      display: none; }
    .b-header .b-header__top {
      height: 64px; }
    .b-header .b-header__bottom {
      top: -2px;
      overflow: scroll;
      padding-top: 0; }
    .b-header .b-bottom-menu {
      width: 690px; }
      .b-header .b-bottom-menu li {
        margin-right: 6px; }
        .b-header .b-bottom-menu li:last-child {
          margin-right: 26px; }
        .b-header .b-bottom-menu li a {
          font-size: 11px;
          padding-top: 12px;
          padding-bottom: 10px; }
          .b-header .b-bottom-menu li a:before {
            height: 3px;
            top: 0px; }
  .b-menu-toggle {
    margin-left: -38px;
    top: 65px; }
  .b-header .b-header__login_box .b-header__login-popup {
    right: 0;
    padding: 0 15px 2px;
    border-top-right-radius: 0; }
    .b-header .b-header__login_box .b-header__login-popup:before {
      right: 0; }
    .b-header .b-header__login_box .b-header__login-popup li {
      padding: 17px 0 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0; }
      .b-header .b-header__login_box .b-header__login-popup li:last-child {
        border-bottom: 0; } }

.ulLine {
  width: 75%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1); }

@media only screen and (max-width: 767px) {
  .ulLine {
    display: none; } }

.overflowPreload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: #fff !important;
  transition: .2s;
  opacity: 1; }
  .overflowPreload .loader {
    font-size: 60px !important;
    letter-spacing: 10px !important;
    text-transform: uppercase;
    position: relative;
    color: #000 !important; }
    .overflowPreload .loader span {
      font-size: 60px !important;
      letter-spacing: 10px !important;
      color: #fff !important;
      mix-blend-mode: difference;
      position: relative;
      background: transparent !important; }

.scrollTop {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #333333;
  cursor: pointer; }
  .scrollTop:before {
    content: '';
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 20px;
    width: 20px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: 1s; }
  .scrollTop:after {
    content: '';
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 20px;
    width: 20px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: 1s; }
  .scrollTop:hover:before {
    top: 45%; }
  .scrollTop:hover:after {
    top: 65%; }

@media screen and (max-width: 1600px) {
  .new-menu-list {
    display: flex;
    flex-direction: column;
    min-width: 160px; }
  .b-header .b-top-menu.new-menu-list li a {
    padding: 0; } }

.s-ecosystem-menu {
  position: relative;
  z-index: 2;
  margin-right: 15px; }

.s-ecosystem-menu__toggle {
  display: block;
  height: 40px;
  width: 40px;
  background: url(../i/new/ecoSystem/ecosystem_icon.svg) no-repeat 50%/cover;
  font-size: 0;
  border: 0 none; }

.s-ecosystem-menu__toggle_active {
  background-image: url(../i/new/ecoSystem/ecosystem_icon_active.svg); }

.s-ecosystem-menu__body {
  display: none;
  position: absolute;
  left: -20px;
  top: calc(100% + 8px);
  box-sizing: border-box;
  padding: 32px 20px 12px;
  background: #fff;
  box-shadow: 0 3px 28px rgba(73, 78, 83, 0.15);
  border-radius: 10px; }

.s-ecosystem-menu__list {
  display: flex;
  width: 264px;
  flex-wrap: wrap; }

.s-ecosystem-menu__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px;
  padding-bottom: 20px;
  text-decoration: none;
  border-bottom: 0 !important; }

.s-ecosystem-menu__item._more {
  font-size: 12px;
  margin: 12px 0 0;
  width: 100%;
  text-align: center;
  color: #353850 !important; }

.s-ecosystem-menu__logo {
  height: 48px;
  width: 48px; }

.s-ecosystem-menu__label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 12px;
  color: #494e53;
  text-align: center;
  white-space: nowrap;
  text-decoration: none; }

.cc_banner {
  background: #21A038 !important; }
  @media only screen and (max-width: 767px) {
    .cc_banner {
      position: relative; } }

.cc_banner .cc_message {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto !important;
  line-height: 1.2 !important;
  font-size: 12px !important;
  color: #fff; }
  @media only screen and (max-width: 767px) {
    .cc_banner .cc_message {
      text-align: justify;
      margin-bottom: 60px !important; } }
  .cc_banner .cc_message a {
    color: #fff !important;
    border-bottom: 1px solid #fff;
    transition: .5s; }
    .cc_banner .cc_message a:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 0); }

.cc_container .cc_btn, .cc_container .cc_btn:visited {
  border-color: transparent !important;
  background-color: #FFF !important;
  color: #21a038 !important;
  margin: 0 60px 0 10px !important;
  height: 35px;
  width: 35px; }
  @media only screen and (max-width: 767px) {
    .cc_container .cc_btn, .cc_container .cc_btn:visited {
      position: absolute;
      bottom: 0;
      left: calc(50% - 60px);
      width: 100%;
      height: 30px;
      padding: 0 !important;
      float: none !important;
      margin: 10px auto !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      font-size: 1.2em !important;
      background-position: right center; } }

#fvb, #finevision_banner {
  display: none; }

#finevision-need-pay {
  min-height: 80px; }

.finevision-navbar-label {
  font-size: 18px; }

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.frame {
  height: 16px;
  /* equals max image height */
  white-space: nowrap;
  text-align: center;
  margin: .4em 0; }

.frameForSingle {
  margin-top: 15px;
  white-space: nowrap;
  text-align: center; }

._navbar-custom {
  color: black;
  font-size: 14px;
  font-family: "SB Sans Display" !important;
  z-index: 2147483647;
  line-height: 100% !important;
  /*position: absolute;*/
  width: 100%;
  top: 0;
  height: 0;
  /* make it minimum hight */ }

ul._nav {
  width: auto !important; }

._navbar {
  min-height: 90px !important;
  letter-spacing: 0; }
  @media screen and (max-width: 767px) {
    ._navbar {
      height: 165px !important; } }

#_navbar {
  visibility: visible; }

.bold {
  font-weight: bold;
  font-family: "SB Sans Display Bold"; }

ul._nav > li {
  margin-left: 30px;
  border: none !important; }

ul._nav > li > div.frame {
  width: auto !important; }

button._btn {
  height: auto !important; }

.finevision-white {
  background-color: white  !important;
  color: black  !important; }

.finevision-black {
  background-color: black  !important;
  color: white  !important; }

.finevision-darkblue {
  background-color: darkblue  !important;
  color: yellow  !important; }

.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  /* IE 6-9 */ }

.hidden {
  display: none !important; }

#finevision-on {
  display: block !important; }

.finevision-on {
  display: block !important; }

#finevision-off {
  display: none !important; }

.finevision-off {
  display: none !important; }

.shadow-off {
  box-shadow: none !important;
  text-shadow: none !important; }

* {
  text-shadow: none !important; }

.siteBackgroundcurrent div {
  background-image: none !important; }

.wixAds {
  display: none !important; }

.jimdo-free-footer-ad {
  display: none !important; }

#fm_wrapper_h_menu {
  display: none !important; }

.sites-cookie-notice-fit {
  display: none !important; }

.s13itemsContainer div div {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  /* IE 6-9 */ }

.finevision-leftmenu {
  margin: 0;
  padding: 0;
  width: 260px;
  height: 0 !important;
  list-style: none !important; }

.finevision-leftmenu > li {
  margin: 10px;
  padding: 0; }

#finevision-leftmenu {
  margin: 0;
  padding: 0;
  width: 260px;
  height: 0 !important; }

.finevision-content {
  padding-left: 280px !important;
  padding-top: 40px !important; }

#finevision-content {
  padding-left: 280px !important;
  padding-top: 40px !important;
  width: 1000px !important; }

.finevision-content-100 {
  padding-left: 280px !important;
  padding-top: 100px !important; }

.finevision-topmenu {
  float: left !important;
  margin: 0 !important;
  padding: 20px 0 !important;
  position: relative !important; }

.finevision-topmenu > li {
  float: left;
  margin: 0 5px;
  padding: 0 5px;
  list-style: none; }

.finevision-header {
  float: left !important;
  position: relative !important;
  margin: 20px 10px 30px 10px !important;
  font-size: 26px !important;
  width: auto !important;
  left: 0px !important; }

.finevision-line-height-1 {
  line-height: 1 !important; }

.finevision-line-height-2 {
  line-height: 2 !important; }

.finevision-line-height-3 {
  line-height: 3 !important; }

.finevision-iframe-1.25 {
  -ms-zoom: 1.25;
  -moz-transform: scale(1.25);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.25);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1.25);
  -webkit-transform-origin: 0 0; }

#jivo-iframe-container {
  display: none !important;
  top: 1000px !important; }

.small-font {
  background: #FFFFFF;
  font-size: 12px; }

#finevision-bill {
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left; }

[data-toggle=_modal] {
  display: none !important; }

._nav._navbar-_nav li:nth-child(4),
._nav._navbar-_nav li:nth-child(6) {
  display: none !important; }

#finevision_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000; }

body.finevision-white,
body.finevision-black,
body.finevision-darkblue {
  margin-top: 100px; }
  @media screen and (max-width: 767px) {
    body.finevision-white,
    body.finevision-black,
    body.finevision-darkblue {
      margin-top: 165px; } }
  body.finevision-white .b-index-carousel,
  body.finevision-black .b-index-carousel,
  body.finevision-darkblue .b-index-carousel {
    height: auto; }
  body.finevision-white .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label,
  body.finevision-black .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label,
  body.finevision-darkblue .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label {
    min-height: auto; }
  body.finevision-white .b-product .b-product__pic,
  body.finevision-black .b-product .b-product__pic,
  body.finevision-darkblue .b-product .b-product__pic {
    display: none; }
  body.finevision-white .b-product .b-product__tags,
  body.finevision-black .b-product .b-product__tags,
  body.finevision-darkblue .b-product .b-product__tags {
    height: auto; }
  body.finevision-white .b-product .b-product__text_overlay,
  body.finevision-black .b-product .b-product__text_overlay,
  body.finevision-darkblue .b-product .b-product__text_overlay {
    position: relative; }
    body.finevision-white .b-product .b-product__text_overlay:before,
    body.finevision-black .b-product .b-product__text_overlay:before,
    body.finevision-darkblue .b-product .b-product__text_overlay:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background: linear-gradient(rgba(255, 255, 255, 0), #fff);
      z-index: 1000; }
  body.finevision-white .b-index-carousel__box .slick-arrow,
  body.finevision-white .b-calc__result.b-calc__result_alone, body.finevision-white .b-calc__result.b-calc__result_bottom,
  body.finevision-white .b-refer-manual a,
  body.finevision-white .b-menu-toggle,
  body.finevision-black .b-index-carousel__box .slick-arrow,
  body.finevision-black .b-calc__result.b-calc__result_alone,
  body.finevision-black .b-calc__result.b-calc__result_bottom,
  body.finevision-black .b-refer-manual a,
  body.finevision-black .b-menu-toggle,
  body.finevision-darkblue .b-index-carousel__box .slick-arrow,
  body.finevision-darkblue .b-calc__result.b-calc__result_alone,
  body.finevision-darkblue .b-calc__result.b-calc__result_bottom,
  body.finevision-darkblue .b-refer-manual a,
  body.finevision-darkblue .b-menu-toggle {
    background: grey !important; }
  body.finevision-white .b-refer-manual a,
  body.finevision-black .b-refer-manual a,
  body.finevision-darkblue .b-refer-manual a {
    line-height: normal !important; }
  body.finevision-white .b-calc__result.b-calc__result_alone, body.finevision-white .b-calc__result.b-calc__result_bottom span,
  body.finevision-black .b-calc__result.b-calc__result_alone,
  body.finevision-black .b-calc__result.b-calc__result_bottom span,
  body.finevision-darkblue .b-calc__result.b-calc__result_alone,
  body.finevision-darkblue .b-calc__result.b-calc__result_bottom span {
    background: transparent !important; }
  body.finevision-white .b-header .b-header__logo,
  body.finevision-black .b-header .b-header__logo,
  body.finevision-darkblue .b-header .b-header__logo {
    background: url(../i/new/header/logo.svg) no-repeat 0 !important; }
  body.finevision-white .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label .b-index-sort__icon,
  body.finevision-black .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label .b-index-sort__icon,
  body.finevision-darkblue .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label .b-index-sort__icon {
    background: rgba(0, 0, 0, 0.1) !important; }
  body.finevision-white .b-popup__overlay.b-popup__overlay_activation .b-popup__close, body.finevision-white .b-popup__overlay.b-popup__overlay_feedback .b-popup__close, body.finevision-white .b-popup__overlay.b-popup__overlay_ins .b-popup__close, body.finevision-white .b-popup__overlay.b-popup__overlay_refer-manual .b-popup__close,
  body.finevision-black .b-popup__overlay.b-popup__overlay_activation .b-popup__close,
  body.finevision-black .b-popup__overlay.b-popup__overlay_feedback .b-popup__close,
  body.finevision-black .b-popup__overlay.b-popup__overlay_ins .b-popup__close,
  body.finevision-black .b-popup__overlay.b-popup__overlay_refer-manual .b-popup__close,
  body.finevision-darkblue .b-popup__overlay.b-popup__overlay_activation .b-popup__close,
  body.finevision-darkblue .b-popup__overlay.b-popup__overlay_feedback .b-popup__close,
  body.finevision-darkblue .b-popup__overlay.b-popup__overlay_ins .b-popup__close,
  body.finevision-darkblue .b-popup__overlay.b-popup__overlay_refer-manual .b-popup__close {
    background: url(../i/new/i-popup-close-green.svg) no-repeat !important; }
  body.finevision-white .js-header-wrapper.b-header__wrapper.b-wrapper,
  body.finevision-white .js-sort-section.b-index-sort__section,
  body.finevision-white .b-index-sort__section .b-index-sort__box.b-index-sort__box_big,
  body.finevision-white .b-header-main__tel .finevision-white,
  body.finevision-black .js-header-wrapper.b-header__wrapper.b-wrapper,
  body.finevision-black .js-sort-section.b-index-sort__section,
  body.finevision-black .b-index-sort__section .b-index-sort__box.b-index-sort__box_big,
  body.finevision-black .b-header-main__tel .finevision-white,
  body.finevision-darkblue .js-header-wrapper.b-header__wrapper.b-wrapper,
  body.finevision-darkblue .js-sort-section.b-index-sort__section,
  body.finevision-darkblue .b-index-sort__section .b-index-sort__box.b-index-sort__box_big,
  body.finevision-darkblue .b-header-main__tel .finevision-white {
    background: transparent !important; }
  body.finevision-white .b-index-carousel__box .slick-dots,
  body.finevision-black .b-index-carousel__box .slick-dots,
  body.finevision-darkblue .b-index-carousel__box .slick-dots {
    display: none; }
  body.finevision-white .b-index-carousel .b-calc__result.b-calc__result_bottom-custom:before,
  body.finevision-black .b-index-carousel .b-calc__result.b-calc__result_bottom-custom:before,
  body.finevision-darkblue .b-index-carousel .b-calc__result.b-calc__result_bottom-custom:before {
    background: url(../i/new/pages/index/i-button-custom.png) no-repeat; }
  body.finevision-white .fineBtn,
  body.finevision-black .fineBtn,
  body.finevision-darkblue .fineBtn {
    display: none; }
  body.finevision-white button, body.finevision-white a,
  body.finevision-black button,
  body.finevision-black a,
  body.finevision-darkblue button,
  body.finevision-darkblue a {
    line-height: normal !important; }
  body.finevision-white .FlexWrp li,
  body.finevision-white .FlexWrp span,
  body.finevision-white .FlexWrp a,
  body.finevision-black .FlexWrp li,
  body.finevision-black .FlexWrp span,
  body.finevision-black .FlexWrp a,
  body.finevision-darkblue .FlexWrp li,
  body.finevision-darkblue .FlexWrp span,
  body.finevision-darkblue .FlexWrp a {
    line-height: normal !important;
    background-color: transparent !important; }
  body.finevision-white .b-link__text,
  body.finevision-black .b-link__text,
  body.finevision-darkblue .b-link__text {
    line-height: 1.4 !important; }
  @media screen and (max-width: 767px) {
    body.finevision-white .js-header-circle,
    body.finevision-black .js-header-circle,
    body.finevision-darkblue .js-header-circle {
      display: none !important; } }
  body.finevision-white .b-index-sort__call,
  body.finevision-black .b-index-sort__call,
  body.finevision-darkblue .b-index-sort__call {
    background: gray !important; }
  body.finevision-white a,
  body.finevision-black a,
  body.finevision-darkblue a {
    line-height: normal !important; }
  body.finevision-white .b-button,
  body.finevision-black .b-button,
  body.finevision-darkblue .b-button {
    display: flex;
    justify-content: center;
    align-items: center; }
  body.finevision-white .partners-flex-el img,
  body.finevision-black .partners-flex-el img,
  body.finevision-darkblue .partners-flex-el img {
    filter: grayscale(0) !important; }
  body.finevision-white .b-product .b-product__text h4 .b-link .b-link__text,
  body.finevision-black .b-product .b-product__text h4 .b-link .b-link__text,
  body.finevision-darkblue .b-product .b-product__text h4 .b-link .b-link__text {
    box-shadow: none; }
  @media only screen and (max-width: 767px) {
    body.finevision-white .b-index-carousel__box .slick-arrow,
    body.finevision-black .b-index-carousel__box .slick-arrow,
    body.finevision-darkblue .b-index-carousel__box .slick-arrow {
      top: 146px; }
    body.finevision-white .b-index-carousel .b-product-section__wrapper h2 a,
    body.finevision-black .b-index-carousel .b-product-section__wrapper h2 a,
    body.finevision-darkblue .b-index-carousel .b-product-section__wrapper h2 a {
      box-shadow: none; }
    body.finevision-white .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom,
    body.finevision-black .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom,
    body.finevision-darkblue .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom {
      display: none; } }

body.finevision-white .b-product .b-product__text_overlay:before {
  background: linear-gradient(rgba(255, 255, 255, 0), #fff); }

body.finevision-black .b-product .b-product__text_overlay:before {
  background: linear-gradient(rgba(255, 255, 255, 0), #000); }

body.finevision-darkblue .b-product .b-product__text_overlay:before {
  background: linear-gradient(rgba(255, 255, 255, 0), #00008b); }

.container-fluid {
  color: #fff; }

@media screen and (max-width: 767px) {
  ._navbar-brand.bold {
    display: none; } }

._nav._navbar-_nav {
  color: #000; }
  @media screen and (max-width: 767px) {
    ._nav._navbar-_nav {
      width: 100% !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 0; }
      ._nav._navbar-_nav li {
        width: 49%;
        margin: 0 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        ._nav._navbar-_nav li ._btn-group {
          display: flex !important;
          justify-content: center; }
          ._nav._navbar-_nav li ._btn-group ._btn-lg {
            padding: 5px 8px; } }

.partners {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding-bottom: 60px; }
  @media only screen and (max-width: 767px) {
    .partners {
      padding-bottom: 30px; } }
  .partners-head {
    font-size: 30px;
    font-family: "SB Sans Display" !important;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
    padding-left: 45px;
    padding-right: 45px;
    margin-top: -25px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    @media only screen and (max-width: 767px) {
      .partners-head {
        margin-top: 0; } }
  .partners-flex {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .partners-flex {
        flex-direction: column; } }
    .partners-flex-el {
      padding: 0 20px;
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column; }
      .partners-flex-el:first-child {
        padding-left: 0; }
      .partners-flex-el:last-child {
        padding-right: 0; }
      @media only screen and (max-width: 767px) {
        .partners-flex-el {
          width: 100%;
          margin-bottom: 30px;
          padding: 0 20px !important; } }
      .partners-flex-el .img {
        height: 40px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border-bottom: 0 none; }
        .partners-flex-el .img img {
          width: 100%;
          max-height: 100%;
          height: 23px; }
      .partners-flex-el .title {
        font-size: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 50px;
        line-height: 1.3;
        color: #38783a; }
        @media only screen and (max-width: 767px) {
          .partners-flex-el .title {
            font-size: 20px;
            padding-bottom: 10px;
            height: auto; } }
      .partners-flex-el .desc {
        font-size: 13px;
        line-height: 1.3;
        color: #38783a; }
        @media only screen and (max-width: 767px) {
          .partners-flex-el .desc {
            font-size: 16px; } }

.select {
  position: relative; }
  .select-choose {
    position: relative;
    width: 100%;
    max-width: 450px;
    cursor: pointer; }
    .select-choose.active .select-input {
      border-radius: 25px 25px 0 0;
      border-bottom: 0; }
    .select-choose.active .select-arrow {
      transform: rotate(180deg); }
    .select-choose:before {
      content: '';
      position: absolute;
      height: calc(100% - 2px);
      width: 30px;
      top: 1px;
      right: 50px;
      background: linear-gradient(-90deg, #fff, rgba(255, 255, 255, 0));
      z-index: 4; }
  .select-input {
    appearance: none;
    padding-right: 50px;
    padding-left: 20px;
    height: 50px;
    width: 100%;
    max-width: 450px;
    border-radius: 50px;
    border: 1px solid #e1e1e1;
    font-size: 19px;
    font-weight: 400;
    pointer-events: none;
    box-shadow: none !important; }
  .select-arrow {
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
    height: 12px;
    width: 17px;
    transition: .5s; }
  .select-option {
    position: absolute;
    z-index: 2;
    width: 100%;
    max-width: 450px;
    top: 50px;
    left: 0;
    border: 1px solid #e1e1e1;
    border-top: 0;
    border-radius: 0 0 25px 20px;
    background: #fff;
    display: none;
    max-height: 400px;
    overflow-y: scroll; }
    .select-option-el {
      padding: 12px 24px;
      font-size: 19px;
      line-height: 25px;
      border-bottom: 1px solid #e1e1e1;
      cursor: pointer;
      transition: .5s; }
      .select-option-el.active, .select-option-el:hover {
        background: #e1e1e1; }
      .select-option-el:last-child {
        border-bottom: 0 none; }
  .select.disabled {
    cursor: no-drop; }
    .select.disabled .select-choose {
      pointer-events: none;
      opacity: .5; }

.mapBlock {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .mapBlock * {
    box-sizing: border-box; }
  .mapBlock-search {
    width: 100%;
    height: 131px;
    background: #21A038; }
    .mapBlock-search .cnt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 131px;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 20px; }
      .mapBlock-search .cnt .text {
        width: 100%;
        color: #fff;
        font-size: 24px;
        line-height: 34px; }
      .mapBlock-search .cnt .select {
        width: 349px;
        flex-shrink: 0;
        margin-right: 52px; }
        .mapBlock-search .cnt .select:last-child {
          margin-right: 0;
          margin-bottom: 0; }
  .mapBlock .map {
    height: 391px;
    width: 100%; }

.cell {
  height: 100%;
  position: relative;
  padding: 0 0 14px 0;
  border-bottom: 1px solid #BDBDBD;
  border-left: 1px solid #BDBDBD;
  border-right: 1px solid #BDBDBD;
  transition: 0.5s; }
  .cell--along {
    margin-bottom: 8px; }
  .cell--margin {
    margin-bottom: 24px; }
  .cell__border-box {
    display: flex;
    width: 100%;
    height: 16px; }
  .cell__border-left {
    flex-shrink: 0;
    width: 12px;
    border-top: 1px solid #BDBDBD;
    transition: 0.5s; }
  .cell__border-content {
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    padding: 0 12px; }
  .cell__border-right {
    flex-grow: 1;
    min-width: 24px;
    border-top: 1px solid #BDBDBD;
    transition: 0.5s; }
  .cell__name {
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.04em;
    color: #21A038;
    background-color: transparent;
    transition: 0.5s; }
  .cell__data {
    position: relative;
    padding: 0 24px; }
    .cell__data--prefix {
      padding: 0 24px 0 60px; }
  .cell__prefix-icon {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0, -50%); }
  .cell__sign {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #21A038; }
  .cell__sign-buffer {
    position: absolute;
    left: -2000px;
    top: -2000px;
    visibility: hidden;
    white-space: nowrap; }
  .cell__error-message {
    position: absolute;
    width: 100%;
    bottom: 3px;
    left: 24px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
    color: #f54848;
    display: none; }
  .cell--error {
    border-color: #f54848; }
    .cell--error .cell__name {
      color: #f54848; }
    .cell--error .cell__border-left {
      border-color: #f54848; }
    .cell--error .cell__border-right {
      border-color: #f54848; }
  .cell--40 {
    width: 40%;
    margin-right: 16px; }
  .cell__row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap; }
  .cell--100 {
    width: 100%; }
  .cell--h-auto {
    height: auto; }
  .cell--50 {
    width: calc(50% - 16px);
    margin-right: 16px; }
    .cell--50.show {
      display: block; }
    .cell--50.hide {
      display: none; }

.cell-input {
  width: 100%;
  padding: 0;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.04em;
  color: #353E48;
  border: none;
  outline: none;
  background-color: transparent; }
  .cell-input::placeholder {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #353E48; }
  .cell-input.error {
    color: #f54848; }

.list {
  margin-bottom: 24px; }
  .list__item {
    position: relative;
    padding-left: 28px;
    font-size: 16px;
    line-height: 26px; }
    .list__item--fs17 {
      font-size: 17px;
      line-height: 24px; }
    @media screen and (max-width: 768px) {
      .list__item {
        font-size: 14px;
        line-height: 22px; } }
    .list__item:not(:last-child) {
      margin-bottom: 16px; }
    .list__item:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      background-color: #21A038;
      border-radius: 50%; }

.si-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 50; }
  .si-popup--active {
    opacity: 1;
    visibility: visible; }
    .si-popup--active .si-popup__content {
      transform: translate(0, 0);
      opacity: 1; }
  .si-popup__container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    padding: 30px 10px; }
  .si-popup__content {
    position: relative;
    max-width: 560px;
    padding: 40px 48px 48px;
    background: #ffffff;
    transition: 0.5s;
    transform: translate(0, -100%);
    opacity: 0; }
    @media screen and (max-width: 768px) {
      .si-popup__content {
        padding: 24px; } }
  .si-popup__title {
    margin-bottom: 32px;
    font-size: 32px;
    line-height: 45px;
    color: #333f48; }
    @media screen and (max-width: 768px) {
      .si-popup__title {
        font-size: 22px;
        line-height: 33px; } }
    .si-popup__title--center {
      text-align: center; }
  .si-popup__text {
    margin: 0 0 10px;
    font-weight: normal;
    color: #000000;
    text-align: center; }
    .si-popup__text--no-center {
      text-align: left; }
  .si-popup__row {
    margin-bottom: 24px; }
  .si-popup__close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer; }
    @media screen and (max-width: 768px) {
      .si-popup__close {
        right: 10px;
        top: 10px; } }
    .si-popup__close-icon {
      fill: #909cb5;
      transition: 0.5s; }
      @media screen and (max-width: 768px) {
        .si-popup__close-icon {
          width: 18px; } }
      .si-popup__close-icon:hover {
        fill: #21a038; }

.gift-block {
  margin-top: 40px; }
  .gift-block--mobile {
    display: none; }
    @media screen and (max-width: 1024px) {
      .gift-block--mobile {
        display: block; } }

.gift-block__flex {
  display: flex;
  align-items: center; }
  .gift-block__flex--mobile {
    display: none; }
    @media screen and (max-width: 1024px) {
      .gift-block__flex--mobile {
        display: flex; } }

@media screen and (max-width: 600px) {
  .gift-block__flex {
    flex-direction: column; } }

.gift-block__el--left {
  margin-right: 30px; }

@media screen and (max-width: 600px) {
  .gift-block__el--left {
    margin-right: 0;
    margin-bottom: 20px; } }

.gift-block__icon {
  fill: green;
  width: 100px;
  height: 100px; }

.gift-block__text {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
  color: #333333; }

.gift-block__link {
  font-size: 18px;
  line-height: 28px;
  color: green;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.5s; }

.gift-block__title {
  font-size: 22px;
  line-height: 32px;
  color: green;
  margin-bottom: 20px;
  text-decoration: none; }

.gift-block__link:hover {
  border-bottom: 1px solid green; }

.gift-block__banner {
  width: 100%; }
  @media screen and (max-width: 1024px) {
    .gift-block__banner--desktop {
      display: none; } }
  @media screen and (max-width: 1024px) {
    .gift-block__banner--mobile {
      width: 235px;
      margin-right: 30px;
      flex-shrink: 0; } }
  @media screen and (max-width: 600px) {
    .gift-block__banner--mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px; } }

.questions {
  margin-top: 40px; }
  .questions__box {
    display: flex;
    align-items: flex-start;
    transition: 0.5s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    .questions__box--active {
      margin-bottom: 15px; }
      @media screen and (min-width: 1024px) {
        .questions__box--active {
          margin-bottom: 28px; } }
  .questions__plus {
    position: relative;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-top: 5px; }
    .questions__plus-horizontal {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 14px;
      height: 4px;
      background-color: #333F48; }
    .questions__plus-vertical {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 4px;
      height: 14px;
      background-color: #333F48;
      transition: 0.5s; }
      .questions__plus-vertical--active {
        transform: rotate(90deg); }
  .questions__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #333F48; }
    @media screen and (min-width: 1024px) {
      .questions__text {
        font-size: 28px;
        line-height: 34px; } }
  .questions__answer {
    display: none;
    padding-left: 24px; }
    @media screen and (min-width: 1200px) {
      .questions__answer {
        font-size: 19px;
        line-height: 30px; } }
    @media screen and (max-width: 1199px) {
      .questions__answer {
        font-size: 15px;
        line-height: 24px; } }
  .questions__description {
    margin-bottom: 20px; }
  .questions__table {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; }
    @media screen and (max-width: 768px) {
      .questions__table {
        flex-direction: row; } }
  .questions__row {
    display: flex;
    margin-bottom: 10px; }
    @media screen and (max-width: 768px) {
      .questions__row {
        width: 50%;
        flex-direction: column; } }
  .questions__cell {
    width: 25%;
    padding-right: 10px; }
    @media screen and (max-width: 768px) {
      .questions__cell {
        width: 100%;
        flex-direction: column; } }
    .questions__cell--bold {
      font-weight: bold; }
    .questions__cell--green {
      color: #08A652; }
  .questions__file {
    display: flex;
    align-items: center;
    border: none;
    text-decoration: none; }
    .questions__file-icon {
      margin-right: 10px; }
    .questions__file-text {
      font-size: 17px;
      line-height: 26px;
      color: #08a652; }

.questions {
  padding: 72px 0;
  background: #e7e6e6; }
  @media screen and (max-width: 767px) {
    .questions {
      padding: 40px 0 120px; } }
  .questions__list {
    list-style: none;
    margin: 0;
    padding: 0; }
  .questions__item {
    padding-bottom: 32px;
    background: #fff;
    border-bottom: 2px solid #e7e6e6; }
  .questions__box {
    display: flex;
    align-items: center;
    padding: 28px 70px 0 32px;
    transition: 0.5s;
    cursor: pointer; }
    @media screen and (max-width: 767px) {
      .questions__box {
        padding: 16px 20px; } }
  .questions__text {
    flex-grow: 1;
    margin-right: 30px;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    transition: 0.5s; }
    @media screen and (max-width: 767px) {
      .questions__text {
        font-size: 12px;
        line-height: 17px; } }
  .questions__text-inner {
    margin: 0 0 12px 32px; }
  .questions__arrow {
    flex-shrink: 0;
    position: relative;
    width: 40px;
    height: 40px;
    background: #eef3ff;
    border-radius: 50%;
    transition: 0.5s;
    background: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z' fill='%23030D45'/%3E%3C/svg%3E") center center no-repeat;
    background-size: contain; }
    .questions__arrow.questions__arrow--active {
      transform: rotate(180deg); }
    .questions__arrow--active {
      transform: rotate(-45deg); }
  .questions__answer {
    display: none;
    padding: 28px 32px 0; }
    @media screen and (max-width: 767px) {
      .questions__answer {
        font-size: 14px;
        line-height: 25px; } }
  .questions__marked-list {
    list-style-type: disc;
    list-style-position: inside; }
  .questions__body {
    padding: 140px 0 0;
    background: #FFF; }
  .questions__title {
    padding: 0 32px;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em; }

.wrapper {
  max-width: 1360px;
  padding: 0 50px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .wrapper {
      padding: 0 20px; } }

.videoElement {
  margin-left: -70px; }

.js-ns-questionable {
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 500px;
  z-index: 100; }
  @media screen and (max-width: 768px) {
    .js-ns-questionable {
      right: auto;
      width: 100%; } }

.questionable.hidden {
  display: none; }

input[type=radio] {
  display: none; }

.ns-form__head {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  background: #26c93c;
  border-radius: 24px;
  color: white;
  padding: 14px 16px;
  border: none;
  margin: 0 auto;
  z-index: 30; }

.ns-form__send {
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  background: #26c93c;
  box-shadow: 0px 8px 16px rgba(36, 201, 33, 0.2);
  border-radius: 12px;
  color: white;
  padding: 14px 16px;
  border: none;
  width: 213px;
  z-index: 30;
  margin: 10px auto;
  cursor: pointer; }

.ns-form__fieldset {
  padding: 0;
  border: none; }

.ns-form__buttons {
  display: flex;
  justify-content: space-between; }

.ns-form.js-form-hidden {
  display: none; }

.ns-form__flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.ns-form__el {
  background: white;
  box-shadow: 0px 8px 16px rgba(3, 7, 3, 0.2);
  border-radius: 24px; }
  .ns-form__el:not(:last-child) {
    margin-bottom: 24px; }

.ns-form__buttons {
  display: flex;
  justify-content: space-between; }

.ns-form__group {
  display: flex;
  gap: 12px;
  padding: 10px 0; }

.ns-form__error {
  display: none;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 140%;
  color: var(--error-primery-color); }

.ns-form.js-form-hidden {
  display: none; }

.radiobutton {
  --text-primery-color: #030e05;
  --color-green: #26c93c;
  --color-white: #ffffff; }
  .radiobutton__list {
    display: flex;
    flex-direction: column;
    gap: 12px; }
  .radiobutton__item {
    display: flex;
    flex-shrink: 0;
    align-items: center; }
  .radiobutton__window {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin-right: 12px;
    background: transparent;
    border-radius: 50%;
    border: 1px solid var(--color-green); }
  .radiobutton__circle {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--color-green);
    transform: scale(0);
    transition: 0.5s; }
    .radiobutton__circle::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: white; }
  .radiobutton__name {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: black;
    cursor: pointer; }
  .radiobutton__input:checked + .radiobutton__name .radiobutton__window {
    border-color: transparent; }
  .radiobutton__input:checked + .radiobutton__name .radiobutton__circle {
    transform: scale(1); }

.ns-cell__p20 {
  padding: 20px; }

.ns-cell__error-message {
  font-size: 16px;
  line-height: 140%;
  color: red;
  display: none;
  width: 213px;
  margin: 10px auto;
  text-align: center; }
  .ns-cell__error-message.show {
    display: block !important; }

.ns-cell-select {
  position: relative;
  width: 100%;
  z-index: 10; }
  .ns-cell-select__header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-white-first);
    text-align: center; }
    .ns-cell-select__header--v2 {
      border: 1px solid var(--color-gray5);
      border-radius: 12px;
      background: white;
      backdrop-filter: blur(26px);
      padding: 16px 24px; }
  .ns-cell-select__current {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: var(--color-black-third);
    transition: opacity 0.5s cubic-bezier(0.32, 0, 0.67, 0); }
    @media screen and (max-width: 767px) {
      .ns-cell-select__current {
        font-size: 14px; } }
  .ns-cell-select__icon {
    fill: var(--color-gray-first);
    transition: 0.5s;
    margin-left: 8px; }
    @media screen and (max-width: 767px) {
      .ns-cell-select__icon {
        width: 14px;
        height: auto; } }
  .ns-cell-select__body-icon {
    opacity: 0;
    fill: var(--color-gray-first);
    transition: 0.2s; }
  .ns-cell-select__body-link {
    text-decoration: none;
    transition: 0.5s; }
  .ns-cell-select__body-link:hover {
    color: var(--color-green); }
  .ns-cell-select__body {
    display: none;
    position: absolute;
    left: 50%;
    right: 0;
    top: calc(100% + 20px);
    width: 351px;
    max-height: 300px;
    overflow-y: auto;
    transform: translate(-50%, 0);
    background-color: #ffffff;
    box-shadow: 0px 2px 15px 0px #ced1d5;
    border-radius: 16px; }
  .ns-cell-select.is-active {
    z-index: 11; }
    .ns-cell-select.is-active .cell-select__icon {
      transform: rotate(180deg); }
  .ns-cell-select__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    padding: 16px 24px;
    color: var(--color-gray-first);
    transition: color 0.5s; }
    .ns-cell-select__item a {
      border-bottom: none; }
    @media screen and (max-width: 767px) {
      .ns-cell-select__item {
        font-size: 14px; } }
    .ns-cell-select__item.is-chosen {
      color: var(--color-black-third); }
      .ns-cell-select__item.is-chosen .cell-select__body-icon {
        opacity: 1; }
  .ns-cell-select__item:not(:last-child) {
    border-bottom: 0.2px solid rgba(135, 139, 144, 0.5); }

.biletPromoOld-banner1 {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: #F9F9F9;
  backdrop-filter: blur(26px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin: 110px 0 64px; }
  @media screen and (max-width: 767px) {
    .biletPromoOld-banner1 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, auto);
      padding: 15px 15px 32px 15px;
      margin: 50px 0; } }
  .biletPromoOld-banner1__info {
    padding: 62px 128px; }
    @media screen and (max-width: 767px) {
      .biletPromoOld-banner1__info {
        order: 2;
        width: 100%;
        padding: 0; } }
  .biletPromoOld-banner1__title {
    color: #353850;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
    @media screen and (max-width: 479px) {
      .biletPromoOld-banner1__title {
        font-size: 24px; } }
  .biletPromoOld-banner1__text {
    color: #353850;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 38px 0 22px; }
    @media screen and (max-width: 479px) {
      .biletPromoOld-banner1__text {
        font-size: 16px;
        line-height: 120%; } }
  .biletPromoOld-banner1__link {
    color: #21A038;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
    outline: 0;
    border: none;
    background: transparent; }
    @media screen and (max-width: 479px) {
      .biletPromoOld-banner1__link {
        font-size: 16px; } }
    .biletPromoOld-banner1__link:hover, .biletPromoOld-banner1__link:active, .biletPromoOld-banner1__link:visited, .biletPromoOld-banner1__link:focus {
      color: #21A038;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-decoration-line: underline; }
      @media screen and (max-width: 479px) {
        .biletPromoOld-banner1__link:hover, .biletPromoOld-banner1__link:active, .biletPromoOld-banner1__link:visited, .biletPromoOld-banner1__link:focus {
          font-size: 16px; } }
  .biletPromoOld-banner1__image {
    width: 100%;
    max-width: 549px;
    aspect-ratio: 1/0.6302; }
    .biletPromoOld-banner1__image-container {
      padding: 0 124px 0 0;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .biletPromoOld-banner1__image-container {
          width: 100%;
          padding: 0 0 24px 0;
          justify-content: center; } }

.biletPromoOld__text {
  color: #353850;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; }
  @media screen and (max-width: 479px) {
    .biletPromoOld__text {
      font-size: 16px; } }

.biletPromoOld__title {
  color: #353850;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 64px 0 24px; }
  @media screen and (max-width: 479px) {
    .biletPromoOld__title {
      font-size: 24px;
      margin: 24px 0; } }

.biletPromoOld__table {
  width: 100%;
  border-collapse: collapse; }
  .biletPromoOld__table th {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; }
  .biletPromoOld__table td {
    color: #353850;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; }
  .biletPromoOld__table th:first-of-type {
    border-radius: 10px 0px 0px 0px;
    padding: 18px 0 18px 69px;
    text-align: left; }
    @media screen and (max-width: 479px) {
      .biletPromoOld__table th:first-of-type {
        padding: 18px 0 18px 17px;
        border-radius: 0; } }
  .biletPromoOld__table th:last-of-type {
    border-radius: 0px 10px 0px 0px;
    padding: 18px 0 18px 75px;
    text-align: left; }
    @media screen and (max-width: 479px) {
      .biletPromoOld__table th:last-of-type {
        padding: 18px 0 18px 17px;
        border-radius: 0; } }
  .biletPromoOld__table td:first-of-type {
    width: 50%;
    padding: 0 0 24px 69px; }
    @media screen and (max-width: 479px) {
      .biletPromoOld__table td:first-of-type {
        width: 33%;
        padding: 0 0 24px 17px; } }
  .biletPromoOld__table td:last-of-type {
    width: 50%;
    padding: 0 0 24px 75px; }
    @media screen and (max-width: 479px) {
      .biletPromoOld__table td:last-of-type {
        width: 66%;
        padding: 0 0 24px 17px; } }
  .biletPromoOld__table-th--green {
    background: #11BE1C;
    color: #FFF; }
  .biletPromoOld__table-th--lite-green {
    background: #b8ecbb;
    color: #333F48; }
  .biletPromoOld__table-th--sea-green {
    background: #42e3b4;
    color: #FFF; }
  .biletPromoOld__table-th--pang {
    background: #c7f7e9;
    color: #333F48; }
  .biletPromoOld__table-td--empty {
    height: 32px; }
  .biletPromoOld__table-container {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(197, 197, 197, 0.17);
    margin-bottom: 24px; }
    @media screen and (max-width: 767px) {
      .biletPromoOld__table-container {
        overflow-x: auto;
        padding: 21px 0; } }
  .biletPromoOld__table-header {
    color: #353850;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin: 64px 0 24px; }
    @media screen and (max-width: 479px) {
      .biletPromoOld__table-header {
        font-size: 16px;
        margin: 32px 0 24px; } }

.biletPromoOld__subscribe {
  color: rgba(53, 56, 80, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; }
  @media screen and (max-width: 479px) {
    .biletPromoOld__subscribe {
      margin-bottom: 32px; } }

.biletPromoOld-banner2 {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background: #F9F9F9;
  backdrop-filter: blur(26px);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: 8.5px 48.5px;
  margin: 40px 0 27px; }
  @media screen and (max-width: 767px) {
    .biletPromoOld-banner2 {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, auto);
      padding: 35px 18px 57px; } }
  .biletPromoOld-banner2__image {
    width: 100%;
    max-width: 294px;
    aspect-ratio: 1/1; }
    .biletPromoOld-banner2__image-container {
      grid-column: 1 / 2;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .biletPromoOld-banner2__image-container {
          grid-row: 1 / 2;
          justify-content: center;
          flex-direction: column; } }
  .biletPromoOld-banner2__info {
    grid-column: 2 / 5;
    padding: 44px 0 45px 113px; }
    @media screen and (max-width: 767px) {
      .biletPromoOld-banner2__info {
        padding: 0;
        grid-row: 2 / 3;
        grid-column: 1 / 2; } }
  .biletPromoOld-banner2__title {
    color: #353850;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; }
    @media screen and (max-width: 479px) {
      .biletPromoOld-banner2__title {
        font-size: 20px;
        line-height: normal; } }
    .biletPromoOld-banner2__title--mobile {
      display: none; }
      @media screen and (max-width: 767px) {
        .biletPromoOld-banner2__title--mobile {
          display: block; } }
    .biletPromoOld-banner2__title--desktop {
      display: block; }
      @media screen and (max-width: 767px) {
        .biletPromoOld-banner2__title--desktop {
          display: none; } }
  .biletPromoOld-banner2__text {
    color: #353850;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin: 32px 0; }
    @media screen and (max-width: 479px) {
      .biletPromoOld-banner2__text {
        font-size: 14px;
        line-height: normal;
        margin: 20px 0; } }
  .biletPromoOld-banner2__list {
    list-style-position: inside;
    max-width: 430px; }
    .biletPromoOld-banner2__list-item {
      display: flex;
      align-items: flex-start; }
      .biletPromoOld-banner2__list-item:not(:last-of-type) {
        margin-bottom: 20px; }
      .biletPromoOld-banner2__list-item-icon {
        width: 9px;
        height: 9px;
        margin-right: 20px; }
      .biletPromoOld-banner2__list-item-text {
        color: #353850;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; }
        @media screen and (max-width: 479px) {
          .biletPromoOld-banner2__list-item-text {
            font-size: 14px;
            line-height: 140%; } }

.biletPromoOld__download {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  border-bottom: none;
  align-items: center;
  max-width: 260px; }
  .biletPromoOld__download-text {
    color: #353850;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    margin: 0 18px 0 69px; }
    @media screen and (max-width: 767px) {
      .biletPromoOld__download-text {
        font-size: 20px;
        margin: 0 18px 0 16px; } }

.biletPromoOld__dropdown-content {
  display: none; }

.b-first-section__vzr .b-product__wrapper {
  margin-right: 405px; }

.b-product__wrapper {
  position: relative;
  margin-right: 315px; }

.b-product-section__wrapper {
  position: relative;
  margin-right: 335px;
  margin-left: 0; }

.b-date-picker {
  position: relative; }
  .b-date-picker .datepick {
    margin: auto;
    font-family: "SB Sans Display" !important;
    width: 245px !important;
    font-size: 16px;
    line-height: 27px;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding-bottom: 18px; }
    .b-date-picker .datepick a {
      border: none; }
    .b-date-picker .datepick .datepick-cmd-today {
      display: none; }
    .b-date-picker .datepick .datepick-cmd-prev,
    .b-date-picker .datepick .datepick-cmd-next {
      font-size: 0;
      line-height: 0;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 1px solid #21A038;
      background: none;
      box-sizing: border-box;
      padding: 0;
      margin: 11px 0 0;
      position: relative;
      transform: background .25s; }
      .b-date-picker .datepick .datepick-cmd-prev:before,
      .b-date-picker .datepick .datepick-cmd-next:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 7px;
        height: 15px;
        margin: -8px 0 0 -4px;
        background: url("../i/new/i-arrow-datapicker.svg") no-repeat; }
      .b-date-picker .datepick .datepick-cmd-prev:hover,
      .b-date-picker .datepick .datepick-cmd-next:hover {
        border-color: #21A038;
        background: #21A038; }
        .b-date-picker .datepick .datepick-cmd-prev:hover:before,
        .b-date-picker .datepick .datepick-cmd-next:hover:before {
          background: url("../i/new/i-arrow-datapicker-hover.svg") no-repeat; }
    .b-date-picker .datepick .datepick-cmd-next:before {
      margin: -8px 0 0 -2px;
      transform: rotate(180deg); }
  .b-date-picker .datepick-month {
    border: none;
    width: 100%; }
    .b-date-picker .datepick-month table {
      table-layout: fixed; }
      .b-date-picker .datepick-month table thead {
        display: none; }
    .b-date-picker .datepick-month td {
      border: none;
      background: none;
      width: 35px; }
      .b-date-picker .datepick-month td .datepick-today {
        background: none;
        color: #000; }
      .b-date-picker .datepick-month td span, .b-date-picker .datepick-month td a {
        padding: 4px 0; }
      .b-date-picker .datepick-month td span {
        color: rgba(0, 0, 0, 0.4); }
      .b-date-picker .datepick-month td a,
      .b-date-picker .datepick-month td .datepick-weekend {
        background: none; }
      .b-date-picker .datepick-month td span.datepick-weekend {
        background: #fff; }
      .b-date-picker .datepick-month td a {
        color: #21A038;
        background: transparent;
        border-radius: 50%;
        transition: color .25s, background .25s; }
        .b-date-picker .datepick-month td a:hover {
          color: #000; }
    .b-date-picker .datepick-month tr.active-row td {
      background: rgba(0, 153, 66, 0.2); }
    .b-date-picker .datepick-month tr.last-active-row td {
      background: rgba(0, 153, 66, 0.2); }
    .b-date-picker .datepick-month tr.last-active-row.first-active-row td {
      background: none; }
      .b-date-picker .datepick-month tr.last-active-row.first-active-row td.active-td {
        background: rgba(0, 153, 66, 0.2); }
    .b-date-picker .datepick-month tr .active-td {
      border-radius: 50% 0 0 50%;
      background: rgba(0, 153, 66, 0.2); }
      .b-date-picker .datepick-month tr .active-td:first-child {
        border-radius: 5px 0 0 5px; }
      .b-date-picker .datepick-month tr .active-td:last-child {
        border-radius: 0 5px 5px 0 !important; }
        .b-date-picker .datepick-month tr .active-td:last-child.active-first-td {
          border-radius: 50% 0 0 50% !important; }
      .b-date-picker .datepick-month tr .active-td ~ .active-td {
        border-radius: 0; }
        .b-date-picker .datepick-month tr .active-td ~ .active-td .datepick-selected {
          color: #21A038 !important; }
      .b-date-picker .datepick-month tr .active-td.active-last-td {
        border-radius: 0 50% 50% 0 !important; }
        .b-date-picker .datepick-month tr .active-td.active-last-td .datepick-selected {
          color: #fff !important;
          background: #21A038; }
        .b-date-picker .datepick-month tr .active-td.active-last-td.active-td ~ td {
          background: none !important; }
    .b-date-picker .datepick-month tr .active-first-td {
      border-radius: 50% 0 0 50% !important; }
      .b-date-picker .datepick-month tr .active-first-td .datepick-selected {
        color: #fff !important;
        background: #21A038; }
      .b-date-picker .datepick-month tr .active-first-td.active-td ~ td {
        background: rgba(0, 153, 66, 0.2); }
  .b-date-picker .datepick-month-header {
    background: none;
    color: #000;
    font-size: 17px;
    font-weight: normal;
    position: absolute;
    top: 15px;
    left: 55px;
    right: 55px; }
  .b-date-picker .datepick-nav, .b-date-picker .datepick-ctrl {
    background: none; }
  .b-date-picker .datepick-nav {
    height: 48px; }
  .b-date-picker .datepick-cmd-current {
    display: none; }

.b-calc {
  position: relative;
  padding-bottom: 100px; }
  .b-calc .b-dropdown {
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.4); }
  .b-calc.b-calc__travel .b-dropdown {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
  .b-calc.b-calc__panaceya {
    padding-bottom: 150px; }
  .b-calc .b-calendar__box_faq {
    position: absolute;
    left: 70px;
    top: 160px;
    width: 200px;
    font-size: 16px;
    line-height: 25px;
    opacity: .5; }
  .b-calc .b-calc__section {
    position: relative;
    margin-bottom: 10px; }
    .b-calc .b-calc__section.passed .b-calc__section_desc {
      display: none; }
    .b-calc .b-calc__section.passed .b-calc__section_desc-show {
      display: block; }
    .b-calc .b-calc__section.passed .b-dropdown_open ~ .b-calc__section_desc-show {
      display: none; }
    .b-calc .b-calc__section .b-calc__label {
      float: left;
      vertical-align: top;
      padding-right: 10px;
      box-sizing: border-box;
      padding-top: 13px;
      width: 210px; }
      .b-calc .b-calc__section .b-calc__label.b-calc__label_short {
        width: 125px; }
  .b-calc .b-calendar__box {
    float: left;
    vertical-align: top;
    position: relative;
    width: 280px;
    background: #fff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px; }
    .b-calc .b-calendar__box .b-calendar__text {
      height: 53px;
      position: relative;
      padding: 13px 10px 13px 15px;
      z-index: 3;
      cursor: pointer; }
      .b-calc .b-calendar__box .b-calendar__text.open {
        cursor: default; }
        .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_1,
        .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_2 {
          color: #000; }
          .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_1.active,
          .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_2.active {
            color: #21A038; }
            .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_1.active:before,
            .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_2.active:before {
              opacity: 1; }
        .b-calc .b-calendar__box .b-calendar__text.open .b-calendar__text_alone.active {
          color: #000; }
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_alone {
        color: #21A038;
        display: block; }
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1,
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_2 {
        vertical-align: top;
        color: #21A038;
        position: relative; }
        .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1:before,
        .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_2:before {
          content: "";
          position: absolute;
          bottom: -16px;
          height: 5px;
          background: #21A038;
          left: -5px;
          right: -5px;
          opacity: 0; }
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1 {
        float: left; }
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_2 {
        float: right; }

.b-dropdown__box_travel-theme {
  float: left;
  vertical-align: top;
  width: 225px;
  margin-right: 15px; }

.b-dropdown__box_travel-where {
  float: left;
  vertical-align: top;
  width: 520px; }

.b-dropdown__box_travel-money {
  float: left;
  vertical-align: top;
  position: relative;
  width: 120px; }
  .b-dropdown__box_travel-money .b-dropdown__item {
    position: relative; }
  .b-dropdown__box_travel-money .b-calc__section_desc-show {
    position: absolute;
    color: rgba(0, 0, 0, 0.4);
    border: none;
    left: 100%;
    top: 50%;
    margin-top: -13px;
    margin-left: 10px;
    padding-left: 34px;
    font-size: 16px;
    line-height: 25px;
    white-space: nowrap;
    display: none; }
    .b-dropdown__box_travel-money .b-calc__section_desc-show:hover {
      color: #21A038; }
      .b-dropdown__box_travel-money .b-calc__section_desc-show:hover:before {
        opacity: 1; }
    .b-dropdown__box_travel-money .b-calc__section_desc-show:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 25px;
      height: 25px;
      background: url("../i/new/i-question.svg") no-repeat;
      opacity: .3;
      transition: opacity .25s; }
  .b-dropdown__box_travel-money .b-dropdown.b-dropdown_open .b-dropdown__text {
    overflow: visible;
    white-space: normal;
    position: initial; }
  .b-dropdown__box_travel-money .b-dropdown.b-dropdown_open .b-dropdown__sub {
    overflow: visible;
    position: initial; }
  .b-dropdown__box_travel-money .b-dropdown.b-dropdown_open .b-dropdown__item:hover .b-calc__section_money-faq,
  .b-dropdown__box_travel-money .b-dropdown.b-dropdown_open .b-dropdown__text:hover .b-calc__section_money-faq {
    display: block; }
  .b-dropdown__box_travel-money .b-calc__section_money-faq {
    position: absolute;
    left: 100%;
    top: 50%;
    margin-left: 25px;
    transform: translate(0, -50%);
    width: 235px;
    opacity: .5;
    color: #000;
    font-size: 16px;
    line-height: 25px;
    display: none; }

.b-calc__section_desc {
  float: left;
  vertical-align: top;
  width: 100%;
  margin-top: 30px; }
  .b-calc__section_desc p {
    margin-bottom: 18px; }
  .b-calc__section_desc ul {
    column-count: 2;
    -moz-column-count: 2; }
    .b-calc__section_desc ul li {
      box-sizing: border-box;
      padding-right: 10%;
      padding-left: 26px;
      position: relative;
      padding-bottom: 13px;
      -webkit-column-break-inside: avoid;
      -moz-column-break-inside: avoid;
      -moz-page-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid-column;
      display: table; }
      .b-calc__section_desc ul li:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 14px;
        height: 9px;
        background: url("../i/new/i-ul-green.svg") no-repeat; }
      .b-calc__section_desc ul li a {
        border-bottom: none; }

.b-calc__section_column {
  float: left;
  vertical-align: top;
  width: 435px; }

.b-amount__section {
  position: relative; }
  .b-amount__section.nomore .b-amount-input__i_plus {
    display: none; }
  .b-amount__section.b-amount__box_zero .b-amount__box .b-amount-input__i_minus {
    display: none; }
  .b-amount__section.b-amount__box_zero .b-amount__box .b-input {
    background: none;
    box-shadow: none;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.4); }
  .b-amount__section.b-amount__box_zero .b-amount__section_label {
    width: 245px;
    margin-right: -35px; }
  .b-amount__section .b-amount__section_label {
    float: left;
    vertical-align: top;
    width: 205px;
    margin-right: 5px;
    overflow: hidden;
    padding-top: 13px; }
    .b-amount__section .b-amount__section_label span {
      position: relative;
      display: inline-block;
      vertical-align: top; }
      .b-amount__section .b-amount__section_label span:after {
        content: "";
        position: absolute;
        left: 100%;
        margin-left: 5px;
        width: 445px;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        top: 50%; }
  .b-amount__section .b-amount__box {
    position: relative;
    width: 116px;
    float: left;
    vertical-align: top;
    margin-top: 6px; }
    .b-amount__section .b-amount__box:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -18px;
      width: 36px;
      z-index: 5; }
    .b-amount__section .b-amount__box .b-input {
      padding: 7px 5px;
      width: 35px;
      height: 41px;
      text-align: center;
      display: block;
      margin: auto; }
    .b-amount__section .b-amount__box .b-amount-input__i {
      position: absolute;
      top: 2px;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 1px solid #21A038;
      background: none;
      box-sizing: border-box;
      transition: background .25s; }
      .b-amount__section .b-amount__box .b-amount-input__i:hover {
        background: #21A038; }
        .b-amount__section .b-amount__box .b-amount-input__i:hover:before {
          background-position: -50px 0 !important; }
      .b-amount__section .b-amount__box .b-amount-input__i:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%; }
      .b-amount__section .b-amount__box .b-amount-input__i.b-amount-input__i_minus {
        left: 0; }
        .b-amount__section .b-amount__box .b-amount-input__i.b-amount-input__i_minus:before {
          width: 14px;
          height: 3px;
          margin: -2px 0 0 -7px;
          background: url("../i/new/i-amount-minus.svg") no-repeat;
          background-size: 100px 3px; }
      .b-amount__section .b-amount__box .b-amount-input__i.b-amount-input__i_plus {
        right: 0; }
        .b-amount__section .b-amount__box .b-amount-input__i.b-amount-input__i_plus:before {
          width: 14px;
          height: 14px;
          margin: -7px 0 0 -7px;
          background: url("../i/new/i-amount-plus.svg") no-repeat;
          background-size: 100px 14px; }

.b-calc__section_box {
  margin-bottom: 22px; }
  .b-calc__section_box:last-child {
    margin-bottom: 0; }

.b-checkbox__section {
  position: relative;
  margin-left: -10px; }
  .b-checkbox__section.disabled.hidden {
    display: block !important; }
  .b-checkbox__section.disabled .b-checkbox__label {
    cursor: default;
    color: rgba(0, 0, 0, 0.4); }
    .b-checkbox__section.disabled .b-checkbox__label:before {
      display: none; }
    .b-checkbox__section.disabled .b-checkbox__label .b-checkbox__icon {
      border: 1px solid rgba(0, 0, 0, 0.1) !important; }
  .b-checkbox__section .b-checkbox__faq-box_call {
    display: none; }
  .b-checkbox__section:first-child {
    margin-top: 7px; }
  .b-checkbox__section input {
    font-size: 0;
    line-height: 0;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    top: -100%;
    left: 0;
    visibility: hidden; }
    .b-checkbox__section input:checked + .b-checkbox__label {
      color: #000; }
      .b-checkbox__section input:checked + .b-checkbox__label .b-checkbox__icon {
        background: #21A038;
        border-color: #21A038; }
        .b-checkbox__section input:checked + .b-checkbox__label .b-checkbox__icon:before {
          opacity: 1; }
  .b-checkbox__section .b-checkbox__label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 6px 18px 6px 40px;
    color: #21A038;
    transition: color .25s; }
    .b-checkbox__section .b-checkbox__label:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -7px;
      bottom: -7px;
      background: #fff;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      opacity: 0;
      transition: opacity .25s; }
    .b-checkbox__section .b-checkbox__label .b-checkbox__text {
      position: relative; }
    .b-checkbox__section .b-checkbox__label .b-checkbox__icon {
      position: absolute;
      top: 9px;
      left: 10px;
      width: 20px;
      height: 20px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: transparent;
      border-radius: 5px;
      box-sizing: border-box;
      transition: border-color .25s, background .25s; }
      .b-checkbox__section .b-checkbox__label .b-checkbox__icon:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4px 0 0 -7px;
        width: 14px;
        height: 9px;
        background: url("../i/new/i-check-box-checked.svg") no-repeat;
        opacity: 0;
        transition: opacity .25s; }
    .b-checkbox__section .b-checkbox__label .b-checkbox__price {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      position: relative;
      margin-left: 7px;
      font-weight: normal; }
      .b-checkbox__section .b-checkbox__label .b-checkbox__price:before {
        content: "+";
        display: inline-block;
        vertical-align: top;
        padding-right: 3px; }
      .b-checkbox__section .b-checkbox__label .b-checkbox__price:after {
        content: "a";
        font-family: "SB Sans Display" !important;
        font-size: 90%;
        padding-left: 3px; }
    .b-checkbox__section .b-checkbox__label:hover:before {
      opacity: 1; }
    .b-checkbox__section .b-checkbox__label:hover .b-checkbox__icon {
      border-color: #21A038; }

.b-checkbox__faq {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(0, -50%);
  width: 390px;
  background: #fff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px 30px;
  margin-bottom: 40px;
  z-index: -1;
  font-size: 16px;
  line-height: 25px;
  visibility: hidden;
  opacity: 0;
  transition: visibility .25s, opacity .25s;
  z-index: 12; }
  .b-checkbox__faq.open {
    visibility: visible;
    opacity: 1; }
  .b-checkbox__faq h6 {
    margin-top: 30px;
    margin-bottom: 12px; }
    .b-checkbox__faq h6:first-child {
      margin-top: 0; }
  .b-checkbox__faq p {
    margin-bottom: 10px; }

.b-calc__promocode {
  position: relative; }
  .b-calc__promocode .b-input {
    float: left;
    vertical-align: top;
    width: 110px; }
  .b-calc__promocode .b-calc__promocode_link {
    float: left;
    vertical-align: top;
    margin: 0 0 0 20px; }
    .b-calc__promocode .b-calc__promocode_link.unactive {
      opacity: .8; }
    .b-calc__promocode .b-calc__promocode_link .disabled {
      opacity: .3;
      cursor: default; }

.b-calc__last-info {
  margin: 40px 0 0 210px;
  color: rgba(0, 0, 0, 0.4);
  width: 285px;
  position: relative; }
  .b-calc__last-info:before {
    content: "";
    position: absolute;
    left: 100%;
    bottom: 60%;
    margin-left: 16px;
    width: 236px;
    height: 82px;
    background: url("../i/new/pages/travel/i-top-arrow.svg") no-repeat right bottom; }
  .b-calc__last-info b {
    font-weight: bold;
    font-family: "SB Sans Display Bold"; }

.b-calc__result_box {
  position: absolute;
  right: 0;
  top: 0;
  width: 355px;
  height: 355px;
  z-index: 10; }
  .b-calc__result_box.alone {
    width: 280px;
    height: 280px; }
  .b-calc__result_box .b-calc__result {
    transition: visibility .25s, opacity .25s, transform .5s; }
  .b-calc__result_box.close .b-calc__result {
    transform: rotateY(-90deg);
    transition: opacity .5s .25s, transform .5s; }

.saleBlock {
  position: relative;
  margin-bottom: 20px; }
  .saleBlock .sale {
    position: absolute;
    bottom: 23px;
    left: 50%;
    transform: translate(-50%, 100%);
    font-size: 30px !important;
    line-height: 1;
    color: red;
    font-weight: 400 !important; }
    .saleBlock .sale .b-rub {
      font-size: 30px;
      font-weight: 400; }
  .saleBlock span {
    position: relative; }
  .saleBlock .saleLine {
    position: absolute;
    top: 60px;
    left: -10px;
    height: 2px;
    width: calc(100% + 40px);
    background: red;
    transform: rotate(15deg); }

.b-calc__result {
  position: absolute;
  right: 0;
  top: 0;
  width: 355px;
  height: 355px;
  border-radius: 50%;
  background: #3d4b46;
  overflow: hidden;
  color: #fff;
  z-index: 10;
  transition: visibility .25s, opacity .25s; }
  .b-calc__result.b-calc__result_mobile, .b-calc__result.b-calc__result_bottom {
    transition: visibility .25s, opacity .25s; }
  .b-calc__result.visibility {
    visibility: hidden;
    opacity: 0; }
  .b-calc__result.empty * {
    visibility: hidden; }
  .b-calc__result.empty .b-calc__result_pic {
    visibility: visible !important; }
  .b-calc__result.empty:before {
    border-radius: 50%;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3) url("../i/new/rolling.svg") no-repeat center; }
  .b-calc__result.b-calc__result_link {
    background: #21A038;
    width: 280px;
    height: 280px;
    text-align: center;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    overflow: visible; }
    .b-calc__result.b-calc__result_link.clean {
      background: none;
      box-shadow: none; }
      .b-calc__result.b-calc__result_link.clean .b-calc__result_link-activate {
        top: 30px; }
      .b-calc__result.b-calc__result_link.clean .b-calc__result_link-mian {
        display: none !important; }
    .b-calc__result.b-calc__result_link .b-calc__result_link-activate {
      position: absolute;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      right: -35px;
      top: -70px;
      background: #ffcd54;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      box-sizing: border-box;
      padding-top: 62px;
      color: #000;
      z-index: 3; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate:hover:before {
        transform: scale(1.1);
        background: rgba(255, 205, 84, 0.3); }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate:before {
        content: "";
        position: absolute;
        width: 170px;
        height: 170px;
        top: 50%;
        left: 50%;
        margin: -85px 0 0 -85px;
        border-radius: 50%;
        background: rgba(255, 205, 84, 0.1);
        transition: transform .25s, background .25s; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate span {
        display: block;
        position: relative; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-activate span:before {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-bottom: 10px;
          margin-left: -20px;
          width: 43px;
          height: 22px;
          background: url("../i/new/pages/products/i-activate-polis.svg") no-repeat; }
    .b-calc__result.b-calc__result_link .b-calc__result_link-activate.buy {
      background: #21A038;
      font-size: 26px;
      line-height: 1.4;
      color: #fff;
      padding-top: 0px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate.buy:hover:before {
        transform: scale(1.1);
        background: rgba(33, 160, 56, 0.3); }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate.buy:before {
        content: "";
        position: absolute;
        width: 170px;
        height: 170px;
        top: 50%;
        left: 50%;
        margin: -85px 0 0 -85px;
        border-radius: 50%;
        background: rgba(33, 160, 56, 0.1);
        transition: transform .25s, background .25s; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate.buy span {
        display: block;
        position: relative; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-activate.buy span:before {
          display: none; }
    .b-calc__result.b-calc__result_link .b-calc__result_link-mian {
      display: block;
      position: relative;
      border: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      font-weight: bold;
      font-family: "SB Sans Display Bold";
      font-size: 30px;
      line-height: 35px;
      padding-top: 165px;
      color: #fff;
      border-radius: 50%;
      transition: background .25s; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-mian:hover {
        background: #21A038; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-mian:before {
        content: "";
        position: absolute;
        top: 36px;
        left: 50%;
        margin-left: -80px;
        width: 149px;
        height: 113px;
        background: url("../i/new/pages/products/i-buy-polis.svg") no-repeat; }
  .b-calc__result .b-call-promo__link {
    margin: -20px 0 15px;
    display: none; }
    .b-calc__result .b-call-promo__link.used a {
      margin-left: 24px; }
      .b-calc__result .b-call-promo__link.used a:before {
        display: block; }
    .b-calc__result .b-call-promo__link a {
      color: #fff;
      border-bottom-color: rgba(255, 255, 255, 0.33);
      transition: color .25s;
      position: relative; }
      .b-calc__result .b-call-promo__link a:before {
        content: "%";
        line-height: 38px;
        font-weight: bold;
        font-family: "SB Sans Display Bold";
        color: #fff;
        text-align: center;
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.1);
        right: 100%;
        top: 50%;
        margin-top: -19px;
        margin-right: 9px;
        padding-left: 2px;
        display: none; }
      .b-calc__result .b-call-promo__link a:hover {
        color: rgba(255, 255, 255, 0.5); }
  .b-calc__result .b-button__box {
    text-align: center;
    display: none; }
  .b-calc__result.b-calc__result_mobile {
    display: none; }
  .b-calc__result.calculated .b-calc__result_scroll,
  .b-calc__result.calculated .b-button__box,
  .b-calc__result.calculated .b-call-promo__link {
    display: block; }
  .b-calc__result.calculated .b-calc__result_text,
  .b-calc__result.calculated .b-calc__result_sum-small {
    display: none; }
  .b-calc__result .b-calc__result_scroll {
    position: absolute;
    background: #ededed;
    width: 134px;
    height: 134px;
    right: -44px;
    top: 0;
    transform: translate(0, -46%);
    border-radius: 50%;
    transition: background .25s;
    text-align: center;
    border: none;
    padding-top: 90px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 15px;
    display: none; }
    .b-calc__result .b-calc__result_scroll:before {
      content: "";
      position: absolute;
      top: 30px;
      left: 50%;
      width: 46px;
      height: 53px;
      margin-left: -23px;
      background: url("../i/new/i-bar-calc-scroll.svg") no-repeat; }
    .b-calc__result .b-calc__result_scroll:hover {
      background: #fff; }
  .b-calc__result.b-calc__result_alone {
    top: 0 !important; }
    .b-calc__result.b-calc__result_alone .b-calc__result_scroll {
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s, visibility .25s; }
    .b-calc__result.b-calc__result_alone.calc-left .b-calc__result_scroll {
      opacity: 1;
      visibility: visible; }
  .b-calc__result.b-calc__result_bottom, .b-calc__result.b-calc__result_alone {
    top: 73px;
    width: 280px;
    height: 280px;
    background: #21A038;
    overflow: visible;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
    .b-calc__result.b-calc__result_bottom .b-calc__result_wrapper, .b-calc__result.b-calc__result_alone .b-calc__result_wrapper {
      left: 10%;
      width: 80%; }
    .b-calc__result.b-calc__result_bottom .b-link, .b-calc__result.b-calc__result_alone .b-link {
      color: #fff;
      transition: opacity .25s; }
      .b-calc__result.b-calc__result_bottom .b-link .b-link__text, .b-calc__result.b-calc__result_alone .b-link .b-link__text {
        border-bottom-color: #fff;
        border-bottom-width: 5px; }
      .b-calc__result.b-calc__result_bottom .b-link:hover, .b-calc__result.b-calc__result_alone .b-link:hover {
        opacity: .8; }
    .b-calc__result.b-calc__result_bottom .b-text__bigger, .b-calc__result.b-calc__result_alone .b-text__bigger {
      font-size: 16px;
      line-height: 25px; }
    .b-calc__result.b-calc__result_bottom .b-calc__result_sum, .b-calc__result.b-calc__result_alone .b-calc__result_sum {
      font-size: 65px;
      line-height: 75px;
      margin-top: -4px;
      margin-left: -10%;
      margin-right: -10%; }
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock, .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock {
        position: relative;
        margin-bottom: 20px; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock .sale, .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock .sale {
          position: absolute;
          bottom: 23px;
          left: 50%;
          transform: translate(-50%, 100%);
          font-size: 30px !important;
          line-height: 1;
          color: red;
          font-weight: 400 !important; }
          .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock .sale .b-rub, .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock .sale .b-rub {
            font-size: 30px;
            font-weight: 400; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock span, .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock span {
          position: relative; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock .saleLine, .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock .saleLine {
          position: absolute;
          top: 55px;
          z-index: 2;
          left: -10px;
          height: 2px;
          width: calc(100% + 40px);
          background: red;
          transform: rotate(15deg); }
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum.small, .b-calc__result.b-calc__result_alone .b-calc__result_sum.small {
        font-size: 45px; }
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-rub, .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-rub {
        font-size: 30px; }
  .b-calc__result .b-calc__result_pic {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .6;
    background-size: cover;
    background-position: center; }
  .b-calc__result .b-calc__result_wrapper {
    position: absolute;
    left: 15%;
    width: 70%;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    margin-top: -5px; }
  .b-calc__result .b-calc__result_sum {
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    font-size: 96px;
    line-height: 100px;
    white-space: nowrap;
    margin: 0 -15% 3px; }
    .b-calc__result .b-calc__result_sum .b-calc__result_sum-small,
    .b-calc__result .b-calc__result_sum .b-rub {
      font-size: 41px; }

.b-travel-how-buy {
  color: #fff;
  padding: 73px 0 50px;
  z-index: 3; }
  .b-travel-how-buy .b-travel-how-buy__column_box {
    position: relative;
    counter-reset: column; }
  .b-travel-how-buy .b-travel-how-buy__column {
    float: left;
    vertical-align: top;
    position: relative;
    width: 175px;
    margin-right: 70px; }
    .b-travel-how-buy .b-travel-how-buy__column.b-travel-how-buy__column_wide {
      width: auto; }
      .b-travel-how-buy .b-travel-how-buy__column.b-travel-how-buy__column_wide:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0; }
      .b-travel-how-buy .b-travel-how-buy__column.b-travel-how-buy__column_wide .b-travel-how-buy__column_pic {
        float: left;
        vertical-align: top;
        margin-right: 25px; }
      .b-travel-how-buy .b-travel-how-buy__column.b-travel-how-buy__column_wide .b-travel-how-buy__column_text {
        float: left;
        vertical-align: top;
        max-width: 135px;
        margin: 20% 0 0; }
    .b-travel-how-buy .b-travel-how-buy__column.active .b-travel-how-buy__column_pic {
      border-color: #fff;
      background: #fff; }
      .b-travel-how-buy .b-travel-how-buy__column.active .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-1:before {
        background: url("../i/new/pages/products/i-steps-1-green.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column.active .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-2:before {
        background: url("../i/new/pages/products/i-steps-2-green.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column.active .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-3:before {
        background: url("../i/new/pages/products/i-steps-3-green.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column.active .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-4:before {
        background: url("../i/new/pages/products/i-steps-4-green.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column.active .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-5:before {
        background: url("../i/new/pages/products/i-steps-5-green.svg") no-repeat; }
    .b-travel-how-buy .b-travel-how-buy__column:before {
      counter-increment: column;
      content: counters(column, ".");
      width: 39px;
      height: 38px;
      position: absolute;
      left: 0;
      padding-top: 5px;
      text-align: center;
      top: -5px;
      background: url("../i/new/pages/products/i-steps-num-round.svg") no-repeat; }
    .b-travel-how-buy .b-travel-how-buy__column:last-child {
      margin-right: 0; }
    .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic {
      width: 175px;
      height: 175px;
      border-radius: 50%;
      position: relative;
      border: 1px solid rgba(255, 255, 255, 0.3);
      transition: border-color .25s, background .25s; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-4:before {
        width: 106px;
        height: 95px;
        top: 46%;
        background: url("../i/new/pages/products/i-steps-4-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-5:before {
        width: 54px;
        height: 92px;
        background: url("../i/new/pages/products/i-steps-5-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-11:before {
        top: 47%;
        width: 116px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-11-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-12:before {
        top: 49%;
        left: 49%;
        width: 125px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-12-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-13:before {
        width: 71px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-13-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-14:before {
        width: 65px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-14-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-15:before {
        top: 46%;
        width: 102px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-15-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-16:before {
        top: 49%;
        left: 55%;
        width: 102px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-16-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-17:before {
        top: 48%;
        left: 47%;
        width: 95px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-17-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-18:before {
        top: 47%;
        width: 116px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-11-white.svg") no-repeat; }
      .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic.b-travel-how-buy__column_pic-19:before {
        width: 106px;
        height: 105px;
        background: url("../i/new/pages/products/i-steps-19-white.svg") no-repeat; }
    .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_text {
      margin: 5px 30px 0; }

.b-nav-page {
  position: absolute;
  top: -25px;
  left: 0;
  right: 0;
  z-index: 25;
  min-width: 1200px; }
  .b-nav-page .b-nav-page__sub {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 100%;
    margin-top: 6px;
    border-radius: 5px;
    background: #21A038;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transition: opacity .25s; }
    .b-nav-page .b-nav-page__sub .b-nav-page__item {
      float: none !important;
      border-right: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .b-nav-page .b-nav-page__sub .b-nav-page__item .b-nav-page__link {
        white-space: nowrap; }
      .b-nav-page .b-nav-page__sub .b-nav-page__item:last-child {
        border-bottom: none; }
  .b-nav-page.fixed-bottom {
    position: fixed;
    top: inherit;
    bottom: 0; }
    .b-nav-page.fixed-bottom .b-nav-page__list {
      border-radius: 5px 5px 0 0; }
  .b-nav-page.fixed-top {
    position: fixed;
    top: 0; }
    .b-nav-page.fixed-top .b-nav-page__list {
      border-radius: 0 0 5px 5px; }
  .b-nav-page .b-nav-page__list {
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    background: #21A038;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    transition: border-radius .25s; }
    .b-nav-page .b-nav-page__list .b-nav-page__item {
      float: left;
      vertical-align: top;
      position: relative;
      border-right: 1px solid rgba(0, 0, 0, 0.1); }
      .b-nav-page .b-nav-page__list .b-nav-page__item.b-nav-page__item_added {
        overflow: hidden; }
        .b-nav-page .b-nav-page__list .b-nav-page__item.b-nav-page__item_added.active {
          overflow: visible; }
          .b-nav-page .b-nav-page__list .b-nav-page__item.b-nav-page__item_added.active .b-nav-page__link_num {
            color: transparent !important;
            background: #21A038; }
            .b-nav-page .b-nav-page__list .b-nav-page__item.b-nav-page__item_added.active .b-nav-page__link_num:before {
              opacity: 1; }
          .b-nav-page .b-nav-page__list .b-nav-page__item.b-nav-page__item_added.active .b-nav-page__sub {
            opacity: 1; }
      .b-nav-page .b-nav-page__list .b-nav-page__item:first-child .b-nav-page__link {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
      .b-nav-page .b-nav-page__list .b-nav-page__item:last-child {
        border-right: none; }
        .b-nav-page .b-nav-page__list .b-nav-page__item:last-child .b-nav-page__link {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px; }
    .b-nav-page .b-nav-page__list .b-nav-page__link {
      display: block;
      border: none;
      position: relative;
      height: 49px;
      font-size: 16px;
      line-height: 25px;
      padding: 12px 14px;
      color: #fff;
      transition: background .25s; }
      .b-nav-page .b-nav-page__list .b-nav-page__link.b-nav-page__link_num:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 15px;
        height: 7px;
        margin: -4px 0 0 -7px;
        opacity: 0;
        background: url("../i/new/i-accordion-white.svg") no-repeat; }
      .b-nav-page .b-nav-page__list .b-nav-page__link:hover, .b-nav-page .b-nav-page__list .b-nav-page__link.active {
        background: #21A038; }

.b-product-section {
  position: relative;
  padding-top: 80px; }
  .b-product-section#section_0_editor {
    padding-top: 150px; }
  .b-product-section.b-product-section__custom {
    padding-top: 50px; }
    .b-product-section.b-product-section__custom .b-old-calc select {
      float: left;
      vertical-align: top;
      width: 48%;
      height: 53px;
      font-size: 17px;
      padding: 13px 18px;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
      outline: none;
      border: none;
      color: #21A038;
      transition: color .25s;
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none; }
      .b-product-section.b-product-section__custom .b-old-calc select:hover {
        color: #21A038; }
      .b-product-section.b-product-section__custom .b-old-calc select + select {
        float: right; }
    .b-product-section.b-product-section__custom .b-text__wrapper .b-table {
      margin-top: 30px; }
      .b-product-section.b-product-section__custom .b-text__wrapper .b-table table {
        table-layout: fixed; }
      .b-product-section.b-product-section__custom .b-text__wrapper .b-table tr:first-child td {
        vertical-align: middle; }
        .b-product-section.b-product-section__custom .b-text__wrapper .b-table tr:first-child td:first-child {
          border-right: 1px solid rgba(0, 0, 0, 0.1); }
        .b-product-section.b-product-section__custom .b-text__wrapper .b-table tr:first-child td:last-child {
          text-align: center; }
      .b-product-section.b-product-section__custom .b-text__wrapper .b-table tr:nth-child(2) td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 4%; }
        .b-product-section.b-product-section__custom .b-text__wrapper .b-table tr:nth-child(2) td:nth-child(2) {
          text-align: center; }
  .b-product-section h2 + .b-main-tabs__section {
    margin-top: -35px; }
  .b-product-section.b-product-section__pb {
    padding-bottom: 60px; }

.b-inside-tabs__section {
  position: relative; }

.b-inside-tabs__tabs {
  float: left;
  vertical-align: top;
  width: 215px;
  margin-right: 35px;
  background: #21A038;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
  .b-inside-tabs__tabs li:first-child a {
    border-top: none; }
  .b-inside-tabs__tabs li:hover + li .b-inside-tabs__tabs_link, .b-inside-tabs__tabs li.active + li .b-inside-tabs__tabs_link {
    border-top-color: transparent; }
  .b-inside-tabs__tabs .b-inside-tabs__tabs_link {
    display: block;
    position: relative;
    padding: 11px 15px 14px;
    box-sizing: border-box;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    color: #fff;
    background: transparent;
    transition: background .25s, color .25s, border-color .25s; }
    .b-inside-tabs__tabs .b-inside-tabs__tabs_link:hover, .b-inside-tabs__tabs .b-inside-tabs__tabs_link.active {
      background: #21A038;
      border-top-color: transparent; }
    .b-inside-tabs__tabs .b-inside-tabs__tabs_link.active {
      color: #81bd9a;
      cursor: default; }

.b-inside-tabs__result_box {
  float: left;
  vertical-align: top;
  width: 465px; }

.b-inside-tabs__result {
  display: none; }
  .b-inside-tabs__result .b-accordion.b-ul {
    margin-left: 0; }
    .b-inside-tabs__result .b-accordion.b-ul .b-accordion__item {
      padding-left: 59px;
      position: relative; }
      .b-inside-tabs__result .b-accordion.b-ul .b-accordion__item:before {
        left: 20px; }
  .b-inside-tabs__result .b-accordion.b-accordion__list .b-accordion__item {
    position: relative; }
    .b-inside-tabs__result .b-accordion.b-accordion__list .b-accordion__item:before {
      left: 20px; }
  .b-inside-tabs__result .b-accordion.b-accordion__list .b-accordion__link {
    margin-left: 0; }
  .b-inside-tabs__result .b-accordion.b-accordion__list .b-accordion__text {
    margin-left: 0;
    padding-left: 0; }
  .b-inside-tabs__result .b-accordion .b-accordion__item {
    margin-left: -20px;
    padding-left: 20px; }
    .b-inside-tabs__result .b-accordion .b-accordion__item:first-child {
      margin-top: 0; }
  .b-inside-tabs__result.active {
    display: block; }

.b-product-section__insurance {
  margin-bottom: 0;
  padding-top: 0;
  color: #fff; }
  .b-product-section__insurance .b-text__wrapper {
    color: #fff; }
  .b-product-section__insurance h2 {
    display: inline-block;
    vertical-align: top;
    position: relative; }
    .b-product-section__insurance h2:after {
      content: "";
      position: absolute;
      left: 100%;
      top: -12px;
      margin-left: 12px;
      width: 55px;
      height: 60px;
      background: url("../i/new/i-ins-case.svg") no-repeat;
      background-size: cover; }
  .b-product-section__insurance .b-wrapper {
    padding: 80px 0 60px; }
  .b-product-section__insurance .b-button__box {
    margin-left: 110px; }
  .b-product-section__insurance ol {
    position: relative;
    counter-reset: ol-ins;
    margin-bottom: 40px; }
    .b-product-section__insurance ol:before {
      content: "";
      position: absolute;
      left: 62px;
      top: 10px;
      bottom: 0;
      width: 1px;
      background: rgba(255, 255, 255, 0.15); }
    .b-product-section__insurance ol li {
      position: relative;
      padding-left: 110px;
      padding-top: 13px;
      margin-bottom: 32px; }
      .b-product-section__insurance ol li ul li {
        padding-left: 39px;
        padding-bottom: 0;
        margin-bottom: 17px;
        padding-top: 0; }
        .b-product-section__insurance ol li ul li:before {
          content: "";
          counter-increment: none;
          left: 0;
          top: -1px;
          width: 30px;
          height: 30px;
          background: url("../i/new/i-ul-text-white.svg") no-repeat center;
          background-size: 15px 10px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          font-size: 19px;
          line-height: 27px;
          text-align: center;
          display: block; }
      .b-product-section__insurance ol li a {
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.3);
        transition: opacity .25s; }
        .b-product-section__insurance ol li a:hover {
          opacity: .65; }
      .b-product-section__insurance ol li .b-text__wrapper {
        margin-left: 0; }
      .b-product-section__insurance ol li:last-child {
        margin-bottom: 0; }
      .b-product-section__insurance ol li:before {
        counter-increment: ol-ins;
        content: counters(ol-ins, ".");
        position: absolute;
        left: 0;
        top: 0;
        font-size: 55px;
        line-height: 65px;
        font-weight: bold;
        font-family: "SB Sans Display Bold";
        opacity: .4; }

.b-calc__mortgage {
  margin-right: -30px; }
  .b-calc__mortgage .b-dropdown__box {
    float: left;
    vertical-align: top; }
  .b-calc__mortgage .b-dropdown__box_who {
    width: 120px;
    margin-right: 24px; }
  .b-calc__mortgage .b-dropdown.b-dropdown_open.first-open .b-dropdown__sub .b-dropdown__item.hidden {
    display: block !important; }
  .b-calc__mortgage .b-calc__section .b-calc__label {
    white-space: nowrap;
    width: auto; }
  .b-calc__mortgage .b-calc__section_column {
    width: auto;
    position: relative; }
    .b-calc__mortgage .b-calc__section_column .b-input {
      width: 125px;
      margin-right: 5px; }
  .b-calc__mortgage .b-input_faq {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
    display: block;
    white-space: nowrap;
    font-size: 14px;
    line-height: 25px; }

.b-calc__family-protection {
  margin-right: -30px; }
  .b-calc__family-protection .b-dropdown__box {
    float: left;
    vertical-align: top; }
  .b-calc__family-protection .b-dropdown__box_price {
    width: 140px; }
  .b-calc__family-protection .b-dropdown__box_who {
    width: 130px;
    margin-right: 24px; }
  .b-calc__family-protection .b-dropdown.b-dropdown_open.first-open .b-dropdown__sub .b-dropdown__item.hidden {
    display: block !important; }
  .b-calc__family-protection .b-calc__section .b-calc__label {
    white-space: nowrap;
    width: auto; }
  .b-calc__family-protection .b-calc__section_column {
    width: auto; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

.b-calc__family-head .b-dropdown__box {
  float: left;
  vertical-align: top; }

.b-calc__family-head .b-dropdown__box_price {
  width: 140px;
  margin-right: 24px; }

.b-calc__family-head .b-dropdown.b-dropdown_open.first-open .b-dropdown__sub .b-dropdown__item.hidden {
  display: block !important; }

.b-calc__family-head .b-calc__section .b-calc__label {
  white-space: nowrap;
  width: auto; }

.b-calc__protected-borrower {
  padding-bottom: 230px; }
  .b-calc__protected-borrower .b-dropdown__box {
    float: left;
    vertical-align: top;
    width: 130px;
    margin-right: 24px; }
  .b-calc__protected-borrower .b-input_faq {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
    display: block;
    white-space: nowrap;
    font-size: 14px;
    line-height: 25px; }
  .b-calc__protected-borrower .b-calc__section_column {
    width: auto;
    margin-right: 5px;
    position: relative; }
    .b-calc__protected-borrower .b-calc__section_column .b-input {
      width: 130px; }
      .b-calc__protected-borrower .b-calc__section_column .b-input.age {
        width: 65px; }
  .b-calc__protected-borrower .b-calc__section .b-calc__label {
    white-space: nowrap;
    width: auto; }

.b-calc__panaceya .b-calc__section_column {
  width: auto; }

.b-calc__panaceya .b-dropdown__box {
  float: left;
  vertical-align: top; }

.b-calc__panaceya .b-calc__section .b-calc__label {
  white-space: nowrap;
  width: auto; }

.b-calc__panaceya .b-dropdown__box_price {
  width: 140px;
  margin-right: 24px; }

.b-calc__panaceya .b-dropdown__box_age {
  width: 85px;
  margin-right: 10px; }

.b-calc__panaceya .b-dropdown.b-dropdown_open.first-open .b-dropdown__sub .b-dropdown__item.hidden {
  display: block !important; }

.b-first-section__first-capital {
  padding-bottom: 100px; }

.b-first-section__mites-protection {
  padding-bottom: 200px; }

.b-first-section__multipolis .b-checkbox__faq {
  color: #000;
  margin-top: 0;
  padding-top: 20px;
  transform: none;
  position: absolute; }

.b-multipolis__section_title {
  position: relative;
  padding: 33px 0 8px; }
  .b-multipolis__section_title li {
    float: left;
    vertical-align: top;
    width: 45%; }
    .b-multipolis__section_title li:last-child {
      width: 10%; }

.b-multipolis__section {
  display: block;
  background: #f9f9f9;
  border-radius: 12px;
  position: relative;
  color: #000;
  padding: 0 15px;
  margin-left: -15px;
  margin-right: -15px; }
  .b-multipolis__section .b-multipolis__down-calc_text {
    padding: 26px 35px 10px;
    position: absolute;
    top: 0;
    left: 100%;
    margin-left: 25px;
    width: 380px;
    background: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    z-index: 20;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    visibility: hidden;
    opacity: 0;
    transition: visibility .25s, opacity .25s; }
    .b-multipolis__section .b-multipolis__down-calc_text ul {
      column-count: 2;
      -moz-column-count: 2;
      font-size: 14px;
      line-height: 25px; }
      .b-multipolis__section .b-multipolis__down-calc_text ul li {
        box-sizing: border-box;
        padding-right: 5%;
        padding-left: 20px;
        position: relative;
        margin-bottom: 10px;
        -webkit-column-break-inside: avoid;
        -moz-column-break-inside: avoid;
        -moz-page-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
        display: table;
        opacity: .25; }
        .b-multipolis__section .b-multipolis__down-calc_text ul li.active {
          opacity: 1; }
        .b-multipolis__section .b-multipolis__down-calc_text ul li:before {
          content: "";
          position: absolute;
          left: 1px;
          top: 7px;
          width: 12px;
          height: 8px;
          background: url("../i/new/i-small-ul.svg") no-repeat; }
    .b-multipolis__section .b-multipolis__down-calc_text.show {
      visibility: visible;
      opacity: 1; }
  .b-multipolis__section .b-multipolis__label {
    float: left;
    vertical-align: top;
    width: 45%;
    padding-right: 15px;
    padding-left: 35px;
    box-sizing: border-box;
    position: relative; }
    .b-multipolis__section .b-multipolis__label .b-calc__label {
      float: none;
      width: auto;
      padding: 0;
      height: 72px;
      display: table-cell;
      vertical-align: middle; }
      .b-multipolis__section .b-multipolis__label .b-calc__label span {
        display: inline-block; }
  .b-multipolis__section .b-multipolis__slider_box {
    float: left;
    vertical-align: top;
    width: 45%;
    padding-right: 15px;
    box-sizing: border-box;
    position: relative;
    padding-top: 24px; }
    .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_single {
      text-align: right; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_single div {
        display: inline-block;
        width: 33.333%;
        text-align: center;
        font-size: 14px;
        line-height: 25px; }
    .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider {
      height: 2px;
      border: none;
      background: rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      margin: 0 45px 8px;
      position: relative;
      z-index: 3;
      cursor: pointer; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider:before {
        content: "";
        position: absolute;
        top: -10px;
        bottom: -10px;
        left: 0;
        right: 0; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider .ui-slider-range {
        top: 0;
        bottom: 0;
        height: auto;
        border-radius: 2px;
        background: #21A038; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider .ui-slider-handle {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: none;
        outline: none;
        cursor: pointer;
        top: -23px;
        margin-left: -25px;
        background: none !important; }
        .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider .ui-slider-handle:before {
          content: "";
          height: 12px;
          width: 12px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -6px 0 0 -6px;
          background: #21A038;
          transition: background .25s;
          box-shadow: 0 0 20px rgba(0, 153, 0, 0.4); }
        .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider .ui-slider-handle:hover:before {
          background: #21A038; }
    .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values {
      position: relative; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values a {
        display: none;
        vertical-align: top;
        margin-top: -1px;
        margin-left: 3px;
        width: 25px;
        height: 25px;
        background: url(../i/new/i-question.svg) no-repeat;
        border: none;
        opacity: .5;
        transition: opacity .25s; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values li {
        float: left;
        vertical-align: top;
        width: 33.333%;
        text-align: center;
        font-size: 14px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.4);
        transition: color .25s; }
        .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values li.active {
          color: #000; }
  .b-multipolis__section .b-multipolis__section_item {
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .b-multipolis__section .b-multipolis__section_item:first-child:before {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px; }
    .b-multipolis__section .b-multipolis__section_item:last-child {
      border-bottom: none; }
      .b-multipolis__section .b-multipolis__section_item:last-child:before {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px; }
    .b-multipolis__section .b-multipolis__section_item:before {
      content: "";
      position: absolute;
      top: 0;
      left: -15px;
      right: -15px;
      bottom: 0;
      background: #fff;
      opacity: 0;
      transition: opacity .25s; }
  .b-multipolis__section .b-checkbox__faq-box_call {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -14px;
    width: 25px;
    height: 25px;
    background: url(../i/new/i-question.svg) no-repeat;
    border: none;
    opacity: .5;
    display: block;
    transition: opacity .25s; }
    .b-multipolis__section .b-checkbox__faq-box_call:hover {
      opacity: 1; }
  .b-multipolis__section .b-multipolis__price_box {
    float: right;
    vertical-align: top;
    width: 10%;
    box-sizing: border-box;
    position: relative; }
    .b-multipolis__section .b-multipolis__price_box .b-multipolis__price {
      height: 72px;
      display: table;
      width: 100%; }
      .b-multipolis__section .b-multipolis__price_box .b-multipolis__price div {
        display: table-cell;
        vertical-align: middle;
        width: 100%; }

.b-first-section .b-product__wrapper .b-main-title__icons {
  padding-bottom: 60px; }
  .b-first-section .b-product__wrapper .b-main-title__icons table {
    table-layout: fixed;
    width: 100%; }
    .b-first-section .b-product__wrapper .b-main-title__icons table tr:last-child td {
      border-bottom: none; }
    .b-first-section .b-product__wrapper .b-main-title__icons table td {
      padding-bottom: 35px;
      padding-top: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-right: 45px;
      font-size: 17px;
      line-height: 27px; }
      .b-first-section .b-product__wrapper .b-main-title__icons table td img {
        display: block;
        vertical-align: top;
        width: auto;
        height: 105px;
        margin-bottom: 15px; }
      .b-first-section .b-product__wrapper .b-main-title__icons table td:last-child {
        padding-right: 0; }
        .b-first-section .b-product__wrapper .b-main-title__icons table td:last-child img {
          margin-right: 45px; }

@media only screen and (min-width: 1200px) {
  .b-multipolis__section_item:hover:before {
    opacity: 1; } }

@media only screen and (min-width: 1600px) {
  .b-first-section__first-capital {
    padding-bottom: 200px; }
  .b-calc {
    font-size: 19px;
    line-height: 27px; }
    .b-calc .b-dropdown__box_price {
      width: 155px; }
    .b-calc.b-calc__multipolis .b-calc__section .b-calc__label {
      margin-bottom: 5px; }
    .b-calc.b-calc__family-head .b-calc__section .b-calc__label, .b-calc.b-calc__protected-borrower .b-calc__section .b-calc__label, .b-calc.b-calc__mortgage .b-calc__section .b-calc__label {
      width: auto; }
    .b-calc.b-calc__panaceya {
      padding-bottom: 250px; }
      .b-calc.b-calc__panaceya .b-calc__section .b-calc__label {
        width: auto; }
      .b-calc.b-calc__panaceya .b-dropdown__box_price {
        width: 150px; }
      .b-calc.b-calc__panaceya .b-dropdown__box_age {
        width: 90px; }
    .b-calc .b-calendar__box_faq {
      font-size: 19px;
      line-height: 30px;
      width: 230px; }
    .b-calc .b-calendar__box {
      width: 320px; }
    .b-calc .b-calc__section .b-calc__label {
      width: 230px; }
      .b-calc .b-calc__section .b-calc__label.b-calc__label_short {
        width: 140px; }
  .b-dropdown__box_travel-money {
    width: 130px; }
  .b-first-section__vzr .b-product__wrapper,
  .b-product__wrapper {
    margin-right: 605px; }
  .b-dropdown__box_travel-where {
    width: 595px; }
  .b-calc__result_box,
  .b-promo-code__box {
    width: 550px;
    height: 550px; }
  .b-calc__result_box.alone {
    width: 400px;
    height: 400px; }
  .b-calc__result_box.alone + .b-promo-code__box {
    width: 400px;
    height: 400px; }
  .b-promo-code__box .b-promo-code .b-promo-code__close {
    width: 90px;
    height: 90px;
    right: 45px !important; }
  .b-calc__result {
    width: 550px;
    height: 550px; }
    .b-calc__result.b-calc__result_link {
      width: 400px;
      height: 400px; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate {
        width: 160px;
        height: 160px;
        padding: 75px 20px 0; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-activate:before {
          width: 200px;
          height: 200px;
          margin: -100px 0 0 -100px; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-mian {
        padding-top: 245px; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-mian:before {
          top: 86px;
          transform: scale(1.2); }
    .b-calc__result .b-calc__result_scroll {
      width: 150px;
      height: 150px;
      top: 20px;
      right: -20px;
      padding-top: 100px;
      font-size: 16px; }
      .b-calc__result .b-calc__result_scroll:before {
        top: 35px; }
    .b-calc__result.b-calc__result_bottom, .b-calc__result.b-calc__result_alone {
      width: 400px;
      height: 400px; }
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum, .b-calc__result.b-calc__result_alone .b-calc__result_sum {
        font-size: 100px;
        line-height: 105px; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock, .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock {
          position: relative;
          margin-bottom: 20px; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum .sale, .b-calc__result.b-calc__result_alone .b-calc__result_sum .sale {
          position: absolute;
          bottom: 23px;
          left: 50%;
          transform: translate(-50%, 100%);
          font-size: 30px !important;
          line-height: 1;
          color: red;
          font-weight: 400 !important; }
          .b-calc__result.b-calc__result_bottom .b-calc__result_sum .sale .b-rub, .b-calc__result.b-calc__result_alone .b-calc__result_sum .sale .b-rub {
            font-size: 30px;
            font-weight: 400; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum span, .b-calc__result.b-calc__result_alone .b-calc__result_sum span {
          position: relative; }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum .saleLine, .b-calc__result.b-calc__result_alone .b-calc__result_sum .saleLine {
          position: absolute;
          top: 60px;
          left: -10px;
          height: 2px;
          width: calc(100% + 40px);
          background: red;
          transform: rotate(15deg); }
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-calc__result_sum-small,
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-rub, .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-calc__result_sum-small,
        .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-rub {
          font-size: 40px; }
    .b-calc__result .b-calc__result_sum {
      font-size: 122px;
      line-height: 130px;
      margin-left: -20%;
      margin-right: -20%; }
      .b-calc__result .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result .b-calc__result_sum .b-rub {
        font-size: 52px; }
    .b-calc__result .b-calc__result_wrapper {
      font-size: 19px;
      line-height: 30px;
      left: 20%;
      width: 60%; }
      .b-calc__result .b-calc__result_wrapper .b-text__bigger {
        font-size: 23px; }
  .b-dropdown__box_travel-theme {
    width: 260px; }
  .b-date-picker .datepick {
    font-size: 19px;
    width: 281px !important; }
  .b-date-picker .datepick-month td {
    width: 40px; }
    .b-date-picker .datepick-month td span, .b-date-picker .datepick-month td a {
      height: 40px;
      line-height: 35px; }
  .b-date-picker .datepick-month-header {
    font-size: 19px; }
  .b-nav-page {
    top: -32px; }
    .b-nav-page .b-nav-page__list .b-nav-page__link {
      font-size: 18px;
      height: 65px;
      line-height: 41px; }
  .b-travel-how-buy .b-travel-how-buy__column {
    width: 230px; }
  .b-product-section__wrapper {
    margin-right: 450px; }
  .b-accordion .b-accordion__link:after {
    top: 12px; }
  .b-accordion.b-accordion__questions .b-accordion__link:before {
    top: 2px; }
  .b-calc__section_column {
    width: 505px; }
  .b-amount__section.b-amount__box_zero .b-amount__section_label {
    width: 286px; }
  .b-amount__section .b-amount__section_label {
    width: 245px; }
  .b-calc__promocode .b-input {
    width: 120px; }
  .b-calc__last-info {
    margin-left: 230px; }
  .b-checkbox__faq {
    width: 550px;
    font-size: 19px;
    line-height: 30px;
    left: 100%;
    margin-left: 115px;
    right: auto; } }

@media only screen and (max-width: 1199px) {
  .b-multipolis__section_title {
    display: none; }
  .b-product-section__insurance .b-main-tabs__section .b-main-tabs__tabs {
    margin-right: -115px; }
  .b-first-section__mites-protection {
    padding-bottom: 90px; }
  .b-calc {
    font-size: 15px;
    line-height: 24px; }
    .b-calc.b-calc__family-head .b-calc__section_column {
      width: 100%; }
    .b-calc.b-calc__family-head .b-calc__section .b-calc__label {
      width: auto; }
    .b-calc.b-calc__mortgage .b-calc__section .b-calc__label {
      white-space: normal;
      max-width: none;
      width: 180px; }
    .b-calc.b-calc__mortgage .b-calc__section_column {
      margin-bottom: 11px;
      float: left; }
      .b-calc.b-calc__mortgage .b-calc__section_column .b-calc__section_column + .b-calc__label {
        width: auto; }
      .b-calc.b-calc__mortgage .b-calc__section_column .b-input {
        width: 120px; }
    .b-calc.b-calc__family-protection .b-calc__section .b-calc__label {
      max-width: 150px;
      text-align: right;
      white-space: normal; }
    .b-calc.b-calc__family-protection .b-calc__section_column {
      margin-bottom: 11px;
      float: left; }
    .b-calc.b-calc__family-protection .b-dropdown__box_age + .b-calc__label {
      text-align: left;
      width: auto; }
    .b-calc.b-calc__protected-borrower .b-calc__section .b-calc__label {
      width: auto; }
    .b-calc.b-calc__panaceya .b-calc__section .b-calc__label {
      width: 150px;
      text-align: right;
      white-space: normal; }
    .b-calc.b-calc__panaceya .b-calc__section_column {
      margin-bottom: 11px;
      float: left; }
    .b-calc.b-calc__panaceya .b-dropdown__box_age + .b-calc__label {
      text-align: left;
      width: auto; }
    .b-calc .b-calendar__box_faq {
      font-size: 15px;
      line-height: 24px;
      position: relative;
      left: 0;
      top: 0;
      float: left;
      vertical-align: top;
      width: 395px;
      margin-top: 50px; }
    .b-calc .b-calc__section .b-calc__label {
      width: 115px; }
      .b-calc .b-calc__section .b-calc__label.b-calc__label_short {
        width: 115px; }
    .b-calc .b-calendar__box {
      margin-left: 115px;
      margin-top: 11px;
      margin-right: 100px; }
      .b-calc .b-calendar__box:before {
        content: "на даты";
        position: absolute;
        right: 100%;
        top: 13px;
        width: 115px;
        text-align: left; }
  .b-dropdown__box_travel-theme {
    width: 280px;
    margin-right: 0; }
  .b-dropdown__box_travel-where,
  .b-calc__section_column {
    width: 338px; }
  .b-first-section__vzr .b-product__wrapper,
  .b-product__wrapper,
  .b-product-section__wrapper {
    margin-right: 230px; }
  .b-calc__result_box,
  .b-promo-code__box {
    width: 200px;
    height: 200px;
    right: 25px; }
  .b-promo-code__box .b-promo-code .b-promo-code__close {
    transform: scale(0.7);
    top: -21px !important;
    right: 10px !important; }
  .b-promo-code__box .b-promo-code__wrapper {
    width: 70%; }
  .b-promo-code__box .b-promo-code__title {
    width: 100%;
    margin-bottom: 10px; }
  .b-promo-code__box .b-button__box {
    margin-top: 13px; }
  .b-calc__result.b-calc__result_bottom {
    right: 25px; }
  .b-calc__result_box.alone,
  .b-calc__result_box.alone + .b-promo-code__box {
    width: 200px;
    height: 200px; }
  .b-calc__result,
  .b-calc__result.b-calc__result_bottom,
  .b-calc__result.b-calc__result_alone {
    width: 200px;
    height: 200px; }
    .b-calc__result .b-call-promo__link,
    .b-calc__result.b-calc__result_bottom .b-call-promo__link,
    .b-calc__result.b-calc__result_alone .b-call-promo__link {
      font-size: 12px;
      line-height: 14px;
      margin: -10px 0 15px; }
    .b-calc__result.b-calc__result_link,
    .b-calc__result.b-calc__result_bottom.b-calc__result_link,
    .b-calc__result.b-calc__result_alone.b-calc__result_link {
      width: 200px;
      height: 200px; }
      .b-calc__result.b-calc__result_link.clean .b-calc__result_link-activate,
      .b-calc__result.b-calc__result_bottom.b-calc__result_link.clean .b-calc__result_link-activate,
      .b-calc__result.b-calc__result_alone.b-calc__result_link.clean .b-calc__result_link-activate {
        right: 30px; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate,
      .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-activate,
      .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-activate {
        width: 100px;
        height: 100px;
        right: -15px;
        top: -60px;
        font-size: 12px;
        line-height: 14px;
        padding-top: 55px; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-activate:before,
        .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-activate:before,
        .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-activate:before {
          width: 120px;
          height: 120px;
          margin: -60px 0 0 -60px;
          transform: scale(1) !important; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-mian,
      .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-mian,
      .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-mian {
        font-size: 22px;
        line-height: 25px;
        padding-top: 116px; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-mian:before,
        .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-mian:before,
        .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-mian:before {
          background-size: cover;
          top: 20px;
          width: 110px;
          height: 83px;
          margin-left: -59px; }
    .b-calc__result .b-calc__result_scroll,
    .b-calc__result.b-calc__result_bottom .b-calc__result_scroll,
    .b-calc__result.b-calc__result_alone .b-calc__result_scroll {
      width: 70px;
      height: 70px;
      right: -10px;
      font-size: 0;
      padding-top: 0; }
      .b-calc__result .b-calc__result_scroll:before,
      .b-calc__result.b-calc__result_bottom .b-calc__result_scroll:before,
      .b-calc__result.b-calc__result_alone .b-calc__result_scroll:before {
        top: 50%;
        margin-top: -26px;
        transform: scale(0.75); }
    .b-calc__result .b-calc__result_wrapper,
    .b-calc__result.b-calc__result_bottom .b-calc__result_wrapper,
    .b-calc__result.b-calc__result_alone .b-calc__result_wrapper {
      width: 100%;
      left: 0;
      line-height: 17px; }
      .b-calc__result .b-calc__result_wrapper .b-button__box,
      .b-calc__result.b-calc__result_bottom .b-calc__result_wrapper .b-button__box,
      .b-calc__result.b-calc__result_alone .b-calc__result_wrapper .b-button__box {
        margin-top: -5px; }
    .b-calc__result .b-text__bigger,
    .b-calc__result.b-calc__result_bottom .b-text__bigger,
    .b-calc__result.b-calc__result_alone .b-text__bigger {
      font-size: 13px;
      line-height: 17px; }
    .b-calc__result .b-calc__result_sum,
    .b-calc__result.b-calc__result_bottom .b-calc__result_sum,
    .b-calc__result.b-calc__result_alone .b-calc__result_sum {
      margin-bottom: 0;
      font-size: 49px;
      line-height: 55px; }
      .b-calc__result .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result .b-calc__result_sum .b-rub,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-rub,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-rub {
        font-size: 22px; }
    .b-calc__result .b-calc__result_text,
    .b-calc__result.b-calc__result_bottom .b-calc__result_text,
    .b-calc__result.b-calc__result_alone .b-calc__result_text {
      display: none; }
  .b-nav-page {
    min-width: 0; }
    .b-nav-page .b-nav-page__list .b-nav-page__link {
      font-size: 15px;
      line-height: 24px; }
      .b-nav-page .b-nav-page__list .b-nav-page__link:hover {
        background: inherit; }
      .b-nav-page .b-nav-page__list .b-nav-page__link.active {
        background: #21A038; }
  .b-travel-how-buy .b-travel-how-buy__column {
    margin-right: 25px;
    width: 125px; }
    .b-travel-how-buy .b-travel-how-buy__column:before {
      left: -4px;
      top: -14px; }
    .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_pic {
      zoom: .7; }
    .b-travel-how-buy .b-travel-how-buy__column .b-travel-how-buy__column_text {
      margin-left: 0;
      margin-right: 0; }
  .b-inside-tabs__tabs {
    margin-right: 0;
    width: 100%;
    overflow: visible; }
    .b-inside-tabs__tabs li:first-child .b-inside-tabs__tabs_link {
      border-top: none !important; }
    .b-inside-tabs__tabs li:hover + li {
      background: none; }
      .b-inside-tabs__tabs li:hover + li .b-inside-tabs__tabs_link {
        border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .b-inside-tabs__tabs li .b-inside-tabs__tabs_link:hover {
      background: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .b-inside-tabs__tabs li .b-inside-tabs__tabs_link:before {
      content: "";
      position: absolute;
      width: 15px;
      height: 7px;
      right: 15px;
      top: 20px;
      background: url("../i/new/i-accordion-white.svg") no-repeat;
      opacity: 0; }
    .b-inside-tabs__tabs li .b-inside-tabs__tabs_link.active {
      color: #fff; }
      .b-inside-tabs__tabs li .b-inside-tabs__tabs_link.active:before {
        opacity: 1; }
    .b-inside-tabs__tabs li:first-child .b-inside-tabs__tabs_link {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
    .b-inside-tabs__tabs .b-accordion {
      background: #fff;
      margin-left: -20px;
      margin-right: -20px;
      border-radius: 5px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.1); }
      .b-inside-tabs__tabs .b-accordion .b-accordion__item {
        margin-left: 0;
        border-radius: 0;
        box-shadow: none;
        background: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 0;
        padding-bottom: 13px;
        margin-top: 0; }
        .b-inside-tabs__tabs .b-accordion .b-accordion__item:last-child {
          border-bottom: none; }
        .b-inside-tabs__tabs .b-accordion .b-accordion__item .b-accordion__link {
          font-size: 15px;
          line-height: 24px; }
  .b-product-section__insurance .b-bg {
    right: 120px; }
  .b-product-section__insurance .b-wrapper {
    padding: 80px 25px 60px 60px; }
  .b-product-section__insurance .b-accordion .b-accordion__link {
    font-size: 17px;
    line-height: 24px; }
    .b-product-section__insurance .b-accordion .b-accordion__link:after {
      opacity: 1; }
    .b-product-section__insurance .b-accordion .b-accordion__link .b-accordion__link_wrapper span:before {
      display: none; }
  .b-checkbox__faq {
    display: none !important; }
  .b-calc__last-info {
    margin-left: 115px; }
    .b-calc__last-info:before {
      width: 100px;
      bottom: 70%;
      margin-left: -30px; }
  .b-amount__section .b-amount__box .b-amount-input__i:hover {
    background: none; }
    .b-amount__section .b-amount__box .b-amount-input__i:hover:before {
      background-position: 0 0 !important; }
  .b-calc__section_column-check-box {
    margin: 0 -10px;
    background: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px; }
  .b-checkbox__faq-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding-top: 60px;
    overflow: scroll;
    background: #21A038;
    z-index: 20; }
    .b-checkbox__faq-box .b-checkbox__faq-box_close {
      position: absolute;
      top: 10px;
      left: 50%;
      width: 29px;
      height: 29px;
      margin-left: -15px;
      background: url("../i/new/i-checkbox-faq-close.svg") no-repeat; }
    .b-checkbox__faq-box .b-checkbox__faq-box_text {
      margin: 0 auto 40px;
      padding: 30px 20px;
      background: #fff;
      border-radius: 5px;
      max-width: 400px; }
  .b-checkbox__section {
    margin: 0;
    padding: 6px 0 7px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .b-checkbox__section .b-checkbox__faq-box_call {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 25px;
      height: 25px;
      background: url("../i/new/i-question.svg") no-repeat;
      border: none;
      opacity: .5;
      display: block; }
    .b-checkbox__section:last-child {
      border-bottom: none; }
    .b-checkbox__section .b-checkbox__label {
      margin-right: 40px; }
      .b-checkbox__section .b-checkbox__label .b-checkbox__icon {
        top: 8px; }
      .b-checkbox__section .b-checkbox__label:before {
        display: none; }
      .b-checkbox__section .b-checkbox__label:hover .b-checkbox__icon {
        border-color: 1px solid rgba(0, 0, 0, 0.1); }
  .b-calc__multipolis .b-calc__section .b-calc__label {
    width: 100%;
    margin-bottom: 5px; }
  .b-multipolis__section {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background: none;
    padding: 0; }
    .b-multipolis__section .b-multipolis__down-calc_text {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      padding: 60px 0 0;
      width: auto;
      margin-left: 0;
      overflow: scroll;
      background: #21A038;
      z-index: 20;
      border-radius: 0; }
      .b-multipolis__section .b-multipolis__down-calc_text .b-multipolis__down-calc_text_close {
        position: absolute;
        top: 10px;
        left: 50%;
        width: 29px;
        height: 29px;
        margin-left: -15px;
        background: url(../i/new/i-checkbox-faq-close.svg) no-repeat; }
      .b-multipolis__section .b-multipolis__down-calc_text .b-multipolis__down-calc_text-wrapper {
        margin: 0 auto 40px;
        padding: 30px 20px;
        background: #fff;
        border-radius: 5px;
        max-width: 400px; }
    .b-multipolis__section .b-checkbox__faq-box_call {
      margin-top: -12px; }
    .b-multipolis__section .b-multipolis__section_item {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 17px;
      background: #f9f9f9;
      border-radius: 5px;
      margin-bottom: 6px; }
      .b-multipolis__section .b-multipolis__section_item:last-child {
        margin-bottom: 0; }
    .b-multipolis__section .b-multipolis__label {
      width: 70%;
      padding-top: 18px;
      padding-bottom: 15px; }
      .b-multipolis__section .b-multipolis__label .b-calc__label {
        display: block;
        height: auto;
        margin-bottom: 0; }
    .b-multipolis__section .b-multipolis__price_box {
      width: 30%;
      text-align: right; }
      .b-multipolis__section .b-multipolis__price_box .b-multipolis__price {
        display: inline-block;
        vertical-align: top;
        height: auto;
        padding-top: 18px;
        padding-bottom: 15px; }
        .b-multipolis__section .b-multipolis__price_box .b-multipolis__price div {
          display: inline-block;
          position: relative; }
          .b-multipolis__section .b-multipolis__price_box .b-multipolis__price div:before {
            content: "Цена -"; }
    .b-multipolis__section .b-multipolis__slider_box {
      padding-top: 9px;
      width: 100%;
      padding-right: 0;
      border-top: 1px dashed rgba(0, 0, 0, 0.1); }
      .b-multipolis__section .b-multipolis__slider_box:before {
        content: "Страховая защита";
        display: block; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_single {
        text-align: right;
        margin-top: -23px; }
        .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_single div {
          width: auto;
          text-align: right; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider {
        margin: 14px auto 10px;
        width: 84%; }
        .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider .ui-slider-handle {
          -ms-touch-action: none;
          touch-action: none; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values a {
        display: inline-block; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values li:first-child {
        text-align: left; }
      .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values li:last-child {
        text-align: right; }
  .b-calc__promocode .b-calc__promocode_link {
    margin-top: 4px; }
  .b-inside-tabs__result {
    background: #fff;
    padding: 13px 15px 15px; }
    .b-inside-tabs__result .b-accordion {
      margin-left: -25px;
      margin-right: -25px;
      margin-bottom: 10px; }
      .b-inside-tabs__result .b-accordion.b-ul {
        margin-left: -25px; }
      .b-inside-tabs__result .b-accordion .b-accordion__item {
        padding-left: 25px;
        padding-right: 25px; }
  .b-first-section .b-product__wrapper .b-main-title__icons table td {
    padding-right: 20px;
    font-size: 13px;
    line-height: 24px; }
    .b-first-section .b-product__wrapper .b-main-title__icons table td img {
      height: 75px; } }

@media only screen and (max-width: 767px) {
  .b-calc__section_column-check-box {
    width: 106% !important; }
  .b-first-section__mites-protection {
    padding-bottom: 0px; }
  .b-first-section__vzr .b-product__wrapper,
  .b-product__wrapper,
  .b-product-section__wrapper {
    margin-right: 0; }
  .b-calc__last-info {
    margin-left: 0;
    margin-top: 30px;
    width: 100%; }
    .b-calc__last-info:before {
      display: none; }
  .b-amount__section {
    white-space: nowrap; }
    .b-amount__section.b-amount__box_zero .b-amount__section_label {
      width: 216px; }
    .b-amount__section .b-amount__section_label {
      width: 175px; }
    .b-amount__section .b-amount__box {
      margin-right: -10px; }
  .b-calc__section_desc ul {
    column-count: auto;
    -moz-column-count: auto; }
  li {
    padding-right: 0; }
  .b-calc {
    padding-bottom: 25px; }
    .b-calc.b-calc__family-protection {
      margin-right: 0; }
      .b-calc.b-calc__family-protection .b-calc__section_column {
        float: none; }
      .b-calc.b-calc__family-protection .b-dropdown__box {
        width: 100%;
        margin-right: 0; }
      .b-calc.b-calc__family-protection .b-calc__section .b-calc__label {
        text-align: left;
        white-space: normal;
        max-width: none; }
    .b-calc.b-calc__mortgage {
      margin-right: 0; }
      .b-calc.b-calc__mortgage .b-dropdown__box_who {
        width: 100%;
        margin-right: 0; }
      .b-calc.b-calc__mortgage .b-calc__section .b-calc__label {
        width: 100%; }
      .b-calc.b-calc__mortgage .b-calc__section_column {
        width: 100%; }
        .b-calc.b-calc__mortgage .b-calc__section_column .b-calc__section_column {
          width: auto; }
          .b-calc.b-calc__mortgage .b-calc__section_column .b-calc__section_column + .b-calc__label {
            padding-top: 14px;
            width: auto !important; }
        .b-calc.b-calc__mortgage .b-calc__section_column .b-input {
          width: 120px; }
    .b-calc.b-calc__protected-borrower .b-calc__section {
      margin-bottom: 12px; }
    .b-calc.b-calc__protected-borrower .b-calc__section_column {
      margin-bottom: 25px; }
      .b-calc.b-calc__protected-borrower .b-calc__section_column:last-child {
        margin-bottom: 0; }
      .b-calc.b-calc__protected-borrower .b-calc__section_column .b-calc__label {
        width: auto !important;
        padding-right: 10px;
        padding-top: 13px; }
      .b-calc.b-calc__protected-borrower .b-calc__section_column + .b-calc__label {
        float: left;
        vertical-align: top;
        width: auto !important;
        padding-top: 15px; }
    .b-calc.b-calc__panaceya {
      padding-bottom: 25px; }
      .b-calc.b-calc__panaceya .b-dropdown__box_price {
        width: 100%; }
      .b-calc.b-calc__panaceya .b-calc__section_column {
        float: none; }
        .b-calc.b-calc__panaceya .b-calc__section_column:last-child .b-calc__label {
          width: auto !important;
          padding-right: 10px; }
      .b-calc.b-calc__panaceya .b-calc__section .b-calc__label {
        text-align: left;
        white-space: normal; }
    .b-calc .b-calc__section {
      margin-bottom: 25px; }
      .b-calc .b-calc__section .b-calc__label {
        width: 100% !important;
        padding-right: 0;
        margin-bottom: 5px;
        padding-top: 0; }
    .b-calc .b-calendar__box {
      margin: 14px 0 0 0;
      width: 100%; }
      .b-calc .b-calendar__box:before {
        display: none; }
    .b-calc .b-calendar__box_faq {
      width: 100%;
      margin-top: 20px; }
  .b-dropdown__box_travel-theme {
    width: 100%; }
  .b-nav-page__wrapper + .b-wrapper {
    background: rgba(61, 75, 70, 0.5);
    width: 100%;
    padding: 0 5%; }
  .b-nav-page {
    position: relative !important;
    top: 0; }
    .b-nav-page.fixed-top {
      position: fixed !important; }
      .b-nav-page.fixed-top .b-nav-page__list {
        margin-left: 40px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        overflow: hidden; }
        .b-nav-page.fixed-top .b-nav-page__list:before {
          content: "";
          position: absolute;
          right: 13px;
          top: 20px;
          width: 16px;
          height: 7px;
          background: url("../i/new/i-accordion.svg") no-repeat;
          transform: rotate(180deg);
          transition: transform .25s; }
        .b-nav-page.fixed-top .b-nav-page__list.open:before {
          transform: rotate(0deg); }
        .b-nav-page.fixed-top .b-nav-page__list.open .b-nav-page__link {
          display: block; }
      .b-nav-page.fixed-top .b-nav-page__link {
        display: none; }
        .b-nav-page.fixed-top .b-nav-page__link.active {
          display: block; }
    .b-nav-page .b-nav-page__list {
      background: #fff;
      box-shadow: none;
      border-radius: 5px;
      display: block; }
      .b-nav-page .b-nav-page__list .b-nav-page__item {
        float: none;
        border-right: none; }
        .b-nav-page .b-nav-page__list .b-nav-page__item:last-child .b-nav-page__link {
          border-bottom: none; }
      .b-nav-page .b-nav-page__list .b-nav-page__link {
        color: #21A038;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0 !important; }
        .b-nav-page .b-nav-page__list .b-nav-page__link.active {
          color: #000;
          background: transparent; }
  .b-travel-how-buy {
    padding: 20px 0 0; }
    .b-travel-how-buy .b-travel-how-buy__column_box {
      margin-top: 17px;
      padding-top: 55px;
      padding-bottom: 30px; }
      .b-travel-how-buy .b-travel-how-buy__column_box .slick-arrow {
        z-index: 5;
        top: 105px;
        transform: scale(0.82); }
        .b-travel-how-buy .b-travel-how-buy__column_box .slick-arrow.slick-prev {
          left: 0; }
        .b-travel-how-buy .b-travel-how-buy__column_box .slick-arrow.slick-next {
          right: 0; }
      .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column {
        margin: 0;
        text-align: center;
        padding: 9px 50px 0; }
        .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column:before {
          top: 0;
          left: 50%;
          margin-left: -73px; }
        .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column.b-travel-how-buy__column_wide .b-travel-how-buy__column_pic {
          float: none;
          margin-right: 0; }
        .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column.b-travel-how-buy__column_wide .b-travel-how-buy__column_text {
          max-width: none;
          margin: 0; }
        .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column .b-travel-how-buy__column_pic {
          display: inline-block;
          vertical-align: top;
          width: 135px;
          height: 135px;
          zoom: 1; }
          .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column .b-travel-how-buy__column_pic:before {
            transform: translate(-50%, -50%) scale(0.75); }
        .b-travel-how-buy .b-travel-how-buy__column_box .b-travel-how-buy__column .b-travel-how-buy__column_text {
          margin-left: 0;
          margin-right: 0; }
  .b-accordion,
  .b-inside-tabs__result .b-accordion,
  .b-accordion.b-ul {
    margin-left: -5.5%;
    margin-right: -5.5%; }
    .b-accordion .b-accordion__item,
    .b-inside-tabs__result .b-accordion .b-accordion__item,
    .b-accordion.b-ul .b-accordion__item {
      padding-left: 5.5%;
      padding-right: 5.5%;
      margin-left: 0; }
  .b-accordion.b-ul .b-accordion__link,
  .b-accordion.b-ul .b-accordion__text {
    padding-left: 39px; }
  .b-product-section {
    padding-top: 50px; }
    .b-product-section.b-product-section__custom .b-old-calc select {
      float: none !important;
      width: 100%;
      margin-bottom: 15px;
      font-size: 15px; }
    .b-product-section.b-product-section__pb {
      padding-bottom: 50px; }
  .b-product-section__insurance {
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 0; }
    .b-product-section__insurance .b-button__box {
      margin-bottom: 40px; }
    .b-product-section__insurance h2:after {
      height: 40px;
      width: 37px;
      margin-left: 10px;
      top: -12px; }
    .b-product-section__insurance ol:before {
      display: none; }
    .b-product-section__insurance ol li {
      padding-left: 39px;
      padding-top: 0; }
      .b-product-section__insurance ol li:before {
        left: 0;
        top: -1px;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.1);
        font-size: 15px;
        line-height: 27px;
        text-align: center;
        opacity: 1;
        font-weight: normal; }
    .b-product-section__insurance .b-button__box {
      margin-left: 0;
      text-align: center; }
      .b-product-section__insurance .b-button__box .b-button {
        width: 100%; }
    .b-product-section__insurance .b-wrapper {
      padding: 0; }
  .b-dropdown__box_travel-where,
  .b-calc__section_column {
    width: 100%; }
  .b-calc__result_box,
  .b-calc__result_box.alone {
    left: 0;
    right: 0;
    width: auto;
    transform: none !important;
    position: relative;
    height: auto; }
  .b-calc__result_box.close .b-calc__result {
    transform: translate(-100%, 0); }
  .b-promo-code__box {
    left: -5.5%;
    right: -5.5%;
    top: auto;
    bottom: 0;
    position: absolute;
    width: auto !important;
    transform: none !important;
    height: auto !important;
    overflow: hidden;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    z-index: 1; }
    .b-promo-code__box.open {
      transition: visibility .5s .0s, z-index .5s 0s;
      z-index: 3; }
      .b-promo-code__box.open .b-promo-code {
        transform: translate(0, 0); }
    .b-promo-code__box .b-promo-code {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      border-radius: 0;
      padding: 15px 0;
      box-shadow: none;
      transform: translate(100%, 0);
      backface-visibility: hidden; }
      .b-promo-code__box .b-promo-code .b-promo-code__table-cell {
        display: block; }
      .b-promo-code__box .b-promo-code .b-promo-code__close {
        top: 0 !important;
        right: auto;
        left: 0 !important; }
  .b-calc__result.b-calc__result_bottom {
    transform: none !important; }
    .b-calc__result.b-calc__result_bottom .b-button__box {
      margin-bottom: 0; }
  .b-calc__result,
  .b-calc__result.b-calc__result_bottom,
  .b-calc__result.b-calc__result_alone {
    box-sizing: border-box;
    background: #21A038;
    position: relative;
    width: auto;
    border-radius: 0;
    left: 0;
    right: 0;
    font-size: 15px;
    line-height: 24px;
    box-shadow: none;
    height: auto;
    margin-left: -5.5%;
    margin-right: -5.5%; }
    .b-calc__result.visibility,
    .b-calc__result.b-calc__result_bottom.visibility,
    .b-calc__result.b-calc__result_alone.visibility {
      visibility: visible;
      opacity: 1; }
    .b-calc__result.empty:before,
    .b-calc__result.b-calc__result_bottom.empty:before,
    .b-calc__result.b-calc__result_alone.empty:before {
      border-radius: 0;
      background: rgba(0, 0, 0, 0.3) url("../i/new/rolling-small.svg") no-repeat center; }
    .b-calc__result .b-call-promo__link,
    .b-calc__result.b-calc__result_bottom .b-call-promo__link,
    .b-calc__result.b-calc__result_alone .b-call-promo__link {
      float: left;
      width: 100%;
      margin: 0;
      text-align: center;
      font-size: 15px;
      line-height: 24px;
      margin-top: 10px; }
    .b-calc__result.b-calc__result_link,
    .b-calc__result.b-calc__result_bottom.b-calc__result_link,
    .b-calc__result.b-calc__result_alone.b-calc__result_link {
      width: auto;
      height: auto; }
      .b-calc__result.b-calc__result_link.clean,
      .b-calc__result.b-calc__result_bottom.b-calc__result_link.clean,
      .b-calc__result.b-calc__result_alone.b-calc__result_link.clean {
        min-height: 100px;
        margin-top: -30px;
        margin-bottom: 30px; }
        .b-calc__result.b-calc__result_link.clean .b-calc__result_link-activate,
        .b-calc__result.b-calc__result_bottom.b-calc__result_link.clean .b-calc__result_link-activate,
        .b-calc__result.b-calc__result_alone.b-calc__result_link.clean .b-calc__result_link-activate {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -48%);
          right: auto; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-activate,
      .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-activate,
      .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-activate {
        right: 15px;
        top: -50px; }
      .b-calc__result.b-calc__result_link .b-calc__result_link-mian,
      .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-mian,
      .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-mian {
        font-size: 19px;
        line-height: 24px;
        padding-top: 100px;
        padding-bottom: 20px;
        border-radius: 0; }
        .b-calc__result.b-calc__result_link .b-calc__result_link-mian:before,
        .b-calc__result.b-calc__result_bottom.b-calc__result_link .b-calc__result_link-mian:before,
        .b-calc__result.b-calc__result_alone.b-calc__result_link .b-calc__result_link-mian:before {
          top: 10px; }
    .b-calc__result.b-calc__result_mobile,
    .b-calc__result.b-calc__result_bottom.b-calc__result_mobile,
    .b-calc__result.b-calc__result_alone.b-calc__result_mobile {
      position: fixed;
      height: 70px;
      margin: -70px 0 0;
      top: 100%;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
      display: block;
      z-index: 11; }
      .b-calc__result.b-calc__result_mobile.b-calc__result_link,
      .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link,
      .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link {
        text-align: left;
        overflow: hidden; }
        .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate,
        .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate,
        .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate {
          right: 0;
          top: 0;
          height: 50px;
          border-radius: 0;
          padding-top: 0;
          padding-left: 10px; }
          .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate + .b-calc__result_link-mian,
          .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate + .b-calc__result_link-mian,
          .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate + .b-calc__result_link-mian {
            padding-left: 10px;
            padding-right: 110px;
            text-align: center; }
            .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate + .b-calc__result_link-mian:before,
            .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate + .b-calc__result_link-mian:before,
            .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate + .b-calc__result_link-mian:before {
              display: none; }
          .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate:before,
          .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate:before,
          .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate:before {
            width: 280px;
            left: -7px;
            height: 98px;
            top: 50%;
            margin: -49px 0 0;
            background: #ffcd54;
            border-radius: 100px;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
          .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate span,
          .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate span,
          .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-activate span {
            line-height: 48px;
            overflow: hidden;
            width: 82px;
            white-space: nowrap; }
        .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-mian,
        .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-mian,
        .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-mian {
          padding: 0 5% 0 100px;
          line-height: 50px; }
          .b-calc__result.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-mian:before,
          .b-calc__result.b-calc__result_bottom.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-mian:before,
          .b-calc__result.b-calc__result_alone.b-calc__result_mobile.b-calc__result_link .b-calc__result_link-mian:before {
            left: 10px;
            margin-left: 0;
            top: -14px;
            width: 79px;
            height: 60px; }
    .b-calc__result .b-text__bigger,
    .b-calc__result.b-calc__result_bottom .b-text__bigger,
    .b-calc__result.b-calc__result_alone .b-text__bigger {
      font-size: 15px;
      line-height: 24px;
      float: left;
      vertical-align: top;
      padding-top: 15px;
      max-width: 50%; }
      .b-calc__result .b-text__bigger br,
      .b-calc__result.b-calc__result_bottom .b-text__bigger br,
      .b-calc__result.b-calc__result_alone .b-text__bigger br {
        display: none; }
    .b-calc__result .b-calc__result_sum,
    .b-calc__result.b-calc__result_bottom .b-calc__result_sum,
    .b-calc__result.b-calc__result_alone .b-calc__result_sum {
      float: right;
      vertical-align: top;
      font-size: 33px;
      line-height: 52px;
      margin: 0;
      height: 50px; }
      .b-calc__result .b-calc__result_sum.saleBlock,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum.saleBlock,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum.saleBlock {
        position: relative;
        margin-bottom: 20px; }
      .b-calc__result .b-calc__result_sum .sale,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .sale,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .sale {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 100%);
        font-size: 20px !important;
        line-height: 1;
        color: red;
        font-weight: 400 !important; }
        .b-calc__result .b-calc__result_sum .sale .b-rub,
        .b-calc__result.b-calc__result_bottom .b-calc__result_sum .sale .b-rub,
        .b-calc__result.b-calc__result_alone .b-calc__result_sum .sale .b-rub {
          font-size: 20px;
          font-weight: 400; }
      .b-calc__result .b-calc__result_sum span,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum span,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum span {
        position: relative; }
      .b-calc__result .b-calc__result_sum .saleLine,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .saleLine,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .saleLine {
        position: absolute;
        z-index: 2;
        top: 20px;
        left: -5px;
        height: 2px;
        width: calc(100% + 20px);
        background: red;
        transform: rotate(15deg); }
      .b-calc__result .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result .b-calc__result_sum .b-rub,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result.b-calc__result_bottom .b-calc__result_sum .b-rub,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-calc__result_sum-small,
      .b-calc__result.b-calc__result_alone .b-calc__result_sum .b-rub {
        font-size: 14px; }
    .b-calc__result .b-calc__result_pic,
    .b-calc__result.b-calc__result_bottom .b-calc__result_pic,
    .b-calc__result.b-calc__result_alone .b-calc__result_pic {
      display: none; }
    .b-calc__result .b-calc__result_wrapper,
    .b-calc__result.b-calc__result_bottom .b-calc__result_wrapper,
    .b-calc__result.b-calc__result_alone .b-calc__result_wrapper {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 90%;
      margin: auto; }
  .b-calc__result_box {
    z-index: 2; }
  .b-calc__result {
    z-index: 2; }
    .b-calc__result.b-calc__result_bottom {
      margin-top: 45px;
      top: 0; }
    .b-calc__result.b-calc__result_mobile.calculated .b-calc__result_sum {
      float: left;
      vertical-align: top;
      margin-top: 0; }
    .b-calc__result.b-calc__result_mobile.calculated.mobile-hide-text .b-text__bigger {
      display: none; }
    .b-calc__result.b-calc__result_mobile.calculated.mobile-hide-text .b-call-promo__link {
      display: block; }
    .b-calc__result.b-calc__result_mobile.calculated .b-call-promo__link {
      float: left;
      font-size: 11px;
      line-height: 16px;
      width: 70px;
      margin-left: 15px;
      text-align: left;
      display: none; }
    .b-calc__result.b-calc__result_mobile.calculated .b-text__bigger {
      font-size: 11px;
      line-height: 16px;
      width: 85px;
      padding-top: 9px; }
      .b-calc__result.b-calc__result_mobile.calculated .b-text__bigger span {
        display: none; }
        .b-calc__result.b-calc__result_mobile.calculated .b-text__bigger span.b-nowrap, .b-calc__result.b-calc__result_mobile.calculated .b-text__bigger span.b-rub {
          display: inline; }
    .b-calc__result.b-calc__result_mobile.calculated .b-button__box {
      float: right;
      vertical-align: top;
      position: relative;
      margin-top: 0 !important;
      margin-right: -5.5%;
      padding-left: 7px;
      width: auto;
      padding-bottom: 0;
      top: 5px; }
      .b-calc__result.b-calc__result_mobile.calculated .b-button__box:before {
        content: "";
        position: absolute;
        width: 280px;
        left: 0;
        height: 98px;
        top: 50%;
        margin-top: -49px;
        background: #fff;
        border-radius: 100px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
      .b-calc__result.b-calc__result_mobile.calculated .b-button__box .b-button {
        height: 50px;
        padding-top: 9px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 0;
        box-shadow: none;
        width: auto;
        margin-top: 5px; }
    .b-calc__result.calculated .b-calc__result_wrapper {
      text-align: left; }
    .b-calc__result.calculated .b-calc__result_scroll {
      display: none; }
    .b-calc__result.calculated .b-calc__result_sum {
      margin-top: 12px; }
    .b-calc__result.calculated .b-button__box {
      float: left;
      vertical-align: top;
      width: 100%;
      margin-top: 14px !important;
      padding-bottom: 23px; }
      .b-calc__result.calculated .b-button__box .b-button {
        width: 100%;
        text-align: center; }
  .b-multipolis__section .b-multipolis__section_item {
    padding-bottom: 50px; }
  .b-multipolis__section .b-multipolis__label {
    width: 100%;
    padding: 12px 35px 8px 0; }
    .b-multipolis__section .b-multipolis__label .b-checkbox__faq-box_call {
      left: auto;
      right: 0; }
  .b-multipolis__section .b-multipolis__price_box {
    float: none;
    position: absolute;
    text-align: left;
    bottom: 11px;
    left: 15px;
    right: 15px;
    width: auto; }
    .b-multipolis__section .b-multipolis__price_box .b-multipolis__price {
      padding: 0; }
  .b-multipolis__section .b-multipolis__slider_box {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
    padding-top: 11px;
    padding-bottom: 15px; }
    .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider {
      width: 100%; }
    .b-multipolis__section .b-multipolis__slider_box .b-multipolis__slider_values a {
      display: block;
      margin: 4px auto 0;
      padding: 0; }
  .b-calc .b-calendar__box .b-calendar__text {
    padding-left: 18px;
    padding-right: 18px; }
    .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1,
    .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_2 {
      float: left;
      width: 50%;
      text-align: left;
      box-sizing: border-box; }
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1:before,
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_2:before {
        left: 0;
        right: 0; }
    .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1 {
      padding-right: 10px; }
      .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_1:after {
        content: "";
        position: absolute;
        top: -13px;
        bottom: -16px;
        right: 0;
        width: 1px;
        background: rgba(0, 0, 0, 0.1); }
    .b-calc .b-calendar__box .b-calendar__text .b-calendar__text_2 {
      padding-left: 10px; }
  .b-date-picker {
    padding: 0 18px; }
    .b-date-picker .datepick {
      width: 100% !important; }
    .b-date-picker .datepick-month {
      width: 245px;
      float: none;
      margin: auto; }
  .b-inside-tabs__result .b-accordion.b-ul .b-accordion__item .b-accordion__link,
  .b-inside-tabs__result .b-accordion.b-ul .b-accordion__item .b-accordion__text {
    padding-left: 0; }
  .b-product-section h2 + .b-main-tabs__section {
    margin-top: 0; }
  .b-first-section .b-product__wrapper .b-main-title__icons table {
    display: block; }
    .b-first-section .b-product__wrapper .b-main-title__icons table tr {
      display: block; }
      .b-first-section .b-product__wrapper .b-main-title__icons table tr:last-child td:last-child {
        border-bottom: none !important; }
    .b-first-section .b-product__wrapper .b-main-title__icons table td {
      display: block;
      padding-right: 0;
      font-size: 15px;
      line-height: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important; }
      .b-first-section .b-product__wrapper .b-main-title__icons table td img {
        height: 75px; } }

.error {
  padding-top: 100px; }
  .error__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center; }
    @media screen and (max-width: 767px) {
      .error__container {
        display: flex;
        flex-direction: column; } }
  @media screen and (max-width: 767px) {
    .error__content {
      margin-top: 50px; } }
  .error__button {
    margin-top: 60px; }
  .error__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .error .title {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 150%;
    color: #333f48; }
  .error .text {
    font-size: 16px;
    line-height: 150%;
    color: #333f48; }

.b-index-top {
  position: relative;
  height: auto; }

.b-index-carousel__box {
  position: relative;
  height: 100%; }
  .b-index-carousel__box:hover .slick-dots li button span {
    opacity: 1; }
  .b-index-carousel__box .slick-arrow {
    background: #21A038; }
    .b-index-carousel__box .slick-arrow:before {
      background: url("../i/new/i-slick-white.png") no-repeat; }
    .b-index-carousel__box .slick-arrow:hover {
      background: #fff; }
      .b-index-carousel__box .slick-arrow:hover:before {
        background: url("../i/new/i-slick-green.png") no-repeat; }
    .b-index-carousel__box .slick-arrow.slick-prev {
      left: 0;
      margin-left: 27px; }
    .b-index-carousel__box .slick-arrow.slick-next {
      right: 0;
      margin-right: 27px; }
  .b-index-carousel__box .slick-dots {
    width: 1050px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 85px;
    padding-left: 65px; }
    .b-index-carousel__box .slick-dots li {
      float: left;
      vertical-align: top;
      margin: 0 15px 0 0;
      position: relative; }
      .b-index-carousel__box .slick-dots li:first-child {
        margin: 0 15px; }
      .b-index-carousel__box .slick-dots li.slick-active button {
        background: #21A038;
        cursor: default;
        box-shadow: -2px 10px 10px rgba(0, 0, 0, 0.2); }
        .b-index-carousel__box .slick-dots li.slick-active button:hover {
          background: #21A038; }
      .b-index-carousel__box .slick-dots li button {
        font-size: 0;
        line-height: 0;
        color: transparent;
        display: block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #fff;
        transition: background .25s, box-shadow .25s; }
        .b-index-carousel__box .slick-dots li button:hover {
          background: #21A038; }

.b-index-carousel {
  position: relative;
  height: 700px !important; }
  .b-index-carousel .b-wrapper {
    height: 100%; }
  .b-index-carousel .b-product-section__wrapper {
    padding-top: 165px;
    margin-right: 420px;
    margin-left: 65px;
    max-width: 500px; }
    .b-index-carousel .b-product-section__wrapper p {
      max-height: 81px;
      overflow: hidden; }
    .b-index-carousel .b-product-section__wrapper h2 {
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 36px; }
      .b-index-carousel .b-product-section__wrapper h2 a {
        color: #fff;
        border: none;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background: #21A038;
        font-family: "SB Sans Display Bold";
        padding: 5px 13px 5px 10px;
        display: inline;
        font-weight: 700;
        transition: .5s;
        text-decoration: none; }
        .b-index-carousel .b-product-section__wrapper h2 a:hover {
          background: #21A038;
          box-shadow: -10px 34px 45px rgba(0, 0, 0, 0.5);
          text-decoration: none; }
  .b-index-carousel .b-calc__result {
    top: 175px;
    right: 150px;
    background: #fff;
    color: #000;
    box-shadow: -6px 20px 30px rgba(0, 0, 0, 0.5); }
    .b-index-carousel .b-calc__result .b-text__bigger {
      line-height: 27px;
      display: block;
      margin-bottom: 15px;
      width: 70%;
      margin-left: auto;
      margin-right: auto; }
    .b-index-carousel .b-calc__result .b-button {
      height: 50px;
      font-size: 16px;
      line-height: 30px;
      padding: 8px 25px 12px;
      box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.15); }
    .b-index-carousel .b-calc__result .b-calc__result_sum {
      font-size: 42px;
      line-height: 55px;
      font-weight: normal;
      margin-bottom: 8px; }
      .b-index-carousel .b-calc__result .b-calc__result_sum .b-rub {
        font-size: 35px;
        line-height: 55px; }
      .b-index-carousel .b-calc__result .b-calc__result_sum .b-calc__result_sum-small {
        font-size: 42px;
        line-height: 55px;
        display: inline; }
    .b-index-carousel .b-calc__result.b-calc__result_bottom-custom {
      top: 50%;
      padding: 10px;
      background: #21A038;
      text-align: center;
      font-size: 19px;
      line-height: 27px;
      color: #fff;
      transition: .5s;
      height: 50px;
      width: 200px;
      border-radius: 50px;
      position: static;
      display: block;
      margin-top: 40px;
      box-shadow: none;
      margin-left: 65px; }
      .b-index-carousel .b-calc__result.b-calc__result_bottom-custom:hover {
        box-shadow: -6px 20px 30px rgba(0, 0, 0, 0.5);
        text-decoration: none; }
  .b-index-carousel.slick-active .b-index-carousel__rotate {
    transform: rotateZ(0deg);
    opacity: 1; }
    .b-index-carousel.slick-active .b-index-carousel__rotate .b-index-carousel__rotate-wrapper {
      transform: rotateZ(0deg); }
  .b-index-carousel .b-index-carousel__rotate {
    position: absolute;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    top: 175px;
    right: 150px;
    transform-origin: 50% 50%;
    transform: rotateZ(150deg);
    opacity: 0;
    transition: transform .35s .5s; }
    .b-index-carousel .b-index-carousel__rotate .b-index-carousel__rotate-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      width: 150px;
      height: 150px;
      transform: rotateZ(-150deg);
      transition: transform .35s .5s; }
  .b-index-carousel .b-calc__result_index {
    position: absolute;
    top: -50px;
    right: -70px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.5); }
    .b-index-carousel .b-calc__result_index .b-calc__result_index-wrapper {
      position: absolute;
      left: 10px;
      right: 10px;
      top: 50%;
      margin-top: -10px;
      transform: translate(0, -50%); }
    .b-index-carousel .b-calc__result_index strong {
      font-size: 25px;
      display: block;
      margin-top: 9px;
      text-align: right;
      padding-right: 10px; }

.b-index-sort__section_overlay {
  position: absolute;
  top: 700px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #222222;
  opacity: .7;
  z-index: 4;
  display: none; }

.b-index-sort__section {
  position: relative;
  z-index: 20;
  margin-top: -33px;
  min-height: 58px;
  margin-bottom: 40px; }
  .b-index-sort__section.fixed .b-index-sort__container {
    position: fixed;
    visibility: hidden; }
  .b-index-sort__section.fixed .b-index-sort__wrapper {
    opacity: 0; }
  .b-index-sort__section.fixed.visibility {
    opacity: 0;
    z-index: -1;
    transition: opacity .25s, z-index .25s .25s; }
  .b-index-sort__section.fixed.no-opacity .b-index-sort__container {
    visibility: visible; }
  .b-index-sort__section.fixed.no-opacity .b-index-sort__wrapper {
    opacity: 1; }
  .b-index-sort__section .b-index-sort__container {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    transition: opacity .25s .05s, z-index .1s, visibility .25s .0s;
    z-index: 5; }
  .b-index-sort__section .b-index-sort__wrapper {
    float: left;
    vertical-align: top;
    position: relative;
    transition: opacity .25s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  .b-index-sort__section .b-index-sort__box {
    float: left;
    vertical-align: top;
    margin-right: 10px;
    border-radius: 5px;
    box-shadow: -2px 10px 15px rgba(0, 0, 0, 0.1);
    background: #fff;
    transition: .5s;
    width: 33%;
    font-size: 15px;
    line-height: 22px; }
    .b-index-sort__section .b-index-sort__box:last-child {
      margin-right: 0; }
    .b-index-sort__section .b-index-sort__box:hover {
      text-decoration: none;
      box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.2); }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_green .b-index-sort .b-index-sort__label .b-index-sort__icon span {
      background: #21A038; }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_green .b-index-sort input:checked + .b-index-sort__label .b-index-sort__icon {
      background: rgba(58, 172, 106, 0.2); }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_red .b-index-sort .b-index-sort__label .b-index-sort__icon span {
      background: #B45457; }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_red .b-index-sort input:checked + .b-index-sort__label .b-index-sort__icon {
      background: #B45457; }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_yellow .b-index-sort .b-index-sort__label .b-index-sort__icon span {
      background: #FAED00; }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_yellow .b-index-sort input:checked + .b-index-sort__label .b-index-sort__icon {
      background: rgba(255, 189, 28, 0.3); }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_purple .b-index-sort .b-index-sort__label .b-index-sort__icon span {
      background: #00ADEE; }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_purple .b-index-sort input:checked + .b-index-sort__label .b-index-sort__icon {
      background: rgba(151, 117, 196, 0.2); }
    .b-index-sort__section .b-index-sort__box.b-index-sort__box_big {
      height: 50px !important;
      overflow: hidden; }
      .b-index-sort__section .b-index-sort__box.b-index-sort__box_big:hover {
        overflow: visible; }
        .b-index-sort__section .b-index-sort__box.b-index-sort__box_big:hover .b-index-sort {
          opacity: 1; }
          .b-index-sort__section .b-index-sort__box.b-index-sort__box_big:hover .b-index-sort:first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }
    .b-index-sort__section .b-index-sort__box .b-index-sort {
      position: relative;
      overflow: hidden;
      transition: opacity .25s .05s; }
      .b-index-sort__section .b-index-sort__box .b-index-sort:first-child {
        border-radius: 5px; }
      .b-index-sort__section .b-index-sort__box .b-index-sort + .b-index-sort {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        opacity: 0; }
      .b-index-sort__section .b-index-sort__box .b-index-sort:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px; }
      .b-index-sort__section .b-index-sort__box .b-index-sort input {
        opacity: 0;
        position: absolute;
        left: -100%;
        top: -100%;
        width: 0;
        height: 0; }
        .b-index-sort__section .b-index-sort__box .b-index-sort input:checked + .b-index-sort__label .b-index-sort__icon span {
          left: 100%; }
      .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label {
        min-height: 50px;
        display: block;
        padding: 13px 50px 15px 15px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        transition: color .25s;
        color: #21A038; }
        .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label:hover {
          color: #21A038; }
        .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label .b-index-sort__icon {
          position: absolute;
          right: 12px;
          top: 50%;
          margin-top: -4px;
          height: 9px;
          width: 10px;
          border-radius: 9px;
          background: rgba(0, 0, 0, 0.1);
          transition: background .25s; }
          .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label .b-index-sort__icon span {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            margin-left: -17px;
            left: 14px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
            transition: left .25s;
            box-shadow: -3px 10px 10px rgba(0, 0, 0, 0.2); }

.b-products__section {
  position: relative;
  padding-bottom: 75px; }
  .b-products__section .b-products__section_empty {
    text-align: center;
    display: none; }
  .b-products__section.hide-show-more .b-button__box_show {
    display: none; }
  .b-products__section .b-products__section_wrapper {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap; }
  .b-products__section .b-products__section_background {
    position: absolute;
    top: -65px;
    width: 100%;
    height: 279px;
    background: url("../i/new/decoretion.svg") no-repeat;
    background-size: cover; }

.b-product {
  position: relative;
  font-size: 14px;
  line-height: 25px;
  margin: 5px;
  height: auto;
  width: 252px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 5px;
  vertical-align: top;
  float: left;
  transition: box-shadow .25s; }
  .b-product.financial_literacy:hover .b-product__pic {
    background: none;
    box-shadow: none; }
  .b-product.financial_literacy:hover .b-product__price {
    color: #fff; }
  .b-product.financial_literacy.b-product__big .b-product__pic {
    background: none;
    box-shadow: none; }
    .b-product.financial_literacy.b-product__big .b-product__pic .b-product__price {
      display: none; }
  .b-product.financial_literacy .b-product__price {
    display: none; }
  .b-product.financial_literacy .b-product__pic {
    background: none;
    box-shadow: none; }
  .b-product.financial_literacy .b-product__type {
    background: #FAED00; }
    .b-product.financial_literacy .b-product__type:before {
      background: url("../i/new/i-financial-literacy.svg") no-repeat;
      width: 39px;
      height: 27px;
      background-size: 100% auto; }
  .b-product.financial_literacy .b-product__basket {
    background: #fff; }
    .b-product.financial_literacy .b-product__basket:before {
      left: 55%;
      width: 23px;
      height: 31px;
      background: url("../i/new/i-index-video-product.svg") no-repeat;
      background-size: cover; }
  .b-product.news:hover .b-product__main {
    background: none;
    color: rgba(0, 0, 0, 0.9); }
  .b-product.news:hover .b-product__text h4 .b-link .b-link__text {
    box-shadow: -10px 33px 45px rgba(0, 0, 0, 0.3); }
  .b-product.news.b-product__big .b-product__main {
    background: none;
    color: rgba(0, 0, 0, 0.9); }
  .b-product.news.b-product__big .b-product__bg {
    display: none; }
  .b-product.news.b-product__big .b-product__text {
    max-width: none;
    margin-right: 43px; }
  .b-product.news .b-product__tags li a {
    color: rgba(0, 0, 0, 0.4) !important; }
    .b-product.news .b-product__tags li a:hover {
      color: black !important; }
  .b-product.news .b-product__type {
    background: #00ADEE; }
    .b-product.news .b-product__type:before {
      background: url("../i/new/i-news.svg") no-repeat;
      width: 24px;
      height: 27px;
      background-size: 100% auto; }
  .b-product.news .b-product__text {
    padding-top: 7px; }
  .b-product .b-product__text_overlay {
    max-height: 323px;
    min-height: 323px; }
  .b-product .b-product__text_overlay:before {
    content: "";
    position: absolute;
    z-index: 22;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0)); }
  .b-product.b-product__big {
    width: 514px;
    font-size: 16px;
    line-height: 25px; }
    @media only screen and (max-width: 767px) {
      .b-product.b-product__big {
        width: 100%; } }
    .b-product.b-product__big:hover .b-product__bg {
      transform: scale(1.1); }
    .b-product.b-product__big .b-product__bg {
      opacity: .5;
      transition: transform .25s; }
    .b-product.b-product__big .b-product__main {
      color: rgba(255, 255, 255, 0.9);
      background: #56675f; }
    .b-product.b-product__big .b-product__pic {
      background: #fff;
      position: absolute;
      right: 70px;
      top: 65px;
      z-index: 4;
      box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.4); }
      .b-product.b-product__big .b-product__pic .b-product__pic_pic {
        opacity: 0; }
      .b-product.b-product__big .b-product__pic .b-product__price {
        opacity: 1; }
    .b-product.b-product__big .b-product__text_overlay {
      max-height: 315px;
      min-height: 250px; }
    .b-product.b-product__big .b-product__text {
      max-width: 270px;
      margin-left: 43px;
      padding-top: 14px; }
      .b-product.b-product__big .b-product__text h4 {
        font-size: 25px;
        line-height: 31px;
        width: 90%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 21px; }
        .b-product.b-product__big .b-product__text h4 .b-link {
          color: #fff; }
          .b-product.b-product__big .b-product__text h4 .b-link .b-link__text {
            padding: .2em 7px 2px 7px;
            box-shadow: -10px 33px 45px rgba(0, 0, 0, 0.3);
            background: #21A038;
            border-bottom-color: transparent; }
    .b-product.b-product__big .b-product__tags li a {
      color: rgba(255, 255, 255, 0.4); }
      .b-product.b-product__big .b-product__tags li a:hover {
        color: #fff; }
  .b-product.travel .b-product__type:before {
    background: url("../i/new/header/menu-travel3.svg") no-repeat;
    width: 29px;
    height: 27px;
    background-size: 100% auto; }
  .b-product.health .b-product__type:before {
    background: url("../i/new/header/menu-health3.svg") no-repeat;
    width: 29px;
    height: 29px;
    background-size: 100% auto; }
  .b-product.finance .b-product__type:before {
    background: url("../i/new/header/menu-finance3.svg") no-repeat;
    width: 22px;
    height: 25px;
    background-size: 100% auto; }
  .b-product.family .b-product__type:before {
    background: url("../i/new/header/menu-family3.svg") no-repeat;
    width: 30px;
    height: 27px;
    background-size: 100% auto; }
  .b-product.business .b-product__type:before {
    background: url("../i/new/header/menu-business3.svg") no-repeat;
    width: 17px;
    height: 34px;
    background-size: 100% auto; }
  .b-product.realty .b-product__type:before, .b-product.build .b-product__type:before {
    background: url("../i/new/header/menu-build3.svg") no-repeat;
    width: 33px;
    height: 26px;
    background-size: 100% auto; }
  .b-product.investment .b-product__type:before {
    background: url("../i/new/header/menu-invest3.svg") no-repeat;
    width: 27px;
    height: 24px;
    background-size: 100% auto; }
  .b-product .b-product__tags {
    position: absolute;
    font-size: 14px;
    line-height: 25px;
    left: 50px;
    top: 18px;
    z-index: 3;
    right: 8px;
    height: 25px;
    overflow: hidden; }
    .b-product .b-product__tags li {
      float: left;
      vertical-align: top;
      margin-left: 10px; }
      .b-product .b-product__tags li a {
        color: rgba(0, 0, 0, 0.4);
        border-bottom: none;
        white-space: nowrap; }
  .b-product .b-product__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity .25s; }
  .b-product:hover .b-button__box .b-button {
    border-color: #fff; }
  .b-product:hover .b-product__bg {
    opacity: .5; }
  .b-product:hover .b-product__tags li a {
    color: rgba(255, 255, 255, 0.4); }
    .b-product:hover .b-product__tags li a:hover {
      color: #fff; }
  .b-product:hover .b-product__main {
    background: #56675f;
    color: rgba(255, 255, 255, 0.9); }
  .b-product:hover .b-product__price {
    opacity: 1;
    color: #000; }
  .b-product:hover .b-product__pic {
    box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.4); }
    .b-product:hover .b-product__pic.b-product__pic_simple {
      box-shadow: none; }
    .b-product:hover .b-product__pic .b-product__pic_pic {
      opacity: 0; }
  .b-product:hover .b-product__text h4 .b-link {
    color: #fff; }
    .b-product:hover .b-product__text h4 .b-link .b-link__text {
      box-shadow: -10px 33px 45px rgba(0, 0, 0, 0.5);
      background: #21A038;
      border-bottom-color: transparent; }
  .b-product .b-product__basket {
    position: absolute;
    right: -43px;
    bottom: 0;
    z-index: 3;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.15);
    background: #21A038; }
    .b-product .b-product__basket:before {
      content: "";
      position: absolute;
      width: 36px;
      height: 39px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("../i/new/i-product-basket.png") no-repeat; }
  .b-product .b-product__main {
    display: block;
    padding-top: 60px;
    width: 100%;
    height: 100%;
    border-bottom: none;
    color: rgba(0, 0, 0, 0.9);
    transition: color .25s, background .25s; }
  .b-product .b-product__type {
    position: absolute;
    left: 8px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: #21A038;
    border-radius: 50px;
    z-index: 2; }
    .b-product .b-product__type:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0.7); }
  .b-product .b-product__text_overlay {
    overflow: hidden;
    padding-bottom: 15px;
    height: calc(100% - 165px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; }
  .b-product .b-product__text {
    position: relative;
    margin: 0 30px;
    z-index: 2; }
    .b-product .b-product__text em {
      background: #21A038;
      color: #fff; }
    .b-product .b-product__text h4 {
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      margin-left: -3px;
      margin-right: -3px;
      margin-bottom: 11px; }
      .b-product .b-product__text h4 .b-link {
        transition: color .25s; }
        .b-product .b-product__text h4 .b-link .b-link__text {
          padding: .1em 3px 2px 3px;
          transition: border-color .25s, background .25s, box-shadow .25s;
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone; }
    .b-product .b-product__text p {
      margin-bottom: 20px; }
      .b-product .b-product__text p:last-child {
        margin-bottom: 0; }
  .b-product .more {
    display: block;
    text-align: left;
    background-color: transparent;
    color: #2B9938;
    font-size: 14px;
    margin-left: 30px; }
  .b-product .b-product__price {
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-size: 19px;
    z-index: 2;
    opacity: 0;
    transition: opacity .25s;
    color: #000; }
    .b-product .b-product__price span {
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        .b-product .b-product__price span {
          color: #fff; } }
    .b-product .b-product__price .btn-green {
      display: block;
      background-color: #2B9938;
      color: #fff;
      font-size: 17px;
      padding: 10px;
      width: calc(100% - 60px) !important;
      margin-top: 10px;
      text-align: center;
      border: 0 none !important;
      border-radius: 5px !important;
      cursor: pointer; }
  .b-product .b-product__pic {
    width: 140px;
    height: 140px;
    position: relative;
    margin: 0 auto 20px;
    border-radius: 50%;
    background: #fff;
    opacity: 1;
    z-index: 1;
    transition: box-shadow .25s; }
    .b-product .b-product__pic.b-product__pic_simple {
      border: 1px solid #fff;
      background: none; }
      .b-product .b-product__pic.b-product__pic_simple .b-product__basket {
        display: none; }
      .b-product .b-product__pic.b-product__pic_simple:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 49px;
        height: 38px;
        margin: -19px 0 0 -24px;
        background: url("/static/i/new/i-product-arrow-simple.png") no-repeat; }
    .b-product .b-product__pic .b-product__pic_pic {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      overflow: hidden;
      background-position: left top;
      background-size: cover;
      transition: opacity .25s; }

.b-subscribe__section {
  position: relative;
  padding: 80px 0 70px; }
  .b-subscribe__section .b-bg__pic {
    opacity: .3; }
  .b-subscribe__section .b-subscribe__title {
    text-align: center;
    width: 630px;
    margin: 0 auto 40px; }
  .b-subscribe__section .b-subscribe__success {
    text-align: center; }
  .b-subscribe__section .b-subscribe__error {
    text-align: center;
    margin-top: 15px; }
  .b-subscribe__section .b-subscribe {
    position: relative;
    width: 510px;
    margin: auto; }
    .b-subscribe__section .b-subscribe .b-input {
      width: 250px;
      float: left;
      vertical-align: top; }
      .b-subscribe__section .b-subscribe .b-input:first-child {
        margin-right: 10px; }
    .b-subscribe__section .b-subscribe .b-button__box {
      text-align: center;
      margin-top: 55px; }

.b-specs__section {
  position: relative;
  padding: 0 0 60px; }
  .b-specs__section .b-specs__carousel {
    margin-left: -9px;
    margin-right: -9px;
    margin-top: -5px;
    padding: 5px 0; }
  .b-specs__section .slick-arrow {
    background: #21A038; }
    .b-specs__section .slick-arrow:before {
      background: url("../i/new/i-slick-white.png") no-repeat; }
    .b-specs__section .slick-arrow:hover {
      background: #fff; }
      .b-specs__section .slick-arrow:hover:before {
        background: url("../i/new/i-slick-green.png") no-repeat; }
  .b-specs__section .b-specs {
    position: relative;
    margin: 9px;
    height: 365px;
    width: 338px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    vertical-align: top;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 40px 35px;
    transition: box-shadow .25s; }
    .b-specs__section .b-specs:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }
      .b-specs__section .b-specs:hover .b-link {
        color: #21A038;
        border-bottom-color: rgba(33, 160, 56, 0.33); }
      .b-specs__section .b-specs:hover .b-specs__pic {
        transform: scale(1.2); }
    .b-specs__section .b-specs.b-specs_white {
      color: #fff; }
      .b-specs__section .b-specs.b-specs_white .b-link {
        color: #fff;
        border-bottom-color: rgba(255, 255, 255, 0.3); }
    .b-specs__section .b-specs .b-specs__pic {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transform: scale(1);
      transition: transform .25s; }
    .b-specs__section .b-specs .b-specs__text {
      width: 80%;
      position: relative;
      z-index: 2; }
      .b-specs__section .b-specs .b-specs__text p {
        width: 80%; }
    .b-specs__section .b-specs .b-link {
      position: absolute;
      bottom: 30px;
      left: 35px;
      z-index: 2; }

.headText {
  font-size: 40px;
  line-height: 1.4;
  color: #000;
  margin-bottom: 32px; }
  @media screen and (max-width: 768px) {
    .headText {
      text-align: center; } }

.insuranceFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }
  .insuranceFilter-el {
    margin-bottom: 25px;
    margin-right: 10px; }
    @media screen and (max-width: 768px) {
      .insuranceFilter-el {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px; } }
    .insuranceFilter-el input {
      display: none; }
    .insuranceFilter-el label {
      padding: 10px 30px;
      background-color: #F2F2F2;
      border-radius: 5px;
      cursor: pointer;
      transition: .5s;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 768px) {
        .insuranceFilter-el label {
          width: 100%; } }
      .insuranceFilter-el label img {
        margin-right: 7px; }
      .insuranceFilter-el label .white {
        display: none; }
      .insuranceFilter-el label .black {
        display: inline-block; }
    .insuranceFilter-el input:checked + label {
      background: #2B9938;
      color: #fff; }
      .insuranceFilter-el input:checked + label .white {
        display: inline-block; }
      .insuranceFilter-el input:checked + label .black {
        display: none; }

.b-subscribe .b-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.2); }

.b-subscribe .b-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.2); }

.b-subscribe .b-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.2); }

.b-subscribe .b-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.2); }

@media only screen and (min-width: 768px) {
  .b-product.news:hover {
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.3); }
  .b-product:hover {
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
    z-index: 4; } }

@media only screen and (max-device-width: 1024px) {
  .b-product.news:hover {
    box-shadow: inherit; }
  .b-product:hover {
    box-shadow: inherit; } }

@media only screen and (min-width: 1600px) {
  .b-specs {
    width: 454px; }
  .b-index-carousel__box .slick-dots {
    width: 1400px; }
  .b-index-sort__section .b-index-sort__box {
    width: 33%; }
  .b-index-carousel .b-product-section__wrapper p {
    max-height: 90px; }
  .b-index-carousel .b-calc__result {
    width: 280px;
    height: 280px; }
    .b-index-carousel .b-calc__result .b-text__bigger {
      font-size: 16px; }
  .b-product {
    width: 342px; }
    .b-product h4 {
      font-size: 30px;
      line-height: 38px; }
    .b-product.b-product__big {
      width: 694px; } }

@media only screen and (max-width: 1199px) {
  .b-specs__section .b-wrapper {
    padding: 0 60px; }
  .b-specs__section .b-specs__carousel .slick-arrow {
    background: #21A038; }
    .b-specs__section .b-specs__carousel .slick-arrow:before {
      background: url("../i/new/i-slick-white.png") no-repeat; }
  .b-index-carousel__box .slick-dots {
    transform: none;
    left: 60px;
    right: 25px;
    width: auto;
    bottom: 60px; }
    .b-index-carousel__box .slick-dots li button span {
      font-size: 12px;
      line-height: 18px;
      margin-top: -31px;
      padding-bottom: 10px;
      opacity: 1; }
  .b-index-carousel__box .slick-arrow {
    background: #21A038 !important; }
    .b-index-carousel__box .slick-arrow:before {
      background: url("../i/new/i-slick-white.png") no-repeat !important; }
    .b-index-carousel__box .slick-arrow.slick-prev {
      margin-left: -34px; }
    .b-index-carousel__box .slick-arrow.slick-next {
      margin-right: -34px; }
  .b-index-carousel .b-product-section__wrapper {
    margin-right: 290px; }
    .b-index-carousel .b-product-section__wrapper h2 {
      font-size: 31px;
      line-height: 45px; }
    .b-index-carousel .b-product-section__wrapper p {
      max-height: none; }
  .b-index-carousel .b-calc__result {
    top: 190px;
    right: 85px; }
    .b-index-carousel .b-calc__result .b-text__bigger {
      line-height: 20px;
      margin-bottom: 10px;
      width: 60%; }
    .b-index-carousel .b-calc__result .b-calc__result_sum {
      font-size: 35px;
      line-height: 40px; }
      .b-index-carousel .b-calc__result .b-calc__result_sum .b-calc__result_sum-small {
        font-size: 35px;
        line-height: 40px; }
      .b-index-carousel .b-calc__result .b-calc__result_sum .b-rub {
        line-height: 40px;
        font-size: 30px; }
    .b-index-carousel .b-calc__result.b-calc__result_bottom-custom {
      right: 150px; }
  .b-index-carousel .b-index-carousel__rotate {
    width: 200px;
    height: 200px;
    top: 190px;
    right: 85px; }
    .b-index-carousel .b-index-carousel__rotate .b-index-carousel__rotate-wrapper {
      width: 115px;
      height: 115px; }
  .b-index-carousel .b-calc__result_index {
    width: 115px;
    height: 115px;
    font-size: 11px;
    line-height: 16px;
    right: -55px; }
    .b-index-carousel .b-calc__result_index .b-calc__result_index-wrapper {
      margin-top: -5px; }
    .b-index-carousel .b-calc__result_index b {
      font-size: 18px; }
  .b-product {
    width: 47%;
    margin: 1.5%; }
    .b-product .b-product__text h4 {
      font-size: 25px;
      line-height: 35px; }
  .b-products__section {
    margin-top: -11px; }
    .b-products__section .b-products__section_wrapper {
      margin-left: -1.5%;
      margin-right: -1.5%;
      padding-top: 1.5%; }
    .b-products__section .b-products__section_background {
      top: -1px; }
  .b-index-sort__section {
    margin-top: 11px;
    min-height: 0;
    margin-bottom: 0; }
    .b-index-sort__section.fixed .b-index-sort__container {
      opacity: 1;
      visibility: visible; }
    .b-index-sort__section.open {
      z-index: 100; }
      .b-index-sort__section.open .b-index-sort__call {
        opacity: 0; }
    .b-index-sort__section .b-index-sort__wrapper {
      float: none;
      opacity: 1 !important;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column; }
    .b-index-sort__section .b-index-sort__popup_close {
      position: absolute;
      top: 18px;
      left: 50%;
      margin-left: -15px;
      width: 29px;
      height: 29px;
      background: url("../i/new/i-checkbox-faq-close.svg");
      border: none; }
    .b-index-sort__section .b-index-sort__popup {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 38, 25, 0.5);
      padding-top: 75px;
      box-sizing: border-box;
      overflow: scroll;
      display: none; }
      .b-index-sort__section .b-index-sort__popup .b-index-sort__box {
        float: none;
        width: 280px;
        margin: 0 auto 15px; }
        .b-index-sort__section .b-index-sort__popup .b-index-sort__box.b-index-sort__box_big {
          height: auto !important;
          overflow: visible;
          background: #fff; }
          .b-index-sort__section .b-index-sort__popup .b-index-sort__box.b-index-sort__box_big .b-index-sort {
            opacity: 1; }
    .b-index-sort__section .b-index-sort__call {
      position: absolute;
      top: 2px;
      width: 45px;
      height: 45px;
      border-radius: 5px;
      background: #21A038;
      border: none;
      right: 25px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
      transition: opacity .25s; }
      .b-index-sort__section .b-index-sort__call:before {
        content: "";
        position: absolute;
        width: 31px;
        height: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url("../i/new/i-filters.svg") no-repeat; } }

@media only screen and (max-width: 767px) {
  .b-subscribe__section {
    padding: 60px 0; }
    .b-subscribe__section .b-subscribe__title {
      width: 100%; }
    .b-subscribe__section .b-subscribe {
      width: 90%;
      margin: auto; }
      .b-subscribe__section .b-subscribe .b-input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px; }
        .b-subscribe__section .b-subscribe .b-input:last-child {
          margin-bottom: 0; }
      .b-subscribe__section .b-subscribe .b-button__box {
        margin-top: 30px;
        width: 100%; }
        .b-subscribe__section .b-subscribe .b-button__box .b-button {
          width: 100%; }
  .b-index-carousel__box .slick-arrow {
    top: 120px; }
    .b-index-carousel__box .slick-arrow.slick-next {
      right: 2% !important;
      margin-right: 0;
      display: none !important; }
    .b-index-carousel__box .slick-arrow.slick-prev {
      left: 98% !important;
      margin-left: -108px;
      display: none !important; }
  .b-index-carousel__box .slick-dots {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
    right: 0;
    display: flex;
    justify-content: center;
    padding-left: 0; }
  .b-index-top {
    height: 520px; }
    .b-index-top .b-index-carousel {
      height: 520px !important; }
      .b-index-top .b-index-carousel .b-product-section__wrapper {
        padding-top: 160px; }
      .b-index-top .b-index-carousel .b-index-carousel__rotate {
        display: none; }
      .b-index-top .b-index-carousel .b-wrapper {
        height: 100%; }
      .b-index-top .b-index-carousel .b-calc__result {
        left: 2.5%;
        right: 2.5%;
        bottom: 2.5%;
        top: auto;
        margin-top: 0;
        position: absolute;
        border-radius: 7px; }
        .b-index-top .b-index-carousel .b-calc__result .b-text__bigger {
          font-size: 13px;
          line-height: 21px;
          padding-top: 12px; }
        .b-index-top .b-index-carousel .b-calc__result .b-button {
          height: 46px;
          font-size: 15px;
          padding: 6px 0 10px; }
        .b-index-top .b-index-carousel .b-calc__result .b-button__box {
          margin-top: 2px !important;
          padding-bottom: 20px; }
        .b-index-top .b-index-carousel .b-calc__result .b-calc__result_sum {
          font-size: 24px;
          line-height: 31px;
          height: auto;
          margin-top: 19px; }
          .b-index-top .b-index-carousel .b-calc__result .b-calc__result_sum .b-calc__result_sum-small {
            font-size: 24px;
            line-height: 31px; }
          .b-index-top .b-index-carousel .b-calc__result .b-calc__result_sum .b-rub {
            font-size: 20px;
            line-height: 31px; }
        .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom {
          bottom: 35px;
          left: 5%;
          font-size: 15px;
          line-height: 26px;
          margin: 0 auto; }
          .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom:before {
            width: 24px;
            height: 13px;
            background-size: cover;
            margin: -6px 0 0 -12px; }
          .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom span {
            top: 50%;
            margin-top: -13px;
            left: 100%;
            right: auto;
            padding-top: 0;
            padding-left: 10px;
            transform: none; }
  .b-index-carousel.b-index-carousel_custom .b-product-section__wrapper p {
    display: block;
    max-height: 120px; }
  .b-index-carousel .b-product-section__wrapper {
    max-width: none;
    margin-left: 0;
    margin-right: 0; }
    .b-index-carousel .b-product-section__wrapper h2 {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 26px; }
    .b-index-carousel .b-product-section__wrapper p {
      display: none; }
  .b-products__section {
    margin-top: 0;
    padding-bottom: 30px; }
    .b-products__section .b-wrapper {
      width: 95%; }
    .b-products__section .b-products__section_wrapper {
      padding: 10px 0 0;
      margin: 0 0 30px; }
  .b-index-sort__section {
    margin-top: 0; }
    .b-index-sort__section .b-index-sort__call {
      right: -2.5%; }
  .b-product {
    width: 100%;
    margin: 0 0 10px; }
    .b-product.news .b-product__main {
      background: none;
      color: rgba(0, 0, 0, 0.9); }
    .b-product.financial_literacy .b-product__pic {
      box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.4); }
      .b-product.financial_literacy .b-product__pic .b-product__basket {
        right: 0;
        width: auto;
        height: auto;
        left: 0;
        top: 0;
        box-shadow: none; }
        .b-product.financial_literacy .b-product__pic .b-product__basket:before {
          width: 33px;
          height: 45px; }
    .b-product .b-product__tags,
    .b-product .b-product__date {
      font-size: 11px !important; }
    .b-product .b-product__text {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      max-height: 280px; }
      .b-product .b-product__text h4 {
        font-size: 23px;
        line-height: 27px; }
    .b-product .b-product__pic {
      box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.4); }
      .b-product .b-product__pic.b-product__pic_simple {
        box-shadow: none; }
      .b-product .b-product__pic .b-product__pic_pic {
        opacity: 0; }
      .b-product .b-product__pic .b-product__price {
        opacity: 1; }
    .b-product .b-product__tags li a {
      color: rgba(255, 255, 255, 0.4); }
    .b-product .b-product__bg {
      opacity: .5; }
    .b-product .b-product__main {
      background: #56675f;
      color: rgba(255, 255, 255, 0.9); }
    .b-product .b-product__text h4 .b-link {
      color: #fff; }
      .b-product .b-product__text h4 .b-link .b-link__text {
        box-shadow: -10px 33px 45px rgba(0, 0, 0, 0.5);
        background: #21A038;
        border-bottom-color: transparent; }
  .b-specs__section {
    padding: 0; }
    .b-specs__section .b-wrapper {
      padding: 0; }
    .b-specs__section h2 {
      position: absolute;
      top: 31px;
      left: 0;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 15px;
      white-space: nowrap;
      opacity: .3;
      z-index: 3; }
    .b-specs__section .b-specs {
      border-radius: 0;
      box-shadow: none;
      padding: 77px 15px;
      margin: 0; }
      .b-specs__section .b-specs h4 {
        font-size: 23px;
        line-height: 27px; }
      .b-specs__section .b-specs .b-link {
        left: 15px; }
    .b-specs__section .b-specs__carousel {
      margin: 0 -5.5%;
      padding: 0;
      overflow: hidden; }
      .b-specs__section .b-specs__carousel .slick-arrow {
        top: 16px;
        transform: scale(0.82); }
        .b-specs__section .b-specs__carousel .slick-arrow.slick-prev {
          right: 50px;
          left: auto; }
        .b-specs__section .b-specs__carousel .slick-arrow.slick-next {
          right: 0; } }

.b-top__section.b-top__section_about .b-main-tabs__section {
  padding-top: 20px;
  padding-bottom: 90px; }
  .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs {
    padding-right: 0; }
    .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs li {
      padding-right: 50px; }
      .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs li .b-custom-tab i:before {
        width: 23px;
        height: 29px;
        background-image: url("../i/new/i-activation-sbs.png"); }
      .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs li:last-child {
        padding-right: 0; }
        .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs li:last-child .b-custom-tab i:before {
          width: 28px;
          height: 23px;
          top: 52%;
          left: 52%;
          background-image: url("../i/new/i-activation-sbsg.png"); }
  .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__result_box {
    padding-top: 50px; }

.b-top__section.b-top__section_question {
  padding-bottom: 60px; }
  .b-top__section.b-top__section_question .b-question-menu li {
    max-width: none !important; }
    .b-top__section.b-top__section_question .b-question-menu li a {
      min-height: 0 !important; }
  .b-top__section.b-top__section_question .b-about-section__back {
    display: none; }
  .b-top__section.b-top__section_question .b-about-section__menu-label {
    font-style: italic;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 25px; }
  .b-top__section.b-top__section_question .b-about-section__menu .b-about-section__menu_link {
    padding: 0 35px 0 0; }
    .b-top__section.b-top__section_question .b-about-section__menu .b-about-section__menu_link.active {
      font-weight: bold; }
  .b-top__section.b-top__section_question .b-main-tabs__section {
    padding-top: 6px; }
    .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs {
      border-bottom: none;
      z-index: 3; }
      .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link {
        font-size: 16px;
        line-height: 25px;
        padding-bottom: 38px; }
    .b-top__section.b-top__section_question .b-main-tabs__section .b-question-content {
      background: #fff;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
      padding-bottom: 60px;
      padding-top: 50px; }
    .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__result_box {
      padding: 0; }
  .b-top__section.b-top__section_question .b-main-tabs__result_box:before {
    content: "";
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom-style: dashed; }
  .b-top__section.b-top__section_question .b-question__dop {
    box-shadow: none;
    border-radius: 0;
    padding: 35px 0 0;
    margin: 60px auto 0;
    width: 600px; }

.b-about-section {
  position: relative; }
  .b-about-section.b-about-section__first-state .b-about-section_r {
    display: none; }
  .b-about-section.b-about-section__first-state .b-about-section_l {
    width: 100%;
    border-right: none;
    position: relative !important; }
    .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper {
      display: block;
      position: relative; }
      .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu-label {
        position: absolute;
        left: 0;
        top: -7px;
        font-size: 25px;
        line-height: 35px;
        font-family: "SB Sans Display Bold";
        font-weight: bold;
        color: #000;
        font-style: normal;
        width: 160px;
        background: #fff;
        z-index: 3; }
      .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu {
        position: relative;
        transform: translate(0, 0) !important;
        overflow: hidden;
        min-height: 400px; }
        .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu ul {
          width: 285px;
          margin: auto;
          position: relative; }
          .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu ul:before {
            content: "";
            position: absolute;
            right: 100%;
            margin-right: 30px;
            width: 1000%;
            height: 1px;
            background: #e5e5e5;
            top: 12px; }
          .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu ul:after {
            content: "";
            position: absolute;
            right: 100%;
            margin-right: 29px;
            width: 7px;
            height: 15px;
            top: 5px;
            transform: rotate(180deg);
            background: url("../i/new/i-arrow-question.svg") no-repeat; }
  .b-about-section .b-about-section_l,
  .b-about-section .b-about-section_r {
    float: left;
    vertical-align: top;
    box-sizing: border-box; }
  .b-about-section .b-about-section__menu_wrapper {
    position: relative;
    min-height: 100px; }
  .b-about-section .b-about-section_l {
    width: 285px; }
  .b-about-section .b-about-section_r {
    width: 765px;
    padding-left: 70px;
    border-left: 1px solid rgba(0, 0, 0, 0.1); }
    .b-about-section .b-about-section_r .b-accordion .b-accordion__item {
      margin-bottom: 17px; }
    .b-about-section .b-about-section_r .b-accordion .b-accordion__text {
      padding-top: 26px; }
    .b-about-section .b-about-section_r .b-accordion .b-accordion__link.b-accordion__link_pic {
      padding-left: 100px;
      padding-top: 12px;
      min-height: 80px; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__link.b-accordion__link_pic + .b-accordion__text {
        padding-top: 14px;
        padding-left: 12px;
        padding-right: 12px; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__link.b-accordion__link_pic .b-accordion__link_pic-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-size: cover; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__link.b-accordion__link_pic span p {
        margin-top: 2px; }

.b-about-section__menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .b-about-section__menu li {
    margin-bottom: 15px; }
    .b-about-section__menu li:last-child {
      margin-bottom: 0; }
  .b-about-section__menu .b-about-section__menu_link {
    display: block;
    border: none;
    padding: 4px 31px;
    position: relative; }
    .b-about-section__menu .b-about-section__menu_link:hover:before {
      opacity: .7; }
    .b-about-section__menu .b-about-section__menu_link:after {
      content: "";
      position: absolute;
      right: -3px;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #21A038;
      display: none; }
    .b-about-section__menu .b-about-section__menu_link:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      opacity: .4;
      transition: opacity .25s; }
    .b-about-section__menu .b-about-section__menu_link.active {
      color: #000;
      cursor: default; }
      .b-about-section__menu .b-about-section__menu_link.active:after {
        display: block; }
      .b-about-section__menu .b-about-section__menu_link.active:before {
        opacity: .15; }
    .b-about-section__menu .b-about-section__menu_link.about:before {
      width: 25px;
      height: 21px;
      top: 5px;
      left: -1px;
      background: url("../i/new/pages/about/i-about.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.about.active:before {
      background: url("../i/new/pages/about/i-about-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.history:before {
      width: 25px;
      height: 19px;
      top: 7px;
      left: -1px;
      background: url("../i/new/pages/about/i-history.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.history.active:before {
      background: url("../i/new/pages/about/i-history-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.team:before {
      width: 24px;
      height: 16px;
      top: 8px;
      left: -1px;
      background: url("../i/new/pages/about/i-team.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.team.active:before {
      background: url("../i/new/pages/about/i-team-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.info:before {
      width: 22px;
      height: 21px;
      top: 6px;
      left: -1px;
      background: url("../i/new/pages/about/i-info.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.info.active:before {
      background: url("../i/new/pages/about/i-info-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.procurement:before {
      width: 25px;
      height: 22px;
      top: 6px;
      left: -1px;
      background: url("../i/new/pages/about/i-procurement.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.procurement.active:before {
      background: url("../i/new/pages/about/i-procurement-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.agents:before {
      width: 23px;
      height: 18px;
      top: 8px;
      left: 0px;
      background: url("../i/new/pages/about/i-agents.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.agents.active:before {
      background: url("../i/new/pages/about/i-agents-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.careers:before {
      width: 21px;
      height: 20px;
      top: 6px;
      left: 2px;
      background: url("../i/new/pages/about/i-careers.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.careers.active:before {
      background: url("../i/new/pages/about/i-careers-active.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.contacts:before {
      width: 19px;
      height: 23px;
      top: 3px;
      left: 3px;
      background: url("../i/new/pages/about/i-contacts.svg") no-repeat; }
    .b-about-section__menu .b-about-section__menu_link.contacts.active:before {
      background: url("../i/new/pages/about/i-contacts-active.svg") no-repeat; }

.b-careers + .b-button__box {
  margin-top: 50px;
  margin-bottom: 30px; }

.b-careers.b-text__wrapper table {
  margin-bottom: 25px; }
  .b-careers.b-text__wrapper table tbody tr:last-child td {
    padding-bottom: 14px !important;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1); }
  .b-careers.b-text__wrapper table tbody td {
    font-size: 19px;
    line-height: 29px;
    font-weight: bold;
    font-family: "SB Sans Display Bold"; }
  .b-careers.b-text__wrapper table td {
    text-align: left;
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .b-careers.b-text__wrapper table thead td {
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    border-bottom: none; }

.b-about-section_r {
  min-height: 400px; }
  .b-about-section_r .b-text__wrapper {
    margin-left: 0;
    padding-top: 0; }
    .b-about-section_r .b-text__wrapper + .b-accordion {
      margin-top: 25px; }
  .b-about-section_r .b-accordion__item .b-accordion__link span:not(.b-accordion__link_wrapper) {
    color: rgba(0, 0, 0, 0.4); }
  .b-about-section_r .b-accordion__item .b-accordion__link .b-accordion__link_wrapper span {
    color: inherit;
    padding-left: 0; }

.b-history__box {
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 15px; }
  .b-history__box:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 1px;
    top: 15px;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1); }
  .b-history__box + .b-button__box {
    width: 100%;
    text-align: center; }
    .b-history__box + .b-button__box .b-button {
      display: inline-block;
      vertical-align: top; }

.b-history {
  width: 45%;
  text-align: right;
  margin-top: -133px;
  position: relative; }
  .b-history.b-history__mt {
    margin-top: -25px; }
    .b-history.b-history__mt + .b-history {
      margin-top: -25px; }
  .b-history:before {
    content: "";
    width: 12px;
    height: 30px;
    background: #f9f9f9;
    position: absolute;
    left: 100%;
    margin-left: 9.5%;
    top: 10px; }
  .b-history:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #21A038;
    box-sizing: border-box;
    left: 100%;
    margin-left: 9.5%;
    top: 19px; }
  .b-history:first-child {
    margin-top: 0; }
  .b-history:nth-child(even) {
    margin-left: 55%;
    text-align: left; }
    .b-history:nth-child(even):before {
      left: 0;
      margin-left: -12.5%; }
    .b-history:nth-child(even):after {
      left: 0;
      margin-left: -12.5%; }
    .b-history:nth-child(even) .b-history__date:before {
      right: 0;
      left: -30px; }
  .b-history .b-history__date {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 18px;
    color: rgba(0, 0, 0, 0.4);
    position: relative; }
    .b-history .b-history__date:before {
      content: "";
      position: absolute;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      bottom: 0;
      left: 0;
      right: -30px; }
  .b-history .b-history__pic {
    display: inline-block;
    width: 100%;
    height: 215px;
    margin-bottom: 3px; }
    .b-history .b-history__pic span {
      display: block;
      height: 100%;
      border-radius: 5px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative; }

.b-about-section__box {
  position: relative;
  margin-bottom: 75px; }
  .b-about-section__box:last-child {
    margin-bottom: 0; }
  .b-about-section__box .b-inside-tabs__result_box {
    width: 444px; }
    .b-about-section__box .b-inside-tabs__result_box .b-docs__list {
      column-count: auto;
      -moz-column-count: auto; }
  .b-about-section__box .b-info__date {
    display: block;
    padding-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px; }
  .b-about-section__box .b-docs__list {
    column-count: 2;
    -moz-column-count: 2;
    position: relative;
    margin-top: -8px; }
    .b-about-section__box .b-docs__list .b-docs__call {
      display: none; }
    .b-about-section__box .b-docs__list li {
      float: none;
      display: table;
      width: auto;
      padding-right: 5%;
      padding-top: 8px;
      margin: 0 0 24px;
      break-inside: avoid-column;
      position: relative; }
      .b-about-section__box .b-docs__list li:hover p {
        display: block; }
      .b-about-section__box .b-docs__list li a:before {
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.08); }
      .b-about-section__box .b-docs__list li p {
        position: absolute;
        transform: translate(0, -125%);
        top: 30px;
        left: 40px;
        width: 285px;
        padding: 17px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        font-size: 14px;
        line-height: 25px;
        color: #000;
        z-index: 10;
        display: none; }
        .b-about-section__box .b-docs__list li p:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 24px;
          width: 0;
          height: 0;
          border-top: 13px solid #fff;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent; }

.b-map {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 750px; }

.b-contacts__box {
  background: #fff;
  padding: 26px 35px 34px;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  font-size: 15px;
  line-height: 21px; }
  .b-contacts__box .b-contacts__label,
  .b-contacts__box h5 {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.2);
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 21px;
    margin-top: 32px; }
    .b-contacts__box .b-contacts__label:first-child,
    .b-contacts__box h5:first-child {
      margin-top: 0; }
    .b-contacts__box .b-contacts__label + h4,
    .b-contacts__box h5 + h4 {
      margin-top: 0; }
  .b-contacts__box .b-contacts__column_section {
    margin-bottom: 32px; }
    .b-contacts__box .b-contacts__column_section p {
      padding-bottom: 4px; }
    .b-contacts__box .b-contacts__column_section:last-child {
      margin-bottom: 0; }
  .b-contacts__box h4 {
    margin-top: 20px;
    margin-bottom: 0; }
    .b-contacts__box h4 + h4 {
      margin-top: -4px; }
    .b-contacts__box h4 a {
      border-bottom: none;
      color: #000; }
  .b-contacts__box:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 26px;
    bottom: 34px;
    width: 1px;
    background: rgba(0, 0, 0, 0.1); }
  .b-contacts__box .b-contacts__column {
    float: left;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box; }
    .b-contacts__box .b-contacts__column .b-button__box {
      margin-top: 30px;
      margin-bottom: 15px; }
    .b-contacts__box .b-contacts__column:first-child {
      padding-right: 30px; }
    .b-contacts__box .b-contacts__column:last-child {
      padding-left: 30px; }

.b-contact-page .b-about-section {
  padding-top: 50px; }

.b-contact-page .b-about-section__menu {
  transform: translate(0px, 0px) !important; }

.b-contact-page .b-about-section_r {
  margin-top: -8px; }

.b-contact-page .b-main-tabs__result_box {
  min-height: 750px; }

.b-contact-page .b-main-tabs__result {
  padding-top: 50px; }

.b-contact-page .b-main-tabs__section .b-wrapper {
  z-index: 3; }

.b-contact-page .b-main-tabs__section .b-main-tabs__tabs {
  border-bottom: none;
  margin-bottom: 1px; }

.b-contact-page .b-about-section .b-about-section_r {
  border-left: none; }

.b-contact-page .b-about-section__menu:before {
  content: "";
  position: absolute;
  background: #fff;
  border-radius: 5px;
  top: -13px;
  right: -3px;
  bottom: -11px;
  left: -18px; }

.b-contact-page .b-top__section.b-top__section_about .b-main-tabs__section {
  padding-bottom: 0; }
  .b-contact-page .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__result_box {
    padding-top: 0; }

.b-popup__сareer {
  width: 610px; }
  .b-popup__сareer textarea {
    height: 150px; }
  .b-popup__сareer .b-сareer-form__column .b-input__box {
    float: left;
    vertical-align: top;
    width: 47%;
    margin-top: 0; }
    .b-popup__сareer .b-сareer-form__column .b-input__box:first-child {
      margin-right: 6%; }

.b-about-section__question .b-product-section {
  padding-top: 0; }

.b-about-section__question .b-accordion .b-accordion__text {
  padding-top: inherit !important; }

@media only screen and (min-width: 1600px) {
  .b-about-section .b-about-section_l {
    width: 330px; }
  .b-about-section .b-about-section_r {
    width: 1070px; }
  .b-about-section__box .b-inside-tabs__result_box {
    width: 748px; }
  .b-history:before {
    top: 14px; }
  .b-history:after {
    top: 23px;
    margin-left: 10%; }
  .b-history .b-history__date:before {
    right: -45px; }
  .b-history:nth-child(even):after {
    margin-left: -12.3%; }
  .b-history:nth-child(even) .b-history__date:before {
    left: -45px; } }

@media only screen and (max-width: 1199px) {
  .b-contact-page .b-map {
    height: 850px; }
  .b-contact-page .b-main-tabs__result_box {
    min-height: 850px; }
    .b-contact-page .b-main-tabs__result_box .b-wrapper {
      width: auto;
      margin: 0 65px 0 60px;
      padding: 0; }
  .b-docs-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #21A038;
    overflow-y: scroll;
    z-index: 100;
    padding-top: 60px;
    padding-bottom: 60px;
    display: none; }
    .b-docs-popup .b-docs-popup__close {
      position: absolute;
      top: 10px;
      left: 50%;
      width: 29px;
      height: 29px;
      margin-left: -15px;
      background: url(../i/new/i-checkbox-faq-close.svg) no-repeat; }
    .b-docs-popup .b-docs-popup__content {
      margin: 0 2.5% 40px;
      padding: 30px 20px;
      background: #fff;
      border-radius: 5px; }
  .b-about-section__box .b-inside-tabs__result {
    background: #fff;
    padding: 15px 15px 30px; }
    .b-about-section__box .b-inside-tabs__result .b-docs__list {
      column-count: auto;
      -moz-column-count: auto; }
  .b-about-section__box .b-docs__list li {
    padding-right: 40px; }
    .b-about-section__box .b-docs__list li p {
      display: none !important; }
    .b-about-section__box .b-docs__list li .b-docs__call {
      position: absolute;
      display: block;
      right: 10px;
      top: 10px;
      padding: 0;
      min-height: 0;
      border: none;
      width: 25px;
      height: 25px;
      background: url(../i/new/i-question.svg) no-repeat;
      opacity: .3;
      transition: opacity .25s; }
      .b-about-section__box .b-docs__list li .b-docs__call:before {
        display: none; }
  .b-about-section__menu {
    font-size: 13px;
    line-height: 24px; }
    .b-about-section__menu .b-about-section__menu_link {
      padding-right: 15px; }
    .b-about-section__menu li {
      margin-bottom: 10px; }
  .b-about-section.b-about-section__first-state .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu ul {
    margin-left: 50%; }
  .b-about-section .b-about-section_l {
    width: 215px;
    position: absolute;
    left: 0;
    top: 55px;
    z-index: 5; }
  .b-about-section .b-about-section_r {
    width: auto;
    margin-left: 215px;
    padding-left: 25px; }
  .b-history:after {
    background: #f9f9f9;
    margin-left: 8.8%;
    top: 17px; }
  .b-history .b-history__date:before {
    right: -20%; }
  .b-history:nth-child(even) .b-history__date:before {
    left: -20%; }
  .b-history:nth-child(even):after {
    margin-left: -13.5%; }
  .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs {
    width: auto;
    margin-right: -10px; }
    .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs .b-main-tabs__tabs_link {
      font-size: 14px;
      padding-bottom: 20px; }
  .b-top__section.b-top__section_question .b-about-section .b-about-section_l {
    top: 0; } }

@media only screen and (max-width: 767px) {
  .b-contact-page .b-main-tabs__result_box {
    margin-bottom: 200px; }
    .b-contact-page .b-main-tabs__result_box .b-wrapper {
      margin: auto;
      width: 90%; }
  .b-contact-page .b-about-section__menu:before {
    display: none; }
  .b-contact-page .b-map {
    height: auto;
    bottom: -200px; }
  .b-contacts__box {
    padding: 15px 13px 10px; }
    .b-contacts__box .b-contacts__label, .b-contacts__box h5 {
      font-size: 10px;
      line-height: 15px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.4);
      margin-top: 32px; }
      .b-contacts__box .b-contacts__label:first-child, .b-contacts__box h5:first-child {
        margin-top: 0; }
    .b-contacts__box .b-contacts__column_section {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .b-contacts__box:before {
      display: none; }
    .b-contacts__box .b-contacts__column {
      float: none;
      width: 100%;
      padding: 0 !important;
      margin-bottom: 15px; }
      .b-contacts__box .b-contacts__column:last-child {
        margin-bottom: 0; }
        .b-contacts__box .b-contacts__column:last-child .b-contacts__column_section:last-child {
          border-bottom: none; }
  .b-careers.b-text__wrapper table td {
    padding-left: 10px; }
    .b-careers.b-text__wrapper table td:first-child {
      padding-left: 0; }
  .b-careers.b-text__wrapper table thead td {
    font-size: 10px;
    line-height: 15px; }
  .b-careers.b-text__wrapper table tbody td {
    font-size: 13px;
    line-height: 18px; }
  .b-about-section__box .b-docs__list {
    column-count: auto;
    -moz-column-count: auto; }
    .b-about-section__box .b-docs__list li {
      margin-bottom: 8px; }
      .b-about-section__box .b-docs__list li .b-docs__call {
        top: 4px; }
  .b-about-section .b-about-section__menu_wrapper {
    min-height: 0; }
  .b-about-section .b-about-section_l {
    position: relative;
    float: none;
    width: 100%;
    margin-bottom: 30px;
    top: 5px;
    min-height: 343px; }
  .b-about-section .b-about-section_r {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-left: 0;
    border-left: 0;
    min-height: 0; }
    .b-about-section .b-about-section_r .b-accordion .b-accordion__item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
      padding-bottom: 10px;
      margin-bottom: 5px; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__item:last-child {
        border-bottom: none; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__item.active {
        border-bottom: none;
        border-radius: 5px;
        padding-bottom: 0; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__item .b-accordion__link.b-accordion__link_pic {
        padding-top: 0; }
      .b-about-section .b-about-section_r .b-accordion .b-accordion__item .b-accordion__link span {
        padding-left: 0;
        display: block;
        font-size: 11px;
        line-height: 21px; }
  .b-top__section.b-top__section_about .b-main-tabs__section {
    padding-bottom: 40px; }
    .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs li {
      padding-right: 1.5%; }
      .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__tabs li:last-child {
        padding-right: 0; }
    .b-top__section.b-top__section_about .b-main-tabs__section .b-main-tabs__result_box {
      padding-top: 20px; }
  .b-top__section.b-top__section_question .b-main-tabs__section .b-question-content {
    padding-top: 0;
    background: none;
    box-shadow: none;
    padding-bottom: 0; }
  .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs {
    margin-right: 0;
    margin-top: 0; }
    .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs.show {
      display: block; }
    .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li {
      float: none;
      display: block;
      width: 100%;
      max-width: none !important;
      padding: 0; }
      .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li:last-child .b-main-tabs__tabs_link {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link {
        min-height: 0 !important;
        font-size: 15px;
        line-height: 24px;
        padding: 13px 0 13px 32px;
        box-sizing: border-box;
        border-top: 1px solid rgba(0, 0, 0, 0.1); }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.active:after {
          background-position: -50px 0 !important;
          opacity: .15; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          margin-top: 13px;
          opacity: .3;
          transition: opacity .25s; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.travel:after {
          left: -2px;
          top: 1px;
          width: 20px;
          height: 20px;
          background: url("../i/new/header/menu-travel.svg") no-repeat 0 0;
          background-size: 100px 20px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.health:after {
          left: -2px;
          top: 1px;
          width: 21px;
          height: 20px;
          background: url("../i/new/header/menu-health.svg") no-repeat 0 0;
          background-size: 100px 20px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.family:after {
          left: 0;
          top: 1px;
          width: 16px;
          height: 23px;
          background: url("../i/new/header/menu-family.svg") no-repeat 0 0;
          background-size: 100px 23px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.build:after {
          left: -5px;
          top: 3px;
          width: 25px;
          height: 19px;
          background: url("../i/new/header/menu-build.svg") no-repeat 0 0;
          background-size: 100px 20px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.finance:after {
          left: -1px;
          top: 5px;
          width: 16px;
          height: 19px;
          background: url("../i/new/header/menu-finance.svg") no-repeat 0 0;
          background-size: 100px 20px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.business:after {
          left: 2px;
          top: 1px;
          width: 12px;
          height: 25px;
          background: url("../i/new/header/menu-business.svg") no-repeat 0 0;
          background-size: 100px 25px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link.investment:after {
          left: -3px;
          top: 2px;
          width: 24px;
          height: 23px;
          background: url("../i/new/header/menu-invest.svg") no-repeat 0 0;
          background-size: 100px 23px; }
        .b-top__section.b-top__section_question .b-main-tabs__section .b-main-tabs__tabs li .b-main-tabs__tabs_link:before {
          display: none; }
  .b-top__section.b-top__section_question .b-about-section__menu-label {
    margin-bottom: 0;
    padding: 15px 0; }
  .b-top__section.b-top__section_question .b-about-section__menu {
    border-radius: 0;
    position: relative;
    width: auto;
    margin-left: -5.5%;
    margin-right: -5.5%;
    padding-left: 5.5%;
    padding-right: 5.5%;
    font-size: 15px;
    line-height: 24px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.15); }
    .b-top__section.b-top__section_question .b-about-section__menu li:first-child .b-about-section__menu_link {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .b-top__section.b-top__section_question .b-about-section__menu .b-about-section__menu_link {
      height: auto;
      display: block;
      padding: 13px 0; }
      .b-top__section.b-top__section_question .b-about-section__menu .b-about-section__menu_link.active {
        font-weight: normal; }
  .b-top__section.b-top__section_question .b-main-tabs__result_box.hide {
    display: none; }
  .b-top__section.b-top__section_question .b-main-tabs__result_box:before {
    display: none; }
  .b-top__section.b-top__section_question .b-about-section__back {
    display: block;
    background: #fff;
    border-radius: 5px;
    padding: 13px 15px 13px 33px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    margin-bottom: 15px;
    position: relative; }
    .b-top__section.b-top__section_question .b-about-section__back:before {
      content: "";
      position: absolute;
      left: 9px;
      top: 50%;
      width: 16px;
      height: 7px;
      margin-top: -4px;
      transform: rotate(-90deg);
      background: url(../i/new/i-accordion.svg) no-repeat; }
  .b-top__section.b-top__section_question .b-question__dop {
    width: auto;
    margin-top: 0;
    background: none; }
  .b-about-section__menu {
    background: #fff;
    line-height: 18px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transform: translate(0, 0) !important; }
    .b-about-section__menu.fixed {
      position: fixed;
      right: 5%;
      left: 5%;
      margin-left: 40px;
      width: auto;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
      .b-about-section__menu.fixed:before {
        content: "";
        position: absolute;
        right: 13px;
        top: 21px;
        width: 16px;
        height: 7px;
        background: url(../i/new/i-accordion.svg) no-repeat;
        transform: rotate(180deg);
        transition: transform .25s; }
      .b-about-section__menu.fixed .b-about-section__menu_link {
        display: none; }
        .b-about-section__menu.fixed .b-about-section__menu_link.active {
          display: block;
          border-bottom: none; }
      .b-about-section__menu.fixed.open:before {
        opacity: 0; }
      .b-about-section__menu.fixed.open .b-about-section__menu_link {
        display: block; }
        .b-about-section__menu.fixed.open .b-about-section__menu_link.active {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .b-about-section__menu li {
      margin-bottom: 0; }
      .b-about-section__menu li:last-child .b-about-section__menu_link {
        border-bottom: none !important; }
    .b-about-section__menu .b-about-section__menu_link {
      padding: 17px 30px 13px 35px;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 49px;
      display: block;
      text-overflow: ellipsis; }
      .b-about-section__menu .b-about-section__menu_link:after {
        display: none !important; }
      .b-about-section__menu .b-about-section__menu_link:before {
        margin-left: 8px;
        margin-top: 8px;
        transform: scale(0.85); }
  .b-history__box {
    padding-bottom: 40px;
    margin-bottom: 10px; }
    .b-history__box:before {
      left: 0; }
    .b-history__box + .b-button__box .b-button {
      width: 100%; }
  .b-history {
    width: auto !important;
    text-align: left !important;
    padding-left: 6%;
    box-sizing: border-box;
    margin: 0 0 40px !important; }
    .b-history:last-child {
      margin-bottom: 0 !important; }
    .b-history:before {
      left: -5px !important;
      margin-left: 0 !important;
      top: -9px; }
    .b-history:after {
      left: -5px !important;
      margin-left: 0 !important;
      top: 1px; }
    .b-history .b-history__date {
      font-weight: normal;
      color: rgba(0, 0, 0, 0.4);
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 6px; }
      .b-history .b-history__date:before {
        display: none; }
    .b-history .b-history__pic {
      margin-left: -6.5%;
      display: block;
      width: auto;
      height: 200px;
      position: relative;
      margin-bottom: 20px; }
      .b-history .b-history__pic:before {
        content: "";
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
        background: #f9f9f9; }
  .b-popup__сareer .b-сareer-form__column .b-input__box {
    float: none;
    width: 100%;
    margin-right: 0 !important; }
    .b-popup__сareer .b-сareer-form__column .b-input__box:last-child {
      margin-top: 15px; }
  .b-about-section__question .b-product-section .b-wrapper {
    width: 100%; }
  .b-question-menu-overlay {
    margin-left: -5.5%;
    margin-right: -5.5%;
    padding-left: 5.5%;
    padding-right: 5.5%;
    font-size: 15px;
    line-height: 24px;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.15); }
    .b-question-menu-overlay .b-about-section__menu-label {
      margin-bottom: 0;
      padding: 15px 0; }
    .b-question-menu-overlay .b-question-menu li:first-child .b-about-section__menu_link {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .b-question-menu-overlay .b-question-menu li:last-child .b-about-section__menu_link {
      border-bottom: none; }
    .b-question-menu-overlay .b-about-section__menu_link {
      height: auto;
      display: block;
      padding: 13px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
      .b-question-menu-overlay .b-about-section__menu_link.active {
        font-weight: normal; } }

.b-razv-top {
  min-height: 600px; }
  .b-razv-top .b-main-title {
    width: 775px;
    margin: auto;
    font-size: 19px;
    line-height: 29px; }
  .b-razv-top .b-text__wrapper {
    width: 490px; }

.b-razv-scroll {
  margin-top: -60px;
  position: relative;
  min-height: 265px;
  z-index: 11; }
  .b-razv-scroll.fixed .b-razv-scroll__wrapper {
    position: fixed;
    top: -160px; }
  .b-razv-scroll.visibility .b-razv-scroll__wrapper {
    opacity: 0;
    visibility: hidden; }
  .b-razv-scroll .b-razv-scroll__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
    transition: opacity .25s, visibility .25s; }

.b-razv-links {
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  text-align: left; }
  .b-razv-links li {
    float: left;
    vertical-align: top;
    width: 20%; }
    .b-razv-links li:last-child a {
      border-right: none; }
  .b-razv-links a {
    display: block;
    border-bottom: none;
    height: 265px;
    padding: 175px 20px 0;
    box-sizing: border-box;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    position: relative; }
    .b-razv-links a:before {
      content: "";
      position: absolute;
      left: 50%;
      transition: opacity .25s; }
    .b-razv-links a:hover:before {
      opacity: .7; }
    .b-razv-links a.b-razv-links_1:before {
      width: 118px;
      height: 131px;
      margin-left: -59px;
      top: 21px;
      background: url("../i/new/pages/razv/i-razv-link-1.png") no-repeat; }
    .b-razv-links a.b-razv-links_2:before {
      width: 135px;
      height: 93px;
      margin-left: -67px;
      top: 41px;
      background: url("../i/new/pages/razv/i-razv-link-2.png") no-repeat; }
    .b-razv-links a.b-razv-links_3:before {
      width: 126px;
      height: 91px;
      margin-left: -63px;
      top: 41px;
      background: url("../i/new/pages/razv/i-razv-link-3.png") no-repeat; }
    .b-razv-links a.b-razv-links_4:before {
      width: 71px;
      height: 122px;
      margin-left: -36px;
      top: 23px;
      background: url("../i/new/pages/razv/i-razv-link-4.png") no-repeat; }
    .b-razv-links a.b-razv-links_5:before {
      width: 162px;
      height: 129px;
      margin-left: -81px;
      top: 12px;
      background: url("../i/new/pages/razv/i-razv-link-5.png") no-repeat; }

.b-razv-how-works {
  position: relative;
  text-align: center;
  padding: 65px 0 50px; }
  .b-razv-how-works .b-text__wrapper {
    margin: 0; }
    .b-razv-how-works .b-text__wrapper ul {
      overflow: hidden; }
      .b-razv-how-works .b-text__wrapper ul li {
        float: left;
        vertical-align: top;
        width: 33.333%;
        box-sizing: border-box;
        margin-left: 0;
        text-align: left;
        position: relative;
        padding-left: 39px;
        padding-right: 50px;
        list-style: none; }
        .b-razv-how-works .b-text__wrapper ul li:before {
          content: "";
          position: absolute;
          left: 0;
          top: -1px;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.1);
          background: url("../i/new/i-ul-text-white.svg") no-repeat center;
          background-size: 15px 10px;
          font-size: 19px;
          line-height: 27px;
          text-align: center;
          display: block; }

.b-razv-how-works__steps {
  position: relative;
  padding-top: 8px;
  margin-bottom: 30px;
  counter-reset: razv-steps; }
  .b-razv-how-works__steps .b-razv-how-works__steps_item {
    float: left;
    vertical-align: top;
    width: 33.333%;
    box-sizing: border-box;
    padding-right: 4.333%;
    position: relative;
    font-size: 19px;
    line-height: 29px; }
  .b-razv-how-works__steps .b-razv-how-works__steps_icon {
    display: inline-block;
    vertical-align: top;
    width: 228px;
    height: 228px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
    position: relative; }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%; }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon:before {
      counter-increment: razv-steps;
      content: counters(razv-steps, ".");
      width: 51px;
      height: 50px;
      line-height: 47px;
      position: absolute;
      left: 0;
      text-align: center;
      top: -8px;
      background: url("../i/new/pages/razv/i-steps-count.png") no-repeat; }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon.b-razv-how-works__steps_icon-1:after {
      width: 110px;
      height: 144px;
      margin: -72px 0 0 -55px;
      background: url("../i/new/pages/razv/i-razv-steps-1.png") no-repeat; }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon.b-razv-how-works__steps_icon-2:after {
      width: 168px;
      height: 89px;
      margin: -44px 0 0 -84px;
      background: url("../i/new/pages/razv/i-razv-steps-2.png") no-repeat; }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon.b-razv-how-works__steps_icon-3:after {
      width: 135px;
      height: 149px;
      margin: -74px 0 0 -67px;
      background: url("../i/new/pages/razv/i-razv-steps-3.png") no-repeat; }
  .b-razv-how-works__steps .b-razv-how-works__steps_text {
    text-align: left;
    width: 280px;
    display: block; }

.b-razv-features__box {
  position: relative;
  padding: 65px 0 50px;
  text-align: center; }

.b-razv-features {
  position: relative;
  font-size: 19px;
  line-height: 29px; }
  .b-razv-features li {
    float: left;
    vertical-align: top;
    width: 33.333%;
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 170px;
    margin-bottom: 22px;
    box-sizing: border-box; }
    .b-razv-features li:before {
      content: "";
      position: absolute;
      top: 35px;
      left: 40%; }
    .b-razv-features li.b-razv-features_1:before {
      width: 145px;
      height: 101px;
      margin-left: -72px;
      margin-top: 3px;
      background: url("../i/new/pages/razv/i-razv-features-1.png") no-repeat; }
    .b-razv-features li.b-razv-features_2:before {
      width: 107px;
      height: 88px;
      margin-left: -53px;
      margin-top: 11px;
      background: url("../i/new/pages/razv/i-razv-features-2.png") no-repeat; }
    .b-razv-features li.b-razv-features_3:before {
      width: 141px;
      height: 106px;
      margin-left: -70px;
      margin-top: -4px;
      background: url("../i/new/pages/razv/i-razv-features-3.png") no-repeat; }
    .b-razv-features li.b-razv-features_4:before {
      width: 90px;
      height: 109px;
      margin-left: -45px;
      background: url("../i/new/pages/razv/i-razv-features-4.png") no-repeat; }
    .b-razv-features li.b-razv-features_5:before {
      width: 142px;
      height: 108px;
      margin-left: -71px;
      background: url("../i/new/pages/razv/i-razv-features-5.png") no-repeat; }
    .b-razv-features li.b-razv-features_6:before {
      width: 113px;
      height: 117px;
      margin-left: -56px;
      background: url("../i/new/pages/razv/i-razv-features-6.png") no-repeat; }
    .b-razv-features li .b-razv-features__text,
    .b-razv-features li .b-razv-features__faq {
      text-align: left;
      width: 280px; }
      .b-razv-features li .b-razv-features__text sup,
      .b-razv-features li .b-razv-features__faq sup {
        color: #21A038; }
    .b-razv-features li .b-razv-features__faq {
      margin-top: 11px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      line-height: 25px; }

.b-razv-links__bottom {
  padding: 65px 0 107px;
  text-align: center; }

.b-footnote {
  position: relative;
  font-size: 11px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.5);
  background: #21A038;
  padding: 45px 0 50px; }
  .b-footnote p {
    margin-bottom: 15px;
    box-sizing: border-box;
    padding-right: 35px; }
    .b-footnote p:last-child {
      margin-bottom: 0; }
  .b-footnote .b-wrapper {
    column-count: 3;
    -moz-column-count: 3; }
    .b-footnote .b-wrapper p {
      break-inside: avoid-column;
      -webkit-backface-visibility: hidden; }

.b-accumulate__box {
  padding: 0 0 100px;
  position: relative;
  box-sizing: border-box; }
  .b-accumulate__box .b-accumulate__title {
    padding-top: 80px;
    text-align: center;
    width: 680px;
    margin: auto; }
  .b-accumulate__box .b-wrapper {
    z-index: 4; }

.b-accumulate__line {
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 2; }
  .b-accumulate__line.b-accumulate__line_1 {
    top: 50%;
    border-top: 1px dashed #FAED00;
    opacity: 0;
    transition: opacity .25s; }
    .b-accumulate__line.b-accumulate__line_1.show {
      opacity: 1; }
    .b-accumulate__line.b-accumulate__line_1 .b-accumulate__line_point {
      background: #FAED00; }
      .b-accumulate__line.b-accumulate__line_1 .b-accumulate__line_point:before {
        background: url("../i/new/pages/razv/incident.svg") no-repeat; }
  .b-accumulate__line.b-accumulate__line_2 {
    top: 400px;
    border-top: 1px dashed #21A038;
    will-change: scroll-position, transform; }
    .b-accumulate__line.b-accumulate__line_2 .b-accumulate__line_point {
      background: #21A038; }
      .b-accumulate__line.b-accumulate__line_2 .b-accumulate__line_point:before {
        background: url("../i/new/pages/razv/finish.svg") no-repeat; }
  .b-accumulate__line .b-accumulate__line_point {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-top: -33px;
    margin-bottom: 10px;
    position: relative; }
    .b-accumulate__line .b-accumulate__line_point:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin: -20px 0 0 -20px; }
  .b-accumulate__line .b-accumulate__line_text {
    width: 165px;
    margin-left: 4px; }
    .b-accumulate__line .b-accumulate__line_text sup {
      color: #21A038; }

.b-accumulate {
  position: relative;
  height: 190px;
  text-align: center;
  will-change: height; }
  .b-accumulate.middle .b-accumulate__column .b-accumulate__text-bottom {
    visibility: visible;
    opacity: 1; }
  .b-accumulate.last .b-accumulate__column .b-accumulate__text-bottom span {
    display: none; }
    .b-accumulate.last .b-accumulate__column .b-accumulate__text-bottom span:last-child {
      display: block; }
  .b-accumulate .b-accumulate__column {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -116px;
    width: 232px;
    box-sizing: border-box;
    text-align: left; }
    .b-accumulate .b-accumulate__column:before {
      content: "";
      position: absolute;
      top: 190px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }
    .b-accumulate .b-accumulate__column .b-accumulate__text-top {
      position: absolute;
      bottom: 19px;
      left: 17px;
      right: 15px;
      z-index: 4; }
    .b-accumulate .b-accumulate__column .b-accumulate__text-bottom {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin-top: 57px;
      padding: 14px 17px;
      box-sizing: border-box;
      visibility: hidden;
      opacity: 0;
      transition: opacity .25s, visibility .25s; }
      .b-accumulate .b-accumulate__column .b-accumulate__text-bottom span:last-child {
        display: none; }
    .b-accumulate .b-accumulate__column .b-accumulate__price {
      position: absolute;
      height: 54px;
      left: 0;
      right: 0;
      top: 100%;
      padding: 0 17px;
      box-sizing: border-box;
      font-weight: bold;
      font-family: "SB Sans Display Bold";
      font-size: 25px;
      line-height: 54px; }
    .b-accumulate .b-accumulate__column .b-accumulate__column_bg {
      display: block;
      height: 190px;
      position: relative;
      z-index: 2; }
    .b-accumulate .b-accumulate__column.b-accumulate__column_1 {
      margin-left: -236px; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_1:before {
        background: #FAED00;
        opacity: .5; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_1 .b-accumulate__price,
      .b-accumulate .b-accumulate__column.b-accumulate__column_1 .b-accumulate__text-bottom {
        background: #FAED00; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_1 .b-accumulate__column_bg {
        opacity: .5;
        background: -moz-linear-gradient(top, rgba(255, 202, 74, 0) 0%, #ffca4a 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255, 202, 74, 0) 0%, #ffca4a 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255, 202, 74, 0) 0%, #ffca4a 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffca4a', endColorstr='#FAED00',GradientType=0 );
        /* IE6-9 */ }
    .b-accumulate .b-accumulate__column.b-accumulate__column_2 {
      margin-left: 4px; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_2:before {
        background: #009942;
        opacity: .3; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_2 .b-accumulate__text-bottom {
        background: #21A038;
        color: #fff; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_2 .b-accumulate__price {
        background: #21A038;
        color: #fff; }
        .b-accumulate .b-accumulate__column.b-accumulate__column_2 .b-accumulate__price:after {
          content: "";
          position: absolute;
          right: 13px;
          top: 50%;
          width: 23px;
          height: 23px;
          margin-top: -12px;
          background: url("../i/new/sber-whois-product_white.svg") no-repeat;
          background-size: cover; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_2 .b-accumulate__column_bg {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#009942+0,009942+100&0+0,1+100 */
        background: -moz-linear-gradient(top, rgba(0, 153, 66, 0) 0%, #009942 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 153, 66, 0) 0%, #009942 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 153, 66, 0) 0%, #009942 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00009942', endColorstr='#009942',GradientType=0 );
        /* IE6-9 */
        opacity: .3; }

@media only screen and (min-width: 1600px) {
  .b-accumulate__box .b-accumulate__title {
    width: 1000px; }
  .b-accumulate .b-accumulate__column {
    width: 280px; }
    .b-accumulate .b-accumulate__column.b-accumulate__column_1 {
      margin-left: -284px; }
  .b-accumulate__line .b-accumulate__line_text {
    width: 200px; } }

@media only screen and (max-width: 1199px) {
  .b-razv-top .b-main-title {
    width: 80%;
    font-size: 17px;
    line-height: 24px; }
  .b-razv-top .b-text__wrapper {
    width: 100%; }
  .b-razv-scroll {
    min-height: 220px; }
    .b-razv-scroll.fixed .b-razv-scroll__wrapper {
      top: -110px; }
  .b-razv-links a {
    padding: 130px 10px 0;
    font-size: 13px;
    line-height: 20px;
    height: 220px;
    overflow: hidden; }
    .b-razv-links a:before {
      transform: scale(0.7) translate(0, -30px); }
  .b-razv-how-works__steps .b-razv-how-works__steps_item {
    font-size: 17px;
    line-height: 24px; }
  .b-razv-how-works__steps .b-razv-how-works__steps_text {
    width: 100%; }
  .b-razv-how-works__steps .b-razv-how-works__steps_icon {
    width: 180px;
    height: 180px; }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon:after {
      transform: scale(0.7); }
    .b-razv-how-works__steps .b-razv-how-works__steps_icon:before {
      width: 40px;
      height: 40px;
      background-size: cover;
      top: -7px;
      line-height: 38px; }
  .b-razv-features {
    font-size: 17px;
    line-height: 24px; }
    .b-razv-features li {
      padding-top: 135px; }
      .b-razv-features li:before {
        transform: scale(0.75) translate(0, -20px); }
      .b-razv-features li .b-razv-features__text,
      .b-razv-features li .b-razv-features__faq {
        width: 100%;
        box-sizing: border-box;
        padding-right: 10%; }
      .b-razv-features li .b-razv-features__faq {
        line-height: 24px;
        font-size: 13px; } }

@media only screen and (max-width: 767px) {
  .b-razv-top .b-main-title {
    width: 100%;
    font-size: 15px;
    line-height: 24px; }
  .b-razv-top {
    box-sizing: border-box;
    padding-bottom: 280px; }
  .b-razv-scroll {
    min-height: 320px;
    margin-top: -250px; }
    .b-razv-scroll .b-razv-scroll__open {
      position: absolute;
      right: 0;
      top: 0;
      height: 49px;
      width: 20px;
      z-index: 5;
      border-bottom: none;
      display: none; }
      .b-razv-scroll .b-razv-scroll__open:before {
        content: "";
        position: absolute;
        right: 50%;
        top: 50%;
        margin: -3px 0 0 -8px;
        width: 16px;
        height: 7px;
        background: url(../i/new/i-accordion.svg) no-repeat;
        transform: rotate(180deg);
        transition: transform .25s; }
    .b-razv-scroll.fixed.open .b-razv-scroll__open:before {
      transform: rotate(0); }
    .b-razv-scroll.fixed.open .b-razv-scroll__wrapper .b-razv-links li {
      display: block; }
      .b-razv-scroll.fixed.open .b-razv-scroll__wrapper .b-razv-links li:first-child a {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .b-razv-scroll.fixed .b-razv-scroll__open {
      display: block; }
    .b-razv-scroll.fixed .b-razv-scroll__wrapper {
      top: 0; }
      .b-razv-scroll.fixed .b-razv-scroll__wrapper .b-razv-links {
        margin-left: 40px;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        .b-razv-scroll.fixed .b-razv-scroll__wrapper .b-razv-links li {
          display: none; }
          .b-razv-scroll.fixed .b-razv-scroll__wrapper .b-razv-links li:first-child {
            display: block; }
            .b-razv-scroll.fixed .b-razv-scroll__wrapper .b-razv-links li:first-child a {
              border-bottom: none; }
  .b-razv-links {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
    .b-razv-links li {
      float: none;
      width: 100%; }
      .b-razv-links li:last-child a {
        border-bottom: none; }
    .b-razv-links a {
      font-size: 15px;
      line-height: 24px;
      color: #21A038;
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 12px 14px;
      height: auto; }
      .b-razv-links a:before {
        display: none; }
  .b-accumulate__box {
    height: auto !important;
    padding-bottom: 200px; }
    .b-accumulate__box .b-accumulate__title {
      width: 100%;
      padding-top: 20px;
      text-align: left; }
  .b-accumulate {
    height: 500px; }
    .b-accumulate .b-accumulate__column {
      width: 150px;
      font-size: 13px;
      line-height: 20px; }
      .b-accumulate .b-accumulate__column.b-accumulate__column_1 {
        margin-left: -154px;
        max-height: 250px !important; }
      .b-accumulate .b-accumulate__column .b-accumulate__text-bottom {
        visibility: visible;
        opacity: 1;
        padding: 10px 7px;
        margin-top: 46px; }
      .b-accumulate .b-accumulate__column .b-accumulate__text-top {
        bottom: 15px;
        left: 7px;
        right: 7px; }
      .b-accumulate .b-accumulate__column .b-accumulate__price {
        font-size: 15px;
        padding: 0 7px;
        height: 42px;
        line-height: 40px; }
  .b-accumulate__line .b-accumulate__line_text {
    font-size: 13px;
    line-height: 20px; }
  .b-accumulate__line.b-accumulate__line_1 {
    top: 380px !important;
    visibility: visible;
    opacity: 1; }
    .b-accumulate__line.b-accumulate__line_1 .b-wrapper {
      display: none; }
  .b-accumulate__line.b-accumulate__line_2 {
    top: auto !important;
    bottom: 199px; }
  .b-razv-how-works {
    padding-top: 0; }
    .b-razv-how-works h3 {
      position: absolute;
      top: 31px;
      left: 0;
      right: 0;
      text-align: left; }
    .b-razv-how-works .b-text__wrapper ul li {
      float: none;
      width: 100%;
      padding-right: 0; }
  .b-razv-how-works__steps {
    padding-top: 80px; }
    .b-razv-how-works__steps .slick-arrow {
      top: 145px;
      transform: scale(0.82) translate(0, 0); }
      .b-razv-how-works__steps .slick-arrow.slick-prev {
        left: -10px; }
      .b-razv-how-works__steps .slick-arrow.slick-next {
        right: -10px; }
    .b-razv-how-works__steps .b-razv-how-works__steps_item {
      font-size: 15px;
      line-height: 24px;
      padding-right: 0;
      outline: none; }
      .b-razv-how-works__steps .b-razv-how-works__steps_item .b-razv-how-works__steps_text {
        width: 60%;
        text-align: center;
        margin: auto; }
  .b-razv-features__box {
    padding: 30px 0; }
  .b-razv-features {
    font-size: 0;
    line-height: 0; }
    .b-razv-features li {
      float: none;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      font-size: 15px;
      line-height: 24px; }
      .b-razv-features li .b-razv-features__faq {
        font-size: 11px;
        line-height: 16px; }
  .b-razv-links__bottom {
    padding: 30px 0 60px; }
    .b-razv-links__bottom h2 {
      text-align: left; }
  .b-footnote {
    padding: 25px 0 30px;
    font-size: 9px;
    line-height: 11px; }
    .b-footnote .b-wrapper {
      column-count: 2;
      -moz-column-count: 2;
      width: 100%;
      padding-left: 20px; }
      .b-footnote .b-wrapper p {
        padding-right: 20px;
        margin-bottom: 10px; } }

.FlexWrp {
  padding-top: 165px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }
  .FlexWrp .Flex-el {
    width: calc(50% - 20px);
    margin-bottom: 15px; }
    @media only screen and (max-width: 767px) {
      .FlexWrp .Flex-el {
        width: 100%; } }
  .FlexWrp h1 {
    width: 100%; }

.b-header.white a,
.b-header.green-day a {
  color: #fff !important; }

.b-header.white .b-header__search_i:before,
.b-header.green-day .b-header__search_i:before {
  background: url(../i/new/header/i-search-white.svg) no-repeat 0 0; }
  @media screen and (max-width: 767px) {
    .b-header.white .b-header__search_i:before,
    .b-header.green-day .b-header__search_i:before {
      background: url(../i/new/header/i-search.svg) no-repeat 0 0; } }

.b-header.white .b-header__logo,
.b-header.green-day .b-header__logo {
  background: url(../i/new/header/logo-white.svg) no-repeat 0; }

.b-header.white .b-header-main__wrapper a,
.b-header.green-day .b-header-main__wrapper a {
  color: inherit !important; }

@media screen and (max-width: 767px) {
  .b-header.white .b-top-menu a,
  .b-header.green-day .b-top-menu a {
    color: inherit !important; } }

.greenDay {
  width: 100%;
  overflow: hidden; }
  .greenDay-img {
    padding-top: 70px;
    background: #191b18; }
    .greenDay-img img {
      width: 100%;
      height: auto; }
      @media screen and (max-width: 767px) {
        .greenDay-img img {
          margin-left: 20px;
          width: 600px;
          position: relative;
          left: 50%;
          transform: translate(-50%); } }

.flex {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto; }
  .flex-el {
    display: block;
    margin-top: 70px;
    text-decoration: none;
    border: 0 none;
    width: 100%;
    position: relative; }
    @media screen and (max-width: 767px) {
      .flex-el {
        padding: 0 20px;
        margin-top: 35px; } }
    .flex-el img {
      width: 100%;
      max-width: 980px; }
      .flex-el img.hd {
        display: block; }
        @media screen and (max-width: 767px) {
          .flex-el img.hd {
            display: none; } }
      .flex-el img.xs {
        display: none; }
        @media screen and (max-width: 767px) {
          .flex-el img.xs {
            display: block; } }

.star {
  width: 100%;
  max-width: 1050px;
  margin: 70px auto;
  color: #4b4b4b;
  font-family: "SB Sans Display" !important;
  font-size: 13px;
  font-weight: 400; }
  @media screen and (max-width: 767px) {
    .star {
      margin: 35px auto;
      padding: 0 20px;
      font-size: 20px; } }

.nv {
  padding-top: 140px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden; }
  .nv-head {
    position: relative;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
    .nv-head img {
      width: 100%;
      height: auto; }
  .nv-wrap {
    margin: 30px auto 40px; }
    .nv-wrap-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      color: #21A038;
      margin-bottom: 60px; }
      .nv-wrap-head h1 {
        margin: 0;
        padding: 0;
        font-size: 40px;
        line-height: 1.4; }
      .nv-wrap-head h2 {
        margin: 0;
        padding: 0;
        font-size: 32px;
        line-height: 1.4; }
  .nv .nv-li {
    margin-top: 40px;
    margin-bottom: 60px;
    padding-left: 80px;
    font-size: 18px;
    position: relative; }
    .nv .nv-li:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 70px;
      width: 70px;
      background: url("/static/i/new/li.png") no-repeat center; }
  .nv p {
    padding: 10px 0; }
  .nv ul {
    list-style: none;
    padding-left: 15px; }
    .nv ul li {
      position: relative; }
      .nv ul li:before {
        content: '';
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translate(0, -50%);
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: #21A038; }
  .nv b {
    font-weight: bold;
    font-family: "SB Sans Display Bold"; }

.safety {
  padding-top: 140px;
  padding-bottom: 70px; }

.safety__head {
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #21A038;
  margin-bottom: 45px; }

.safety__subhead {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 45px; }

.safety__connection {
  display: flex;
  justify-content: space-around; }
  @media screen and (max-width: 767px) {
    .safety__connection {
      flex-direction: column;
      align-items: center; } }

.safety__el {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 410px; }
  @media screen and (max-width: 767px) {
    .safety__el {
      margin-bottom: 30px; } }
  .safety__el:last-child {
    margin-left: 30px;
    max-width: 325px; }

.safety__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px; }
  .safety__icon--email svg {
    width: 100px;
    height: 108px; }
  .safety__icon--mail svg {
    width: 114px;
    height: 76px; }

.safety__text {
  font-size: 16px;
  line-height: 24px;
  text-align: center; }

.safety__el:last-child .safety__text {
  text-align: left; }

.b-header.white a,
.b-header.green-day a {
  color: #fff !important; }

.b-header.white .b-header__search_i:before,
.b-header.green-day .b-header__search_i:before {
  background: url(../i/new/header/i-search-white.svg) no-repeat 0 0; }
  @media screen and (max-width: 767px) {
    .b-header.white .b-header__search_i:before,
    .b-header.green-day .b-header__search_i:before {
      background: url(../i/new/header/i-search.svg) no-repeat 0 0; } }

.b-header.white .b-header__logo,
.b-header.green-day .b-header__logo {
  background: url(../i/new/header/logo-white.svg) no-repeat 0; }

.b-header.white .b-header-main__wrapper a,
.b-header.green-day .b-header-main__wrapper a {
  color: inherit !important; }

@media screen and (max-width: 767px) {
  .b-header.white .b-top-menu a,
  .b-header.green-day .b-top-menu a {
    color: inherit !important; } }

.actionDiv {
  width: 100%;
  overflow: hidden; }
  .actionDiv-img {
    padding-top: 70px;
    background: #191b18;
    position: relative; }
    .actionDiv-img img {
      width: 100%;
      height: auto; }
      @media screen and (max-width: 767px) {
        .actionDiv-img img {
          margin-left: 20px;
          width: 600px;
          position: relative;
          left: 50%;
          transform: translate(-50%); } }
    .actionDiv-img-text {
      width: 100%;
      max-width: 1115px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .actionDiv-img-text span {
        font-size: 40px;
        display: inline-block;
        color: #fff;
        border: none;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background: #21A038;
        padding: 5px 13px 5px 10px;
        font-weight: 600;
        font-family: "SB Sans Display Bold";
        transition: background .25s;
        box-shadow: -10px 34px 45px rgba(0, 0, 0, 0.5); }
  .actionDiv-content {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto; }

.proftest {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/static/i/proftest/bg.png") no-repeat center;
  background-size: cover;
  overflow-y: scroll;
  overflow-x: hidden; }
  .proftest button {
    cursor: pointer; }

.popap {
  display: none;
  position: relative;
  min-height: 100px;
  width: 95%;
  max-width: 900px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 50px; }
  .popap-logo {
    position: absolute;
    top: 50px;
    left: 50px;
    height: 23px;
    width: auto; }
  .popap-head {
    color: #21A038;
    font-family: "SB Sans Display" !important;
    font-weight: bold;
    font-family: "SB Sans Display Bold";
    font-size: 38px;
    text-align: center;
    margin-top: 70px;
    line-height: 1; }
  .popap-text {
    color: #000000;
    font-family: "SB Sans Display" !important;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 30px; }
  .popap .button {
    width: 140px;
    height: 44px;
    border-radius: 22px;
    background-color: #21A038;
    color: #fff;
    font-size: 18px; }

.popap-start {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .popap-start .popap-text {
    text-align: center; }

.popap-questions {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  display: none; }
  .popap-questions .left {
    max-width: 696px;
    width: 50%;
    flex-shrink: 0; }
  .popap-questions .right {
    padding: 50px; }
    .popap-questions .right img {
      width: 100%;
      max-width: 400px;
      height: auto;
      object-fit: cover; }
  .popap-questions .question {
    font-size: 18px;
    margin-top: 47px;
    padding-left: 15px; }
  .popap-questions .answer {
    padding-left: 15px;
    margin-top: 15px; }
    .popap-questions .answer-el {
      padding-bottom: 25px;
      padding-top: 25px;
      border-bottom: 1px solid #dcdcdc;
      padding-left: 40px;
      position: relative;
      cursor: pointer;
      font-size: 18px; }
      .popap-questions .answer-el:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background: #dcdcdc;
        transition: .5s; }
      .popap-questions .answer-el:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 12px;
        height: 10px;
        width: 20px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg) translate(0, calc(-50% - 5px));
        transition: .5s; }
      .popap-questions .answer-el.active:before, .popap-questions .answer-el:hover:before {
        background: #21A038; }
      .popap-questions .answer-el:last-child {
        border-bottom: 0; }
  .popap-questions .btnDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px; }
    .popap-questions .btnDiv button {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      padding: 10px 45px;
      background: transparent;
      border: 0 none;
      box-shadow: none;
      border-radius: 50px; }
    .popap-questions .btnDiv .button-back {
      border: 1px solid #21A038;
      color: #21A038; }
      .popap-questions .btnDiv .button-back.disabled {
        opacity: 0;
        pointer-events: none;
        cursor: default; }
    .popap-questions .btnDiv .button-next {
      color: #fff;
      background: #21A038; }
  .popap-questions .testCount {
    position: absolute;
    top: 126px;
    left: -15px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: "SB Sans Display Bold";
    padding: 5px 20px;
    background: #21A038;
    display: flex;
    justify-content: center;
    align-items: center; }
    .popap-questions .testCount .current:after {
      content: '/'; }
    .popap-questions .testCount:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 0px solid transparent;
      border-right: 15px solid #228049;
      border-bottom: 15px solid transparent;
      transform: translate(0, 100%); }

.popap-result {
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  display: none; }
  .popap-result .left {
    width: 100%;
    max-width: 700px;
    padding-right: 50px; }
  .popap-result .right {
    padding: 50px; }
    .popap-result .right img {
      width: 100%;
      max-width: 400px;
      height: auto;
      object-fit: cover; }
  .popap-result .popap-head {
    text-align: left;
    margin-top: 40px; }
  .popap-result .profileHead {
    margin-top: 20px;
    color: #000000;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px; }
  .popap-result .profileDescription {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 22px;
    overflow: hidden;
    position: relative;
    transition: .5s;
    margin-bottom: 30px; }
  .popap-result .button {
    width: 200px; }

.proftestNew .popap {
  max-width: 1100px;
  font-family: "SB Sans Display" !important; }
  .proftestNew .popap-head {
    font-size: 35px;
    line-height: 1.3;
    text-align: center; }
  .proftestNew .popap-input {
    margin: 20px 0; }
    .proftestNew .popap-input-block {
      padding: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .proftestNew .popap-input-block .input-label {
        color: #000000;
        font-family: "SB Sans Display" !important;
        font-size: 20px;
        font-weight: 400;
        display: block; }
      .proftestNew .popap-input-block .input-input {
        padding: 10px 15px;
        border: 1px solid #21A038;
        border-radius: 5px;
        min-width: 300px;
        color: #21A038;
        font-size: 16px;
        text-align: center; }
  .proftestNew .popap .button {
    min-width: 300px;
    white-space: nowrap;
    border: 0 none;
    transition: .5s; }
    .proftestNew .popap .button:hover {
      background-color: #228049; }
  .proftestNew .popap .sendSuccess {
    display: none;
    color: #21A038;
    font-size: 20px;
    text-align: center;
    border-radius: 5px;
    transition: .5s;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px 100px;
    box-shadow: 0 0 1000px 1000px rgba(0, 0, 0, 0.4); }
    .proftestNew .popap .sendSuccess .close {
      position: absolute;
      top: 5px;
      right: 10px;
      color: #333;
      font-size: 30px;
      font-weight: lighter;
      cursor: pointer; }

.corpPromo * {
  box-sizing: border-box; }

.corpPromo .b-travel-how-buy__column_text {
  max-width: 160px !important; }

.corpPromo .form-group {
  margin-bottom: 15px; }
  .corpPromo .form-group .control-label {
    font-weight: normal;
    text-transform: uppercase;
    color: grey;
    font-size: 12px; }
  .corpPromo .form-group .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }
  .corpPromo .form-group .error {
    border-color: red; }

.corpPromo .error-text {
  position: relative;
  top: -10px;
  color: red;
  font-size: 12px; }

.corpPromo .btn-success {
  border-radius: 50px;
  background: #37a465;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  color: #fff; }

.corpPromo p:last-child {
  margin-bottom: 30px; }

.corpPromo form {
  margin: 40px 0;
  width: 100%;
  max-width: 400px; }

.corpPromo .popap-over {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #21A038;
  z-index: 100;
  display: none; }
  .corpPromo .popap-over .popap-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: url("../i/new/i-popup-close.png") no-repeat center;
    width: 60px;
    height: 60px;
    cursor: pointer; }
  .corpPromo .popap-over .popap-wrp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    padding: 50px 100px;
    max-width: 600px;
    background: #fff;
    border-radius: 5px;
    font-size: 36px;
    text-align: center;
    line-height: 1.2; }

.medic {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
  padding-top: 154px;
  padding-bottom: 150px; }
  .medic .button_group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    flex-wrap: wrap; }
    .medic .button_group button {
      margin: 10px 20px;
      display: inline-block;
      vertical-align: top;
      background: none;
      outline: none;
      position: relative;
      box-sizing: border-box;
      border-radius: 28px;
      font-size: 16px;
      line-height: 29px;
      font-family: "SB Sans Display" !important;
      padding: 12px 10px 15px;
      width: 230px;
      color: #fff;
      border: 1px solid #21A038;
      background: #21A038;
      transition: background .25s,border-color .25s,color .25s;
      cursor: pointer;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2); }
      .medic .button_group button:hover, .medic .button_group button .active {
        border-color: #3b955f;
        background: #3b955f;
        color: #fff; }
  .medic .step {
    margin: 30px auto 40px; }
    .medic .step:not(.step0) {
      display: none; }
    .medic .step_head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      color: #44904b; }
      .medic .step_head h1 {
        margin: 0;
        padding: 0;
        margin-bottom: 60px;
        font-size: 40px;
        line-height: 1.4; }
      .medic .step_head h2 {
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 1.4; }
      .medic .step_head h3 {
        margin: 0;
        margin-top: 20px;
        padding: 0;
        font-size: 20px;
        line-height: 1.4; }
    .medic .step.step0 .step_head {
      align-items: center; }
      .medic .step.step0 .step_head h3 {
        width: 100%;
        text-align: center; }
    .medic .step .b-input__box {
      position: relative; }
      .medic .step .b-input__box .input_options {
        display: none;
        position: absolute;
        z-index: 4;
        bottom: 4px;
        left: 0;
        transform: translate(0, 100%);
        background-color: #fff;
        width: 100%;
        height: 250px;
        overflow-y: scroll;
        border: 1px solid #eeeeee;
        border-top: 0 none;
        border-radius: 0 0 5px 5px;
        padding: 13px 0px 5px;
        font-family: "SB Sans Display" !important;
        font-size: 17px;
        line-height: 27px; }
        .medic .step .b-input__box .input_options .option {
          padding: 5px 18px;
          border-bottom: 1px solid lightgrey;
          transition: .5s;
          cursor: pointer; }
          .medic .step .b-input__box .input_options .option:last-child {
            border-bottom: 0 none; }
          .medic .step .b-input__box .input_options .option:hover, .medic .step .b-input__box .input_options .option .active {
            background-color: lightgrey; }
    .medic .step .b-input {
      border: 1px solid lightgrey; }
    .medic .step .table {
      margin-top: 30px;
      width: 100%; }
      .medic .step .table_head {
        font-weight: bold;
        font-family: "SB Sans Display Bold"; }
        .medic .step .table_head td {
          border-top: 0 none; }
          .medic .step .table_head td:first-child {
            width: 200px;
            padding-left: 10px; }
          .medic .step .table_head td:nth-child(2) {
            padding-right: 10px; }
      .medic .step .table_el {
        width: 100%; }
        .medic .step .table_el:nth-child(2n - 1) {
          background-color: #eeeeee; }
        .medic .step .table_el td {
          padding: 10px 0; }
          .medic .step .table_el td:nth-child(1) {
            padding-left: 10px; }
          .medic .step .table_el td:nth-child(2) {
            padding-right: 10px; }

.btns_sub_el {
  background: #21A038;
  color: #fff;
  padding: 10px 15px;
  margin-right: 10px;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  transition: .5s; }
  .btns_sub_el.active, .btns_sub_el:hover {
    background: #388b58; }

.subHtml:not(.subHtml_0) {
  display: none; }
  .subHtml:not(.subHtml_0) .js-top-result-fix.b-bg.b-bg__top {
    display: none; }

.headText {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .headText .calendar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    color: #979797;
    font-size: 16px;
    cursor: pointer; }
    .headText .calendar svg {
      margin-right: 5px; }

.js-product-section .js-product-section .headText {
  display: none; }

.newsSection {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap; }
  .newsSection_el {
    width: calc(50% - 20px);
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column; }
    @media screen and (max-width: 768px) {
      .newsSection_el {
        width: 100%; } }
    .newsSection_el_date {
      color: #979797;
      font-size: 16px;
      margin-bottom: 16px; }
    .newsSection_el_head {
      font-size: 20px;
      line-height: 30px;
      color: #000;
      font-weight: normal; }
    .newsSection_el_text {
      margin-top: 10px;
      font-size: 16px;
      line-height: 30px;
      color: #000;
      max-height: 175px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 30px; }
      .newsSection_el_text strong {
        font-weight: normal !important; }
    .newsSection_el_link {
      color: #2B9938;
      font-size: 16px;
      text-decoration: none; }
      .newsSection_el_link:hover {
        text-decoration: none; }

.b-product__price2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 15px; }
  .b-product__price2 span {
    font-size: 18px;
    font-weight: 500; }
  .b-product__price2 .btn-green {
    display: block;
    background-color: #2b9938;
    color: #fff;
    font-size: 17px;
    padding: 10px;
    width: calc(100% - 60px) !important;
    margin-top: 10px;
    text-align: center;
    border: 0 none !important;
    border-radius: 5px !important;
    cursor: pointer; }

.onlineHelper {
  width: 100%;
  background: #F2F2F2;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 183px 20px 52px;
  min-height: 100vh; }
  @media screen and (max-width: 750px) {
    .onlineHelper {
      padding: 113px 10px 30px; } }
  .onlineHelper * {
    box-sizing: border-box; }
  .onlineHelper ul {
    padding-left: 10px; }
  .onlineHelper li {
    list-style: circle; }
  .onlineHelper .rowFlex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto; }
    @media screen and (max-width: 1600px) {
      .onlineHelper .rowFlex {
        max-width: 1050px; } }
    @media screen and (max-width: 750px) {
      .onlineHelper .rowFlex {
        flex-direction: column; } }
    .onlineHelper .rowFlex.center {
      justify-content: center; }
      @media screen and (max-width: 750px) {
        .onlineHelper .rowFlex.center {
          align-items: center; } }
    .onlineHelper .rowFlex.left {
      justify-content: flex-start; }
      @media screen and (max-width: 750px) {
        .onlineHelper .rowFlex.left {
          align-items: flex-start; } }
    .onlineHelper .rowFlex.top {
      align-items: flex-start; }
    .onlineHelper .rowFlex.btn-block {
      flex-wrap: wrap;
      align-items: stretch; }
      .onlineHelper .rowFlex.btn-block .btn {
        margin-left: 15px;
        margin-bottom: 15px;
        white-space: normal;
        width: calc(50% - 15px);
        text-transform: lowercase; }
        .onlineHelper .rowFlex.btn-block .btn:nth-child(2n + 1) {
          margin-left: 0; }
  .onlineHelper.inclaim .text b {
    font-weight: bold !important;
    font-family: "SB Sans Display Bold"; }
  .onlineHelper.inclaim ul {
    padding-left: 40px; }
  .onlineHelper .left:not(.rowFlex) {
    width: 100%; }
  .onlineHelper .mainImgRight {
    margin-left: 50px;
    width: 700px;
    height: 700px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 50%; }
    .onlineHelper .mainImgRight.inclaim {
      border-radius: 0; }
    @media screen and (max-width: 1600px) {
      .onlineHelper .mainImgRight {
        width: 400px;
        height: 400px; } }
    @media screen and (max-width: 1024px) {
      .onlineHelper .mainImgRight {
        display: none; } }
  .onlineHelper .head {
    font-size: 40px;
    color: #000;
    line-height: 1.2;
    max-width: 750px;
    margin-left: 0; }
    .onlineHelper .head h2 {
      margin: 0; }
    @media screen and (max-width: 750px) {
      .onlineHelper .head {
        text-align: center;
        font-size: 32px; } }
    .onlineHelper .head.name, .onlineHelper .head.series {
      display: none;
      font-size: 20px;
      line-height: 1.4; }
  .onlineHelper .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    height: auto;
    width: 350px;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: .5s;
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
    flex-shrink: 0; }
    @media screen and (max-width: 750px) {
      .onlineHelper .btn {
        width: 100%; } }
    .onlineHelper .btn:hover {
      background: #207129;
      color: #fff; }
    .onlineHelper .btn_green {
      background-color: #2B9938;
      color: #fff; }
    .onlineHelper .btn_gray {
      background-color: #DADADA;
      color: #fff; }
    .onlineHelper .btn_link {
      background: transparent;
      color: #19BB4F;
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 60px;
      margin-left: 0; }
      .onlineHelper .btn_link:last-child {
        margin-right: 0; }
      .onlineHelper .btn_link svg {
        margin-right: 10px; }
        .onlineHelper .btn_link svg path {
          transition: .5s; }
      .onlineHelper .btn_link:hover {
        background: transparent;
        color: #107832; }
        .onlineHelper .btn_link:hover svg path {
          fill: #107832; }
    .onlineHelper .btn.ml {
      margin-left: 16px; }
      @media screen and (max-width: 750px) {
        .onlineHelper .btn.ml {
          margin-left: 0;
          margin-top: 16px; } }
    .onlineHelper .btn.mt {
      margin-top: 32px; }
      @media screen and (max-width: 750px) {
        .onlineHelper .btn.mt {
          margin-top: 16px; } }
    .onlineHelper .btn_back {
      margin-top: 52px;
      display: none; }
    .onlineHelper .btn:disabled {
      opacity: 0.5;
      cursor: default;
      pointer-events: none; }
      .onlineHelper .btn:disabled:hover {
        background: inherit; }
  .onlineHelper .or {
    font-size: 18px;
    color: #979797;
    margin: 24px 0; }
  .onlineHelper .input {
    height: 48px;
    width: 100%;
    background: #fff;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    padding: 14px;
    font-size: 16px;
    text-align: left; }
    .onlineHelper .input::placeholder {
      color: #979797; }
  .onlineHelper .inputFlex {
    display: flex;
    justify-content: space-between;
    align-items: stretch; }
    .onlineHelper .inputFlex .series_order {
      width: 45%; }
    .onlineHelper .inputFlex .number_order {
      width: calc(55% - 16px); }
  .onlineHelper .selectInput {
    width: 100%;
    position: relative; }
    .onlineHelper .selectInput .selectInputOption {
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background: #fff;
      border: 1px solid #D8D8D8;
      border-radius: 4px; }
      .onlineHelper .selectInput .selectInputOption .selectInputEl {
        padding: 14px;
        font-size: 16px;
        transition: .5s;
        cursor: pointer; }
        .onlineHelper .selectInput .selectInputOption .selectInputEl:hover {
          background: #2B9938;
          color: #fff; }
  .onlineHelper .text {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 40px; }
  .onlineHelper .label_green {
    color: #2B9938;
    font-size: 18px;
    margin: 32px 0 16px; }
  .onlineHelper .label_black {
    color: #000;
    font-size: 18px;
    margin: 32px 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
  .onlineHelper .label_svg_block {
    width: 25px;
    height: 25px;
    margin-left: 16px;
    cursor: pointer;
    position: relative; }
    .onlineHelper .label_svg_block:hover .lable_title {
      display: block; }
  .onlineHelper .label_svg {
    width: 100%;
    height: 100%; }
  .onlineHelper .lable_title {
    display: none;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 10px;
    font-size: 12px;
    width: 500px;
    background: #fff;
    border: 1px solid #2B9938;
    border-radius: 10px; }
    .onlineHelper .lable_title:before {
      content: '';
      height: 20px;
      width: 20px;
      border-left: 1px solid #2B9938;
      border-bottom: 1px solid #2B9938;
      background: #fff;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%) rotate(-45deg); }
  .onlineHelper .select_el {
    padding: 16px 24px;
    background: #fff;
    border-bottom: 1px solid #F2F2F2;
    transition: .5s;
    cursor: pointer;
    font-size: 16px; }
    .onlineHelper .select_el:hover {
      background: #2B9938;
      color: #fff; }
    .onlineHelper .select_el.active {
      background: #2B9938;
      color: #fff;
      pointer-events: none; }
  .onlineHelper .star {
    margin: 16px 0;
    font-size: 16px;
    color: #979797; }
  .onlineHelper .text_green {
    color: #2B9938; }
  .onlineHelper .text_blue {
    color: #337ab7; }
  .onlineHelper .emailInput {
    margin-top: 32px; }
  .onlineHelper table {
    width: 100%;
    background: #fff;
    margin-top: 32px;
    border: 1px solid #f2f2f2; }
    .onlineHelper table td, .onlineHelper table th {
      padding: 16px;
      font-size: 14px;
      border: 1px solid #f2f2f2;
      vertical-align: middle;
      text-align: center;
      width: 30%; }
      @media screen and (max-width: 750px) {
        .onlineHelper table td, .onlineHelper table th {
          padding: 10px; } }
    .onlineHelper table td:first-child, .onlineHelper table th {
      text-align: left; }
    .onlineHelper table td:first-child, .onlineHelper table th:first-child {
      width: 40%; }
    .onlineHelper table th {
      color: #2B9938;
      font-size: 16px;
      text-align: left; }
  .onlineHelper .dn {
    display: none; }
  .onlineHelper .b-product-section__wrapper {
    margin-top: 30px;
    margin-right: 0; }
    .onlineHelper .b-product-section__wrapper h2 {
      font-size: 55px !important;
      font-family: "SB Sans Display Bold" !important;
      font-weight: 700 !important;
      line-height: 60px; }
    .onlineHelper .b-product-section__wrapper .b-table {
      padding: 0;
      margin: 0; }
      .onlineHelper .b-product-section__wrapper .b-table td {
        padding: 5px;
        font-size: 16px;
        font-weight: normal;
        text-align: center; }
  .onlineHelper .step {
    display: none; }
    .onlineHelper .step.step1 {
      display: block; }
  .onlineHelper small {
    font-style: italic; }
  .onlineHelper .pageQuest {
    padding-top: 20px;
    padding-bottom: 20px; }
    .onlineHelper .pageQuest_head {
      color: #2c3136;
      text-align: center;
      font-weight: 600;
      font-size: 21px; }
    .onlineHelper .pageQuest_answers {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;
      margin-top: 20px; }
      .onlineHelper .pageQuest_answers .answer_el {
        border: 0 none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-content: flex-end;
        font-size: 17px;
        line-height: 30px;
        color: #878b90;
        margin: 0 15px;
        transition: .5s;
        cursor: default; }
        .onlineHelper .pageQuest_answers .answer_el svg {
          margin-right: 10px; }
          .onlineHelper .pageQuest_answers .answer_el svg g {
            transition: .5s; }
        .onlineHelper .pageQuest_answers .answer_el.active {
          color: #08a652; }
          .onlineHelper .pageQuest_answers .answer_el.active svg g {
            fill: #08a652; }
        .onlineHelper .pageQuest_answers .answer_el:not(.noHover):hover {
          cursor: pointer;
          color: #08a652; }
          .onlineHelper .pageQuest_answers .answer_el:not(.noHover):hover svg g {
            fill: #08a652; }
        .onlineHelper .pageQuest_answers .answer_el:last-child svg {
          transform: rotate(180deg); }
    .onlineHelper .pageQuest_area {
      margin-top: 30px;
      display: none;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .onlineHelper .pageQuest_area .textarea {
        font-family: inherit;
        width: 100%;
        min-height: 100px;
        resize: none;
        border-color: #ced1d5;
        border-radius: 5px;
        outline: none;
        margin-top: 5px;
        font-size: 17px;
        line-height: 26px;
        padding: 12px 20px; }
      .onlineHelper .pageQuest_area .button {
        font-family: inherit;
        margin-top: 20px;
        width: 216px;
        height: 50px;
        background: #000014;
        color: #fff;
        border: 0 none;
        border-radius: 5px;
        transition: .5s; }
        .onlineHelper .pageQuest_area .button:hover {
          background: #2c3136; }
        .onlineHelper .pageQuest_area .button:disabled {
          opacity: .5;
          pointer-events: none; }
  .onlineHelper .private-questions {
    display: none;
    margin-top: 48px; }
    .onlineHelper .private-questions__item {
      margin-bottom: 24px; }
    .onlineHelper .private-questions__question {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px; }
    .onlineHelper .private-questions__answer {
      font-size: 14px;
      line-height: 18px; }
  .onlineHelper .step__attention {
    margin-top: 24px;
    font-size: 14px;
    line-height: 18px; }
  .onlineHelper .step__note {
    font-size: 14px;
    line-height: 18px; }
  .onlineHelper .step__button-box {
    display: flex; }

.chatPage {
  width: 100%;
  background: #F2F2F2;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 183px 20px 52px;
  min-height: 100vh; }
  @media screen and (max-width: 750px) {
    .chatPage {
      padding: 113px 10px 30px; } }
  .chatPage .chat-retail-icons__container {
    position: fixed !important;
    right: 20px !important;
    top: auto !important;
    bottom: 20px !important;
    z-index: 9999 !important;
    width: 60px !important;
    height: 60px;
    background-color: #08a652;
    border-radius: 50%; }
  .chatPage .chat-retail-icons {
    transition: .5s;
    width: 60px !important;
    height: 60px !important;
    background-color: transparent; }
    .chatPage .chat-retail-icons.iconPhone {
      background-image: url(../i/chatPage/phone-call.png);
      background-size: 30px;
      background-position: center;
      background-repeat: no-repeat; }
      .chatPage .chat-retail-icons.iconPhone:hover {
        background-color: #34b76f; }
    .chatPage .chat-retail-icons.iconSecurety {
      background-image: url(../i/chatPage/security.png);
      background-size: 30px;
      background-position: center;
      background-repeat: no-repeat; }
      .chatPage .chat-retail-icons.iconSecurety:hover {
        background-color: #34b76f; }

.timeWithBenefits {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1920px;
  line-height: 1.2;
  font-family: "SB Sans Display" !important; }
  .timeWithBenefits .xs {
    display: none; }
    @media screen and (max-width: 767px) {
      .timeWithBenefits .xs {
        display: block; } }
  @media screen and (max-width: 767px) {
    .timeWithBenefits .hd {
      display: none; } }
  .timeWithBenefits .header {
    background-color: #21a038; }
    .timeWithBenefits .header .cnt {
      height: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .header .cnt {
          height: 80px; }
          .timeWithBenefits .header .cnt img {
            max-width: 30%; } }
    .timeWithBenefits .header a {
      text-decoration: none; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .header a {
          max-width: 65%;
          flex-shrink: 0; }
          .timeWithBenefits .header a img {
            max-width: 100% !important; } }
  .timeWithBenefits .newBanner {
    background: url("https://ticket.sberbank-insurance.ru/img/header_bg2.png") no-repeat center;
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 20px; }
    @media screen and (max-width: 767px) {
      .timeWithBenefits .newBanner {
        background: url("../../i/timeWithBenefits/bg-xs.png") no-repeat center;
        background-size: cover;
        height: auto;
        padding-bottom: 50px; } }
    .timeWithBenefits .newBanner .logo {
      padding-bottom: 60px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .timeWithBenefits .newBanner .logo img {
        height: 50px; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .newBanner .logo {
          width: 100%;
          max-width: 590px; }
          .timeWithBenefits .newBanner .logo img {
            width: 100%; } }
    .timeWithBenefits .newBanner .head {
      text-align: center;
      font-size: 60px;
      color: #fff;
      font-family: "SB Sans Display Bold";
      font-weight: 600; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .newBanner .head {
          font-size: 36px; } }
      @media screen and (max-width: 359px) {
        .timeWithBenefits .newBanner .head {
          font-size: 30px; } }
    .timeWithBenefits .newBanner .subhead {
      padding-top: 20px;
      font-size: 26px;
      color: #fff;
      text-align: center; }
      @media screen and (max-width: 1024px) {
        .timeWithBenefits .newBanner .subhead {
          font-size: 20px; } }
    .timeWithBenefits .newBanner .btn_white {
      margin-top: 30px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      height: 60px;
      width: 100%;
      max-width: 355px;
      border-radius: 60px;
      border: 1px solid #fff;
      background: #fff;
      color: #21a038;
      font-size: 24px;
      cursor: pointer;
      transition: .5s;
      font-family: "SB Sans Display Bold";
      font-weight: 600; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .newBanner .btn_white {
          display: none; } }
      .timeWithBenefits .newBanner .btn_white:hover {
        background: transparent;
        color: #fff; }
      .timeWithBenefits .newBanner .btn_white:disabled {
        cursor: default;
        opacity: .5; }
  .timeWithBenefits .wrap {
    background: url("/i/timeWithBenefits/bg.png") no-repeat top center;
    background-size: cover; }
    @media screen and (max-width: 1024px) {
      .timeWithBenefits .wrap {
        background-size: 1920px;
        background-repeat: repeat-y; } }
    @media screen and (max-width: 767px) {
      .timeWithBenefits .wrap {
        background: #e3e3e3; } }
    .timeWithBenefits .wrap .ul {
      position: relative;
      width: 100%;
      padding-bottom: 0px;
      padding-top: 40px; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .wrap .ul {
          padding: 0;
          top: -25px; } }
      .timeWithBenefits .wrap .ul_img {
        position: absolute;
        z-index: 1;
        bottom: -60px;
        right: -115px;
        height: 510px; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefits .wrap .ul_img {
            display: none !important; } }
      .timeWithBenefits .wrap .ul .li {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 60px;
        width: calc(100% - 230px); }
        @media screen and (max-width: 1024px) {
          .timeWithBenefits .wrap .ul .li {
            width: 100%; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefits .wrap .ul .li {
            display: flex !important;
            flex-wrap: wrap;
            align-items: center;
            background: #fff;
            border-radius: 20px;
            padding: 30px 20px; } }
        .timeWithBenefits .wrap .ul .li:nth-child(1) {
          width: 100%; }
        .timeWithBenefits .wrap .ul .li:nth-child(2) {
          width: calc(100% - 230px); }
          @media screen and (max-width: 1024px) {
            .timeWithBenefits .wrap .ul .li:nth-child(2) {
              width: 100%; } }
        .timeWithBenefits .wrap .ul .li_icon {
          width: 103px;
          flex-shrink: 0;
          margin-right: 20px; }
          @media screen and (max-width: 767px) {
            .timeWithBenefits .wrap .ul .li_icon {
              width: 80px; } }
        .timeWithBenefits .wrap .ul .li_head {
          font-size: 36px;
          color: #21a038;
          margin-bottom: 10px;
          font-family: "SB Sans Display Bold";
          font-weight: 600; }
          @media screen and (max-width: 767px) {
            .timeWithBenefits .wrap .ul .li_head {
              font-size: 24px;
              width: calc(100% - 100px); } }
        .timeWithBenefits .wrap .ul .li_text {
          font-size: 24px;
          color: #000; }
          @media screen and (max-width: 767px) {
            .timeWithBenefits .wrap .ul .li_text {
              font-size: 20px;
              margin-top: 30px; } }
          .timeWithBenefits .wrap .ul .li_text small {
            font-size: 21px;
            color: rgba(0, 0, 0, 0.5); }
            @media screen and (max-width: 767px) {
              .timeWithBenefits .wrap .ul .li_text small {
                font-size: 16px; } }
  .timeWithBenefits .where {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    left: 123px;
    margin-bottom: -180px; }
    @media screen and (max-width: 1024px) {
      .timeWithBenefits .where {
        left: 0;
        flex-direction: column-reverse; } }
    .timeWithBenefits .where .left {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column; }
      @media screen and (max-width: 1024px) {
        .timeWithBenefits .where .left {
          margin-left: 0;
          background: #fff;
          width: calc(100% + 100px);
          position: relative;
          left: 50%;
          transform: translate(-50%);
          border-radius: 0;
          padding: 0 20px; } }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .where .left {
          width: calc(100% + 48px); } }
      .timeWithBenefits .where .left_head {
        font-size: 32px;
        font-family: "SB Sans Display Bold";
        font-weight: 600;
        line-height: 1.2;
        color: #21a038;
        margin-bottom: 10px;
        text-align: center; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefits .where .left_head {
            text-align: center;
            font-size: 32px;
            line-height: 1.2;
            color: #000; } }
      .timeWithBenefits .where .left_text {
        font-size: 18px;
        text-align: center; }
        .timeWithBenefits .where .left_text b {
          font-weight: normal; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefits .where .left_text {
            text-align: center;
            font-size: 20px; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefits .where .left_text {
            text-align: center; }
            .timeWithBenefits .where .left_text b {
              font-weight: bold;
              font-family: "SB Sans Display Bold"; } }
      @media screen and (max-width: 1024px) {
        .timeWithBenefits .where .left .phone {
          height: 430px;
          object-fit: cover;
          width: 351px;
          object-position: top; } }
    .timeWithBenefits .where .right {
      margin-left: 100px;
      width: 100%;
      background: #21a038;
      border-radius: 33px;
      padding: 20px 50px;
      margin-bottom: 20px; }
      @media screen and (max-width: 1440px) {
        .timeWithBenefits .where .right {
          margin-right: 70px; } }
      @media screen and (max-width: 1024px) {
        .timeWithBenefits .where .right {
          margin-left: 0;
          background: #fff;
          width: calc(100% + 100px);
          position: relative;
          left: 50%;
          transform: translate(-50%);
          border-radius: 0;
          margin-bottom: 0; } }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .where .right {
          padding: 20px;
          width: calc(100% + 48px); } }
      .timeWithBenefits .where .right_head {
        text-align: center;
        font-size: 43px;
        color: #fff;
        margin-bottom: 15px;
        font-family: "SB Sans Display Bold";
        font-weight: 600; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefits .where .right_head {
            color: #21a038; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefits .where .right_head {
            font-size: 32px; } }
      .timeWithBenefits .where .right_li {
        font-size: 26px;
        color: #fff;
        margin-bottom: 50px;
        padding-left: 80px;
        position: relative; }
        .timeWithBenefits .where .right_li:last-child {
          margin-bottom: 30px; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefits .where .right_li {
            color: #000; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefits .where .right_li {
            font-size: 20px;
            padding-left: 70px; } }
        .timeWithBenefits .where .right_li:before {
          content: attr(data-number);
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          transform: translate(0, 0);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          line-height: 26px;
          color: #21a038; }
          @media screen and (max-width: 1024px) {
            .timeWithBenefits .where .right_li:before {
              background: #e3e3e3; } }
        .timeWithBenefits .where .right_li:after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 25px;
          top: 0px;
          transform: translate(-50%, 0);
          width: 5px;
          height: calc(100% + 70px);
          background: #fff; }
          @media screen and (max-width: 1024px) {
            .timeWithBenefits .where .right_li:after {
              background: url("../../i/timeWithBenefits/after_dots.png") repeat-y center;
              background-size: 5px; } }
        .timeWithBenefits .where .right_li:last-child:after {
          display: none; }
  .timeWithBenefits .form {
    background: #faed00;
    padding: 35px 0;
    position: relative;
    z-index: 3; }
    .timeWithBenefits .form_flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
    .timeWithBenefits .form_head {
      font-size: 36px;
      line-height: 1.2;
      color: #000;
      text-align: center;
      font-family: "SB Sans Display Bold";
      font-weight: 600; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .form_head {
          font-size: 30px; } }
      .timeWithBenefits .form_head span {
        font-size: 24px;
        font-family: "SB Sans Display";
        font-weight: 400;
        color: #000; }
        @media screen and (max-width: 767px) {
          .timeWithBenefits .form_head span {
            font-size: 20px; } }
    .timeWithBenefits .form_inputs {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-top: 50px;
      margin-bottom: 60px; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .form_inputs {
          flex-direction: column; } }
      .timeWithBenefits .form_inputs .inputBlock {
        width: 100%;
        max-width: 455px;
        margin-right: 40px;
        position: relative; }
        @media screen and (max-width: 767px) {
          .timeWithBenefits .form_inputs .inputBlock {
            margin-right: 0;
            margin-bottom: 40px; } }
        .timeWithBenefits .form_inputs .inputBlock.select input {
          padding-right: 30px; }
        .timeWithBenefits .form_inputs .inputBlock.select:before {
          content: '';
          position: absolute;
          z-index: 3;
          bottom: 18px;
          right: 10px;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid #000;
          border-right: 2px solid #000;
          transform: rotate(45deg);
          transition: .5s;
          transform-origin: center; }
        .timeWithBenefits .form_inputs .inputBlock.select.active:before {
          bottom: 14px;
          transform: rotate(225deg); }
        .timeWithBenefits .form_inputs .inputBlock:last-child {
          margin-right: 0; }
          @media screen and (max-width: 767px) {
            .timeWithBenefits .form_inputs .inputBlock:last-child {
              margin-bottom: 0; } }
        .timeWithBenefits .form_inputs .inputBlock label {
          font-weight: 600;
          font-family: "SB Sans Display Bold";
          font-size: 24px;
          color: #000; }
        .timeWithBenefits .form_inputs .inputBlock input {
          margin-top: 15px;
          width: 100%;
          background: white;
          border: 0;
          outline: 0;
          height: 45px;
          font-size: 20px;
          color: #000;
          border-radius: 8px;
          padding: 0 10px;
          box-sizing: border-box; }
          @media screen and (max-width: 767px) {
            .timeWithBenefits .form_inputs .inputBlock input {
              font-size: 18px; } }
          .timeWithBenefits .form_inputs .inputBlock input::placeholder {
            color: #000; }
        .timeWithBenefits .form_inputs .inputBlock .option {
          display: none;
          position: absolute;
          z-index: 5;
          bottom: 0px;
          left: 0;
          transform: translate(0, 100%);
          background: white;
          border-radius: 8px;
          padding: 10px 0; }
          .timeWithBenefits .form_inputs .inputBlock .option__el {
            font-size: 20px;
            color: #000;
            padding: 10px;
            cursor: pointer;
            transition: .5s; }
            .timeWithBenefits .form_inputs .inputBlock .option__el.selected, .timeWithBenefits .form_inputs .inputBlock .option__el:hover {
              background: #B1B1B1; }
    .timeWithBenefits .form .errorDiv {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(0, 100%);
      color: red; }
      .timeWithBenefits .form .errorDiv.success {
        color: #21a038; }
    .timeWithBenefits .form_btn {
      height: 60px;
      width: 100%;
      max-width: 355px;
      border-radius: 60px;
      background: #21a038;
      border: 1px solid #21a038;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
      transition: .5s;
      font-weight: 600;
      font-family: "SB Sans Display Bold"; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .form_btn {
          font-size: 20px; } }
      .timeWithBenefits .form_btn:hover {
        background: transparent;
        color: #000; }
      .timeWithBenefits .form_btn:disabled {
        cursor: default;
        opacity: .5; }
  .timeWithBenefits .footer {
    background-color: #21a038;
    position: relative; }
    .timeWithBenefits .footer:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      opacity: .1;
      background: linear-gradient(0deg, #002619 0, rgba(0, 38, 25, 0)); }
    .timeWithBenefits .footer .cnt {
      height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .footer .cnt {
          height: 80px; } }
    .timeWithBenefits .footer a {
      font-size: 20px;
      color: #EBEBEB;
      text-decoration: none;
      border-bottom: 1px solid rgba(235, 235, 235, 0);
      transition: .5s;
      padding-left: 20px;
      position: relative; }
      @media screen and (max-width: 767px) {
        .timeWithBenefits .footer a {
          font-size: 18px; } }
      .timeWithBenefits .footer a:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 15px;
        height: 17px;
        background: url(/static/i/new/i-footer-agreement.png) no-repeat; }
      .timeWithBenefits .footer a:hover {
        border-bottom: 1px solid #ebebeb; }

.timeWithBenefitsNew {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1920px;
  line-height: 1.2;
  font-family: "SB Sans Display" !important; }
  .timeWithBenefitsNew .xs {
    display: none; }
    @media screen and (max-width: 767px) {
      .timeWithBenefitsNew .xs {
        display: block; } }
  @media screen and (max-width: 767px) {
    .timeWithBenefitsNew .hd {
      display: none; } }
  .timeWithBenefitsNew .header {
    background-color: #21a038; }
    .timeWithBenefitsNew .header .cnt {
      height: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .header .cnt {
          height: 80px; }
          .timeWithBenefitsNew .header .cnt img {
            max-width: 30%; } }
    .timeWithBenefitsNew .header a {
      text-decoration: none; }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .header a {
          max-width: 65%;
          flex-shrink: 0; }
          .timeWithBenefitsNew .header a img {
            max-width: 100% !important; } }
  .timeWithBenefitsNew .newBanner {
    background: #F5FAFF;
    padding-bottom: 0px;
    padding-top: 25px; }
    @media screen and (max-width: 767px) {
      .timeWithBenefitsNew .newBanner {
        padding-bottom: 25px; } }
    .timeWithBenefitsNew .newBanner .logo {
      padding-bottom: 70px;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .newBanner .logo {
          padding: 0 20px 30px; } }
      .timeWithBenefitsNew .newBanner .logo img {
        height: 35px; }
        @media screen and (max-width: 767px) {
          .timeWithBenefitsNew .newBanner .logo img {
            width: 100%;
            height: auto; } }
    .timeWithBenefitsNew .newBanner__flex {
      display: flex;
      justify-content: space-between;
      align-items: stretch; }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .newBanner__flex {
          flex-direction: column; } }
      .timeWithBenefitsNew .newBanner__flex .left {
        padding-top: 50px; }
        .timeWithBenefitsNew .newBanner__flex .left .head {
          font-weight: 600;
          font-size: 28px;
          line-height: 36px;
          color: #333F48;
          max-width: 510px; }
          @media screen and (max-width: 767px) {
            .timeWithBenefitsNew .newBanner__flex .left .head {
              text-align: center; } }
        .timeWithBenefitsNew .newBanner__flex .left .text {
          margin-top: 24px;
          font-size: 17px;
          line-height: 22px;
          color: #333F48; }
          @media screen and (max-width: 767px) {
            .timeWithBenefitsNew .newBanner__flex .left .text {
              display: none; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefitsNew .newBanner__flex .left {
            padding-top: 0px;
            position: relative;
            z-index: 2; } }
      .timeWithBenefitsNew .newBanner__flex .right {
        width: 413px;
        flex-shrink: 0;
        margin-top: -60px; }
        .timeWithBenefitsNew .newBanner__flex .right img {
          width: 413px;
          height: auto;
          object-fit: cover;
          object-position: bottom center; }
          @media screen and (max-width: 767px) {
            .timeWithBenefitsNew .newBanner__flex .right img {
              width: 100%; } }
        .timeWithBenefitsNew .newBanner__flex .right .text {
          margin-top: 24px;
          font-size: 17px;
          line-height: 22px;
          color: #333F48;
          display: none; }
          @media screen and (max-width: 767px) {
            .timeWithBenefitsNew .newBanner__flex .right .text {
              text-align: center;
              display: block; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefitsNew .newBanner__flex .right {
            width: 100%;
            margin-top: 0px; } }
  .timeWithBenefitsNew .wrap {
    padding-top: 72px;
    padding-bottom: 72px; }
    @media screen and (max-width: 767px) {
      .timeWithBenefitsNew .wrap {
        padding-top: 30px;
        padding-bottom: 50px; } }
    .timeWithBenefitsNew .wrap .ul .slick {
      display: flex;
      justify-content: space-between;
      align-items: stretch; }
      .timeWithBenefitsNew .wrap .ul .slick .li {
        padding: 48px 22px 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        border: 2px solid #c9c9c9;
        border-radius: 8px;
        width: calc(100% / 3 - (23px / 3 * 2)); }
        .timeWithBenefitsNew .wrap .ul .slick .li_icon {
          height: 72px;
          width: 72px; }
        .timeWithBenefitsNew .wrap .ul .slick .li_desc {
          min-height: 382px;
          width: 100%; }
        .timeWithBenefitsNew .wrap .ul .slick .li_head {
          font-size: 21px;
          line-height: 27px;
          text-align: center;
          color: #333F48;
          margin-top: 48px;
          margin-bottom: 32px;
          min-height: 52px;
          width: 100%; }
        .timeWithBenefitsNew .wrap .ul .slick .li_text {
          font-size: 17px;
          line-height: 22px;
          text-align: center;
          color: #333F48;
          width: 100%; }
          .timeWithBenefitsNew .wrap .ul .slick .li_text a {
            color: #21a038; }
          .timeWithBenefitsNew .wrap .ul .slick .li_text small {
            margin-top: 32px;
            display: block;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #333F48;
            margin-bottom: 48px;
            width: 100%; }
        .timeWithBenefitsNew .wrap .ul .slick .li_btn {
          background: #000;
          border-radius: 4px;
          color: #fff;
          border: 1px solid #000;
          padding: 12px 32px;
          font-weight: 600;
          font-size: 16px;
          line-height: 21px;
          text-align: center;
          cursor: pointer;
          transition: .5s; }
          .timeWithBenefitsNew .wrap .ul .slick .li_btn:hover {
            background: transparent;
            color: #000; }
    .timeWithBenefitsNew .wrap .ul .slick-slider .slick-arrow.slick-prev {
      left: -24px;
      top: 220px; }
    .timeWithBenefitsNew .wrap .ul .slick-slider .slick-arrow.slick-next {
      right: -24px;
      top: 220px; }
  .timeWithBenefitsNew .where {
    background: #F5FAFF;
    padding-top: 60px;
    border-bottom: 1px solid #EFEFEF; }
    @media screen and (max-width: 767px) {
      .timeWithBenefitsNew .where {
        padding-top: 0px; } }
    .timeWithBenefitsNew .where .cnt {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      position: relative; }
      @media screen and (max-width: 1024px) {
        .timeWithBenefitsNew .where .cnt {
          left: 0;
          flex-direction: column-reverse; } }
    .timeWithBenefitsNew .where .left {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column; }
      @media screen and (max-width: 1024px) {
        .timeWithBenefitsNew .where .left {
          margin-left: 0;
          background: #fff;
          width: calc(100% + 100px);
          position: relative;
          left: 50%;
          transform: translate(-50%);
          border-radius: 0;
          padding: 0 20px; } }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .where .left {
          width: calc(100% + 48px);
          padding-top: 30px; } }
      .timeWithBenefitsNew .where .left_head {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #333F48; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefitsNew .where .left_head {
            font-size: 32px;
            line-height: 1.2; } }
      .timeWithBenefitsNew .where .left_text {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #333F48;
        margin-top: 20px;
        margin-bottom: 20px; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefitsNew .where .left_text {
            text-align: center;
            font-size: 20px; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefitsNew .where .left_text {
            text-align: center; } }
      @media screen and (max-width: 1024px) {
        .timeWithBenefitsNew .where .left .phone {
          height: 430px;
          object-fit: cover;
          width: 351px;
          object-position: top; } }
    .timeWithBenefitsNew .where .right {
      display: flex;
      align-items: center;
      margin-left: 100px;
      width: 100%; }
      @media screen and (max-width: 1024px) {
        .timeWithBenefitsNew .where .right {
          margin-left: 0;
          width: calc(100% + 100px);
          position: relative;
          left: 50%;
          transform: translate(-50%);
          margin-bottom: 0; } }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .where .right {
          padding: 20px;
          width: calc(100% + 48px); } }
      .timeWithBenefitsNew .where .right_wrp {
        background: linear-gradient(100.15deg, #F3F9FF 0%, #F7FBFF 100%);
        box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.2);
        border-radius: 16px;
        padding: 20px 50px; }
      .timeWithBenefitsNew .where .right_head {
        text-align: center;
        font-size: 43px;
        color: #fff;
        margin-bottom: 15px;
        font-family: "SB Sans Display Bold";
        font-weight: 600; }
        @media screen and (max-width: 1024px) {
          .timeWithBenefitsNew .where .right_head {
            color: #21a038; } }
        @media screen and (max-width: 767px) {
          .timeWithBenefitsNew .where .right_head {
            font-size: 32px; } }
      .timeWithBenefitsNew .where .right_ul {
        padding-top: 30px;
        padding-bottom: 30px; }
      .timeWithBenefitsNew .where .right_li {
        font-size: 16px;
        line-height: 21px;
        color: #333F48;
        margin-bottom: 45px;
        padding-left: 80px;
        position: relative; }
        .timeWithBenefitsNew .where .right_li:last-child {
          margin-bottom: 0px; }
        @media screen and (max-width: 767px) {
          .timeWithBenefitsNew .where .right_li {
            font-size: 20px;
            padding-left: 70px; } }
        .timeWithBenefitsNew .where .right_li:before {
          content: attr(data-number);
          color: #fff;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #333F48;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          line-height: 26px; }
          @media screen and (max-width: 1024px) {
            .timeWithBenefitsNew .where .right_li:before {
              background: #333F48; } }
        .timeWithBenefitsNew .where .right_li:after {
          content: '';
          color: #fff;
          position: absolute;
          z-index: 1;
          left: 25px;
          top: 0px;
          transform: translate(-50%, 0);
          width: 5px;
          height: calc(100% + 70px);
          background: #333F48; }
          @media screen and (max-width: 1024px) {
            .timeWithBenefitsNew .where .right_li:after {
              background: url("../../i/timeWithBenefits/after_dots.png") repeat-y center;
              background-size: 5px; } }
        .timeWithBenefitsNew .where .right_li:last-child:after {
          display: none; }
  .timeWithBenefitsNew .form {
    background: #F5FAFF;
    padding: 35px 0;
    position: relative;
    z-index: 3; }
    .timeWithBenefitsNew .form_flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
    .timeWithBenefitsNew .form_head {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #333F48; }
    .timeWithBenefitsNew .form_inputs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 48px;
      width: 100%; }
      .timeWithBenefitsNew .form_inputs .inputBlock {
        width: 100%;
        max-width: 410px;
        position: relative;
        margin-bottom: 44px; }
        .timeWithBenefitsNew .form_inputs .inputBlock.select input {
          padding-right: 30px; }
        .timeWithBenefitsNew .form_inputs .inputBlock.select:before {
          content: '';
          position: absolute;
          z-index: 3;
          bottom: 18px;
          right: 10px;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid #000;
          border-right: 2px solid #000;
          transform: rotate(45deg);
          transition: .5s;
          transform-origin: center; }
        .timeWithBenefitsNew .form_inputs .inputBlock.select.active:before {
          bottom: 14px;
          transform: rotate(225deg); }
        .timeWithBenefitsNew .form_inputs .inputBlock:last-child {
          margin-bottom: 0; }
        .timeWithBenefitsNew .form_inputs .inputBlock label {
          font-size: 14px;
          line-height: 18px;
          color: #333F48;
          font-weight: normal; }
        .timeWithBenefitsNew .form_inputs .inputBlock input {
          margin-top: 15px;
          width: 100%;
          background: white;
          border: 0;
          outline: 0;
          height: 45px;
          font-size: 20px;
          color: #000;
          border-radius: 8px;
          padding: 0 10px;
          box-sizing: border-box; }
          @media screen and (max-width: 767px) {
            .timeWithBenefitsNew .form_inputs .inputBlock input {
              font-size: 18px; } }
          .timeWithBenefitsNew .form_inputs .inputBlock input::placeholder {
            color: #000; }
        .timeWithBenefitsNew .form_inputs .inputBlock .option {
          display: none;
          position: absolute;
          z-index: 5;
          bottom: 0px;
          left: 0;
          transform: translate(0, 100%);
          background: white;
          border-radius: 8px;
          padding: 10px 0;
          width: 100%; }
          .timeWithBenefitsNew .form_inputs .inputBlock .option__el {
            font-size: 20px;
            color: #000;
            padding: 10px;
            cursor: pointer;
            transition: .5s;
            display: flex;
            justify-content: flex-start;
            align-items: center; }
            .timeWithBenefitsNew .form_inputs .inputBlock .option__el.selected, .timeWithBenefitsNew .form_inputs .inputBlock .option__el:hover {
              background: #B1B1B1; }
            .timeWithBenefitsNew .form_inputs .inputBlock .option__el svg {
              height: 24px;
              width: 24px;
              margin-right: 10px; }
    .timeWithBenefitsNew .form .errorDiv {
      position: absolute;
      bottom: -11px;
      left: 0;
      transform: translateY(100%);
      color: red;
      text-align: center;
      width: 100%;
      font-size: 20px;
      white-space: nowrap; }
      .timeWithBenefitsNew .form .errorDiv.success {
        color: #21a038; }
    .timeWithBenefitsNew .form_btn {
      height: 60px;
      width: 100%;
      max-width: 410px;
      border-radius: 4px;
      background: #000;
      border: 1px solid #000;
      cursor: pointer;
      transition: .5s;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF; }
      .timeWithBenefitsNew .form_btn:hover {
        background: transparent;
        color: #000; }
      .timeWithBenefitsNew .form_btn:disabled {
        cursor: default;
        opacity: .5; }
  .timeWithBenefitsNew .footer {
    background-color: #171a1e;
    position: relative; }
    .timeWithBenefitsNew .footer:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      opacity: .1;
      background: linear-gradient(0deg, #002619 0, rgba(0, 38, 25, 0)); }
    .timeWithBenefitsNew .footer .cnt {
      height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .footer .cnt {
          height: 80px; } }
    .timeWithBenefitsNew .footer a {
      font-size: 20px;
      color: #EBEBEB;
      text-decoration: none;
      border-bottom: 1px solid rgba(235, 235, 235, 0);
      transition: .5s;
      padding-left: 20px;
      position: relative; }
      @media screen and (max-width: 767px) {
        .timeWithBenefitsNew .footer a {
          font-size: 18px; } }
      .timeWithBenefitsNew .footer a:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 15px;
        height: 17px;
        background: url(/static/i/new/i-footer-agreement.png) no-repeat; }
      .timeWithBenefitsNew .footer a:hover {
        border-bottom: 1px solid #ebebeb; }

.mission {
  padding-top: 140px;
  padding-bottom: 70px; }
  .mission__img {
    width: 100%;
    height: auto; }

.yarkoeDetstvo {
  max-width: 1920px;
  margin: 0 auto;
  /***HEADER***/
  /***MAIN***/
  /***SPOILER***/ }
  .yarkoeDetstvo .container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    position: relative; }
  .yarkoeDetstvo .header-img {
    max-width: 100%;
    width: auto;
    min-width: 320px; }
  .yarkoeDetstvo .top-section {
    margin-top: -50px; }
    @media screen and (max-width: 768px) {
      .yarkoeDetstvo .top-section {
        margin-top: 0; } }
    .yarkoeDetstvo .top-section .flex_cont {
      justify-content: space-between;
      align-items: stretch;
      width: 100%; }
      @media screen and (max-width: 768px) {
        .yarkoeDetstvo .top-section .flex_cont {
          flex-direction: column; } }
      .yarkoeDetstvo .top-section .flex_cont .left_yd {
        align-items: center; }
      @media screen and (max-width: 768px) {
        .yarkoeDetstvo .top-section .flex_cont .right_yd {
          justify-content: center; } }
      .yarkoeDetstvo .top-section .flex_cont .right_yd .top-img {
        margin-top: -80px;
        width: 514px;
        height: auto;
        z-index: -1; }
        @media screen and (min-width: 940px) and (max-width: 985px) {
          .yarkoeDetstvo .top-section .flex_cont .right_yd .top-img {
            width: 464px; } }
        @media screen and (min-width: 768px) and (max-width: 940px) {
          .yarkoeDetstvo .top-section .flex_cont .right_yd .top-img {
            width: 404px; } }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .top-section .flex_cont .right_yd .top-img {
            margin-top: -130px;
            width: 414px;
            max-width: 100%; } }
  .yarkoeDetstvo .flex_cont {
    display: flex; }
  .yarkoeDetstvo .left_yd {
    display: flex; }
  .yarkoeDetstvo .right_yd {
    display: flex; }
  .yarkoeDetstvo .main-header {
    font-size: 28px;
    line-height: 36px;
    color: #333F48;
    margin: 10px 0 30px; }
    @media screen and (max-width: 768px) {
      .yarkoeDetstvo .main-header {
        font-size: 25px;
        line-height: 32px; } }
  .yarkoeDetstvo .green-text {
    color: #21a038; }
  .yarkoeDetstvo .flex {
    display: flex;
    flex-direction: column; }
  .yarkoeDetstvo .flex-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px; }
  .yarkoeDetstvo .section-gentest {
    margin-top: -165px;
    background: #f9f9f9;
    z-index: 2; }
    @media screen and (min-width: 940px) and (max-width: 985px) {
      .yarkoeDetstvo .section-gentest {
        margin-top: -150px; } }
    @media screen and (min-width: 768px) and (max-width: 940px) {
      .yarkoeDetstvo .section-gentest {
        margin-top: -100px; } }
    @media screen and (max-width: 768px) {
      .yarkoeDetstvo .section-gentest {
        margin-top: -95px; } }
    .yarkoeDetstvo .section-gentest .container {
      flex-direction: column; }
      .yarkoeDetstvo .section-gentest .container .subhead_box {
        position: relative;
        width: fit-content;
        margin: 26px 0;
        padding: 10px 20px; }
      .yarkoeDetstvo .section-gentest .container .arrow_box {
        position: relative;
        background: #21a038; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-gentest .container .arrow_box {
            background: transparent; } }
      .yarkoeDetstvo .section-gentest .container .arrow_box:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(33, 160, 56, 0);
        border-left-color: #21a038;
        border-width: 28px;
        margin-top: -28px; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-gentest .container .arrow_box:after {
            display: none; } }
      .yarkoeDetstvo .section-gentest .container .helps {
        font-size: 28px;
        line-height: 36px;
        color: #ffffff !important;
        margin-bottom: 0; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-gentest .container .helps {
            color: #21a038 !important; } }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-gentest .container .helps {
            font-size: 25px;
            line-height: 32px; } }
      .yarkoeDetstvo .section-gentest .container .flex {
        flex-direction: row; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-gentest .container .flex {
            flex-direction: column; } }
        .yarkoeDetstvo .section-gentest .container .flex .flex-item {
          flex-direction: column;
          margin-right: 30px; }
          @media screen and (max-width: 768px) {
            .yarkoeDetstvo .section-gentest .container .flex .flex-item {
              flex-direction: row;
              margin-right: 0; } }
          .yarkoeDetstvo .section-gentest .container .flex .flex-item .item-text {
            padding-top: 10px;
            text-align: center;
            font-size: 17px;
            line-height: 22px;
            color: #333F48; }
            @media screen and (max-width: 768px) {
              .yarkoeDetstvo .section-gentest .container .flex .flex-item .item-text {
                padding-top: 0;
                padding-left: 23px;
                text-align: left; } }
  .yarkoeDetstvo .subhead {
    font-size: 20px;
    line-height: 26px;
    color: #21a038; }
  .yarkoeDetstvo .text {
    font-size: 17px;
    line-height: 22px;
    color: #333F48; }
  .yarkoeDetstvo .item-image {
    width: 42px;
    height: 55px; }
  .yarkoeDetstvo .grafite-text {
    color: #333f48; }
  .yarkoeDetstvo .section-command {
    background-color: #ebf1f8;
    padding-top: 40px;
    padding-bottom: 30px; }
    .yarkoeDetstvo .section-command .container {
      flex-direction: column; }
      .yarkoeDetstvo .section-command .container .subhead-one {
        padding-bottom: 24px;
        font-size: 25px;
        line-height: 32px; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-command .container .subhead-one {
            font-size: 20px;
            line-height: 26px; } }
  .yarkoeDetstvo .head-command {
    padding-bottom: 0; }
  .yarkoeDetstvo .item-command {
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    position: relative;
    padding: 10px 0 0 15px; }
  .yarkoeDetstvo .item-command:before {
    position: absolute;
    content: '';
    top: 16px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #21a038;
    border-radius: 50%; }
  .yarkoeDetstvo .section-contract {
    padding: 34px 0 0; }
    .yarkoeDetstvo .section-contract .container {
      flex-direction: column; }
      .yarkoeDetstvo .section-contract .container .subhead-contract {
        font-size: 28px;
        line-height: 36px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-contract .container .subhead-contract {
            font-size: 25px;
            line-height: 32px; } }
      .yarkoeDetstvo .section-contract .container .contract-phone {
        max-width: 310px; }
      .yarkoeDetstvo .section-contract .container .flex_cont {
        flex-direction: row; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-contract .container .flex_cont {
            flex-direction: column; } }
        .yarkoeDetstvo .section-contract .container .flex_cont .left_yd {
          flex-direction: column;
          align-items: center; }
        .yarkoeDetstvo .section-contract .container .flex_cont .right_yd {
          align-items: center; }
  .yarkoeDetstvo .contract-text {
    text-align: center;
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    padding: 26px 0 14px; }
  .yarkoeDetstvo .section-survey {
    padding: 40px; }
    @media screen and (max-width: 768px) {
      .yarkoeDetstvo .section-survey {
        padding: 40px 0 0; } }
    .yarkoeDetstvo .section-survey .container {
      flex-direction: column; }
      .yarkoeDetstvo .section-survey .container .subhead {
        padding-bottom: 24px; }
      .yarkoeDetstvo .section-survey .container .subhead-survey {
        font-size: 25px;
        line-height: 32px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .yarkoeDetstvo .section-survey .container .subhead-survey {
            font-size: 20px;
            line-height: 26px; } }
    .yarkoeDetstvo .section-survey .item-text {
      padding-left: 23px;
      text-align: left;
      font-size: 17px;
      line-height: 22px;
      color: #333F48; }
  .yarkoeDetstvo .item-point {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #058dc0;
    background: linear-gradient(45deg, #058dc0 0%, #5bcc29 100%);
    display: flex;
    justify-content: center;
    font-size: 17px;
    line-height: 43px;
    color: #fff; }
  .yarkoeDetstvo .mail {
    font-size: 25px;
    line-height: 32px;
    color: #333F48;
    text-align: center;
    text-decoration: none;
    border-bottom: 1px solid transparent; }
  .yarkoeDetstvo .text-decorate {
    text-decoration: underline;
    font-size: 20px;
    line-height: 26px;
    color: #21a038; }
  .yarkoeDetstvo .section-take {
    background: #efefef; }
    .yarkoeDetstvo .section-take .container {
      flex-direction: column; }
      .yarkoeDetstvo .section-take .container .b-wrapper-new {
        width: auto;
        margin: auto;
        position: relative; }
    .yarkoeDetstvo .section-take .form {
      margin: 40px auto;
      max-width: 410px;
      background: #058dc0;
      background: linear-gradient(45deg, #058dc0 0%, #5bcc29 100%);
      padding: 24px 20px 30px;
      box-shadow: 0px 4px 10px 0px rgba(7, 144, 178, 0.5); }
      .yarkoeDetstvo .section-take .form .form_head {
        font-size: 25px;
        line-height: 32px;
        color: #fff;
        padding-bottom: 10px; }
      .yarkoeDetstvo .section-take .form .inputBlock {
        margin-top: 20px; }
      .yarkoeDetstvo .section-take .form .inputBlock label {
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        letter-spacing: 0.02em; }
      .yarkoeDetstvo .section-take .form .inputBlock input {
        margin-top: 8px;
        width: 100%;
        background: #fff;
        border: 0;
        outline: 0;
        height: 40px;
        font-size: 15px;
        line-height: 19px;
        color: #333F48;
        padding: 0 10px;
        box-sizing: border-box; }
      .yarkoeDetstvo .section-take .form .form_btn {
        height: 60px;
        width: 100%;
        max-width: 410px;
        border-radius: 4px;
        background: #000;
        border: 1px solid #000;
        cursor: pointer;
        transition: .5s;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #fff;
        margin-top: 40px; }
  .yarkoeDetstvo .section-info {
    color: #333f48;
    padding: 45px 0; }
    .yarkoeDetstvo .section-info .container {
      justify-content: left; }
    .yarkoeDetstvo .section-info .rules {
      text-decoration: none;
      color: #333f48;
      font-size: 20px;
      line-height: 24px;
      position: relative;
      padding-left: 33px;
      border-bottom: 1px solid transparent !important; }
      @media screen and (max-width: 768px) {
        .yarkoeDetstvo .section-info .rules {
          font-size: 14px;
          line-height: 18px; } }
    .yarkoeDetstvo .section-info .rules:before {
      position: absolute;
      content: '';
      background: url(../../i/pages/yarkoe-detstvo-rules-img.svg) no-repeat;
      top: 0px;
      left: 0;
      width: 22px;
      height: 26px; }
      @media screen and (max-width: 768px) {
        .yarkoeDetstvo .section-info .rules:before {
          top: -5px; } }
  .yarkoeDetstvo .section-questions {
    color: #333F48;
    padding-bottom: 40px; }
    .yarkoeDetstvo .section-questions .container {
      flex-direction: column; }
      .yarkoeDetstvo .section-questions .container .subhead {
        color: #333F48; }
  .yarkoeDetstvo .spoiler-body {
    display: none;
    font-size: 17px;
    line-height: 22px; }
  .yarkoeDetstvo .spoiler-links {
    cursor: pointer;
    font-weight: 400;
    margin: 30px 0 5px;
    position: relative;
    font-size: 17px;
    line-height: 22px;
    color: #333F48; }
  .yarkoeDetstvo .subhead.spoiler-links {
    margin: 0; }
  .yarkoeDetstvo .spoiler-arrow {
    position: absolute;
    top: 4px;
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #333f48 transparent transparent transparent; }

.childrensDay {
  font-family: 'SBSansDisplay', sans-serif;
  max-width: 1920px;
  margin: 0 auto;
  /***HEADER***/
  /***MAIN***/
  /***SPOILER***/ }
  .childrensDay h1 {
    font-family: 'SBSansDisplay', sans-serif; }
  .childrensDay .container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    position: relative; }
  .childrensDay .header-img {
    max-width: 100%;
    width: auto;
    min-width: 320px; }
  .childrensDay .top-section .flex_cont {
    justify-content: space-between;
    align-items: stretch;
    width: 100%; }
    @media screen and (max-width: 768px) {
      .childrensDay .top-section .flex_cont {
        flex-direction: column; } }
    .childrensDay .top-section .flex_cont .left_yd {
      align-items: center; }
    .childrensDay .top-section .flex_cont .right_yd .top-img {
      width: 514px;
      height: auto;
      z-index: -1; }
      @media screen and (min-width: 940px) and (max-width: 985px) {
        .childrensDay .top-section .flex_cont .right_yd .top-img {
          width: 464px; } }
      @media screen and (min-width: 768px) and (max-width: 940px) {
        .childrensDay .top-section .flex_cont .right_yd .top-img {
          width: 404px; } }
      @media screen and (max-width: 768px) {
        .childrensDay .top-section .flex_cont .right_yd .top-img {
          width: 414px;
          max-width: 100%; } }
  .childrensDay .flex_cont {
    display: flex; }
  .childrensDay .left_yd {
    display: flex; }
  .childrensDay .right_yd {
    display: flex; }
  .childrensDay .main-header {
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    color: #333F48;
    margin: 10px 0 30px; }
    @media screen and (max-width: 768px) {
      .childrensDay .main-header {
        font-size: 25px;
        line-height: 32px; } }
  .childrensDay .green-text {
    color: #21a038; }
    .childrensDay .green-text--bold {
      font-size: 40px;
      line-height: 45px; }
      @media screen and (max-width: 768px) {
        .childrensDay .green-text--bold {
          font-size: 30px;
          line-height: 40px; } }
  .childrensDay .flex {
    display: flex;
    flex-direction: column;
    max-width: none; }
  .childrensDay .flex-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px; }
    @media screen and (max-width: 480px) {
      .childrensDay .flex-item {
        margin-bottom: 20px; } }
  .childrensDay .section-gentest {
    padding-top: 90px;
    padding-bottom: 40px;
    background: #ffffff;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .childrensDay .section-gentest {
        padding-top: 32px; } }
    .childrensDay .section-gentest__flex {
      display: flex;
      margin-bottom: 48px; }
      @media screen and (max-width: 1023px) {
        .childrensDay .section-gentest__flex {
          flex-direction: column; } }
    .childrensDay .section-gentest__flex-el:first-child {
      flex-shrink: 0;
      width: 40%;
      margin-right: 24px; }
      @media screen and (max-width: 1023px) {
        .childrensDay .section-gentest__flex-el:first-child {
          width: 100%;
          margin-right: 0;
          margin-bottom: 24px; } }
    .childrensDay .section-gentest .container {
      flex-direction: column; }
      .childrensDay .section-gentest .container .subhead_box {
        position: relative;
        width: fit-content;
        margin: 26px 0;
        padding: 10px 20px; }
      .childrensDay .section-gentest .container .arrow_box {
        position: relative;
        background: #21a038; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-gentest .container .arrow_box {
            background: transparent; } }
      .childrensDay .section-gentest .container .arrow_box:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(33, 160, 56, 0);
        border-left-color: #21a038;
        border-width: 28px;
        margin-top: -28px; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-gentest .container .arrow_box:after {
            display: none; } }
      .childrensDay .section-gentest .container .helps {
        font-size: 28px;
        line-height: 36px;
        color: #ffffff !important;
        margin-bottom: 0; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-gentest .container .helps {
            color: #21a038 !important; } }
        @media screen and (max-width: 768px) {
          .childrensDay .section-gentest .container .helps {
            font-size: 25px;
            line-height: 32px; } }
      .childrensDay .section-gentest .container .item-text--mb12 {
        margin-bottom: 12px; }
      .childrensDay .section-gentest .container .item-text--mb48 {
        margin-bottom: 48px; }
      .childrensDay .section-gentest .container .flex {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-gentest .container .flex {
            flex-direction: column; } }
        .childrensDay .section-gentest .container .flex .flex-item {
          width: 48%;
          align-items: stretch;
          margin-bottom: 0; }
          @media screen and (max-width: 768px) {
            .childrensDay .section-gentest .container .flex .flex-item {
              width: 100%; } }
          .childrensDay .section-gentest .container .flex .flex-item .flex-item__left {
            margin-right: 24px;
            padding-top: 12px; }
          .childrensDay .section-gentest .container .flex .flex-item .flex-item__right {
            padding: 14px 0 32px;
            border-top: 2px solid #EEF3FF; }
            @media screen and (max-width: 768px) {
              .childrensDay .section-gentest .container .flex .flex-item .flex-item__right {
                border-top: none;
                border-bottom: 2px solid #EEF3FF; } }
          .childrensDay .section-gentest .container .flex .flex-item .item-text {
            font-size: 17px;
            line-height: 22px;
            color: #333F48; }
          .childrensDay .section-gentest .container .flex .flex-item .green-text {
            color: #21A038; }
        .childrensDay .section-gentest .container .flex .flex-item:nth-child(3) .flex-item__right {
          border-bottom: 2px solid #EEF3FF; }
        .childrensDay .section-gentest .container .flex .flex-item:nth-child(4) .flex-item__right {
          border-bottom: 2px solid #EEF3FF; }
          @media screen and (max-width: 768px) {
            .childrensDay .section-gentest .container .flex .flex-item:nth-child(4) .flex-item__right {
              border-bottom: none; } }
      .childrensDay .section-gentest .container .item-image {
        width: 32px;
        height: auto; }
  .childrensDay .subhead {
    font-size: 20px;
    line-height: 26px;
    color: #21a038; }
  .childrensDay .text {
    font-size: 17px;
    line-height: 22px;
    color: #333F48; }
  .childrensDay .item-image {
    width: 42px;
    height: 55px; }
  .childrensDay .grafite-text {
    color: #333f48; }
  .childrensDay .section-command {
    position: relative;
    background-color: #ebf1f8;
    padding-top: 70px;
    padding-bottom: 30px; }
    .childrensDay .section-command .container {
      flex-direction: column; }
      .childrensDay .section-command .container .subhead-one {
        font-size: 25px;
        line-height: 32px; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-command .container .subhead-one {
            font-size: 20px;
            line-height: 26px; } }
    .childrensDay .section-command .command-flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      @media screen and (max-width: 768px) {
        .childrensDay .section-command .command-flex {
          flex-direction: column; } }
    .childrensDay .section-command .command__img-box {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      padding: 18px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15); }
    .childrensDay .section-command .command-flex__img {
      width: 100%;
      height: auto; }
  .childrensDay .head-command {
    padding-bottom: 0; }
  .childrensDay .item-command {
    width: 48%;
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    position: relative;
    margin-bottom: 12px;
    padding: 10px 0 15px 15px;
    border-bottom: 1px solid #fff; }
    @media screen and (max-width: 768px) {
      .childrensDay .item-command {
        width: 100%; } }
  .childrensDay .item-command:before {
    position: absolute;
    content: '';
    top: 16px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #21a038;
    border-radius: 50%; }
  .childrensDay .section-contract {
    padding: 34px 0 0; }
    .childrensDay .section-contract .container {
      flex-direction: column; }
      .childrensDay .section-contract .container .subhead-contract {
        font-size: 28px;
        line-height: 36px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-contract .container .subhead-contract {
            font-size: 25px;
            line-height: 32px; } }
      .childrensDay .section-contract .container .contract-phone {
        max-width: 310px; }
      .childrensDay .section-contract .container .flex_cont {
        flex-direction: row; }
        @media screen and (max-width: 1023px) {
          .childrensDay .section-contract .container .flex_cont {
            flex-direction: column; } }
        .childrensDay .section-contract .container .flex_cont .left_yd {
          flex-direction: column;
          align-items: center; }
        .childrensDay .section-contract .container .flex_cont .right_yd {
          flex-direction: column;
          align-items: center; }
    .childrensDay .section-contract .form {
      margin: 40px auto;
      max-width: 410px;
      background: #058dc0;
      background: linear-gradient(45deg, #058dc0 0%, #5bcc29 100%);
      padding: 24px 20px 30px;
      box-shadow: 0px 4px 10px 0px rgba(7, 144, 178, 0.5); }
      @media screen and (max-width: 480px) {
        .childrensDay .section-contract .form {
          max-width: none;
          margin: 0 -20px; } }
      .childrensDay .section-contract .form .form_head {
        font-size: 25px;
        line-height: 32px;
        color: #fff;
        padding-bottom: 10px; }
      .childrensDay .section-contract .form .inputBlock {
        margin-top: 20px; }
      .childrensDay .section-contract .form .inputBlock label {
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        letter-spacing: 0.02em; }
      .childrensDay .section-contract .form .inputBlock input {
        margin-top: 8px;
        width: 100%;
        background: #fff;
        border: 0;
        outline: 0;
        height: 40px;
        font-size: 15px;
        line-height: 19px;
        color: #333F48;
        padding: 0 10px;
        box-sizing: border-box; }
      .childrensDay .section-contract .form .form_btn {
        height: 60px;
        width: 100%;
        max-width: 410px;
        border-radius: 4px;
        background: #000;
        border: 1px solid #000;
        cursor: pointer;
        transition: .5s;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #fff;
        margin-top: 40px; }
  .childrensDay .contract-text {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 30px;
    color: #333F48; }
  .childrensDay .section-survey {
    padding: 40px; }
    @media screen and (max-width: 768px) {
      .childrensDay .section-survey {
        padding: 0; } }
    .childrensDay .section-survey .container {
      flex-direction: column; }
      .childrensDay .section-survey .container .subhead {
        padding-bottom: 24px; }
      .childrensDay .section-survey .container .subhead-survey {
        font-size: 25px;
        line-height: 32px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .childrensDay .section-survey .container .subhead-survey {
            font-size: 20px;
            line-height: 26px; } }
    .childrensDay .section-survey .item-text {
      padding-left: 23px;
      text-align: left;
      font-size: 17px;
      line-height: 22px;
      color: #333F48; }
  .childrensDay .item-point {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #058dc0;
    background: linear-gradient(45deg, #058dc0 0%, #5bcc29 100%);
    display: flex;
    justify-content: center;
    font-size: 17px;
    line-height: 43px;
    color: #fff; }
  .childrensDay .mail {
    margin-bottom: 50px;
    font-size: 25px;
    line-height: 32px;
    color: #333F48;
    text-decoration: none;
    border-bottom: 1px solid transparent; }
  .childrensDay .text-decorate {
    text-decoration: underline;
    font-size: 20px;
    line-height: 26px;
    color: #21a038; }
  .childrensDay .section-take {
    padding-top: 58px;
    background: #EEF3FF; }
    .childrensDay .section-take__flex {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 768px) {
        .childrensDay .section-take__flex {
          flex-direction: column-reverse;
          align-items: center; } }
    .childrensDay .section-take .container {
      flex-direction: column; }
      .childrensDay .section-take .container .b-wrapper-new {
        width: auto;
        margin: auto;
        position: relative; }
    .childrensDay .section-take .contract-phone {
      max-width: 310px; }
      @media screen and (max-width: 768px) {
        .childrensDay .section-take .contract-phone {
          margin-top: 24px; } }
  .childrensDay .section-info {
    color: #333f48;
    padding: 45px 0; }
    .childrensDay .section-info .container {
      flex-direction: column;
      justify-content: left; }
    .childrensDay .section-info .rules {
      text-decoration: none;
      color: #333f48;
      font-size: 20px;
      line-height: 24px;
      position: relative;
      padding-left: 33px;
      border-bottom: 1px solid transparent !important; }
      @media screen and (max-width: 768px) {
        .childrensDay .section-info .rules {
          font-size: 14px;
          line-height: 18px; } }
    .childrensDay .section-info .rules:before {
      position: absolute;
      content: '';
      background: url(../../i/pages/yarkoe-detstvo-rules-img.svg) no-repeat;
      top: 0px;
      left: 0;
      width: 22px;
      height: 26px; }
      @media screen and (max-width: 768px) {
        .childrensDay .section-info .rules:before {
          top: -5px; } }
  .childrensDay .section-questions {
    color: #333F48;
    padding-bottom: 40px; }
    .childrensDay .section-questions .container {
      flex-direction: column; }
      .childrensDay .section-questions .container .subhead {
        color: #333F48; }
  .childrensDay .spoiler-body {
    display: none;
    font-size: 17px;
    line-height: 22px; }
  .childrensDay .spoiler-links {
    cursor: pointer;
    font-weight: 400;
    margin: 30px 0 5px;
    position: relative;
    font-size: 17px;
    line-height: 22px;
    color: #333F48; }
  .childrensDay .subhead.spoiler-links {
    margin: 0; }
  .childrensDay .spoiler-arrow {
    position: absolute;
    top: 4px;
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #333f48 transparent transparent transparent; }
  .childrensDay h2.subtitle {
    font-family: 'SBSansDisplay', sans-serif;
    font-weight: 600;
    margin-bottom: 55px;
    font-size: 28px;
    line-height: 36px;
    color: #21A038;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .childrensDay h2.subtitle {
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 24px; } }
  .childrensDay p.text-promo {
    width: 48%;
    padding: 10px 0 0 15px;
    font-family: 'SBSansDisplay', sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: #333f48; }
    @media screen and (max-width: 768px) {
      .childrensDay p.text-promo {
        width: 100%; } }
  .childrensDay .text-note {
    font-size: 16px;
    line-height: 24px;
    color: #808080; }
  .childrensDay .text-note--mb48 {
    margin-bottom: 48px; }

.giftSbermarket {
  font-family: 'SBSansDisplay', sans-serif;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 150px;
  /***HEADER***/
  /***MAIN***/
  /***SPOILER***/ }
  .giftSbermarket h1 {
    font-family: 'SBSansDisplay', sans-serif; }
  .giftSbermarket .container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    position: relative; }
  .giftSbermarket .header-img {
    max-width: 100%;
    width: auto;
    min-width: 320px; }
  .giftSbermarket .top-section .flex_cont {
    justify-content: space-between;
    align-items: stretch;
    width: 100%; }
    @media screen and (max-width: 768px) {
      .giftSbermarket .top-section .flex_cont {
        flex-direction: column; } }
    .giftSbermarket .top-section .flex_cont .left_yd {
      align-items: center; }
    .giftSbermarket .top-section .flex_cont .right_yd .top-img {
      width: 514px;
      height: auto;
      z-index: -1; }
      @media screen and (min-width: 940px) and (max-width: 985px) {
        .giftSbermarket .top-section .flex_cont .right_yd .top-img {
          width: 464px; } }
      @media screen and (min-width: 768px) and (max-width: 940px) {
        .giftSbermarket .top-section .flex_cont .right_yd .top-img {
          width: 404px; } }
      @media screen and (max-width: 768px) {
        .giftSbermarket .top-section .flex_cont .right_yd .top-img {
          width: 414px;
          max-width: 100%; } }
  .giftSbermarket .flex_cont {
    display: flex; }
  .giftSbermarket .left_yd {
    display: flex; }
  .giftSbermarket .right_yd {
    display: flex; }
  .giftSbermarket .main-header {
    font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    color: #333F48;
    margin: 10px 0 30px; }
    @media screen and (max-width: 768px) {
      .giftSbermarket .main-header {
        font-size: 25px;
        line-height: 32px; } }
  .giftSbermarket .green-text {
    color: #21a038; }
    .giftSbermarket .green-text--bold {
      font-size: 40px;
      line-height: 45px; }
      @media screen and (max-width: 768px) {
        .giftSbermarket .green-text--bold {
          font-size: 30px;
          line-height: 40px; } }
  .giftSbermarket .flex {
    display: flex;
    flex-direction: column;
    max-width: none; }
  .giftSbermarket .flex-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px; }
    @media screen and (max-width: 480px) {
      .giftSbermarket .flex-item {
        margin-bottom: 20px; } }
  .giftSbermarket .section-gentest {
    padding-top: 90px;
    padding-bottom: 40px;
    background: #eef3ff;
    z-index: 2; }
    @media screen and (max-width: 768px) {
      .giftSbermarket .section-gentest {
        padding-top: 32px; } }
    .giftSbermarket .section-gentest__flex {
      display: flex;
      margin-bottom: 48px; }
      @media screen and (max-width: 1023px) {
        .giftSbermarket .section-gentest__flex {
          flex-direction: column; } }
    .giftSbermarket .section-gentest__flex-el:first-child {
      flex-shrink: 0;
      width: 40%;
      margin-right: 24px; }
      @media screen and (max-width: 1023px) {
        .giftSbermarket .section-gentest__flex-el:first-child {
          width: 100%;
          margin-right: 0;
          margin-bottom: 24px; } }
    .giftSbermarket .section-gentest .container {
      flex-direction: column; }
      .giftSbermarket .section-gentest .container .subhead_box {
        position: relative;
        width: fit-content;
        margin: 26px 0;
        padding: 10px 20px; }
      .giftSbermarket .section-gentest .container .arrow_box {
        position: relative;
        background: #21a038; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-gentest .container .arrow_box {
            background: transparent; } }
      .giftSbermarket .section-gentest .container .arrow_box:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(33, 160, 56, 0);
        border-left-color: #21a038;
        border-width: 28px;
        margin-top: -28px; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-gentest .container .arrow_box:after {
            display: none; } }
      .giftSbermarket .section-gentest .container .helps {
        font-size: 28px;
        line-height: 36px;
        color: #ffffff !important;
        margin-bottom: 0; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-gentest .container .helps {
            color: #21a038 !important; } }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-gentest .container .helps {
            font-size: 25px;
            line-height: 32px; } }
      .giftSbermarket .section-gentest .container .item-text--mb12 {
        margin-bottom: 12px; }
      .giftSbermarket .section-gentest .container .item-text--mb48 {
        margin-bottom: 48px; }
      .giftSbermarket .section-gentest .container .flex {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-gentest .container .flex {
            flex-direction: column; } }
        .giftSbermarket .section-gentest .container .flex .flex-item {
          width: 48%;
          align-items: stretch;
          margin-bottom: 0; }
          @media screen and (max-width: 768px) {
            .giftSbermarket .section-gentest .container .flex .flex-item {
              width: 100%; } }
          .giftSbermarket .section-gentest .container .flex .flex-item .flex-item__left {
            margin-right: 24px;
            padding-top: 12px; }
          .giftSbermarket .section-gentest .container .flex .flex-item .flex-item__right {
            padding: 14px 0 32px;
            border-top: 2px solid #EEF3FF; }
            @media screen and (max-width: 768px) {
              .giftSbermarket .section-gentest .container .flex .flex-item .flex-item__right {
                border-top: none;
                border-bottom: 2px solid #EEF3FF; } }
          .giftSbermarket .section-gentest .container .flex .flex-item .item-text {
            font-size: 17px;
            line-height: 22px;
            color: #333F48; }
          .giftSbermarket .section-gentest .container .flex .flex-item .green-text {
            color: #21A038; }
        .giftSbermarket .section-gentest .container .flex .flex-item:nth-child(3) .flex-item__right {
          border-bottom: 2px solid #EEF3FF; }
        .giftSbermarket .section-gentest .container .flex .flex-item:nth-child(4) .flex-item__right {
          border-bottom: 2px solid #EEF3FF; }
          @media screen and (max-width: 768px) {
            .giftSbermarket .section-gentest .container .flex .flex-item:nth-child(4) .flex-item__right {
              border-bottom: none; } }
      .giftSbermarket .section-gentest .container .item-image {
        width: 32px;
        height: auto; }
  .giftSbermarket .subhead {
    font-size: 20px;
    line-height: 26px;
    color: #21a038; }
  .giftSbermarket .text {
    font-size: 17px;
    line-height: 22px;
    color: #333F48; }
  .giftSbermarket .item-image {
    width: 42px;
    height: 55px; }
  .giftSbermarket .grafite-text {
    color: #333f48; }
  .giftSbermarket .section-command {
    position: relative;
    background-color: #ebf1f8;
    padding-top: 70px;
    padding-bottom: 30px; }
    .giftSbermarket .section-command .container {
      flex-direction: column; }
      .giftSbermarket .section-command .container .subhead-one {
        font-size: 25px;
        line-height: 32px; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-command .container .subhead-one {
            font-size: 20px;
            line-height: 26px; } }
    .giftSbermarket .section-command .command-flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      @media screen and (max-width: 768px) {
        .giftSbermarket .section-command .command-flex {
          flex-direction: column; } }
    .giftSbermarket .section-command .command__img-box {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      padding: 18px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15); }
    .giftSbermarket .section-command .command-flex__img {
      width: 100%;
      height: auto; }
  .giftSbermarket .head-command {
    padding-bottom: 0; }
  .giftSbermarket .item-command {
    width: 48%;
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    position: relative;
    margin-bottom: 12px;
    padding: 10px 0 15px 15px;
    border-bottom: 1px solid #fff; }
    @media screen and (max-width: 768px) {
      .giftSbermarket .item-command {
        width: 100%; } }
  .giftSbermarket .item-command:before {
    position: absolute;
    content: '';
    top: 16px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #21a038;
    border-radius: 50%; }
  .giftSbermarket .section-contract {
    padding: 34px 0 0; }
    .giftSbermarket .section-contract .container {
      flex-direction: column; }
      .giftSbermarket .section-contract .container .subhead-contract {
        font-size: 28px;
        line-height: 36px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-contract .container .subhead-contract {
            font-size: 25px;
            line-height: 32px; } }
        .giftSbermarket .section-contract .container .subhead-contract--start {
          text-align: start; }
      .giftSbermarket .section-contract .container .contract-phone {
        max-width: 310px; }
      .giftSbermarket .section-contract .container .flex_cont {
        flex-direction: row; }
        @media screen and (max-width: 1023px) {
          .giftSbermarket .section-contract .container .flex_cont {
            flex-direction: column; } }
        .giftSbermarket .section-contract .container .flex_cont .left_yd {
          flex-direction: column;
          align-items: center; }
        .giftSbermarket .section-contract .container .flex_cont .right_yd {
          flex-direction: column;
          align-items: center; }
    .giftSbermarket .section-contract .form {
      margin: 40px auto;
      max-width: 410px;
      background: #058dc0;
      background: linear-gradient(45deg, #058dc0 0%, #5bcc29 100%);
      padding: 24px 20px 30px;
      box-shadow: 0px 4px 10px 0px rgba(7, 144, 178, 0.5); }
      @media screen and (max-width: 480px) {
        .giftSbermarket .section-contract .form {
          max-width: none;
          margin: 0 -20px; } }
      .giftSbermarket .section-contract .form .form_head {
        font-size: 25px;
        line-height: 32px;
        color: #fff;
        padding-bottom: 10px; }
      .giftSbermarket .section-contract .form .inputBlock {
        margin-top: 20px; }
      .giftSbermarket .section-contract .form .inputBlock label {
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        letter-spacing: 0.02em; }
      .giftSbermarket .section-contract .form .inputBlock input {
        margin-top: 8px;
        width: 100%;
        background: #fff;
        border: 0;
        outline: 0;
        height: 40px;
        font-size: 15px;
        line-height: 19px;
        color: #333F48;
        padding: 0 10px;
        box-sizing: border-box; }
      .giftSbermarket .section-contract .form .form_btn {
        height: 60px;
        width: 100%;
        max-width: 410px;
        border-radius: 4px;
        background: #000;
        border: 1px solid #000;
        cursor: pointer;
        transition: .5s;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #fff;
        margin-top: 40px; }
  .giftSbermarket .contract-text {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 30px;
    color: #333F48; }
  .giftSbermarket .section-survey {
    padding: 40px; }
    @media screen and (max-width: 768px) {
      .giftSbermarket .section-survey {
        padding: 0; } }
    .giftSbermarket .section-survey .container {
      flex-direction: column; }
      .giftSbermarket .section-survey .container .subhead {
        padding-bottom: 24px; }
      .giftSbermarket .section-survey .container .subhead-survey {
        font-size: 25px;
        line-height: 32px;
        text-align: center; }
        @media screen and (max-width: 768px) {
          .giftSbermarket .section-survey .container .subhead-survey {
            font-size: 20px;
            line-height: 26px; } }
    .giftSbermarket .section-survey .item-text {
      padding-left: 23px;
      text-align: left;
      font-size: 17px;
      line-height: 22px;
      color: #333F48; }
  .giftSbermarket .item-point {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #058dc0;
    background: linear-gradient(45deg, #058dc0 0%, #5bcc29 100%);
    display: flex;
    justify-content: center;
    font-size: 17px;
    line-height: 43px;
    color: #fff; }
  .giftSbermarket .mail {
    margin-bottom: 50px;
    font-size: 25px;
    line-height: 32px;
    color: #333F48;
    text-decoration: none;
    border-bottom: 1px solid transparent; }
  .giftSbermarket .text-decorate {
    text-decoration: underline;
    font-size: 20px;
    line-height: 26px;
    color: #21a038; }
  .giftSbermarket .section-take {
    padding-top: 58px;
    background: #EEF3FF; }
    .giftSbermarket .section-take__flex {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media screen and (max-width: 768px) {
        .giftSbermarket .section-take__flex {
          flex-direction: column-reverse;
          align-items: center; } }
    .giftSbermarket .section-take .container {
      flex-direction: column; }
      .giftSbermarket .section-take .container .b-wrapper-new {
        width: auto;
        margin: auto;
        position: relative; }
    .giftSbermarket .section-take .contract-phone {
      max-width: 310px; }
      @media screen and (max-width: 768px) {
        .giftSbermarket .section-take .contract-phone {
          margin-top: 24px; } }
  .giftSbermarket .section-info {
    color: #333f48;
    padding: 45px 0; }
    .giftSbermarket .section-info .container {
      flex-direction: column;
      justify-content: left; }
    .giftSbermarket .section-info .rules {
      text-decoration: none;
      color: #333f48;
      font-size: 20px;
      line-height: 24px;
      position: relative;
      padding-left: 33px;
      border-bottom: 1px solid transparent !important; }
      @media screen and (max-width: 768px) {
        .giftSbermarket .section-info .rules {
          font-size: 14px;
          line-height: 18px; } }
    .giftSbermarket .section-info .rules:before {
      position: absolute;
      content: '';
      background: url(../../i/pages/yarkoe-detstvo-rules-img.svg) no-repeat;
      top: 0px;
      left: 0;
      width: 22px;
      height: 26px; }
      @media screen and (max-width: 768px) {
        .giftSbermarket .section-info .rules:before {
          top: -5px; } }
  .giftSbermarket .section-questions {
    color: #333F48;
    padding-bottom: 40px; }
    .giftSbermarket .section-questions .container {
      flex-direction: column; }
      .giftSbermarket .section-questions .container .subhead {
        color: #333F48; }
  .giftSbermarket .spoiler-body {
    display: none;
    font-size: 17px;
    line-height: 22px; }
  .giftSbermarket .spoiler-links {
    cursor: pointer;
    font-weight: 400;
    margin: 30px 0 5px;
    position: relative;
    font-size: 17px;
    line-height: 22px;
    color: #333F48; }
  .giftSbermarket .subhead.spoiler-links {
    margin: 0; }
  .giftSbermarket .spoiler-arrow {
    position: absolute;
    top: 4px;
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #333f48 transparent transparent transparent; }
  .giftSbermarket h2.subtitle {
    font-family: 'SBSansDisplay', sans-serif;
    font-weight: 600;
    margin-bottom: 55px;
    font-size: 28px;
    line-height: 36px;
    color: #21A038;
    text-align: center; }
    @media screen and (max-width: 768px) {
      .giftSbermarket h2.subtitle {
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 24px; } }
  .giftSbermarket h3.subtitle--long {
    font-weight: 400;
    font-size: 36px;
    line-height: 45px;
    color: #333f48; }
    .giftSbermarket h3.subtitle--long .subtitle__green {
      color: #21A038; }
  .giftSbermarket p.text-promo {
    width: 48%;
    padding: 10px 0 0 15px;
    font-family: 'SBSansDisplay', sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    color: #333f48; }
    @media screen and (max-width: 768px) {
      .giftSbermarket p.text-promo {
        width: 100%; } }
  .giftSbermarket .text-note {
    font-size: 16px;
    line-height: 24px;
    color: #808080; }
  .giftSbermarket .text-note--mb48 {
    margin-bottom: 48px; }

.agents-search {
  padding-bottom: 80px; }
  .agents-search__form {
    margin-bottom: 24px; }
  .agents-search__list {
    margin-bottom: 24px; }
  .agents-search__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 24px;
    border: 1px solid #BDBDBD;
    opacity: 0;
    transform: translate(0, -20px);
    animation: FadeIn 0.5s linear;
    animation-fill-mode: forwards; }
    @media screen and (max-width: 480px) {
      .agents-search__item {
        flex-direction: column;
        align-items: flex-start; } }
    .agents-search__item:not(:last-child) {
      margin-bottom: 10px; }
  @media screen and (max-width: 480px) {
    .agents-search__about {
      margin-bottom: 24px; } }
  .agents-search__name {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 26px;
    color: #21A038; }
  .agents-search__box {
    display: flex; }
    @media screen and (max-width: 768px) {
      .agents-search__box {
        flex-direction: column; } }
  .agents-search__inn {
    margin-right: 15px;
    font-size: 16px;
    line-height: 21px; }
    @media screen and (max-width: 768px) {
      .agents-search__inn {
        margin-right: 0;
        margin-bottom: 8px; } }
  .agents-search__number {
    font-size: 16px;
    line-height: 21px; }
  .agents-search__text {
    margin-bottom: 24px;
    font-size: 16px; }
  .agents-search__info {
    background-color: #f8fafc;
    border-radius: 12px; }
  .agents-search__flex {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .agents-search__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 60px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    text-decoration: none;
    text-align: center;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 4px;
    transition: 0.5s; }
    .agents-search__button:hover {
      color: #000000;
      border-bottom-color: #000000;
      background: #ffffff; }

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: translate(0, -20px); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

.infoPhoneHelper {
  position: relative;
  top: -10px; }
  .infoPhoneHelper .infoIcon {
    cursor: pointer; }
  .infoPhoneHelper:hover .infoPopap {
    display: block; }
  .infoPhoneHelper .infoPopap {
    display: none;
    position: absolute;
    z-index: 222;
    top: -15px;
    left: -20px;
    transform: translate(0, -100%);
    font-size: 16px;
    line-height: 1.2 !important;
    font-weight: normal;
    color: #2b2b2b;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #009942;
    padding: 10px;
    width: 400px; }
    .infoPhoneHelper .infoPopap:before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 25px;
      width: 20px;
      height: 20px;
      border-top: 1px solid #009942;
      border-right: 1px solid #009942;
      background: #fff;
      transform: translate(0, 50%) rotate(135deg); }
  .infoPhoneHelper.down {
    top: 0; }
    .infoPhoneHelper.down .infoPopap {
      top: auto;
      bottom: 5px;
      transform: translate(0, 100%); }
      .infoPhoneHelper.down .infoPopap:before {
        bottom: auto;
        top: -1px;
        transform: translate(0, -50%) rotate(-45deg); }
