.medic {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
  padding-top: 154px;
  padding-bottom: 150px;
  .button_group{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    flex-wrap: wrap;
    button{
      margin: 10px 20px;
      display: inline-block;
      vertical-align: top;
      background: none;
      outline: none;
      position: relative;
      box-sizing: border-box;
      border-radius: 28px;
      font-size: 16px;
      line-height: 29px;
      font-family: $sbFont !important;
      padding: 12px 10px 15px;
      width: 230px;
      color: #fff;
      border: 1px solid #21A038;
      background: #21A038;
      transition: background .25s,border-color .25s,color .25s;
      cursor: pointer;
      box-shadow: 0 0 30px rgba(0,0,0,.2);
      &:hover,.active{
        border-color: #3b955f;
        background: #3b955f;
        color: #fff;
      }
    }
  }
  .step{
    margin: 30px auto 40px;
    &:not(.step0){
      display: none;
    }
    &_head{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      color: #44904b;
      h1{
        margin: 0;
        padding: 0;
        margin-bottom: 60px;
        font-size: 40px;
        line-height: 1.4;
      }
      h2{
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        font-size: 32px;
        line-height: 1.4;
      }
      h3{
        margin: 0;
        margin-top: 20px;
        padding: 0;
        font-size: 20px;
        line-height: 1.4;

      }
    }
    &.step0{
      .step_head{
        align-items: center;
        h3{
          width: 100%;
          text-align: center;
        }
      }
    }
    .b-input__box{
      position: relative;
      .input_options{
        display: none;
        position: absolute;
        z-index: 4;
        bottom: 4px;
        left: 0;
        transform: translate(0, 100%);
        background-color: #fff;
        width: 100%;
        height: 250px;
        overflow-y: scroll;
        border: 1px solid #eeeeee;
        border-top: 0 none;
        border-radius: 0 0 5px 5px;
        padding: 13px 0px 5px;
        font-family: $sbFont !important;
        font-size: 17px;
        line-height: 27px;
        .option{
          padding: 5px 18px;
          border-bottom: 1px solid lightgrey;
          transition: .5s;
          cursor: pointer;
          &:last-child{
            border-bottom: 0 none;
          }
          &:hover,.active{
            background-color: lightgrey;
          }
        }
      }
    }
    .b-input{
      border: 1px solid lightgrey;

    }
    .table{
      margin-top: 30px;
      width: 100%;
      &_head{
        font-weight: bold;
        font-family: $sbFontBold;
        td{
          border-top: 0 none;
          &:first-child{
            width: 200px;
            padding-left: 10px;
          }
          &:nth-child(2){
            padding-right: 10px;
          }
        }
      }
      &_el{
        width: 100%;
        &:nth-child(2n - 1){
          background-color: #eeeeee;
        }
        td{
          padding: 10px 0;
          &:nth-child(1){
            padding-left: 10px;
          }
          &:nth-child(2){
            padding-right: 10px;
          }
        }
      }
    }
  }
}