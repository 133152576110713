.headText{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .calendar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    color: #979797;
    font-size: 16px;
    cursor: pointer;
    svg{
      margin-right: 5px;
    }
  }
}
.js-product-section .js-product-section .headText{
  display: none;
}


.newsSection{
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  &_el{
    width: calc(50% - 20px);
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (max-width: 768px){
      width: 100%;
    }
    &_date{
      color: #979797;
      font-size: 16px;
      margin-bottom: 16px;
    }
    &_head{
      font-size: 20px;
      line-height: 30px;
      color: #000;
      font-weight: normal;
    }
    &_text{
      margin-top: 10px;
      font-size: 16px;
      line-height: 30px;
      color: #000;
      max-height: 175px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 30px;
      strong{
        font-weight: normal !important;
      }
    }
    &_link{
      color: #2B9938;
      font-size: 16px;
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
.b-product__price2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  span{
    font-size: 18px;
    font-weight: 500;
  }
  .btn-green{
    display: block;
    background-color: #2b9938;
    color: #fff;
    font-size: 17px;
    padding: 10px;
    width: calc(100% - 60px)!important;
    margin-top: 10px;
    text-align: center;
    border: 0 none!important;
    border-radius: 5px!important;
    cursor: pointer;
  }
}
