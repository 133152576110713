.si-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 50;

    &--active {
        opacity: 1;
        visibility: visible;

        .si-popup__content {
            transform: translate(0, 0);
            opacity: 1;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        padding: 30px 10px;
    }

    &__content {
        position: relative;
        max-width: 560px;
        padding: 40px 48px 48px;
        background: #ffffff;
        transition: 0.5s;
        transform: translate(0, -100%);
        opacity: 0;

		@media screen and (max-width: 768px) {
			padding: 24px;
		}
    }

    &__title {
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 45px;
        color: #333f48;

		@media screen and (max-width: 768px) {
			font-size: 22px;
            line-height: 33px;
		}

        &--center {
            text-align: center;
        }
    }

    &__text {
        margin: 0 0 10px;
        font-weight: normal;
        color: #000000;
        text-align: center;

        &--no-center {
            text-align: left;
        }
    }

    &__row {
        margin-bottom: 24px;
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

		@media screen and (max-width: 768px) {
			right: 10px;
			top: 10px;
		}

        &-icon {
            fill: #909cb5;
            transition: 0.5s;

			@media screen and (max-width: 768px) {
				width: 18px;
			}

            &:hover {
                fill: #21a038;
            }
        }
    }
}
