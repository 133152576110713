.cell-input {
    width: 100%;
    padding: 0;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: #353E48;
    border: none;
    outline: none;
    background-color: transparent;

    &::placeholder {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        color: #353E48;
    }

    &.error {
        color: #f54848;
    }
}
