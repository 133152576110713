.questions {
    padding: 72px 0;
	background: #e7e6e6;

	@media screen and (max-width: 767px) {
		padding: 40px 0 120px;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		padding-bottom: 32px;
		background: #fff;
		border-bottom: 2px solid #e7e6e6;
	}

    &__box {
        display: flex;
        align-items: center;
        padding: 28px 70px 0 32px;
        transition: 0.5s;
		cursor: pointer;

		@media screen and (max-width: 767px) {
			padding: 16px 20px;
		}
    }

    &__text {
        flex-grow: 1;
        margin-right: 30px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.04em;
        transition: 0.5s;

		@media screen and (max-width: 767px) {
			font-size: 12px;
            line-height: 17px;
		}
    }

    &__text-inner {
        margin: 0 0 12px 32px;
    }

    &__arrow {
		flex-shrink: 0;
		position: relative;
        width: 40px;
		height: 40px;
		background: #eef3ff;
		border-radius: 50%;
		transition: 0.5s;
		background: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.5303 9.46967C18.8232 9.76256 18.8232 10.2374 18.5303 10.5303L12.5303 16.5303C12.2374 16.8232 11.7626 16.8232 11.4697 16.5303L5.46967 10.5303C5.17678 10.2374 5.17678 9.76256 5.46967 9.46967C5.76256 9.17678 6.23744 9.17678 6.53033 9.46967L12 14.9393L17.4697 9.46967C17.7626 9.17678 18.2374 9.17678 18.5303 9.46967Z' fill='%23030D45'/%3E%3C/svg%3E") center center no-repeat;
		background-size: contain;

		&.questions__arrow--active {
			transform: rotate(180deg);
		}
		//&::before {
		//	content: "";
		//	position: absolute;
		//	top: 50%;
		//	left: 50%;
		//	transform: translate(-50%, -50%);
		//	width: 20px;
		//	height: 2px;
		//	background: #27C925;
		//}
		//
		//&::after {
		//	content: "";
		//	position: absolute;
		//	top: 50%;
		//	left: 50%;
		//	transform: translate(-50%, -50%);
		//	width: 2px;
		//	height: 20px;
		//	background: #27C925;
		//}

        &--active {
            transform: rotate(-45deg);
        }
    }

    &__answer {
        display: none;
        // margin: 40px 0 60px;
		padding: 28px 32px 0;

		@media screen and (max-width: 767px) {
			font-size: 14px;
            line-height: 25px;
		}
    }

	&__marked-list {
		list-style-type: disc;
		list-style-position: inside;
	}

	&__body {
		padding: 140px 0 0;
		background: #FFF;
	}

	&__title {
		padding: 0 32px;
		font-weight: bold;
		font-size: 20px;
		line-height: 30px;
		letter-spacing: 0.04em;
	}
}
