.chatPage{
  width: 100%;
  background: #F2F2F2;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 183px 20px 52px;
  min-height: 100vh;
  @media screen and (max-width: 750px){
    padding: 113px 10px 30px;
  }
  .chat-retail-icons__container{
    position: fixed !important;
    right: 20px !important;
    top: auto !important;
    bottom: 20px !important;
    z-index: 9999 !important;
    width: 60px !important;
    height: 60px;
    background-color: #08a652;
    border-radius: 50%;
  }
  .chat-retail-icons{
    transition: .5s;
    width: 60px !important;
    height: 60px !important;
    background-color: transparent;
    &.iconPhone{
      background-image: url(../i/chatPage/phone-call.png);
      background-size: 30px;
      background-position: center;
      background-repeat: no-repeat;
      &:hover{
        background-color: #34b76f;
      }
    }
    &.iconSecurety{
      background-image: url(../i/chatPage/security.png);
      background-size: 30px;
      background-position: center;
      background-repeat: no-repeat;
      &:hover{
        background-color: #34b76f;
      }
    }
  }

}
