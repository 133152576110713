.agents-search {
    padding-bottom: 80px;

    &__form {
        margin-bottom: 24px;
    }

    &__list {
        //min-height: 20vh;
		margin-bottom: 24px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 24px;
        border: 1px solid #BDBDBD;
        opacity: 0;
        transform: translate(0, -20px);
        animation: FadeIn 0.5s linear;
        animation-fill-mode: forwards;

        @media screen and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__about {
        @media screen and (max-width: 480px) {
            margin-bottom: 24px;
        }
    }

    &__name {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 26px;
        color: #21A038;
    }

    &__box {
        display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__inn {
        margin-right: 15px;
		font-size: 16px;
		line-height: 21px;

        @media screen and (max-width: 768px) {
            margin-right: 0;
            margin-bottom: 8px;
        }
    }

	&__number {
		font-size: 16px;
		line-height: 21px;
    }

    &__text {
        margin-bottom: 24px;
        font-size: 16px;
    }

    &__info {
        background-color: #f8fafc;
        border-radius: 12px;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 12px 60px;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		color: #ffffff;
		text-decoration: none;
		text-align: center;
		background: #000000;
		border: 1px solid #000000;
		border-radius: 4px;
		transition: 0.5s;

		&:hover {
			color: #000000;
			border-bottom-color: #000000;
			background: #ffffff;
		}
	}
}

@keyframes FadeIn {
    0% {
        opacity: 0;
        transform: translate(0, -20px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
