.proftest{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("/static/i/proftest/bg.png") no-repeat center;
  background-size: cover;
  overflow-y: scroll;
  overflow-x: hidden;
  button{
    cursor: pointer;
  }
}

.popap{
  display: none;
  position: relative;
  min-height: 100px;
  width: 95%;
  max-width: 900px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding: 50px;
  &-logo{
    position: absolute;
    top: 50px;
    left: 50px;
    height: 23px;
    width: auto;
  }
  &-head{
    color: #21A038;
    font-family: $sbFont !important;
    font-weight: bold;
    font-family: $sbFontBold;
    font-size: 38px;
    text-align: center;
    margin-top: 70px;
    line-height: 1;
  }
  &-text{
    color: #000000;
    font-family: $sbFont !important;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .button{
    width: 140px;
    height: 44px;
    border-radius: 22px;
    background-color: #21A038;
    color: #fff;
    font-size: 18px;
  }

}

.popap-start{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .popap-text{
    text-align: center;
  }
}
.popap-questions{
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  display: none;
  .left{
    max-width: 696px;
    width: 50%;
    flex-shrink: 0;
  }
  .right{
    padding: 50px;
    img{
      width: 100%;
      max-width: 400px;
      height: auto;
      object-fit: cover;
    }
  }
  .question{
    font-size: 18px;
    margin-top: 47px;
    padding-left: 15px;
  }
  .answer{
    padding-left: 15px;
    margin-top: 15px;
    &-el{
      padding-bottom: 25px;
      padding-top: 25px;
      border-bottom: 1px solid #dcdcdc;
      padding-left: 40px;
      position: relative;
      cursor: pointer;
      font-size: 18px;
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background: #dcdcdc;
        transition: .5s;
      }
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 12px;
        height: 10px;
        width: 20px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-45deg) translate(0, calc(-50% - 5px));
        transition: .5s;
      }
      &.active,
      &:hover{
        &:before{
          background: #21A038;
        }
      }
      &:last-child{
        border-bottom: 0;
      }
    }
  }
  .btnDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    button{
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      padding: 10px 45px;
      background: transparent;
      border: 0 none;
      box-shadow: none;
      border-radius: 50px;
    }
    .button-back{
      border: 1px solid #21A038;
      color: #21A038;
      &.disabled{
        opacity: 0;
        pointer-events: none;
        cursor: default;
      }
    }
    .button-next{
      color: #fff;
      background: #21A038;
    }
  }
  .testCount{
    position: absolute;
    top: 126px;
    left: -15px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: $sbFontBold;
    padding: 5px 20px;
    background: #21A038;
    display: flex;
    justify-content: center;
    align-items: center;
    .current:after{
      content: '/';
    }
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 0px solid transparent;
      border-right: 15px solid #228049;
      border-bottom: 15px solid transparent;
      transform: translate(0, 100%);
    }
  }
}
.popap-result{
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  display: none;
  .left{
    width: 100%;
    max-width: 700px;
    padding-right: 50px;
  }
  .right{
    padding: 50px;
    img{
      width: 100%;
      max-width: 400px;
      height: auto;
      object-fit: cover;
    }
  }
  .popap-head{
    text-align: left;
    margin-top: 40px;
  }
  .profileHead{
    margin-top: 20px;
    color: #000000;
    font-size: 22px;
    font-weight: 400;
    line-height: 25px;
  }
  .profileDescription{
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 22px;
    overflow: hidden;
    position: relative;
    transition: .5s;
    margin-bottom: 30px;
  }
  .button{
    width: 200px;
  }
}

.proftestNew{
  .popap{
    max-width: 1100px;
    font-family: $sbFont !important;
    &-head{
      font-size: 35px;
      line-height: 1.3;
      text-align: center;
    }
    &-input{
      margin: 20px 0;
      &-block{
        padding: 15px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .input-label{
          color: #000000;
          font-family: $sbFont !important;
          font-size: 20px;
          font-weight: 400;
          display: block;
        }
        .input-input{
          padding: 10px 15px;
          border: 1px solid #21A038;
          border-radius: 5px;
          min-width: 300px;
          color: #21A038;
          font-size: 16px;
          text-align: center;
        }
      }
    }
    .button{
      min-width: 300px;
      white-space: nowrap;
      border: 0 none;
      transition: .5s;
      &:hover{
        background-color: #228049;
      }
    }
    .sendSuccess{
      display: none;
      color: #21A038;
      font-size: 20px;
      text-align: center;
      border-radius: 5px;
      //opacity: 0;
      transition: .5s;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      padding: 50px 100px;
      box-shadow: 0 0 1000px 1000px rgba(#000, 0.4);
      .close{
        position: absolute;
        top: 5px;
        right: 10px;
        color: #333;
        font-size: 30px;
        font-weight: lighter;
        cursor: pointer;
      }
    }
  }
}