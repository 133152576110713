.select{
  position: relative;
  &-choose{
    position: relative;
    width: 100%;
    max-width: 450px;
    cursor: pointer;
    &.active{
      .select-input{
        border-radius: 25px 25px 0 0;
        border-bottom: 0;
      }
      .select-arrow{
        transform: rotate(180deg);
      }
    }
    &:before{
      content: '';
      position: absolute;
      height: calc(100% - 2px);
      width: 30px;
      top: 1px;
      right: 50px;
      background: linear-gradient(-90deg, #fff, rgba(#fff, 0));
      z-index: 4;
    }
  }
  &-input{
    appearance: none;
    padding-right: 50px;
    padding-left: 20px;
    height: 50px;
    width: 100%;
    max-width: 450px;
    border-radius: 50px;
    border: 1px solid #e1e1e1;
    font-size: 19px;
    font-weight: 400;
    pointer-events: none;
    box-shadow: none !important;
  }
  &-arrow{
    position: absolute;
    z-index: 3;
    top: 50%;
    right: 24px;
    transform: translate(0, -50%);
    height: 12px;
    width: 17px;
    transition: .5s;
  }
  &-option{
    position: absolute;
    z-index: 2;
    width: 100%;
    max-width: 450px;
    top: 50px;
    left: 0;
    border: 1px solid #e1e1e1;
    border-top: 0;
    border-radius: 0 0 25px 20px;
    background: #fff;
    display: none;
    max-height: 400px;
    overflow-y: scroll;
    &-el{
      padding: 12px 24px;
      font-size: 19px;
      line-height: 25px;
      border-bottom: 1px solid #e1e1e1;
      cursor: pointer;
      transition: .5s;
      &.active,
      &:hover{
        background: #e1e1e1;
      }
      &:last-child{
        border-bottom: 0 none;
      }
    }
  }
  &.disabled{
    cursor: no-drop;
    .select-choose{
      pointer-events: none;
      opacity: .5;
    }
  }
}
.mapBlock{
  position: relative;
  width: 100%;
  overflow: hidden;
  *{
    box-sizing: border-box;

  }
  &-search{
    width: 100%;
    height: 131px;
    background: #21A038;
    .cnt{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 131px;
      max-width: 1400px;
      margin: 0 auto;
      padding: 0 20px;
      .text{
        width: 100%;
        color: #fff;
        font-size: 24px;
        line-height: 34px;
      }
      .select{
        width: 349px;
        flex-shrink: 0;
        margin-right: 52px;
        &:last-child{
          margin-right: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  .map{
    height: 391px;
    width: 100%;
  }
}