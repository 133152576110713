.FlexWrp {
  padding-top: 165px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .Flex-el {
    width: calc(50% - 20px);
    margin-bottom: 15px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  h1 {
    width: 100%;
  }
}