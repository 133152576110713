.giftSbermarket {
  font-family: 'SBSansDisplay', sans-serif;
  max-width: 1920px;
  margin: 0 auto;
  padding-top: 150px;

  h1 {
	font-family: 'SBSansDisplay', sans-serif;
  }

  /***HEADER***/

  .container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    //min-width: 320px;
    position: relative;
  }

  .content {
    //padding: 0 30px;
    @media screen and (max-width: 768px) {
     // padding: 0 20px;
    }
  }

  .header-img {
    max-width: 100%;
    width: auto;
    min-width: 320px;
  }

  /***MAIN***/

  .top-section {
    .flex_cont {
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .left_yd {
        align-items: center;
      }

      .right_yd {
        .top-img {
          width: 514px;
          height: auto;
          z-index: -1;
          @media screen and (min-width: 940px) and (max-width: 985px) {
            width: 464px;
          }
          @media screen and (min-width: 768px) and (max-width: 940px) {
            width: 404px;
          }
          @media screen and (max-width: 768px) {
            width: 414px;
            max-width: 100%;
          }
        }
      }
    }
  }

  .flex_cont {
    display: flex;
  }

  .left_yd {
    display: flex;
  }

  .right_yd {
    display: flex;
  }

  .main-header {
	font-weight: normal;
    font-size: 36px;
    line-height: 40px;
    color: #333F48;
    margin: 10px 0 30px;
    @media screen and (max-width: 768px) {
      font-size: 25px;
      line-height: 32px;
    }
  }

  .green-text {
    color: #21a038;

	&--bold {
		font-size: 40px;
		line-height: 45px;

		@media screen and (max-width: 768px) {
			font-size: 30px;
			line-height: 40px;
		  }
	}
  }

  .flex {
    display: flex;
    flex-direction: column;
	max-width: none;
  }

  .flex-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;

	@media screen and (max-width: 480px) {
		margin-bottom: 20px;
	}
  }

  .section-gentest {
	padding-top: 90px;
	padding-bottom: 40px;
    background: #eef3ff;
    z-index: 2;

	@media screen and (max-width: 768px) {
		padding-top: 32px;
	}

	&__flex {
		display: flex;
		margin-bottom: 48px;

		@media screen and (max-width: 1023px) {
			flex-direction: column;
		}
	}

	&__flex-el {
		&:first-child {
			flex-shrink: 0;
			width: 40%;
			margin-right: 24px;

			@media screen and (max-width: 1023px) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 24px;
			}
		}
	}
    .container {
      flex-direction: column;
      .subhead_box {
        position: relative;
        //background: #21a038;
        width: fit-content;
        margin: 26px 0;
        padding: 10px 20px;

      }

      .arrow_box {
        position: relative;
        background: #21a038;
        @media screen and (max-width: 768px) {
          background: transparent;
        }
      }
      .arrow_box:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(33, 160, 56, 0);
        border-left-color: #21a038;
        border-width: 28px;
        margin-top: -28px;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .helps {
        //padding:26px 0;
        font-size: 28px;
        line-height: 36px;
        color: #ffffff !important;
        @media screen and (max-width: 768px) {
          color: #21a038 !important;
        }
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
          font-size: 25px;
          line-height: 32px;
        }
      }
	  .item-text--mb12 {
		margin-bottom: 12px;
	  }
	  .item-text--mb48 {
		margin-bottom: 48px;
	  }
      .flex {
        flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .flex-item {
			width: 48%;
			align-items: stretch;
			margin-bottom: 0;
			@media screen and (max-width: 768px) {
				width: 100%;
			  }
		  .flex-item__left {
			margin-right: 24px;
			padding-top: 12px;
		  }
		  .flex-item__right {
			padding: 14px 0 32px;
			border-top: 2px solid #EEF3FF;

			@media screen and (max-width: 768px) {
				border-top: none;
				border-bottom: 2px solid #EEF3FF;
			  }
		  }
          .item-text {
            font-size: 17px;
            line-height: 22px;
            color: #333F48;
          }
		  .green-text {
				color: #21A038;
			}
        }
		.flex-item:nth-child(3) {
			.flex-item__right {
				border-bottom: 2px solid #EEF3FF;
			  }
		}

		.flex-item:nth-child(4) {
			.flex-item__right {
				border-bottom: 2px solid #EEF3FF;

				@media screen and (max-width: 768px) {
					border-bottom: none;
				  }
			  }
		}

      }
	  .item-image {
		width: 32px;
		height: auto;
	  }
    }
  }

  .subhead {
    font-size: 20px;
    line-height: 26px;
    color: #21a038;
  }

  .text {
    font-size: 17px;
    line-height: 22px;
    color: #333F48;
  }

  .item-image {
    width: 42px;
    height: 55px;
  }

  .grafite-text {
    color: #333f48;
  }

  .section-command {
	position: relative;
    background-color: #ebf1f8;
    padding-top: 70px;
    padding-bottom: 30px;
    .container {
      flex-direction: column;
      .subhead-one {
        font-size: 25px;
        line-height: 32px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
	.command-flex {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@media screen and (max-width: 768px) {
			flex-direction: column;
		}
	}

	.command__img-box {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100px;
		height: 100px;
		padding: 18px;
		background-color: #fff;
		border-radius: 50%;
		box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
	}
	.command-flex__img {
		width: 100%;
		height: auto;
	}
  }

  .head-command {
    padding-bottom: 0;
  }

  .item-command {
	width: 48%;
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    position: relative;
	margin-bottom: 12px;
    padding: 10px 0 15px 15px;
	border-bottom: 1px solid #fff;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
  }

  .item-command:before {
    position: absolute;
    content: '';
    top: 16px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #21a038;
    border-radius: 50%;
  }

  .section-contract {
    padding: 34px 0 0;
    .container {
      flex-direction: column;
      .subhead-contract {
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 25px;
          line-height: 32px;
        }

		&--start {
			text-align: start;
		}
      }
      .contract-phone {
        max-width: 310px;
      }
      .flex_cont {
        flex-direction: row;
        @media screen and (max-width: 1023px) {
          flex-direction: column;
        }
        .left_yd {
          flex-direction: column;
          align-items: center;
        }
        .right_yd {
		  flex-direction: column;
          align-items: center;
        }
      }
    }
	.form {
		margin: 40px auto;
		max-width: 410px;
		background: rgb(5,141,192);
		background: linear-gradient(45deg, rgba(5,141,192,1) 0%, rgba(91,204,41,1) 100%);
		padding: 24px 20px 30px;
		box-shadow: 0px 4px 10px 0px rgba(7,144,178, 0.5);

		@media screen and (max-width: 480px) {
			max-width: none;
			margin: 0 -20px;
		  }

	.form_head {
		font-size: 25px;
		line-height: 32px;
		color: #fff;
		padding-bottom: 10px;
	}

	.inputBlock {
		margin-top: 20px;
	}

	.inputBlock label {
		font-size: 14px;
		line-height: 18px;
		color: #fff;
		//font-weight: 400;
		letter-spacing: 0.02em;
	}

	.inputBlock input {
		margin-top: 8px;
		width: 100%;
		background: #fff;
		border: 0;
		outline: 0;
		height: 40px;
		font-size: 15px;
		line-height: 19px;
		color: #333F48;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.form_btn {
		height: 60px;
		width: 100%;
		max-width: 410px;
		border-radius: 4px;
		background: #000;
		border: 1px solid #000;
		cursor: pointer;
		transition: .5s;
		font-size: 20px;
		line-height: 26px;
		letter-spacing: 0.02em;
		text-align: center;
		color: #fff;
		margin-top: 40px;
	}

	}
  }

  .contract-text {
	margin-bottom: 12px;
    font-size: 20px;
    line-height: 30px;
    color: #333F48;
  }

  .section-survey {
    //background-color: #F6FAFE;
    padding: 40px;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    .container {
      flex-direction: column;
      .subhead {
        padding-bottom: 24px;
      }
      .subhead-survey {
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    .item-text {
      padding-left: 23px;
      text-align: left;
      font-size: 17px;
      line-height: 22px;
      color: #333F48;
    }
  }

  .item-point {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(5,141,192);
    background: linear-gradient(45deg, rgba(5,141,192,1) 0%, rgba(91,204,41,1) 100%);
    display: flex;
    justify-content: center;
    font-size: 17px;
    line-height: 43px;
    color: #fff;
  }

  .mail {
	margin-bottom: 50px;
    font-size: 25px;
    line-height: 32px;
    color: #333F48;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }

  .text-decorate {
    text-decoration: underline;
    font-size: 20px;
    line-height: 26px;
    color: #21a038;
    //padding-bottom: 40px;
  }

  .section-take {
	padding-top: 58px;
    background: #EEF3FF;
	&__flex {
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: 768px) {
			flex-direction: column-reverse;
			align-items: center;
		}
	}
    .container {
      flex-direction: column;

      .b-wrapper-new {
        width: auto;
        margin: auto;
        position: relative;
      }
    }
	.contract-phone {
        max-width: 310px;

		@media screen and (max-width: 768px) {
			margin-top: 24px;
		}
      }

  }

  // .section-report {
  //     .container {
  //         flex-direction: column;
  //         .subhead {
  //             padding: 47px 0 68px;
  //         }
  //         .flex-item {
  //             align-items: center;
  //             .link-report {
  //                 text-decoration: none;
  //                 color: #fff;
  //                 font-size: 25px;
  //                 line-height: 32px;
  //             }
  //         }
  //     }
  // }

  .section-info {
    color: #333f48;
    padding: 45px 0;
    .container {
	  flex-direction: column;
      justify-content: left;
    }
    .rules {
      text-decoration: none;
      color: #333f48;
      font-size: 20px;
      line-height: 24px;
      position: relative;
      padding-left: 33px;
      border-bottom: 1px solid transparent !important;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .rules:before {
      position: absolute;
      content: '';
      background: url(../../i/pages/yarkoe-detstvo-rules-img.svg) no-repeat;
      top: 0px;
      @media screen and (max-width: 768px) {
        top: -5px;
      }
      left: 0;
      width: 22px;
      height: 26px;

    }
  }

  /***SPOILER***/

  .section-questions {
    color: #333F48;
    padding-bottom: 40px;
    .container {
      flex-direction: column;
      .subhead {
        color: #333F48;
      }
    }
  }

  .spoiler-body {
    display: none;
    font-size: 17px;
    line-height: 22px;
  }

  .spoiler-links {
    cursor: pointer;
    font-weight: 400;
    margin: 30px 0 5px;
    position: relative;
    font-size: 17px;
    line-height: 22px;
    color: #333F48;
  }

  .subhead.spoiler-links {
    margin: 0;
  }

  .spoiler-arrow {
    position: absolute;
    top: 4px;
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #333f48 transparent transparent transparent;
  }

  h2.subtitle {
	font-family: 'SBSansDisplay', sans-serif;
	font-weight: 600;
	margin-bottom: 55px;
	font-size: 28px;
	line-height: 36px;
	color: #21A038;
	text-align: center;

	@media screen and (max-width: 768px) {
		font-size: 22px;
		line-height: 34px;
		margin-bottom: 24px;
	}
  }

	h3.subtitle--long {
		font-weight: 400;
		font-size: 36px;
		line-height: 45px;
		color: #333f48;

		.subtitle__green {
			color: #21A038;
		}
	}

  p.text-promo {
	width: 48%;
	padding: 10px 0 0 15px;
	font-family: 'SBSansDisplay', sans-serif;
	font-weight: 600;
	font-size: 17px;
	line-height: 26px;
	color: #333f48;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
  }

  .text-note {
	font-size: 16px;
	line-height: 24px;
	color: #808080;
  }

  .text-note--mb48 {
	margin-bottom: 48px;
  }
}
