.cell {
    height: 100%;
    position: relative;
    padding: 0 0 14px 0;
    border-bottom: 1px solid #BDBDBD;
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
    transition: 0.5s;

    &--along {
        margin-bottom: 8px;
    }

    &--margin {
        margin-bottom: 24px;
    }

    &__border-box {
        display: flex;
        width: 100%;
        height: 16px;
    }

    &__border-left {
        flex-shrink: 0;
        width: 12px;
        border-top: 1px solid #BDBDBD;
        transition: 0.5s;
    }

    &__border-content {
        display: flex;
        align-items: center;
        transform: translateY(-50%);
        padding: 0 12px;
    }

    &__border-right {
        flex-grow: 1;
        min-width: 24px;
        border-top: 1px solid #BDBDBD;
        transition: 0.5s;
    }

    &__name {
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #21A038;
        background-color: transparent;
        transition: 0.5s;
    }

    &__data {
        position: relative;
        padding: 0 24px;

		&--prefix {
			padding: 0 24px 0 60px;
		}
    }

	&__prefix-icon {
		position: absolute;
		left: 24px;
		top: 50%;
		transform: translate(0, -50%);
	}

    &__sign {
        display: none;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        color: #21A038;
    }

    &__sign-buffer {
        position: absolute;
        left: -2000px;
        top: -2000px;
        visibility: hidden;
        white-space: nowrap;
    }

    &__error-message {
        position: absolute;
        width: 100%;
        bottom: 3px;
        left: 24px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.04em;
        color: #f54848;
        display: none;
    }

    &--error {
        border-color: #f54848;

        .cell__name {
            color: #f54848;
        }

        .cell__border-left {
            border-color: #f54848;
        }

        .cell__border-right {
            border-color: #f54848;
        }
    }

    &--40 {
        width: 40%;
        margin-right: 16px;
    }

    &__row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;
    }

    &--100 {
        width: 100%;
    }

    &--h-auto {
        height: auto;
    }

    &--50 {
        width: calc(50% - 16px);
        margin-right: 16px;

        &.show {
            display: block;
        }

        &.hide {
            display: none;
        }
    }
}
