.onlineHelper{
  width: 100%;
  background: #F2F2F2;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 183px 20px 52px;
  min-height: 100vh;
  @media screen and (max-width: 750px){
    padding: 113px 10px 30px;
  }

  *{
    box-sizing: border-box;
  }
  ul{
    padding-left: 10px;
  }
  li{
    list-style: circle;
  }
  .rowFlex{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    @media screen and (max-width: 1600px){
      max-width: 1050px;
    }
    @media screen and (max-width: 750px){
      flex-direction: column;
    }

    &.center{
      justify-content: center;
      @media screen and (max-width: 750px){
        align-items: center;
      }
    }
    &.left{
      justify-content: flex-start;
      @media screen and (max-width: 750px){
        align-items: flex-start;
      }
    }
    &.top{
      align-items: flex-start;
    }
    &.btn-block{
      flex-wrap: wrap;
      align-items: stretch;

      .btn{
        margin-left: 15px;
        margin-bottom: 15px;
        white-space: normal;
        width: calc(50% - 15px);
        text-transform: lowercase;
        &:nth-child(2n + 1){
          margin-left: 0;
        }
      }
    }
  }
  &.inclaim{
    .text{
      b{
        font-weight: bold !important;
        font-family: $sbFontBold;
      }
    }
    ul{
      padding-left: 40px;
    }
  }
  .left:not(.rowFlex){
    width: 100%;
  }
  .mainImgRight{
    margin-left: 50px;
    width: 700px;
    height: 700px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 50%;
    &.inclaim {
      border-radius: 0;
    }

    @media screen and (max-width: 1600px){
      width: 400px;
      height: 400px;
    }
    @media screen and (max-width: 1024px){
      display: none;
    }
  }
  .head{
    font-size: 40px;
    color: #000;
    line-height: 1.2;
    max-width: 750px;
    margin-left: 0;
    h2{
      margin: 0;
    }
    @media screen and (max-width: 750px){
      text-align: center;
      font-size: 32px;
    }
    &.name,
    &.series{
      display: none;
      font-size: 20px;
      line-height: 1.4;
    }
  }
  .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    height: auto;
    width: 350px;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    transition: .5s;
    border: 0 none;
    outline: 0 none;
    box-shadow: none;
    flex-shrink: 0;
    @media screen and (max-width: 750px){
      width: 100%;
    }
    &:hover{
      background: darken(#2B9938, 10);
      color: #fff;
    }

    &_green{
      background-color: #2B9938;
      color: #fff;
    }
    &_gray{
      background-color: #DADADA;
      color: #fff;
    }
    &_link{
      background: transparent;
      color: #19BB4F;
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 60px;
      margin-left: 0;
      &:last-child{
        margin-right: 0;
      }
      svg{
        margin-right: 10px;
        path{
          transition: .5s;
        }
      }
      &:hover{
        background: transparent;
        color: darken(#19BB4F, 15);
        svg{
          path {
            fill: darken(#19BB4F, 15)
          }
        }
      }
    }
    &.ml{
      margin-left: 16px;
      @media screen and (max-width: 750px){
        margin-left: 0;
        margin-top: 16px;
      }
    }
    &.mt{
      margin-top: 32px;
      @media screen and (max-width: 750px){
        margin-top: 16px;
      }
    }
    &_back{
      margin-top: 52px;
      display: none;
    }
    &:disabled{
      opacity: 0.5;
      cursor: default;
      pointer-events: none;
      &:hover{
        background: inherit;
      }
    }
  }
  .or{
    font-size: 18px;
    color: #979797;
    margin: 24px 0;
  }
  .input{
    height: 48px;
    width: 100%;
    background: #fff;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    padding: 14px;
    font-size: 16px;
    text-align: left;
    &::placeholder{
      color: #979797;
    }
  }
  .inputFlex{
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .series_order{
      width: 45%;
    }
    .number_order{
      width: calc(55% - 16px);

    }
  }
  .selectInput{
    width: 100%;
    position: relative;
    .selectInputOption{
      //position: absolute;
      //z-index: 2;
      //bottom: 0;
      //left: 0;
      //transform: translate(0, 100%);
      width: 100%;
      max-height: 200px;
      overflow-y: auto;
      background: #fff;
      border: 1px solid #D8D8D8;
      border-radius: 4px;
      .selectInputEl {
        padding: 14px;
        font-size: 16px;
        transition: .5s;
        cursor: pointer;
        &:hover{
          background: #2B9938;
          color: #fff;
        }
      }
    }
  }
  .text{
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 40px;
  }
  .label_green{
    color: #2B9938;
    font-size: 18px;
    margin: 32px 0 16px;
  }
  .label_black{
    color: #000;
    font-size: 18px;
    margin: 32px 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .label_svg_block{
    width: 25px;
    height: 25px;
    margin-left: 16px;
    cursor: pointer;
    position: relative;
    &:hover{
      .lable_title{
        display: block;
      }
    }
  }
  .label_svg{
    width: 100%;
    height: 100%;
  }
  .lable_title{
    display: none;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 10px;
    font-size: 12px;
    width: 500px;
    background: #fff;
    border: 1px solid #2B9938;
    border-radius: 10px;
    &:before{
      content: '';
      height: 20px;
      width: 20px;
      border-left: 1px solid #2B9938;
      border-bottom: 1px solid #2B9938;
      background: #fff;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%) rotate(-45deg);
    }

  }
  .select{
    &_el{
      padding: 16px 24px;
      background: #fff;
      border-bottom: 1px solid #F2F2F2;
      transition: .5s;
      cursor: pointer;
      font-size: 16px;
      &:hover{
        background: #2B9938;
        color: #fff;
      }
	  &.active {
		background: #2B9938;
        color: #fff;
		pointer-events: none;
	  }
    }
  }
  .star{
    margin: 16px 0;
    font-size: 16px;
    color: #979797;
  }
  .text_green{
    color: #2B9938
  }
  .text_blue{
    color: #337ab7
  }
  .emailInput{
    margin-top: 32px;
  }
  table{
    width: 100%;
    background: #fff;
    margin-top: 32px;
    border: 1px solid #f2f2f2;
    td,th{
      padding: 16px;
      font-size: 14px;
      border: 1px solid #f2f2f2;
      vertical-align: middle;
      text-align: center;
      width: 30%;
      @media screen and (max-width: 750px){
        padding: 10px;
        //font-size: 12px;
      }
    }
    td:first-child,th{
      text-align: left;
    }
    td:first-child,th:first-child{
      width: 40%;
    }
    th{
      color: #2B9938;
      font-size: 16px;
      text-align: left;
      @media screen and (max-width: 750px){
        //font-size: 14px;
      }
    }
  }

  .dn{
    display: none;
  }
  .b-product-section__wrapper{
    margin-top: 30px;
    margin-right: 0;
    h2{
      font-size: 55px !important;
      font-family: $sbFontBold !important;
      font-weight: 700 !important;
      line-height: 60px;
    }
    .b-table{
      padding: 0;
      margin: 0;
      td{
        padding: 5px;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
      }
    }
  }
  .step{
    display: none;
    &.step1{
      display: block;
    }
  }
  small{
    font-style: italic;
  }

  .pageQuest{
    padding-top: 20px;
    padding-bottom: 20px;
    &_head{
      color:  #2c3136;
      text-align: center;
      font-weight: 600;
      font-size: 21px;
    }
    &_answers {
      display: flex;
      justify-content: center;
      align-content: center;
      width: 100%;
      margin-top: 20px;
      .answer_el {
        border: 0 none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-content: flex-end;
        font-size: 17px;
        line-height: 30px;
        color: #878b90;
        margin: 0 15px;
        transition: .5s;
        cursor: default;
        svg{
          margin-right: 10px;
          g{
            transition: .5s;
          }
        }
        &.active{
          color: #08a652;
          svg g{
            fill: #08a652;
          }
        }
        &:not(.noHover){
          &:hover{
            cursor: pointer;
            color: #08a652;
            svg g{
              fill: #08a652;
            }
          }
        }
        &:last-child{
          svg{
            transform: rotate(180deg);
          }
        }
      }
    }
    &_area{
      margin-top: 30px;
      display: none;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .textarea{
        font-family: inherit;
        width: 100%;
        min-height: 100px;
        resize: none;
        border-color: #ced1d5;
        border-radius: 5px;
        outline: none;
        margin-top: 5px;
        font-size: 17px;
        line-height: 26px;
        padding: 12px 20px;
      }
      .button{
        font-family: inherit;
        margin-top: 20px;
        width: 216px;
        height: 50px;
        background: #000014;
        color: #fff;
        border: 0 none;
        border-radius: 5px;
        transition: .5s;
        &:hover{
          background: #2c3136;
        }
        &:disabled{
          opacity: .5;
          pointer-events: none;
        }
      }
    }
  }

  .private-questions {
    display: none;
    margin-top: 48px;

    &__item {
      margin-bottom: 24px;
    }

    &__question {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    &__answer {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .step {

    &__attention {
      margin-top: 24px;
      font-size: 14px;
      line-height: 18px;
    }

    &__note {
      font-size: 14px;
      line-height: 18px;
    }

    &__button-box {
      display: flex;
    }
  }
}
