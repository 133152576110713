
//Медиа запросы для разных ширин экрана
//Пример использования: @include desktop_1200 {font-size: 20px;}
@mixin before_1366 {
	@media screen and (max-width: 1365px) {
		@content;
	}
}

@mixin before_1200 {
	@media screen and (max-width: 1199px) {
		@content;
	}
}

@mixin before_1100 {
	@media screen and (max-width: 1099px) {
		@content;
	}
}

@mixin before_1024 {
	@media screen and (max-width: 1023px) {
		@content;
	}
}

@mixin after_1024 {
	@media screen and (min-width: 1023) {
		@content;
	}
}

@mixin before_768 {
	@media screen and (max-width: 767px) {
		@content;
	}
}

@mixin before_550 {
	@media screen and (max-width: 549px) {
		@content;
	}
}

@mixin before_480 {
	@media screen and (max-width: 479px) {
		@content;
	}
}

//Добавление адаптивного шрифта
//Пример использования: @include adaptive-font(40, 24, 1280);
@mixin adaptive-font($pcSize, $mobSize, $maxWidth) {
	$addSize: $pcSize - $mobSize;
	$addMobSize: $addSize + $addSize * 0.7;

	@media (max-width: 767px) {
		font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
	}

	@media (min-width: 767px) {
		font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
	}
}

.js-ns-questionable {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 500px;
    z-index: 100;

    @media screen and (max-width: 768px) {
        right: auto;
        width: 100%;
    }
}

.questionable {
    &.hidden {
        display: none;
    }
}
input[type=radio] {
	display: none;
}
.ns-form {

	&__head {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;
		background: #26c93c;
		border-radius: 24px;
		color: white;
		padding: 14px 16px;
		border: none;
		margin: 0 auto;
		z-index: 30;
	}
	&__send {
		display: block;
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;
		background: #26c93c;
		box-shadow: 0px 8px 16px rgba(36, 201, 33, 0.2);
		border-radius: 12px;
		color: white;
		padding: 14px 16px;
		border: none;
		width: 213px;
		z-index: 30;
		margin: 10px auto;
		cursor: pointer;
	}
	&__fieldset {
		padding: 0;
		border: none;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}

	&.js-form-hidden {
		display: none;
	}
	&__flex {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	&__el {
		//display: flex;
		//flex-direction: column;
		//justify-content: center;
		background: white;
		box-shadow: 0px 8px 16px rgba(3, 7, 3, 0.2);
		border-radius: 24px;

		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}

	&__group {
		display: flex;
		gap: 12px;
		padding: 10px 0;
	}

	&__error {
		display: none;
		margin-bottom: 12px;
		font-size: 14px;
		line-height: 140%;
		color: var(--error-primery-color);
	}

	&.js-form-hidden {
		display: none;
	}
}


.radiobutton {
	--text-primery-color: #030e05;
	--color-green: #26c93c;
	--color-white: #ffffff;

	&__list {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	&__item {
		display: flex;
		flex-shrink: 0;
		align-items: center;
	}

	&__window {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		width: 18px;
		height: 18px;
		margin-right: 12px;
		background: transparent;
		border-radius: 50%;
		border: 1px solid var(--color-green);
	}

	&__circle {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: var(--color-green);
		transform: scale(0);
		transition: 0.5s;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 6px;
			height: 6px;
			border-radius: 50%;
			background-color: white;
		}
	}

	&__name {
		display: flex;
		align-items: center;
		font-weight: normal;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.04em;
		color: black;
		cursor: pointer;
	}

	&__input:checked + .radiobutton__name {
		.radiobutton__window {
			border-color: transparent;
		}

		.radiobutton__circle {
			transform: scale(1);
		}
	}
}
.ns-cell {
	&__p20 {
		padding: 20px;
	}
	&__error-message {
		font-size: 16px;
		line-height: 140%;
		color: red;
		display: none;
		width: 213px;
		margin: 10px auto;
		text-align: center;
		&.show {
			display: block!important;
		}
	}
}
.ns-cell-select {
	position: relative;
	width: 100%;
	z-index: 10;


	&__header {
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: var(--color-white-first);
		text-align: center;

		&--v2 {
			border: 1px solid var(--color-gray5);
			border-radius: 12px;
			background: white;
			backdrop-filter: blur(26px);
			padding: 16px 24px;
		}
	}

	&__current {
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.04em;
		color: var(--color-black-third);
		// opacity: 0;
		transition: opacity 0.5s cubic-bezier(0.32, 0, 0.67, 0);

		@include before_768 {
			font-size: 14px;
		}
	}

	&__icon {
		fill: var(--color-gray-first);
		transition: 0.5s;

		margin-left: 8px;

		@include before_768 {
			width: 14px;
			height: auto;
		}
	}

	&__body-icon {
		opacity: 0;
		fill: var(--color-gray-first);
		transition: 0.2s;
	}

	&__body-link {
		text-decoration: none;
		transition: 0.5s;
	}

	&__body-link:hover {
		color: var(--color-green);
	}

	&__body {
		display: none;
		position: absolute;
		left: 50%;
		right: 0;
		top: calc(100% + 20px);
		// width: calc(100% + 26px);
		width: 351px;
		max-height: 300px;
		overflow-y: auto;
		transform: translate(-50%, 0);
		background-color: #ffffff;
		box-shadow: 0px 2px 15px 0px #ced1d5;
		border-radius: 16px;
	}

	&.is-active {
		z-index: 11;

		.cell-select__icon {
			transform: rotate(180deg);
		}
	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		font-weight: normal;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.04em;
		padding: 16px 24px;
		color: var(--color-gray-first);
		transition: color 0.5s;

		a {
			border-bottom: none;
		}

		@include before_768 {
			font-size: 14px;
		}

		// &:hover {
		//     background-color: #f2f2f2;
		// }

		&.is-chosen {
			color: var(--color-black-third);

			.cell-select__body-icon {
				opacity: 1;
			}
		}
	}

	&__item:not(:last-child) {
		border-bottom: 0.2px solid rgba(135, 139, 144, 0.5);
	}
}
