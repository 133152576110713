.nv{
  padding-top: 140px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  &-head{
    position: relative;
    max-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
  &-wrap{
    margin: 30px auto 40px;
    &-head{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 16px;
      color: #21A038;
      margin-bottom: 60px;
      h1{
        margin: 0;
        padding: 0;
        font-size: 40px;
        line-height: 1.4;
      }
      h2{
        margin: 0;
        padding: 0;
        font-size: 32px;
        line-height: 1.4;
      }
    }
  }
  .nv-li{
    margin-top: 40px;
    margin-bottom: 60px;
    padding-left: 80px;
    font-size: 18px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      height: 70px;
      width: 70px;
      background: url("/static/i/new/li.png") no-repeat center;
    }
  }
  p{
    padding: 10px 0;
  }
  ul{
    list-style: none;
    padding-left: 15px;
    li{
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translate(0, -50%);
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: #21A038;
      }
    }
  }
  b{
    font-weight: bold;
    font-family: $sbFontBold;
  }
}