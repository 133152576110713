.gift-block{
  margin-top: 40px;

  &--mobile {
    display: none;

    @media screen and (max-width: 1024px){
      display: block;
    }
  }
}

.gift-block__flex {
  display: flex;
  align-items: center;

  &--mobile {
    display: none;

    @media screen and (max-width: 1024px){
      display: flex;
    }
  }
}

@media screen and (max-width: 600px) {
  .gift-block__flex {
    flex-direction: column;
  }
}

.gift-block__el--left {
  margin-right: 30px;
}

@media screen and (max-width: 600px) {

  .gift-block__el--left {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.gift-block__icon {
  fill: green;
  width: 100px;
  height: 100px;
}

.gift-block__text {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 28px;
  color: #333333;
}

.gift-block__link {
  font-size: 18px;
  line-height: 28px;
  color: green;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: 0.5s;
}

.gift-block__title {
  font-size: 22px;
  line-height: 32px;
  color: green;
  margin-bottom: 20px;
  text-decoration: none;
}

.gift-block__link:hover {
  border-bottom: 1px solid green;
}

.gift-block__banner {
  width: 100%;

  &--desktop {

    @media screen and (max-width: 1024px){
      display: none;
    }
  }

  &--mobile {

    @media screen and (max-width: 1024px){
      width: 235px;
      margin-right: 30px;
      flex-shrink: 0;
    }

    @media screen and (max-width: 600px){
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

.questions {
  margin-top: 40px;

  &__box {
    display: flex;
    align-items: flex-start;
    transition: 0.5s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &--active {
      margin-bottom: 15px;

      @media screen and (min-width: 1024px) {
        margin-bottom: 28px;
      }
    }
  }

  &__plus {
    position: relative;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    margin-top: 5px;

    &-horizontal {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 14px;
      height: 4px;
      background-color: #333F48;
    }

    &-vertical {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 4px;
      height: 14px;
      background-color: #333F48;
      transition: 0.5s;

      &--active {
        transform: rotate(90deg);
      }
    }
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #333F48;

    @media screen and (min-width: 1024px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__answer {
    display: none;
    padding-left: 24px;

    @media screen and (min-width: 1200px) {
      font-size: 19px;
      line-height: 30px;
    }
    @media screen and (max-width: 1199px) {
      font-size: 15px;
      line-height: 24px;
    }
  }

  &__description {
    margin-bottom: 20px;
  }

  &__table {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      flex-direction: row;
    }
  }

  &__row {
    display: flex;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      width: 50%;
      flex-direction: column;
    }
  }

  &__cell {
    width: 25%;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }

    &--bold {
      font-weight: bold;
    }

    &--green {
      color: #08A652;
    }
  }

  &__file {
    display: flex;
    align-items: center;
    border: none;
    text-decoration: none;

    &-icon {
      margin-right: 10px;
    }

    &-text {
      font-size: 17px;
      line-height: 26px;
      color: #08a652;
    }
  }
}