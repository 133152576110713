.safety{
  padding-top: 140px;
  padding-bottom: 70px;
}

.safety__head{
  font-weight: bold;
  font-size: 26px;
  line-height: 36px;
  color: #21A038;
  margin-bottom: 45px;
}

.safety__subhead{
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 45px;
}

.safety__connection{
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 767px){
    flex-direction: column;
    align-items: center;
  }
}

.safety__el{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 410px;

  @media screen and (max-width: 767px){
    margin-bottom: 30px;
  }

  &:last-child {
    margin-left: 30px;
    max-width: 325px;
  }
}

.safety__icon{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;

  &--email svg {
    width: 100px;
    height: 108px;
  }

  &--mail svg {
    width: 114px;
    height: 76px;
  }
}

.safety__text{
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.safety__el:last-child .safety__text {
  text-align: left;
}