.b-top__section{

  &.b-top__section_about{

    .b-main-tabs__section{
      padding-top: 20px;
      padding-bottom: 90px;

      .b-main-tabs__tabs{
        padding-right: 0;

        li{
          padding-right: 50px;

          .b-custom-tab{
            i:before{
              width: 23px;
              height: 29px;
              background-image: url("../i/new/i-activation-sbs.png");
            }
          }

          &:last-child{
            padding-right: 0;

            .b-custom-tab{
              i:before{
                width: 28px;
                height: 23px;
                top: 52%;
                left: 52%;
                background-image: url("../i/new/i-activation-sbsg.png");
              }
            }
          }
        }
      }

      .b-main-tabs__result_box{
        padding-top: 50px;
      }
    }
  }

  &.b-top__section_question{
    padding-bottom: 60px;

    .b-question-menu{
      li{
        max-width: none !important;

        a{
          min-height: 0 !important;
        }
      }
    }

    .b-about-section__back{
      display: none;
    }

    .b-about-section__menu-label{
      font-style: italic;
      color: $greyColor;
      margin-bottom: 25px;
    }

    .b-about-section__menu{
      .b-about-section__menu_link{
        padding: 0 35px 0 0;

        &.active{
          font-weight: bold;
        }
      }
    }

    .b-main-tabs__section{
      padding-top: 6px;

      .b-main-tabs__tabs{
        border-bottom: none;
        z-index: 3;

        .b-main-tabs__tabs_link{
          font-size: $smallerFontSize;
          line-height: $smallerFontLineHeight;
          padding-bottom: 38px;
        }
      }
      .b-question-content{
        background: #fff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        padding-bottom: 60px;
        padding-top: 50px;
      }
      .b-main-tabs__result_box{
        padding: 0;
      }
    }

    .b-main-tabs__result_box{
      &:before{
        content: "";
        position: absolute;
        bottom: -60px;
        left: 0;
        right: 0;
        border-bottom: $border;
        border-bottom-style: dashed;
      }
    }

    .b-question__dop{
      box-shadow: none;
      border-radius: 0;
      padding: 35px 0 0;
      margin: 60px auto 0;
      width: 600px;
    }

  }
}

.b-about-section{
  position: relative;

  &.b-about-section__first-state{

    .b-about-section_r{
      display: none;
    }

    .b-about-section_l{
      width: 100%;
      border-right: none;
      position: relative !important;

      .b-about-section__menu_wrapper{
        display: block;
        position: relative;

        .b-about-section__menu-label{
          position: absolute;
          left: 0;
          top: -7px;
          font-size: 25px;
          line-height: 35px;
          font-family: $sbFontBold;
          font-weight: bold;
          color: #000;
          font-style: normal;
          width: 160px;
          background: #fff;
          z-index: 3;
        }

        .b-about-section__menu{
          position: relative;
          transform: translate(0,0) !important;
          overflow: hidden;
          min-height: 400px;

          ul{
            width: 285px;
            margin: auto;
            position: relative;

            &:before{
              content: "";
              position: absolute;
              right: 100%;
              margin-right: 30px;
              width: 1000%;
              height: 1px;
              background: #e5e5e5;
              top: 12px;
            }
            &:after{
              content: "";
              position: absolute;
              right: 100%;
              margin-right: 29px;
              width: 7px;
              height: 15px;
              top: 5px;
              transform: rotate(180deg);
              background: url("../i/new/i-arrow-question.svg") no-repeat;
            }
          }
        }
      }
    }

  }

  .b-about-section_l,
  .b-about-section_r{
    float: left;
    vertical-align: top;
    box-sizing: border-box;
  }

  .b-about-section__menu_wrapper{
    position: relative;
    min-height: 100px;
  }

  .b-about-section_l{
    width: 285px;
  }
  .b-about-section_r{
    width: 765px;
    padding-left: 70px;
    border-left: $border;

    .b-accordion{
      .b-accordion__item{
        margin-bottom: 17px;
      }
      .b-accordion__text{
        padding-top: 26px;
      }
      .b-accordion__link {

        &.b-accordion__link_pic{
          padding-left: 100px;
          padding-top: 12px;
          min-height: 80px;

          &+.b-accordion__text{
            padding-top: 14px;
            padding-left: 12px;
            padding-right: 12px;
          }

          .b-accordion__link_pic-box{
            position: absolute;
            left: 0;
            top: 0;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-size: cover;
          }

          span p {
            margin-top: 2px;
          }
        }

      }

    }
  }
}

.b-about-section__menu{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  li{
    margin-bottom: 15px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .b-about-section__menu_link{
    display: block;
    border: none;
    padding: 4px 31px;
    position: relative;

    &:hover{
      &:before{
        opacity: .7;
      }
    }

    &:after{
      content: "";
      position: absolute;
      right: -3px;
      top: 0;
      bottom: 0;
      width: 5px;
      background: $linkColor;
      display: none;
    }

    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      opacity: .4;
      transition: opacity .25s;
    }

    &.active{
      color: #000;
      cursor: default;
      &:after{
        display: block;
      }
      &:before{
        opacity: .15;
      }
    }

    &.about{
      &:before{
        width: 25px;
        height: 21px;
        top: 5px;
        left: -1px;
        background: url("../i/new/pages/about/i-about.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-about-active.svg") no-repeat;
      }
    }

    &.history{
      &:before{
        width: 25px;
        height: 19px;
        top: 7px;
        left: -1px;
        background: url("../i/new/pages/about/i-history.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-history-active.svg") no-repeat;
      }
    }

    &.team{
      &:before{
        width: 24px;
        height: 16px;
        top: 8px;
        left: -1px;
        background: url("../i/new/pages/about/i-team.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-team-active.svg") no-repeat;
      }
    }

    &.info{
      &:before{
        width: 22px;
        height: 21px;
        top: 6px;
        left: -1px;
        background: url("../i/new/pages/about/i-info.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-info-active.svg") no-repeat;
      }
    }

    &.procurement{
      &:before{
        width: 25px;
        height: 22px;
        top: 6px;
        left: -1px;
        background: url("../i/new/pages/about/i-procurement.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-procurement-active.svg") no-repeat;
      }
    }

    &.agents{
      &:before{
        width: 23px;
        height: 18px;
        top: 8px;
        left: 0px;
        background: url("../i/new/pages/about/i-agents.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-agents-active.svg") no-repeat;
      }
    }

    &.careers{
      &:before{
        width: 21px;
        height: 20px;
        top: 6px;
        left: 2px;
        background: url("../i/new/pages/about/i-careers.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-careers-active.svg") no-repeat;
      }
    }

    &.contacts{
      &:before{
        width: 19px;
        height: 23px;
        top: 3px;
        left: 3px;
        background: url("../i/new/pages/about/i-contacts.svg") no-repeat;
      }
      &.active:before{
        background: url("../i/new/pages/about/i-contacts-active.svg") no-repeat;
      }
    }
  }
}

.b-careers{
  &+.b-button__box{
    margin-top: 50px;
    margin-bottom: 30px;
  }
  &.b-text__wrapper{
    table{
      margin-bottom: 25px;
      tbody{
        tr{
          &:last-child{
            td{
              padding-bottom: 14px !important;
              border-bottom: 1px dashed $borderGreyColor;
            }
          }
        }
        td{
          font-size: $biggerFontSize;
          line-height: $biggerFontLineHeight;
          font-weight: bold;
          font-family: $sbFontBold;
        }
      }
      td{
        text-align: left;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }
      thead{
        td{
          font-size: 14px;
          line-height: 20px;
          text-transform: uppercase;
          color: $greyColor;
          border-bottom: none;
        }
      }
    }
  }
}

.b-about-section_r{
  min-height: 400px;
  .b-text__wrapper{
    margin-left: 0;
    padding-top: 0;

    &+.b-accordion{
      margin-top: 25px;
    }
  }

  .b-accordion__item{
    .b-accordion__link{
      span:not(.b-accordion__link_wrapper){
        color: $greyColor;
      }
      .b-accordion__link_wrapper span{
        color: inherit;
        padding-left: 0;
      }
    }
  }
}

.b-history__box{
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 15px;

  &:before{
    content: "";
    position: absolute;
    left: 50%;
    width: 1px;
    top: 15px;
    bottom: 0;
    background: $borderGreyColor;
  }

  &+.b-button__box{
    width: 100%;
    text-align: center;

    .b-button{
      display: inline-block;
      vertical-align: top;
    }
  }
}
.b-history{
  width: 45%;
  text-align: right;
  margin-top: -133px;
  position: relative;

  &.b-history__mt{
    margin-top: -25px;

    &+.b-history{
      margin-top: -25px;
    }
  }

  &:before{
    content: "";
    width: 12px;
    height: 30px;
    background: $bgColor;
    position: absolute;
    left: 100%;
    margin-left: 9.5%;
    top: 10px;
  }

  &:after{
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid $linkColor;
    box-sizing: border-box;
    left: 100%;
    margin-left: 9.5%;
    top: 19px;
  }

  &:first-child{
    margin-top: 0;
  }

  &:nth-child(even){
    margin-left: 55%;
    text-align: left;

    &:before{
      left: 0;
      margin-left: -12.5%;
    }

    &:after{
      left: 0;
      margin-left: -12.5%;
    }

    .b-history__date{
      &:before{
        right: 0;
        left: -30px;
      }
    }
  }

  .b-history__date{
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
    font-family: $sbFontBold;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 18px;
    color: $greyColor;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      height: 1px;
      background: $borderGreyColor;
      bottom: 0;
      left: 0;
      right: -30px;
    }
  }

  .b-history__pic{
    display: inline-block;
    width: 100%;
    height: 215px;
    margin-bottom: 3px;

    span{
      display: block;
      height: 100%;
      border-radius: $inputBorderRadius;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
    }
  }
}

.b-about-section__box{
  position: relative;
  margin-bottom: 75px;

  &:last-child{
    margin-bottom: 0;
  }

  .b-inside-tabs__result_box{
    width: 444px;

    .b-docs__list{
      column-count: auto;
      -moz-column-count: auto;
    }
  }

  .b-info__date{
    display: block;
    padding-bottom: 1px;
    border-bottom: $border;
    text-transform: uppercase;
    color: $greyColor;
    font-weight: bold;
    font-family: $sbFontBold;
    font-size: $smallerFontSize;
    line-height: $smallerFontLineHeight;
    margin-bottom: 20px;
  }

  .b-docs__list{
    column-count: 2;
    -moz-column-count: 2;
    position: relative;
    margin-top: -8px;

    .b-docs__call{
      display: none;
    }

    li{
      float: none;
      display: table;
      width: auto;
      padding-right: 5%;
      padding-top: 8px;
      margin: 0 0 24px;
      break-inside: avoid-column;
      position: relative;


      &:hover{
        p{
          display: block;
        }
      }

      a{
        &:before{
          box-shadow: 0 0 7px rgba(0, 0, 0, 0.08);
        }
      }

      p{
        position: absolute;
        transform: translate(0,-125%);
        top: 30px;
        left: 40px;
        width: 285px;
        padding: 17px;
        border-radius: $inputBorderRadius;
        background: #fff;
        box-shadow: $boxShadow2;
        box-sizing: border-box;
        font-size: 14px;
        line-height: 25px;
        color: $regularColor;
        z-index: 10;
        display: none;

        &:before{
          content: "";
          position: absolute;
          top: 100%;
          left: 24px;
          width: 0;
          height: 0;
          border-top: 13px solid #fff;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
        }
      }
    }
  }
}

.b-map{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 750px;
}

.b-contacts__box{
  background: #fff;
  padding: 26px 35px 34px;
  border-radius: $inputBorderRadius;
  box-sizing: border-box;
  position: relative;
  font-size: 15px;
  line-height: 21px;

  .b-contacts__label,
  h5{
    text-transform: uppercase;
    color: rgba(0,0,0, .2);
    font-weight: bold;
    font-family: $sbFontBold;
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 21px;
    margin-top: 32px;

    &:first-child{
      margin-top: 0;
    }

    &+h4{
      margin-top: 0;
    }
  }

  .b-contacts__column_section{
    margin-bottom: 32px;

    p{
      padding-bottom: 4px;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  h4{
    margin-top: 20px;
    margin-bottom: 0;
    &+h4{
      margin-top: -4px;
    }
    a{
      border-bottom: none;
      color: $regularColor;
    }
  }

  &:before{
    content: "";
    position: absolute;
    left: 50%;
    top: 26px;
    bottom: 34px;
    width: 1px;
    background: $borderGreyColor;
  }

  .b-contacts__column{
    float: left;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;

    .b-button__box{
      margin-top: 30px;
      margin-bottom: 15px;
    }

    &:first-child{
      padding-right: 30px;
    }
    &:last-child{
      padding-left: 30px;
    }
  }
}

.b-contact-page{

  .b-about-section{
    padding-top: 50px;

  }

  .b-about-section__menu{
    transform: translate(0px, 0px) !important;
  }

  .b-about-section_r{
    margin-top: -8px;
  }

  .b-main-tabs__result_box{
    min-height: 750px;
  }
  .b-main-tabs__result{
    padding-top: 50px;
  }

  .b-main-tabs__section{
    .b-wrapper{
      z-index: 3;
    }
    .b-main-tabs__tabs{
      border-bottom: none;
      margin-bottom: 1px;
    }
  }

  .b-about-section{
    .b-about-section_r{
      border-left: none;
    }
  }

  .b-about-section__menu{

    &:before{
      content: "";
      position: absolute;
      background: #fff;
      border-radius: $inputBorderRadius;
      top: -13px;
      right: -3px;
      bottom: -11px;
      left: -18px;
    }
  }

  .b-top__section.b-top__section_about .b-main-tabs__section{
    padding-bottom: 0;

    .b-main-tabs__result_box{
      padding-top: 0;
    }
  }
}

.b-popup__сareer{
  width: 610px;

  textarea{
    height: 150px;
  }

  .b-сareer-form__column{
    .b-input__box{
      float: left;
      vertical-align: top;
      width: 47%;
      margin-top: 0;

      &:first-child{
        margin-right: 6%;
      }
    }
  }
}

.b-about-section__question{
  .b-product-section{
    padding-top: 0;
  }
  .b-accordion .b-accordion__text{
    padding-top: inherit !important;
  }
}

@media only screen and (min-width: 1600px){
  .b-about-section{
    .b-about-section_l{
      width: 330px;
    }
    .b-about-section_r{
      width: 1070px;
    }
  }
  .b-about-section__box .b-inside-tabs__result_box{
    width: 748px;
  }
  .b-history{
    &:before{
      top: 14px;
    }
    &:after{
      top: 23px;
      margin-left: 10%;
    }
    .b-history__date{
      &:before{
        right: -45px;
      }
    }

    &:nth-child(even){
      &:after{
       margin-left: -12.3%;
      }
      .b-history__date{
        &:before{
          left: -45px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  @import "../variables_media_tablet";

  .b-contact-page{
    .b-map{
      height: 850px;
    }
    .b-main-tabs__result_box{
      min-height: 850px;

      .b-wrapper{
        width: auto;
        margin: 0 65px 0 60px;
        padding: 0;
      }
    }
  }

  .b-docs-popup{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $linkColor;
    overflow-y: scroll;
    z-index: 100;
    padding-top: 60px;
    padding-bottom: 60px;
    display: none;

    .b-docs-popup__close{
      position: absolute;
      top: 10px;
      left: 50%;
      width: 29px;
      height: 29px;
      margin-left: -15px;
      background: url(../i/new/i-checkbox-faq-close.svg) no-repeat;
    }

    .b-docs-popup__content{
      margin: 0 2.5% 40px;
      padding: 30px 20px;
      background: #fff;
      border-radius: 5px;
    }
  }

  .b-about-section__box{

    .b-inside-tabs__result{
      background: #fff;
      padding: 15px 15px 30px;

      .b-docs__list{
        column-count: auto;
        -moz-column-count: auto;
      }
    }

    .b-docs__list{

      li{

        padding-right: 40px;

        p{
          display: none !important;
        }

        .b-docs__call{
          position: absolute;
          display: block;
          right: 10px;
          top: 10px;
          padding: 0;
          min-height: 0;
          border: none;
          width: 25px;
          height: 25px;
          background: url(../i/new/i-question.svg) no-repeat;
          opacity: .3;
          transition: opacity .25s;

          &:before{
            display: none;
          }
        }

      }
    }

  }

  .b-about-section__menu{
    font-size: $smallerFontSize;
    line-height: $smallerFontLineHeight;

    .b-about-section__menu_link{
      padding-right: 15px;
    }

    li{
      margin-bottom: 10px;
    }
  }

  .b-about-section{

    &.b-about-section__first-state{
      .b-about-section_l .b-about-section__menu_wrapper .b-about-section__menu ul{
        margin-left: 50%;
      }
    }

    .b-about-section_l{
      width: 215px;
      position: absolute;
      left: 0;
      top: 55px;
      z-index: 5;
    }
    .b-about-section_r{
      width: auto;
      margin-left: 215px;
      padding-left: 25px;
    }
  }

  .b-history{
    &:after{
      background: $bgColor;
      margin-left: 8.8%;
      top: 17px;
    }
    .b-history__date{
      &:before{
        right: -20%;
      }
    }
    &:nth-child(even){
      .b-history__date{
        &:before{
          left: -20%;
        }
      }
      &:after{
        margin-left: -13.5%;
      }
    }
  }

  .b-top__section{
    &.b-top__section_question{

      .b-main-tabs__section .b-main-tabs__tabs{
        width: auto;
        margin-right: -10px;

        .b-main-tabs__tabs_link{
          font-size: 14px;
          padding-bottom: 20px;
        }
      }

      .b-about-section .b-about-section_l{
        top: 0;
      }

    }
  }

}

@media only screen and (max-width: 767px){
  @import "../variables_media_mobile";

  .b-contact-page{
    .b-main-tabs__result_box{
      margin-bottom: 200px;
      .b-wrapper{
        margin: auto;
        width: 90%;
      }
    }
    .b-about-section__menu{
      &:before{
        display: none;
      }
    }

    .b-map{
      height: auto;
      bottom: -200px;
    }
  }

  .b-contacts__box{
    padding: 15px 13px 10px;

    .b-contacts__label, h5{
      font-size: 10px;
      line-height: 15px;
      font-weight: normal;
      color: $greyColor;
      margin-top: 32px;

      &:first-child{
        margin-top: 0;
      }
    }

    .b-contacts__column_section{
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: $border;
    }

    &:before{
      display: none;
    }

    .b-contacts__column{
      float: none;
      width: 100%;
      padding: 0 !important;
      margin-bottom: 15px;

      &:last-child{
        margin-bottom: 0;

        .b-contacts__column_section{
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
  }

  .b-careers {
    &.b-text__wrapper {
      table {
        td{
          padding-left: 10px;

          &:first-child{
            padding-left: 0;
          }
        }
        thead{
          td{
            font-size: 10px;
            line-height: 15px;
          }
        }
        tbody{
          td{
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .b-about-section__box{

    .b-docs__list{
      column-count: auto;
      -moz-column-count: auto;

      li{
        margin-bottom: 8px;

        .b-docs__call{
          top: 4px;
        }
      }
    }

  }

  .b-about-section {

    .b-about-section__menu_wrapper{
      min-height: 0;
    }

    .b-about-section_l {
      position: relative;
      float: none;
      width: 100%;
      margin-bottom: 30px;
      top: 5px;
      min-height: 343px;
    }
    .b-about-section_r{
      float: none;
      width: 100%;
      margin-left: 0;
      padding-left: 0;
      border-left: 0;
      min-height: 0;

      .b-accordion{
        .b-accordion__item{
          border-bottom: $border;
          border-radius: 0;
          padding-bottom: 10px;
          margin-bottom: 5px;

          &:last-child{
            border-bottom: none;
          }

          &.active{
            border-bottom: none;
            border-radius: 5px;
            padding-bottom: 0;
          }

          .b-accordion__link{

            &.b-accordion__link_pic{
              padding-top: 0;
            }

            span{
              padding-left: 0;
              display: block;
              font-size: 11px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  .b-top__section {

    &.b-top__section_about {
      .b-main-tabs__section{
        padding-bottom: 40px;

        .b-main-tabs__tabs li{
          padding-right: 1.5%;

          &:last-child{
            padding-right: 0;
          }
        }

        .b-main-tabs__result_box{
          padding-top: 20px;
        }
      }
    }

    &.b-top__section_question{

      .b-main-tabs__section{
        .b-question-content{
          padding-top: 0;
          background: none;
          box-shadow: none;
          padding-bottom: 0;
        }

        .b-main-tabs__tabs{
          margin-right: 0;
          margin-top: 0;
          //display: none;

          &.show{
            display: block;
          }

          li{
            float: none;
            display: block;
            width: 100%;
            max-width: none !important;
            padding: 0;

            &:last-child{
              .b-main-tabs__tabs_link{
                border-bottom: $border;
              }
            }

            .b-main-tabs__tabs_link{
              min-height: 0 !important;
              font-size: 15px;
              line-height: 24px;
              padding: 13px 0 13px 32px;
              box-sizing: border-box;
              border-top: $border;

              &.active{
                &:after{
                  background-position: -50px 0 !important;
                  opacity: .15;
                }
              }

              &:after{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                margin-top: 13px;
                opacity: .3;
                transition: opacity .25s;
              }

              &.travel:after{
                left: -2px;
                top: 1px;
                width: 20px;
                height: 20px;
                background: url("../i/new/header/menu-travel.svg") no-repeat 0 0;
                background-size: 100px 20px;
              }
              &.health:after{
                left: -2px;
                top: 1px;
                width: 21px;
                height: 20px;
                background: url("../i/new/header/menu-health.svg") no-repeat 0 0;
                background-size: 100px 20px;
              }
              &.family:after{
                left: 0;
                top: 1px;
                width: 16px;
                height: 23px;
                background: url("../i/new/header/menu-family.svg") no-repeat 0 0;
                background-size: 100px 23px;
              }
              &.build:after{
                left: -5px;
                top: 3px;
                width: 25px;
                height: 19px;
                background: url("../i/new/header/menu-build.svg") no-repeat 0 0;
                background-size: 100px 20px;
              }
              &.finance:after{
                left: -1px;
                top: 5px;
                width: 16px;
                height: 19px;
                background: url("../i/new/header/menu-finance.svg") no-repeat 0 0;
                background-size: 100px 20px;
              }
              &.business:after{
                left: 2px;
                top: 1px;
                width: 12px;
                height: 25px;
                background: url("../i/new/header/menu-business.svg") no-repeat 0 0;
                background-size: 100px 25px;
              }
              &.investment:after{
                left: -3px;
                top: 2px;
                width: 24px;
                height: 23px;
                background: url("../i/new/header/menu-invest.svg") no-repeat 0 0;
                background-size: 100px 23px;
              }

              &:before{
                display: none;
              }
            }
          }
        }
      }

      .b-about-section__menu-label{
        margin-bottom: 0;
        padding: 15px 0;
      }

      .b-about-section__menu{
        border-radius: 0;
        position: relative;
        width: auto;
        margin-left: -5.5%;
        margin-right: -5.5%;
        padding-left: 5.5%;
        padding-right: 5.5%;
        font-size: 15px;
        line-height: 24px;
        box-shadow: 0 0 100px rgba(0,0,0, .15);

        li{
          &:first-child{
            .b-about-section__menu_link{
              border-top: $border;
            }
          }
        }

        .b-about-section__menu_link{
          height: auto;
          display: block;
          padding: 13px 0;

          &.active{
            font-weight: normal;
          }
        }
      }

      .b-main-tabs__result_box{
        &.hide{
          display: none;
        }

        &:before{
          display: none;
        }
      }

      .b-about-section__back{
        display: block;
        background: #fff;
        border-radius: $inputBorderRadius;
        padding: 13px 15px 13px 33px;
        box-shadow: 0 0 30px rgba(0,0,0, .2);
        margin-bottom: 15px;
        position: relative;

        &:before{
          content: "";
          position: absolute;
          left: 9px;
          top: 50%;
          width: 16px;
          height: 7px;
          margin-top: -4px;
          transform: rotate(-90deg);
          background: url(../i/new/i-accordion.svg) no-repeat;
        }
      }

      .b-question__dop{
        width: auto;
        margin-top: 0;
        background: none;
      }
    }
  }

  .b-about-section__menu{
    background: #fff;
    line-height: 18px;
    box-shadow: $boxShadow2;
    border-radius: $inputBorderRadius;
    transform: translate(0,0) !important;

    &.fixed{
      position: fixed;
      right: 5%;
      left: 5%;
      margin-left: 40px;
      width: auto;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &:before{
        content: "";
        position: absolute;
        right: 13px;
        top: 21px;
        width: 16px;
        height: 7px;
        background: url(../i/new/i-accordion.svg) no-repeat;
        transform: rotate(180deg);
        transition: transform .25s;
      }

      .b-about-section__menu_link{
        display: none;

        &.active{
          display: block;
          border-bottom: none;
        }
      }

      &.open{
        &:before{
          opacity: 0;
        }
        .b-about-section__menu_link{
          display: block;

          &.active{
            border-bottom: $border;
          }
        }
      }
    }

    li{
      margin-bottom: 0;

      &:last-child{
        .b-about-section__menu_link{
          border-bottom: none !important;
        }
      }
    }

    .b-about-section__menu_link{
      padding: 17px 30px 13px 35px;
      box-sizing: border-box;
      border-bottom: $border;
      height: 49px;
      display: block;
      text-overflow: ellipsis;

      &:after{
        display: none !important;
      }

      &:before{
        margin-left: 8px;
        margin-top: 8px;
        transform: scale(.85);
      }
    }
  }

  .b-history__box{
    padding-bottom: 40px;
    margin-bottom: 10px;
    &:before{
      left: 0;
    }

    &+.b-button__box{
      .b-button{
        width: 100%;
      }
    }
  }

  .b-history{
    width: auto !important;
    text-align: left !important;
    padding-left: 6%;
    box-sizing: border-box;
    margin: 0 0 40px !important;

    &:last-child{
      margin-bottom: 0 !important;
    }

    &:before{
      left: -5px !important;
      margin-left: 0 !important;
      top: -9px;
    }

    &:after{
      left: -5px !important;
      margin-left: 0 !important;
      top: 1px;
    }

    .b-history__date{
      font-weight: normal;
      color: $greyColor;
      font-size: 10px;
      line-height: 15px;
      margin-bottom: 6px;

      &:before{
        display: none;
      }
    }

    .b-history__pic{
      margin-left: -6.5%;
      display: block;
      width: auto;
      height: 200px;
      position: relative;
      margin-bottom: 20px;

      &:before{
        content: "";
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
        background: $bgColor;
      }
    }
  }

  .b-popup__сareer{
    .b-сareer-form__column .b-input__box{
      float: none;
      width: 100%;
      margin-right: 0 !important;

      &:last-child{
        margin-top: 15px;
      }
    }
  }

  .b-about-section__question{
    .b-product-section .b-wrapper{
      width: 100%;
    }
  }

  .b-question-menu-overlay{
    margin-left: -5.5%;
    margin-right: -5.5%;
    padding-left: 5.5%;
    padding-right: 5.5%;
    font-size: 15px;
    line-height: 24px;
    box-shadow: 0 0 100px rgba(0,0,0, .15);

    .b-about-section__menu-label{
      margin-bottom: 0;
      padding: 15px 0;
    }

    .b-question-menu{
      li{
        &:first-child{
          .b-about-section__menu_link{
            border-top: $border;
          }
        }
        &:last-child{
          .b-about-section__menu_link{
            border-bottom: none;
          }
        }
      }
    }

    .b-about-section__menu_link{
      height: auto;
      display: block;
      padding: 13px 0;
      border-bottom: $border;

      &.active{
        font-weight: normal;
      }
    }
  }

}