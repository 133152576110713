.error {
	padding-top: 100px;

	&__container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		align-items: center;

		@media screen and (max-width: 767px) {
			display: flex;
			flex-direction: column;
		}
	}

	&__content {
		@media screen and (max-width: 767px) {
			margin-top: 50px;
		}
	}

	&__button {
		margin-top: 60px;
	}

	&__img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}

	.title {
		margin-bottom: 20px;
		font-size: 30px;
		line-height: 150%;
		color: #333f48;
	}

	.text {
		font-size: 16px;
		line-height: 150%;
		color: #333f48;
	}
}
