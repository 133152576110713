.cc_banner {
  background: #21A038 !important;
  @media only screen and (max-width: 767px){
    position: relative;
  }
}
.cc_banner .cc_message {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto !important;
  line-height: 1.2!important;
  font-size: 12px !important;
  color: #fff;
  @media only screen and (max-width: 767px){
    //padding-right: 50px !important;
    text-align: justify;
	  margin-bottom: 60px !important;
  }
  a{
    color: #fff !important;
    border-bottom: 1px solid #fff;
    transition: .5s;
    &:hover{
      border-bottom: 1px solid rgba(#fff, 0);
    }

  }
}
.cc_container .cc_btn, .cc_container .cc_btn:visited{
  //background-color: transparent !important;
  border-color: transparent !important;
  //background: url(../i/new/i-popup-close.svg) no-repeat;
	background-color: #FFF !important;
	color: #21a038 !important;
	margin: 0 60px 0 10px !important;
  height: 35px;
  width: 35px;
  @media only screen and (max-width: 767px){
    position: absolute;
    //top: 15px;
    //right: 15px;
	  bottom: 0;
	  left: calc(50% - 60px);
	  width: 100%;
    //width: 30px;
    height: 30px;
    padding: 0 !important;
    float: none !important;
	  margin: 10px auto !important;
	  display: flex !important;
	  align-items: center !important;
	  justify-content: center !important;
	  font-size: 1.2em !important;
    background-position: right center;
  }
}
