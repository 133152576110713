.timeWithBenefitsNew {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1920px;
  line-height: 1.2;
  font-family: $sbFont !important;


  .cnt {
    //max-width: 1366px;
    //margin: 0 auto;
    //padding: 0 50px;
    //@media screen and (max-width: 767px) {
    //  padding: 0 24px;
    //}
  }

  .xs {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .hd {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .header {
    background-color: #21a038;

    .cnt {
      height: 150px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        height: 80px;
        img {
          max-width: 30%;
        }
      }
    }

    a {
      text-decoration: none;
      @media screen and (max-width: 767px) {
        max-width: 65%;
        flex-shrink: 0;
        img {
          max-width: 100% !important;
        }
      }
    }
  }

  .newBanner {
    background: #F5FAFF;
    padding-bottom: 0px;
    padding-top: 25px;
    @media screen and (max-width: 767px) {
      padding-bottom: 25px;
    }

    .logo {
      padding-bottom: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 767px) {
        padding: 0 20px 30px;

      }

      img {
        height: 35px;
        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }

      .left {
        padding-top: 50px;

        .head {
          font-weight: 600;
          font-size: 28px;
          line-height: 36px;
          color: #333F48;
          max-width: 510px;
          @media screen and (max-width: 767px) {
            text-align: center;
          }

        }

        .text {
          margin-top: 24px;
          font-size: 17px;
          line-height: 22px;
          color: #333F48;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        @media screen and (max-width: 767px) {
          padding-top: 0px;
          position: relative;
          z-index: 2;
        }
      }

      .right {
        width: 413px;
        flex-shrink: 0;
        margin-top: -60px;

        img {
          width: 413px;
          height: auto;
          object-fit: cover;
          object-position: bottom center;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        .text {
          margin-top: 24px;
          font-size: 17px;
          line-height: 22px;
          color: #333F48;
          display: none;
          @media screen and (max-width: 767px) {
            text-align: center;
            display: block;
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 0px;
        }

      }
    }


  }

  .wrap {
    padding-top: 72px;
    padding-bottom: 72px;
    @media screen and (max-width: 767px) {
      padding-top: 30px;
      padding-bottom: 50px;
    }

    .ul {
      .slick {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        .li {
          padding: 48px 22px 36px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          border: 2px solid #c9c9c9;
          border-radius: 8px;
          width: calc(100% / 3 - (23px / 3 * 2));

          &_icon {
            height: 72px;
            width: 72px;
          }

          &_desc{
            min-height: 382px;
            width: 100%;
          }

          &_head {
            font-size: 21px;
            line-height: 27px;
            text-align: center;
            color: #333F48;
            margin-top: 48px;
            margin-bottom: 32px;
            min-height: 52px;
            width: 100%;
          }

          &_text {
            font-size: 17px;
            line-height: 22px;
            text-align: center;
            color: #333F48;
            width: 100%;

            a {
              color: #21a038;
            }

            small {
              margin-top: 32px;
              display: block;
              font-size: 14px;
              line-height: 18px;
              text-align: center;
              color: #333F48;
              margin-bottom: 48px;
              width: 100%;
            }
          }

          &_btn {
            background: #000;
            border-radius: 4px;
            color: #fff;
            border: 1px solid #000;
            padding: 12px 32px;
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            cursor: pointer;
            transition: .5s;

            &:hover {
              background: transparent;
              color: #000;
            }
          }
        }
      }

      .slick-slider .slick-arrow.slick-prev {
        left: -24px;
        top: 220px;
      }

      .slick-slider .slick-arrow.slick-next {
        right: -24px;
        top: 220px;
      }

    //  .slick-slider .slick-arrow {
    //    top: auto;
    //    bottom: -45px;
    //    background-color: #f4f4f4;
    //    width: 70px;
    //    height: 20px;
    //    background-image: url("../../i/timeWithBenefits/arrowPrev.svg");
    //    background-position: center;
    //    background-repeat: no-repeat;
    //
    //    &:before {
    //      display: none;
    //    }
    //  }
    //
    //  .slick-slider .slick-arrow.slick-prev {
    //    left: 0px;
    //    transform: scale(1) translateY(-50%);
    //  }
    //
    //  .slick-slider .slick-arrow.slick-next {
    //    right: 0px;
    //    transform: scale(1) translateY(-50%) rotate(180deg);
    //  }
    }
  }

  .where {
    background: #F5FAFF;
    padding-top: 60px;
    border-bottom: 1px solid #EFEFEF;
    @media screen and (max-width: 767px) {
      padding-top: 0px;
    }

    .cnt {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      position: relative;
      @media screen and (max-width: 1024px) {
        left: 0;
        flex-direction: column-reverse;
      }
    }

    .left {
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        background: #fff;
        width: calc(100% + 100px);
        position: relative;
        left: 50%;
        transform: translate(-50%);
        border-radius: 0;
        padding: 0 20px;
      }
      @media screen and (max-width: 767px) {
        width: calc(100% + 48px);
        padding-top: 30px;
      }

      &_head {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #333F48;
        @media screen and (max-width: 1024px) {
          font-size: 32px;
          line-height: 1.2;
        }

      }

      &_text {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #333F48;
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
          text-align: center;
          font-size: 20px;
        }
        @media screen and (max-width: 767px) {
          text-align: center;
        }
      }

      .phone {
        @media screen and (max-width: 1024px) {
          height: 430px;
          object-fit: cover;
          width: 351px;
          object-position: top;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      margin-left: 100px;
      width: 100%;
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        width: calc(100% + 100px);
        position: relative;
        left: 50%;
        transform: translate(-50%);
        margin-bottom: 0;
      }
      @media screen and (max-width: 767px) {
        padding: 20px;
        width: calc(100% + 48px);
      }

      &_wrp {
        background: linear-gradient(100.15deg, #F3F9FF 0%, #F7FBFF 100%);
        box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(174, 174, 192, 0.2);
        border-radius: 16px;
        padding: 20px 50px;
      }

      &_head {
        text-align: center;
        font-size: 43px;
        color: #fff;
        margin-bottom: 15px;
        font-family: $sbFontBold;
        font-weight: 600;
        @media screen and (max-width: 1024px) {
          color: #21a038;
        }
        @media screen and (max-width: 767px) {
          font-size: 32px;
        }
      }

      &_ul {
        padding-top: 30px;
        padding-bottom: 30px;
      }

      &_li {
        font-size: 16px;
        line-height: 21px;
        color: #333F48;
        margin-bottom: 45px;
        padding-left: 80px;
        position: relative;

        &:last-child {
          margin-bottom: 0px;
        }

        @media screen and (max-width: 767px) {
          font-size: 20px;
          padding-left: 70px;
        }

        &:before {
          content: attr(data-number);
          color: #fff;
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: #333F48;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 26px;
          line-height: 26px;
          @media screen and (max-width: 1024px) {
            background: #333F48;
          }
        }

        &:after {
          content: '';
          color: #fff;
          position: absolute;
          z-index: 1;
          left: 25px;
          top: 0px;
          transform: translate(-50%, 0);
          width: 5px;
          height: calc(100% + 70px);
          background: #333F48;
          @media screen and (max-width: 1024px) {
            background: url("../../i/timeWithBenefits/after_dots.png") repeat-y center;
            background-size: 5px
          }
        }

        &:last-child {
          &:after {
            display: none;

          }
        }
      }
    }
  }

  .form {
    background: #F5FAFF;
    padding: 35px 0;
    position: relative;
    z-index: 3;

    &_flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &_head {
      font-weight: 600;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #333F48;
    }

    &_inputs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 50px;
      margin-bottom: 48px;
      width: 100%;

      .inputBlock {
        width: 100%;
        max-width: 410px;
        position: relative;
        margin-bottom: 44px;

        &.select {
          input {
            padding-right: 30px;
          }

          &:before {
            content: '';
            position: absolute;
            z-index: 3;
            bottom: 18px;
            right: 10px;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid #000;
            border-right: 2px solid #000;
            transform: rotate(45deg);
            transition: .5s;
            transform-origin: center;
          }

          &.active {
            &:before {
              bottom: 14px;
              transform: rotate(225deg);
            }
          }
        }


        &:last-child {
          margin-bottom: 0;
        }

        label {
          font-size: 14px;
          line-height: 18px;
          color: #333F48;
          font-weight: normal;
        }

        input {
          margin-top: 15px;
          width: 100%;
          background: rgba(#fff, 1);
          border: 0;
          outline: 0;
          height: 45px;
          font-size: 20px;
          color: #000;
          border-radius: 8px;
          padding: 0 10px;
          box-sizing: border-box;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }

          &::placeholder {
            color: #000;
          }
        }

        .option {
          display: none;
          position: absolute;
          z-index: 5;
          bottom: 0px;
          left: 0;
          transform: translate(0, 100%);
          background: rgba(#fff, 1);
          border-radius: 8px;
          padding: 10px 0;
          width: 100%;

          &__el {
            font-size: 20px;
            color: #000;
            padding: 10px;
            cursor: pointer;
            transition: .5s;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &.selected,
            &:hover {
              background: #B1B1B1;
            }

            svg {
              height: 24px;
              width: 24px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .errorDiv {
      position: absolute;
      bottom: -11px;
      left: 0;
      transform: translateY(100%);
      color: red;
      text-align: center;
      width: 100%;
      font-size: 20px;
      white-space: nowrap;

      &.success {
        color: #21a038;
      }
    }

    &_btn {
      height: 60px;
      width: 100%;
      max-width: 410px;
      border-radius: 4px;
      background: #000;
      border: 1px solid #000;
      cursor: pointer;
      transition: .5s;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #FFFFFF;

      &:hover {
        background: transparent;
        color: #000;
      }

      &:disabled {
        cursor: default;
        opacity: .5;
      }
    }
  }

  .footer {
    background-color: #171a1e;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      opacity: .1;
      background: linear-gradient(0deg, #002619 0, rgba(0, 38, 25, 0));
    }

    .cnt {
      height: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 767px) {
        height: 80px;
      }
    }

    a {
      font-size: 20px;
      color: #EBEBEB;
      text-decoration: none;
      border-bottom: 1px solid rgba(#EBEBEB, 0);
      transition: .5s;
      padding-left: 20px;
      position: relative;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 15px;
        height: 17px;
        background: url(/static/i/new/i-footer-agreement.png) no-repeat;
      }

      &:hover {
        border-bottom: 1px solid rgba(#EBEBEB, 1);
      }
    }
  }
}