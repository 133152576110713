.yarkoeDetstvo {
  //font-family: 'SBSans', sans-serif;
  max-width: 1920px;
  margin: 0 auto;

  /***HEADER***/

  .container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    //min-width: 320px;
    position: relative;
  }

  .content {
    //padding: 0 30px;
    @media screen and (max-width: 768px) {
     // padding: 0 20px;
    }
  }

  .header-img {
    max-width: 100%;
    width: auto;
    min-width: 320px;
  }

  /***MAIN***/

  .top-section {
    //background: url(../../i/pages/yarkoe-detstvo-title-bg.png) no-repeat;
    //background-size: cover;
    //height: 507px;
    margin-top: -50px;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
    .flex_cont {
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .left_yd {
        align-items: center;
      }

      .right_yd {
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
        .top-img {
          margin-top: -80px;
          width: 514px;
          height: auto;
          z-index: -1;
          @media screen and (min-width: 940px) and (max-width: 985px) {
            width: 464px;
          }
          @media screen and (min-width: 768px) and (max-width: 940px) {
            width: 404px;
          }
          @media screen and (max-width: 768px) {
            margin-top: -130px;
            width: 414px;
            max-width: 100%;
          }
        }
      }
    }
  }

  .flex_cont {
    display: flex;
  }

  .left_yd {
    display: flex;
  }

  .right_yd {
    display: flex;
  }

  .main-header {
    font-size: 28px;
    line-height: 36px;
    color: #333F48;
    margin: 10px 0 30px;
    @media screen and (max-width: 768px) {
      font-size: 25px;
      line-height: 32px;
    }
  }

  .green-text {
    color: #21a038;
  }

  .flex {
    display: flex;
    flex-direction: column;
  }

  .flex-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 40px;
  }

  .section-gentest {
    margin-top: -165px;
    background: #f9f9f9;
    z-index: 2;
    @media screen and (min-width: 940px) and (max-width: 985px) {
      margin-top: -150px;
    }
    @media screen and (min-width: 768px) and (max-width: 940px) {
      margin-top: -100px;
    }
    @media screen and (max-width: 768px) {
      margin-top: -95px;
    }
    .container {
      flex-direction: column;
      .subhead_box {
        position: relative;
        //background: #21a038;
        width: fit-content;
        margin: 26px 0;
        padding: 10px 20px;

      }

      .arrow_box {
        position: relative;
        background: #21a038;
        @media screen and (max-width: 768px) {
          background: transparent;
        }
      }
      .arrow_box:after {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(33, 160, 56, 0);
        border-left-color: #21a038;
        border-width: 28px;
        margin-top: -28px;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .helps {
        //padding:26px 0;
        font-size: 28px;
        line-height: 36px;
        color: #ffffff !important;
        @media screen and (max-width: 768px) {
          color: #21a038 !important;
        }
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
          font-size: 25px;
          line-height: 32px;
        }
      }
      .flex {
        flex-direction: row;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .flex-item {
          flex-direction: column;
          margin-right: 30px;
          @media screen and (max-width: 768px) {
            flex-direction: row;
            margin-right: 0;
          }
          .item-text {
            padding-top: 10px;
            text-align: center;
            @media screen and (max-width: 768px) {
              padding-top: 0;
              padding-left: 23px;
              text-align: left;
            }
            font-size: 17px;
            line-height: 22px;
            color: #333F48;
          }
        }
      }
    }
  }

  .subhead {
    font-size: 20px;
    line-height: 26px;
    color: #21a038;
  }

  .text {
    font-size: 17px;
    line-height: 22px;
    color: #333F48;
  }

  .item-image {
    width: 42px;
    height: 55px;
  }

  //.item-text {
  //  padding-top: 10px;
  //  padding-left: 23px;
  //  text-align: center;
  //  @media screen and (max-width: 767px) {
  //    padding-top: 0;
  //    padding-left: 23px;
  //    text-align: left;
  //  }
  //  font-size: 17px;
  //  line-height: 22px;
  //  color: #333F48;
  //}

  .grafite-text {
    color: #333f48;
  }

  .section-command {
    background-color: #ebf1f8;
    padding-top: 40px;
    padding-bottom: 30px;
    .container {
      flex-direction: column;
      .subhead-one {
        padding-bottom: 24px;
        font-size: 25px;
        line-height: 32px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }

  .head-command {
    padding-bottom: 0;
  }

  .item-command {
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    position: relative;
    padding: 10px 0 0 15px;
  }

  .item-command:before {
    position: absolute;
    content: '';
    top: 16px;
    left: 0;
    width: 6px;
    height: 6px;
    background: #21a038;
    border-radius: 50%;
  }

  .section-contract {
    padding: 34px 0 0;
    .container {
      flex-direction: column;
      .subhead-contract {
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 25px;
          line-height: 32px;
        }
      }
      .contract-phone {
        max-width: 310px;
      }
      .flex_cont {
        flex-direction: row;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .left_yd {
          flex-direction: column;
          align-items: center;
        }
        .right_yd {
          align-items: center;
        }
      }
    }
  }

  .contract-text {
    text-align: center;
    font-size: 17px;
    line-height: 22px;
    color: #333f48;
    padding: 26px 0 14px;
  }

  .section-survey {
    //background-color: #F6FAFE;
    padding: 40px;
    @media screen and (max-width: 768px) {
      padding: 40px 0 0;
    }
    .container {
      flex-direction: column;
      .subhead {
        padding-bottom: 24px;
      }
      .subhead-survey {
        font-size: 25px;
        line-height: 32px;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    .item-text {
      padding-left: 23px;
      text-align: left;
      font-size: 17px;
      line-height: 22px;
      color: #333F48;
    }
  }

  .item-point {
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(5,141,192);
    background: linear-gradient(45deg, rgba(5,141,192,1) 0%, rgba(91,204,41,1) 100%);
    display: flex;
    justify-content: center;
    font-size: 17px;
    line-height: 43px;
    color: #fff;
  }

  .mail {
    font-size: 25px;
    line-height: 32px;
    color: #333F48;
    text-align: center;
    //padding-bottom: 40px;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }

  .text-decorate {
    text-decoration: underline;
    font-size: 20px;
    line-height: 26px;
    color: #21a038;
    //padding-bottom: 40px;
  }

  .section-take {
    background: #efefef;
    .container {
      flex-direction: column;

      .b-wrapper-new {
        width: auto;
        margin: auto;
        position: relative;
      }
    }
    .form {
      margin: 40px auto;
      max-width: 410px;
      background: rgb(5,141,192);
      background: linear-gradient(45deg, rgba(5,141,192,1) 0%, rgba(91,204,41,1) 100%);
      padding: 24px 20px 30px;
      box-shadow: 0px 4px 10px 0px rgba(7,144,178, 0.5);


      .form_head {
        font-size: 25px;
        line-height: 32px;
        color: #fff;
        padding-bottom: 10px;
      }

      .inputBlock {
        margin-top: 20px;
      }

      .inputBlock label {
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        //font-weight: 400;
        letter-spacing: 0.02em;
      }

      .inputBlock input {
        margin-top: 8px;
        width: 100%;
        background: #fff;
        border: 0;
        outline: 0;
        height: 40px;
        font-size: 15px;
        line-height: 19px;
        color: #333F48;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .form_btn {
        height: 60px;
        width: 100%;
        max-width: 410px;
        border-radius: 4px;
        background: #000;
        border: 1px solid #000;
        cursor: pointer;
        transition: .5s;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #fff;
        margin-top: 40px;
      }

    }
  }

  // .section-report {
  //     .container {
  //         flex-direction: column;
  //         .subhead {
  //             padding: 47px 0 68px;
  //         }
  //         .flex-item {
  //             align-items: center;
  //             .link-report {
  //                 text-decoration: none;
  //                 color: #fff;
  //                 font-size: 25px;
  //                 line-height: 32px;
  //             }
  //         }
  //     }
  // }

  .section-info {
    color: #333f48;
    padding: 45px 0;
    .container {
      justify-content: left;
    }
    .rules {
      text-decoration: none;
      color: #333f48;
      font-size: 20px;
      line-height: 24px;
      position: relative;
      padding-left: 33px;
      border-bottom: 1px solid transparent !important;
      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .rules:before {
      position: absolute;
      content: '';
      background: url(../../i/pages/yarkoe-detstvo-rules-img.svg) no-repeat;
      top: 0px;
      @media screen and (max-width: 768px) {
        top: -5px;
      }
      left: 0;
      width: 22px;
      height: 26px;

    }
  }

  /***SPOILER***/

  .section-questions {
    color: #333F48;
    padding-bottom: 40px;
    .container {
      flex-direction: column;
      .subhead {
        color: #333F48;
      }
    }
  }

  .spoiler-body {
    display: none;
    font-size: 17px;
    line-height: 22px;
  }

  .spoiler-links {
    cursor: pointer;
    font-weight: 400;
    margin: 30px 0 5px;
    position: relative;
    font-size: 17px;
    line-height: 22px;
    color: #333F48;
  }

  .subhead.spoiler-links {
    margin: 0;
  }

  .spoiler-arrow {
    position: absolute;
    top: 4px;
    right: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #333f48 transparent transparent transparent;
  }
}