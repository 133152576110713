.list {
    margin-bottom: 24px;

    &__item {
        position: relative;
        padding-left: 28px;
        font-size: 16px;
        line-height: 26px;

		&--fs17 {
			font-size: 17px;
			line-height: 24px;
		}

        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 8px;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: #21A038;
			border-radius: 50%;
        }
    }
}
