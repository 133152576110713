#fvb,#finevision_banner {
  display: none;
}

#finevision-need-pay {
  min-height: 80px;
}

.finevision-navbar-label {
  font-size: 18px;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.frame {
  height: 16px;      /* equals max image height */
  white-space: nowrap;
  text-align: center; margin: .4em 0;
}
.frameForSingle {
  margin-top: 15px;
  white-space: nowrap;
  text-align: center;
}
._navbar-custom {
  color: black;
  font-size: 14px;
  font-family: $sbFont !important;
  z-index: 2147483647;
  line-height: 100% !important;
  /*position: absolute;*/
  width: 100%;
  top: 0;
  height: 0;  /* make it minimum hight */
}

ul._nav {
  width: auto !important;
}

._navbar {
  min-height:90px !important;
  letter-spacing: 0;
  @media screen and (max-width: 767px){
    height: 165px !important;
  }
}

#_navbar {visibility: visible;}

.bold {
  font-weight: bold;
  font-family: $sbFontBold;
}
ul._nav > li {
  margin-left: 30px;
  border: none !important;
}
ul._nav > li > div.frame {
  width: auto !important;
}

button._btn {
  height: auto !important;
}


.finevision-white {
  background-color: white  !important;
  color: black  !important;
  //background-image: none !important;

}
.finevision-black {
  background-color: black  !important;
  color: white  !important;
  //background-image: none !important;
}
.finevision-darkblue {
  background-color: darkblue  !important;
  color: yellow  !important;
  //background-image: none !important;
}
.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE 6-9 */
}
.hidden {
  display: none !important;
}

#finevision-on { display: block !important;}
.finevision-on { display: block !important;}

#finevision-off { display: none !important;}
.finevision-off { display: none !important;}


.shadow-off  {
  box-shadow: none !important;
  text-shadow: none !important;
}

* { text-shadow: none !important;}


.siteBackgroundcurrent div { background-image: none !important; }
.wixAds {display: none !important;}
.jimdo-free-footer-ad {display: none !important;}
#fm_wrapper_h_menu {display: none !important;}
.sites-cookie-notice-fit  {display: none !important;}


.s13itemsContainer div div {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE 6-9 */
}


.finevision-leftmenu {
  margin: 0;
  padding: 0;
  width: 260px;
  height: 0 !important;
  list-style:none !important;
}

.finevision-leftmenu > li {
  margin: 10px;
  padding: 0;

}

#finevision-leftmenu {
  margin: 0;
  padding: 0;
  width: 260px;
  height: 0 !important;
}


.finevision-content {
  padding-left: 280px !important;
  padding-top: 40px !important;
}

#finevision-content {
  padding-left: 280px !important;
  padding-top: 40px !important;
  width:1000px !important;
}


.finevision-content-100 {
  padding-left: 280px !important;
  padding-top: 100px !important;
}


.finevision-topmenu {
  float:left !important;
  margin:0 !important;
  padding:20px 0 !important;
  position: relative !important;
}

.finevision-topmenu > li {
  float:left;
  margin:0 5px;
  padding:0 5px;
  list-style:none;

}


.finevision-header  {
  float:left !important;
  position: relative !important;
  margin: 20px 10px 30px 10px !important;
  font-size: 26px !important;
  width: auto !important;
  left: 0px !important;

}

.finevision-line-height-1  {
  line-height: 1 !important;
}

.finevision-line-height-2  {
  line-height: 2 !important;
}

.finevision-line-height-3  {
  line-height: 3 !important;
}

.finevision-iframe-1.25 {
  -ms-zoom: 1.25;
  -moz-transform: scale(1.25);
  -moz-transform-origin: 0 0;
  -o-transform: scale(1.25);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(1.25);
  -webkit-transform-origin: 0 0;
}

#jivo-iframe-container {
  display: none !important;
  top: 1000px !important;
}

.small-font {
  background: #FFFFFF;
  font-size: 12px;
}

#finevision-bill {
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}
[data-toggle=_modal]{
  display: none !important;
}
._nav._navbar-_nav li:nth-child(4),
._nav._navbar-_nav li:nth-child(6){
  display: none !important;
}
#finevision_container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
body.finevision-white,
body.finevision-black,
body.finevision-darkblue {
  margin-top: 100px;
  @media screen and (max-width: 767px) {
    margin-top: 165px;
  }

  .b-index-carousel {
    height: auto;
  }

  .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label {
    min-height: auto;
  }

  .b-product .b-product__pic {
    display: none;
  }

  .b-product .b-product__tags {
    height: auto;
  }

  .b-product .b-product__text_overlay {
    //min-height: auto;
    //max-height: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background: linear-gradient(rgba(#fff, 0), #fff);
      z-index: 1000;
    }
  }

  .b-index-carousel__box .slick-arrow,
  .b-calc__result.b-calc__result_alone, .b-calc__result.b-calc__result_bottom,
  .b-refer-manual a,
  .b-menu-toggle {
    background: grey !important;
  }

  .b-refer-manual a {
    line-height: normal !important;
  }

  .b-calc__result.b-calc__result_alone, .b-calc__result.b-calc__result_bottom span {
    background: transparent !important;
  }

  .b-header .b-header__logo {
    background: url(../i/new/header/logo.svg) no-repeat 0 !important;
  }

  .b-index-sort__section .b-index-sort__box .b-index-sort .b-index-sort__label .b-index-sort__icon {
    background: rgba(0, 0, 0, .1) !important;
  }

  .b-popup__overlay.b-popup__overlay_activation .b-popup__close, .b-popup__overlay.b-popup__overlay_feedback .b-popup__close, .b-popup__overlay.b-popup__overlay_ins .b-popup__close, .b-popup__overlay.b-popup__overlay_refer-manual .b-popup__close {
    background: url(../i/new/i-popup-close-green.svg) no-repeat !important;
  }

  .js-header-wrapper.b-header__wrapper.b-wrapper,
  .js-sort-section.b-index-sort__section,
  .b-index-sort__section .b-index-sort__box.b-index-sort__box_big,
  .b-header-main__tel .finevision-white {
    background: transparent !important;
  }

  .b-index-carousel__box .slick-dots {
    display: none;
  }

  .b-index-carousel .b-calc__result.b-calc__result_bottom-custom:before {
    background: url(../i/new/pages/index/i-button-custom.png) no-repeat;
  }

  .fineBtn {
    display: none;
  }

  button, a {
    line-height: normal !important;
  }

  .FlexWrp li {
  }

  .FlexWrp li,
  .FlexWrp span,
  .FlexWrp a {
    line-height: normal !important;
    background-color: transparent !important;
  }

  .b-link__text {
    line-height: 1.4 !important;
  }

  .js-header-circle {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }

  .b-index-sort__call {
    background: gray !important;
  }

  a {
    line-height: normal !important;
  }

  .b-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .partners-flex-el img {
    filter: grayscale(0) !important;
  }

  .b-product .b-product__text h4 .b-link .b-link__text {
    box-shadow: none;
  }

  @media only screen and (max-width: 767px) {

    .b-index-carousel__box .slick-arrow {
      top: 146px;
    }
    .b-index-carousel .b-product-section__wrapper h2 a{
      box-shadow: none;
    }
    .b-index-top .b-index-carousel .b-calc__result.b-calc__result_bottom-custom{
      display: none;
    }
  }


}

body.finevision-white{
  .b-product .b-product__text_overlay:before{
    background: linear-gradient(hsla(0,0%,100%,0),#fff);
  }
}
body.finevision-black{
  .b-product .b-product__text_overlay:before{
    background: linear-gradient(hsla(0,0%,100%,0),#000);
  }
}
body.finevision-darkblue{
  .b-product .b-product__text_overlay:before{
    background: linear-gradient(hsla(0,0%,100%,0),#00008b);
  }
}
.container-fluid{
  color: #fff;
}
._navbar-brand.bold{
  @media screen and (max-width: 767px) {
    display: none;
  }
}
._nav._navbar-_nav{
  color: #000;
  @media screen and (max-width: 767px){
    width: 100% !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    li{
      width: 49%;
      margin: 0 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      ._btn-group{
        display: flex !important;
        justify-content: center;
        ._btn-lg{
          padding: 5px 8px;
        }
      }
    }
  }
}



//@media only screen and (max-width: 1023px) {
//  .fineBtn{
//    display: none;
//  }
//}
