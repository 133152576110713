.biletPromoOld {

	&-banner1 {
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.80);
		background: #F9F9F9;
		backdrop-filter: blur(26px);
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(1, 1fr);
		margin: 110px 0 64px;

		@include before_768 {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(2, auto);
			padding: 15px 15px 32px 15px;
			margin: 50px 0;
		}

		&__info {
			padding: 62px 128px;

			@include before_768 {
				order: 2;
				width: 100%;
				padding: 0;
			}
		}

		&__title {
			color: #353850;
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;

			@include before_480 {
				font-size: 24px;
			}
		}

		&__text {
			color: #353850;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			margin: 38px 0 22px;

			@include before_480 {
				font-size: 16px;
				line-height: 120%;
			}
		}

		&__link {
			color: #21A038;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			text-decoration-line: underline;
			cursor: pointer;
			outline: 0;
			border: none;
			background: transparent;

			@include before_480 {
				font-size: 16px;
			}

			&:hover, &:active, &:visited, &:focus {
				color: #21A038;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
				text-decoration-line: underline;

				@include before_480 {
					font-size: 16px;
				}
			}
		}

		&__image {
			width: 100%;
			max-width: 549px;
			aspect-ratio: 1/0.6302;

			&-container {
				padding: 0 124px 0 0;
				display: flex;
				align-items: center;

				@include before_768 {
					width: 100%;
					padding: 0 0 24px 0;
					justify-content: center;
				}
			}
		}
	}

	&__text {
		color: #353850;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%;

		@include before_480 {
			font-size: 16px;
		}
	}

	&__title {
		color: #353850;
		font-size: 30px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 64px 0 24px;

		@include before_480 {
			font-size: 24px;
			margin: 24px 0;
		}
	}

	&__table {
		width: 100%;
		border-collapse: collapse;

		& th {
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}

		& td {
			color: #353850;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 160%;
		}

		& th:first-of-type {
			border-radius: 10px 0px 0px 0px;
			padding: 18px 0 18px 69px;
			text-align: left;

			@include before_480 {
				padding: 18px 0 18px 17px;
				border-radius: 0;
			}
		}

		& th:last-of-type {
			border-radius: 0px 10px 0px 0px;
			padding: 18px 0 18px 75px;
			text-align: left;

			@include before_480 {
				padding: 18px 0 18px 17px;
				border-radius: 0;
			}
		}

		& td:first-of-type {
			width: 50%;
			padding: 0 0 24px 69px;

			@include before_480 {
				width: 33%;
				padding: 0 0 24px 17px;
			}
		}

		& td:last-of-type {
			width: 50%;
			padding: 0 0 24px 75px;

			@include before_480 {
				width: 66%;
				padding: 0 0 24px 17px;
			}
		}

		&-th {

			&--green {
				background: #11BE1C;
				color: #FFF;
			}

			&--lite-green {
				background: #b8ecbb;
				color: #333F48;
			}

			&--sea-green {
				background: #42e3b4;
				color: #FFF;
			}

			&--pang {
				background: #c7f7e9;
				color: #333F48;
			}
		}

		&-td {

			&--empty {
				height: 32px;
			}
		}

		&-container {
			border-radius: 10px;
			background: #FFF;
			box-shadow: 0px 0px 14px 0px rgba(197, 197, 197, 0.17);
			margin-bottom: 24px;

			@include before_768 {
				overflow-x: auto;
				padding: 21px 0;
			}
		}

		&-header {
			color: #353850;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 140%;
			margin: 64px 0 24px;

			@include before_480 {
				font-size: 16px;
				margin: 32px 0 24px;
			}
		}
	}

	&__subscribe {
		color: rgba(53, 56, 80, 0.70);
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 160%;

		@include before_480 {
			margin-bottom: 32px;
		}
	}

	&-banner2 {
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.80);
		background: #F9F9F9;
		backdrop-filter: blur(26px);
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(1, 1fr);
		padding: 8.5px 48.5px;
		margin: 40px 0 27px;

		@include before_768 {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(2, auto);
			padding: 35px 18px 57px;
		}

		&__image {
			width: 100%;
			max-width: 294px;
			aspect-ratio: 1/1;

			&-container {
				grid-column: 1 / 2;
				display: flex;
				align-items: center;

				@include before_768 {
					grid-row: 1 / 2;
					justify-content: center;
					flex-direction: column;
				}
			}
		}

		&__info {
			grid-column: 2 / 5;
			padding: 44px 0 45px 113px;

			@include before_768 {
				padding: 0;
				grid-row: 2 / 3;
				grid-column: 1 / 2;
			}
		}

		&__title {
			color: #353850;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 140%;

			@include before_480 {
				font-size: 20px;
				line-height: normal;
			}

			&--mobile {
				display: none;

				@include before_768 {
					display: block;
				}
			}

			&--desktop {
				display: block;

				@include before_768 {
					display: none;
				}
			}
		}

		&__text {
			color: #353850;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 140%;
			margin: 32px 0;

			@include before_480 {
				font-size: 14px;
				line-height: normal;
				margin: 20px 0;
			}
		}

		&__list {
			list-style-position: inside;
			max-width: 430px;

			&-item {
				display: flex;
				align-items: flex-start;

				&:not(:last-of-type) {
					margin-bottom: 20px;
				}

				&-icon {
					width: 9px;
					height: 9px;
					margin-right: 20px;
				}

				&-text {
					color: #353850;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;

					@include before_480 {
						font-size: 14px;
						line-height: 140%;
					}
				}
			}
		}
	}

	&__download {
		text-decoration: none;
		display: flex;
		flex-direction: row;
		border-bottom: none;
		align-items: center;
		max-width: 260px;

		&-text {
			color: #353850;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: 150%;
			text-decoration-line: underline;
			margin: 0 18px 0 69px;

			@include before_768 {
				font-size: 20px;
				margin: 0 18px 0 16px;
			}
		}
	}

	&__dropdown-content {
		display: none;
	}
}
