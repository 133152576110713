.b-index-top{
  position: relative;
  height: auto;
}

.b-index-carousel__box{
  position: relative;
  height: 100%;

  &:hover{
    .slick-dots{
      li{
        button{
          span{
            opacity: 1;
          }
        }
      }
    }
  }

  .slick-arrow{
    background: $linkColor;
    &:before{
      background: url("../i/new/i-slick-white.png") no-repeat;
    }

    &:hover{
      background: #fff;
      &:before{
        background: url("../i/new/i-slick-green.png") no-repeat;
      }
    }

    &.slick-prev{
      left: 0;
      margin-left: 27px;
    }
    &.slick-next{
      right: 0;
      margin-right: 27px;
    }
  }
  .slick-dots{
    width: 1050px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 85px;
    padding-left: 65px;

    li{
      float: left;
      vertical-align: top;
      margin: 0 15px 0 0;
      position: relative;
		&:first-child {
			margin: 0 15px;
		}

      &.slick-active{
        button{
          background: $linkColor;
          cursor: default;
          box-shadow: -2px 10px 10px rgba(0,0,0, .2);

          &:hover{
            background: $linkColor;
          }
        }
      }

      button{
        font-size: 0;
        line-height: 0;
        color: transparent;
        display: block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #fff;
        transition: background .25s, box-shadow .25s;

        &:hover{
          background: $linkColor;
        }
      }
    }
  }
}

.b-index-carousel{
  position: relative;
  height: 700px !important;

  .b-wrapper{
    height: 100%;
  }

  .b-product-section__wrapper{
    padding-top: 165px;
    margin-right: 420px;
    margin-left: 65px;
    max-width: 500px;

    p{
      max-height: 81px;
      overflow: hidden;
    }


    h2{
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 36px;

      a{
        color: #fff;
        border: none;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        background: $linkColor;
        font-family: $sbFontBold;
        padding: 5px 13px 5px 10px;
        display: inline;
        font-weight: 700;
        transition: .5s;
        text-decoration: none;
        &:hover {
          background: $linkColor;
          box-shadow: -10px 34px 45px rgba(0,0,0, .5);
          text-decoration: none;
        }
      }
    }
  }

  .b-calc__result{
    top: 175px;
    right: 150px;
    background: #fff;
    color: #000;
    box-shadow: -6px 20px 30px rgba(0,0,0, .5);

    .b-text__bigger{
      line-height: 27px;
      display: block;
      margin-bottom: 15px;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    .b-button{
      height: 50px;
      font-size: 16px;
      line-height: 30px;
      padding: 8px 25px 12px;
      box-shadow: -5px 20px 30px rgba(0,0,0, .15);
    }

    .b-calc__result_sum{
      font-size: 42px;
      line-height: 55px;
      font-weight: normal;
      margin-bottom: 8px;

      .b-rub{
        font-size: 35px;
        line-height: 55px;
      }

      .b-calc__result_sum-small{
        font-size: 42px;
        line-height: 55px;
        display: inline;
      }
    }

    &.b-calc__result_bottom-custom{
      top: 50%;
      padding: 10px;
      background: $linkColor;
      text-align: center;
      font-size: 19px;
      line-height: 27px;
      color: #fff;
      transition: .5s;
      height: 50px;
      width: 200px;
      border-radius: 50px;
      position: static;
      display: block;
      margin-top: 40px;
      box-shadow: none;
      margin-left: 65px;

      &:hover{
        box-shadow: -6px 20px 30px rgba(0,0,0,.5);
        text-decoration: none;
      }

      span{

      }
    }
  }

  &.slick-active{
    .b-index-carousel__rotate{
      transform: rotateZ(0deg);
      opacity: 1;

      .b-index-carousel__rotate-wrapper{
        transform: rotateZ(0deg);
      }
    }
  }

  .b-index-carousel__rotate{
    position: absolute;
    width: 280px;
    height: 280px;
    border-radius: 50%;
    top: 175px;
    right: 150px;
    transform-origin: 50% 50%;
    transform: rotateZ(150deg);
    opacity: 0;
    transition: transform .35s .5s;

    .b-index-carousel__rotate-wrapper{
      position: absolute;
      right: 0;
      top: 0;
      width: 150px;
      height: 150px;
      transform: rotateZ(-150deg);
      transition: transform .35s .5s;
    }
  }

  .b-calc__result_index{
    position: absolute;
    top: -50px;
    right: -70px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(255,255,255, .9);
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    box-shadow: $boxShadow_shift;

    .b-calc__result_index-wrapper{
      position: absolute;
      left: 10px;
      right: 10px;
      top: 50%;
      margin-top: -10px;
      transform: translate(0, -50%);
    }

    strong{
      font-size: 25px;
      display: block;
      margin-top: 9px;
      text-align: right;
      padding-right: 10px;
    }
  }
}
.b-index-sort__section_overlay{
  position: absolute;
  top: 700px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #222222;
  opacity: .7;
  z-index: 4;
  display: none;
}
.b-index-sort__section{
  position: relative;
  z-index: 20;
  margin-top: -33px;
  min-height: 58px;
  margin-bottom: 40px;

  &.fixed{
    .b-index-sort__container{
      position: fixed;
      visibility: hidden;
    }
    .b-index-sort__wrapper{
      opacity: 0;
    }

    &.visibility{
      opacity: 0;
      z-index: -1;
      //visibility: hidden;
      transition: opacity .25s, z-index .25s .25s;
    }
    &.no-opacity{
      .b-index-sort__container{
        visibility: visible;
      }
      .b-index-sort__wrapper{
        opacity: 1;
      }
    }
  }

  .b-index-sort__container{
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    transition: opacity .25s .05s, z-index .1s, visibility .25s .0s;
    z-index: 5;
  }

  .b-index-sort__wrapper{
    float: left;
    vertical-align: top;
    position: relative;
    transition: opacity .25s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .b-index-sort__box{
    float: left;
    vertical-align: top;
    margin-right: 10px;
    border-radius: $inputBorderRadius;
    box-shadow: -2px 10px 15px rgba(0,0,0, .1);
    background: #fff;
    transition: .5s;
    width: 33%;
    font-size: 15px;
    line-height: 22px;
    &:last-child{
      margin-right: 0;
    }

    &:hover{
      text-decoration: none;
      box-shadow: -5px 20px 30px rgba(0,0,0, .2);
    }

    &.b-index-sort__box_green{
      .b-index-sort {
        .b-index-sort__label .b-index-sort__icon{
          span{
            background: $linkColor;
          }
        }

        input{
          &:checked + .b-index-sort__label{
            .b-index-sort__icon{
              background: rgba(58,172,106, .2);
            }
          }
        }
      }
    }

    &.b-index-sort__box_red{
      .b-index-sort {
        .b-index-sort__label .b-index-sort__icon{
          span{
            background: #B45457;
          }
        }

        input{
          &:checked + .b-index-sort__label{
            .b-index-sort__icon{
              background: #B45457;
            }
          }
        }
      }
    }

    &.b-index-sort__box_yellow{
      .b-index-sort {
        .b-index-sort__label .b-index-sort__icon{
          span{
            background: $yellowColor;
          }
        }

        input{
          &:checked + .b-index-sort__label{
            .b-index-sort__icon{
              background: rgba(255,189,28, .3);
            }
          }
        }
      }
    }

    &.b-index-sort__box_purple{
      .b-index-sort {
        .b-index-sort__label .b-index-sort__icon{
          span{
            background: $newsColor;
          }
        }

        input{
          &:checked + .b-index-sort__label{
            .b-index-sort__icon{
              background: rgba(151,117,196, .2);
            }
          }
        }
      }
    }

    &.b-index-sort__box_big{
      height: 50px !important;
      overflow: hidden;

      &:hover{
        //height: auto;
        overflow: visible;

        .b-index-sort{
          opacity: 1;

          &:first-child{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    .b-index-sort{
      position: relative;
      overflow: hidden;
      transition: opacity .25s .05s;

      &:first-child{
        border-radius: $inputBorderRadius;
      }

      &+.b-index-sort{
        border-top: $border;
        opacity: 0;
      }

      &:last-child{
        border-bottom-left-radius: $inputBorderRadius;
        border-bottom-right-radius: $inputBorderRadius;
      }

      input{
        opacity: 0;
        position: absolute;
        left: -100%;
        top: -100%;
        width: 0;
        height: 0;

        &:checked + .b-index-sort__label{
          .b-index-sort__icon{
            span{
              left: 100%;
            }
          }
        }
      }

      .b-index-sort__label{
        min-height: 50px;
        display: block;
        padding: 13px 50px 15px 15px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        transition: color .25s;
        color: $linkColor;

        &:hover{
          color: $linkColorHover;
        }

        .b-index-sort__icon{
          position: absolute;
          right: 12px;
          top: 50%;
          margin-top: -4px;
          height: 9px;
          width: 10px;
          border-radius: 9px;
          background: rgba(0,0,0, .1);
          transition: background .25s;

          span{
            position: absolute;
            top: 50%;
            margin-top: -10px;
            margin-left: -17px;
            left: 14px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
            transition: left .25s;
            box-shadow: -3px 10px 10px rgba(0,0,0, .2);
          }
        }
      }
    }
  }
}

.b-products__section{
  position: relative;
  padding-bottom: 75px;

  .b-products__section_empty{
    text-align: center;
    display: none;
  }

  &.hide-show-more{
    .b-button__box_show{
      display: none;
    }
  }

  .b-products__section_wrapper{
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
  }

  .b-products__section_background {
    position: absolute;
    top: -65px;
    width: 100%;
    height: 279px;
    background: url("../i/new/decoretion.svg") no-repeat;
    background-size: cover;
    //background: url("../i/new/decoration.svg") no-repeat;
  }
}

.b-product{
  position: relative;
  font-size: 14px;
  line-height: 25px;
  margin: 5px;
  height: auto;
  width: 252px;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0,0,0, .05);
  background: #fff;
  border-radius: $inputBorderRadius;
  vertical-align: top;
  float: left;
  transition: box-shadow .25s;

  &.financial_literacy{

    &:hover{
      .b-product__pic{
        background: none;
        box-shadow: none;
      }
      .b-product__price{
        color: #fff;
      }
    }

    &.b-product__big{
      .b-product__pic{
        background: none;
        box-shadow: none;

        .b-product__price{
          display: none;
        }
      }
    }

    .b-product__price{
      display: none;
    }

    .b-product__pic{
      background: none;
      box-shadow: none;
    }

    .b-product__type{
      background: $yellowColor;

      &:before{
        background: url("../i/new/i-financial-literacy.svg") no-repeat;
        width: 39px;
        height: 27px;
        background-size: 100% auto;
      }
    }

    .b-product__basket{
      background: #fff;

      &:before{
        left: 55%;
        width: 23px;
        height: 31px;
        background: url("../i/new/i-index-video-product.svg") no-repeat;
        background-size: cover;
      }
    }
  }

  &.news{

    &:hover{
      .b-product__main{
        background: none;
        color: rgba(0,0,0, .9);
      }

      .b-product__text h4 .b-link .b-link__text{
        box-shadow: -10px 33px 45px rgba(0,0,0, .3);
      }
    }
    &.b-product__big{
      .b-product__main{
        background: none;
        color: rgba(0, 0, 0, 0.9);
      }
      .b-product__bg{
        display: none;
      }
      .b-product__text{
        max-width: none;
        margin-right: 43px;
      }
    }
    .b-product__tags li a{
      color: rgba(0,0,0, .4) !important;

      &:hover{
        color: rgba(0,0,0, 1) !important;
      }
    }
    .b-product__type{
      background: $newsColor;

      &:before{
        background: url("../i/new/i-news.svg") no-repeat;
        width: 24px;
        height: 27px;
        background-size: 100% auto;
      }
    }
    .b-product__text{
      padding-top: 7px;
    }
  }

  .b-product__text_overlay{
    max-height: 323px;
    min-height: 323px;
  }
  .b-product__text_overlay:before{
      content: "";
      position: absolute;
      z-index: 22;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background: linear-gradient(0deg,#fff,hsla(0,0%,100%,.9) 50%,hsla(0,0%,100%,0));
  }



  &.b-product__big{
    width: 514px;
    font-size: $smallerFontSize;
    line-height: $smallerFontLineHeight;
    @media only screen and (max-width: 767px){
      width: 100%;
    }

    &:hover{
      .b-product__bg{
        transform: scale(1.1);
      }
    }

    .b-product__bg{
      opacity: .5;
      transition: transform .25s;
    }

    .b-product__main{
      color: rgba(255,255,255, .9);
      background: $bgProductPic;
    }

    .b-product__pic{
      background: #fff;
      position: absolute;
      right: 70px;
      top: 65px;
      z-index: 4;
      box-shadow: -5px 20px 30px rgba(0,0,0,.4);

      .b-product__pic_pic{
        opacity: 0;
      }

      .b-product__price{
        opacity: 1;
      }
    }

    .b-product__text_overlay{
      max-height: 315px;
      min-height: 250px;
    }

    .b-product__text{
      max-width: 270px;
      margin-left: 43px;
      padding-top: 14px;

      h4{
        font-size: 25px;
        line-height: 31px;
        width: 90%;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 21px;

        .b-link{
          color: #fff;

          .b-link__text{
            padding: .2em 7px 2px 7px;
            box-shadow: -10px 33px 45px rgba(0,0,0, .3);
            background: $linkColor;
            border-bottom-color: transparent;
          }
        }
      }
    }

    .b-product__tags li a{
      color: rgba(255,255,255, .4);

      &:hover{
        color: #fff;
      }
    }
  }

  &.travel{
    .b-product__type:before{
      background: url("../i/new/header/menu-travel3.svg") no-repeat;
      width: 29px;
      height: 27px;
      background-size: 100% auto;
    }
  }
  &.health{
    .b-product__type:before{
      background: url("../i/new/header/menu-health3.svg") no-repeat;
      width: 29px;
      height: 29px;
      background-size: 100% auto;
    }
  }
  &.finance{
    .b-product__type:before{
      background: url("../i/new/header/menu-finance3.svg") no-repeat;
      width: 22px;
      height: 25px;
      background-size: 100% auto;
    }
  }
  &.family{
    .b-product__type:before{
      background: url("../i/new/header/menu-family3.svg") no-repeat;
      width: 30px;
      height: 27px;
      background-size: 100% auto;
    }
  }
  &.business{
    .b-product__type:before{
      background: url("../i/new/header/menu-business3.svg") no-repeat;
      width: 17px;
      height: 34px;
      background-size: 100% auto;
    }
  }
  &.realty,
  &.build{
    .b-product__type:before{
      background: url("../i/new/header/menu-build3.svg") no-repeat;
      width: 33px;
      height: 26px;
      background-size: 100% auto;
    }
  }
  &.investment{
    .b-product__type:before{
      background: url("../i/new/header/menu-invest3.svg") no-repeat;
      width: 27px;
      height: 24px;
      background-size: 100% auto;
    }
  }

  .b-product__tags{
    position: absolute;
    font-size: 14px;
    line-height: 25px;
    left: 50px;
    top: 18px;
    z-index: 3;
    right: 8px;
    height: 25px;
    overflow: hidden;

    li{
      float: left;
      vertical-align: top;
      margin-left: 10px;

      a{
        color: rgba(0,0,0, .4);
        border-bottom: none;
        white-space: nowrap;
      }
    }
  }

  .b-product__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity .25s;
  }

  &:hover{

    .b-button__box{
      .b-button{
        border-color: #fff;
      }
    }

    .b-product__bg{
      opacity: .5;
    }

    .b-product__tags li a{
      color: rgba(255,255,255, .4);

      &:hover{
        color: #fff;
      }
    }

    .b-product__main{
      background: $bgProductPic;
      color: rgba(255,255,255, .9);
    }
    .b-product__price{
      opacity: 1;
      color: #000;

    }
    .b-product__pic{
      box-shadow: -5px 20px 30px rgba(0,0,0,.4);

      &.b-product__pic_simple{
        box-shadow: none;
      }

      .b-product__pic_pic{
        opacity: 0;
      }
    }
    .b-product__text{
      h4{

        .b-link{
          color: #fff;

          .b-link__text{
            box-shadow: -10px 33px 45px rgba(0,0,0, .5);
            background: $linkColor;
            border-bottom-color: transparent;
          }
        }
      }
    }
  }

  .b-product__basket{
    position: absolute;
    right: -43px;
    bottom: 0;
    z-index: 3;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: -5px 20px 30px rgba(0,0,0, .15);
    background: $linkColor;

    &:before{
      content: "";
      position: absolute;
      width: 36px;
      height: 39px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("../i/new/i-product-basket.png") no-repeat;
    }
  }

  .b-product__main{
    display: block;
    padding-top: 60px;
    width: 100%;
    height: 100%;
    border-bottom: none;
    color: rgba(0,0,0, .9);
    transition: color .25s, background .25s;
  }

  .b-product__type{
    position: absolute;
    left: 8px;
    top: 10px;
    width: 40px;
    height: 40px;
    background: $linkColor;
    border-radius: 50px;
    z-index: 2;

    &:before{
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%) scale(.7);
    }
  }

  .b-product__text_overlay{
    overflow: hidden;
    padding-bottom: 15px;
    //max-height: 165px;
    //min-height: 165px;
    height: calc(100% - 165px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
  }
  .b-product__text{
    position: relative;
    margin: 0 30px;
    z-index: 2;

    em{
      background: $linkColor;
      color: #fff;
    }

    h4{
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      margin-left: -3px;
      margin-right: -3px;
      margin-bottom: 11px;

      .b-link{
        transition: color .25s;

        .b-link__text{
          padding: .1em 3px 2px 3px;
          transition: border-color .25s, background .25s, box-shadow .25s;
          -webkit-box-decoration-break: clone;
          box-decoration-break: clone;
        }
      }
    }

    p{
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .more{
    display: block;
    text-align: left;
    background-color: transparent;
    color: #2B9938;
    font-size: 14px;
    margin-left: 30px;
  }
  .b-product__price{
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    font-size: $biggerFontSize;
    z-index: 2;
    opacity: 0;
    transition: opacity .25s;
    color: #000;
    span{
      position: relative;
      z-index: 2;
      @media screen and (max-width: 768px){
        color: #fff;
      }
    }


    .btn-green{
      display: block;
      background-color: #2B9938;
      color: #fff;
      font-size: 17px;
      padding: 10px;
      width: calc(100% - 60px) !important;
      margin-top: 10px;
      text-align: center;
      border: 0 none !important;
      border-radius: 5px !important;
      cursor: pointer;
    }
  }

  .b-product__pic{
    width: 140px;
    height: 140px;
    position: relative;
    margin: 0 auto 20px;
    border-radius: 50%;
    background: #fff;
    opacity: 1;
    z-index: 1;
    transition: box-shadow .25s;

    &.b-product__pic_simple{
      .b-product__basket{
        display: none;
      }
      border: 1px solid #fff;
      background: none;

      &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 49px;
        height: 38px;
        margin: -19px 0 0 -24px;
        background: url("/static/i/new/i-product-arrow-simple.png") no-repeat;
      }
    }

    .b-product__pic_pic{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      overflow: hidden;
      background-position: left top;
      background-size: cover;
      transition: opacity .25s;
    }
  }
}

.b-subscribe__section{
  position: relative;
  padding: 80px 0 70px;

  .b-bg__pic{
    opacity: .3;
  }

  .b-subscribe__title{
    text-align: center;
    width: 630px;
    margin: 0 auto 40px;
  }

  .b-subscribe__success{
    text-align: center;
  }

  .b-subscribe__error{
    text-align: center;
    margin-top: 15px;
  }

  .b-subscribe{
    position: relative;
    width: 510px;
    margin: auto;

    .b-input{
      width: 250px;
      float: left;
      vertical-align: top;

      &:first-child{
        margin-right: 10px;
      }
    }

    .b-button__box{
      text-align: center;
      margin-top: 55px;
    }
  }
}

.b-specs__section{
  position: relative;
  padding: 0 0 60px;

  .b-specs__carousel{
    margin-left: -9px;
    margin-right: -9px;
    margin-top: -5px;
    padding: 5px 0;
  }

  .slick-arrow{
    background: $linkColor;

    &:before{
      background: url("../i/new/i-slick-white.png") no-repeat;
    }

    &:hover{
      background: #fff;
      &:before{
        background: url("../i/new/i-slick-green.png") no-repeat;
      }
    }
  }

  .b-specs{
    position: relative;
    margin: 9px;
    height: 365px;
    width: 338px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0,0,0, .05);
    //background: $bgPicColor;
    border-radius: $inputBorderRadius;
    vertical-align: top;
    float: left;
    border: none;
    box-sizing: border-box;
    padding: 40px 35px;
    transition: box-shadow .25s;

    &:hover{
      .b-link{
        color: $linkColorHover;
        border-bottom-color: $linkColorHoverBorder;
      }
      .b-specs__pic{
        transform: scale(1.2);
      }
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    }

    &.b-specs_white{
      color: #fff;

      .b-link{
        color: #fff;
        border-bottom-color: rgba(255,255,255, .3);
      }
    }

    .b-specs__pic{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transform: scale(1);
      transition: transform .25s;
    }

    .b-specs__text{
      width: 80%;
      position: relative;
      z-index: 2;

      p{
        width: 80%;
      }
    }

    .b-link{
      position: absolute;
      bottom: 30px;
      left: 35px;
      z-index: 2;
    }
  }
}

.headText{
  font-size: 40px;
  line-height: 1.4;
  color: #000;
  margin-bottom: 32px;
  @media screen and (max-width: 768px){
    text-align: center;
  }
}

.insuranceFilter{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  &-el{
    margin-bottom: 25px;
    margin-right: 10px;
    @media screen and (max-width: 768px){
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
    input{
      display: none;
    }
    label{
      padding: 10px 30px;
      background-color: #F2F2F2;
      border-radius: 5px;
      cursor: pointer;
      transition: .5s;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px){
        width: 100%;
      }
      img{
        margin-right: 7px;
      }
      .white{
        display: none;
      }
      .black{
        display: inline-block;
      }
    }
    input:checked + label{
      background: #2B9938;
      color: #fff;

      .white{
        display: inline-block;
      }
      .black{
        display: none;
      }
    }
  }
}

@include placeholder(rgba(0,0,0, .2), '.b-subscribe .b-input');
@media all and (not handheld){

}
@media only screen and (min-width: 768px){
  .b-product{

    &.news{
      &:hover{
        box-shadow: 0 20px 50px rgba(0,0,0, .3);
      }
    }

    &:hover{
      box-shadow: 0 20px 50px rgba(0,0,0, .5);
      z-index: 4;
    }
  }
}
@media only screen and (max-device-width: 1024px){
  .b-product{

    &.news{
      &:hover{
        box-shadow: inherit;
      }
    }

    &:hover{
      box-shadow: inherit;
    }
  }
}

@media only screen and (min-width: 1600px){

  .b-specs{
    width: 454px;
  }

  .b-index-carousel__box{
    .slick-dots{
      width: 1400px;
    }
  }
  .b-index-sort__section .b-index-sort__box{
    width: 33%;
  }
  .b-index-carousel{
    .b-product-section__wrapper p{
      max-height: 90px;
    }
    .b-calc__result{
      width: 280px;
      height: 280px;

      .b-text__bigger{
        font-size: 16px;
      }
    }
  }
  .b-product{
    width: 342px;

    h4{
      font-size: 30px;
      line-height: 38px;
    }

    //.b-product__text{
    //  max-height: 365px;
    //  width: 330px;
    //}

    &.b-product__big{
      width: 694px;
    }
  }
}

@media only screen and (max-width: 1199px){
  .b-specs__section{
    .b-wrapper{
      padding: 0 60px;
    }

    .b-specs__carousel{
      .slick-arrow {
        background: $linkColor;

        &:before {
          background: url("../i/new/i-slick-white.png") no-repeat;
        }
      }
    }

  }

  .b-index-carousel__box{
    .slick-dots{
      transform: none;
      left: 60px;
      right: 25px;
      width: auto;
      bottom: 60px;

      li{
        button{
          span{
            font-size: 12px;
            line-height: 18px;
            margin-top: -31px;
            padding-bottom: 10px;
            opacity: 1;
          }
        }
      }
    }
    .slick-arrow{
      background: $linkColor !important;

      &:before{
        background: url("../i/new/i-slick-white.png") no-repeat !important;
      }
      &.slick-prev{
        margin-left: -34px;
      }
      &.slick-next{
        margin-right: -34px;
      }
    }
  }
  .b-index-carousel{

    .b-product-section__wrapper{
      margin-right: 290px;

      h2{
        font-size: 31px;
        line-height: 45px;
      }

      p{
        max-height: none;
      }
    }

    .b-calc__result{
      top: 190px;
      right: 85px;

      .b-text__bigger{
        line-height: 20px;
        margin-bottom: 10px;
        width: 60%;
      }

      .b-calc__result_sum{
        font-size: 35px;
        line-height: 40px;

        .b-calc__result_sum-small{
          font-size: 35px;
          line-height: 40px;
        }

        .b-rub{
          line-height: 40px;
          font-size: 30px;
        }
      }

      &.b-calc__result_bottom-custom{
        right: 150px;
      }
    }

    .b-index-carousel__rotate{
      width: 200px;
      height: 200px;
      top: 190px;
      right: 85px;

      .b-index-carousel__rotate-wrapper{
        width: 115px;
        height: 115px;
      }
    }

    .b-calc__result_index{
      width: 115px;
      height: 115px;
      font-size: 11px;
      line-height: 16px;
      right: -55px;

      .b-calc__result_index-wrapper{
        margin-top: -5px;
      }

      b{
        font-size: 18px;
      }
    }
  }

  .b-product{
    width: 47%;
    margin: 1.5%;

    .b-product__text{
      h4{
        font-size: 25px;
        line-height: 35px;
      }
    }
  }

  .b-products__section{
    margin-top: -11px;
    .b-products__section_wrapper{
      margin-left: -1.5%;
      margin-right: -1.5%;
      padding-top: 1.5%;
    }

    .b-products__section_background {
      top: -1px;
    }
  }

  .b-index-sort__section{
    margin-top: 11px;
    min-height: 0;
    margin-bottom: 0;

    &.fixed{
      .b-index-sort__container{
        opacity: 1;
        visibility: visible;
      }
    }

    &.open{
      z-index: 100;
      .b-index-sort__call{
        opacity: 0;
      }
    }

    .b-index-sort__wrapper{
      float: none;
      opacity: 1 !important;
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }

    .b-index-sort__popup_close{
      position: absolute;
      top: 18px;
      left: 50%;
      margin-left: -15px;
      width: 29px;
      height: 29px;
      background: url("../i/new/i-checkbox-faq-close.svg");
      border: none;
    }

    .b-index-sort__popup{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,38,25, .5);
      padding-top: 75px;
      box-sizing: border-box;
      overflow: scroll;
      display: none;

      .b-index-sort__box{
        float: none;
        width: 280px;
        margin: 0 auto 15px;

        &.b-index-sort__box_big{
          height: auto !important;
          overflow: visible;
          background: #fff;

          .b-index-sort{
            opacity: 1;
          }
        }
      }

    }


    .b-index-sort__call{
      position: absolute;
      top: 2px;
      width: 45px;
      height: 45px;
      border-radius: $inputBorderRadius;
      background: $linkColor;
      border: none;
      right: 25px;
      box-shadow: $boxShadow2;
      transition: opacity .25s;

      &:before{
        content: "";
        position: absolute;
        width: 31px;
        height: 34px;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: url("../i/new/i-filters.svg") no-repeat;
      }
    }
  }
}

@media only screen and (max-width: 767px){
  .b-subscribe__section{
    padding: 60px 0;

    .b-subscribe__title{
      width: 100%;
    }

    .b-subscribe{
      width: 90%;
      margin: auto;

      .b-input{
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;

        &:last-child{
          margin-bottom: 0;
        }
      }

      .b-button__box{
        margin-top: 30px;
        width: 100%;

        .b-button{
          width: 100%;
        }
      }
    }
  }
  .b-index-carousel__box{
    .slick-arrow{
      top: 120px;
      &.slick-next{
        right: 2% !important;
        margin-right: 0;
		  display: none !important;
      }
      &.slick-prev{
        left: 98% !important;
        margin-left: -108px;
		  display: none !important;
      }
    }
    .slick-dots{
      //display: none !important;
		bottom: 10px;
		left: 50%;
		transform: translate(-50%);
		right: 0;
		display: flex;
		justify-content: center;
		padding-left: 0;
    }
  }
  .b-index-top{
    height: 520px;

    .b-index-carousel{
      height: 520px !important;

      .b-product-section__wrapper{
        padding-top: 160px;
      }

      .b-index-carousel__rotate{
        display: none;
      }

      .b-wrapper{
        height: 100%;
      }

      .b-calc__result{
        left: 2.5%;
        right: 2.5%;
        bottom: 2.5%;
        top: auto;
        margin-top: 0;
        position: absolute;
        border-radius: 7px;

        .b-text__bigger{
          font-size: 13px;
          line-height: 21px;
          padding-top: 12px;
        }

        .b-button{
          height: 46px;
          font-size: 15px;
          padding: 6px 0 10px;
        }
        .b-button__box{
          margin-top: 2px !important;
          padding-bottom: 20px;
        }

        .b-calc__result_sum{
          font-size: 24px;
          line-height: 31px;
          height: auto;
          margin-top: 19px;

          .b-calc__result_sum-small{
            font-size: 24px;
            line-height: 31px;
          }
          .b-rub{
            font-size: 20px;
            line-height: 31px;
          }
        }

        &.b-calc__result_bottom-custom{

          bottom: 35px;
          left: 5%;
          font-size: 15px;
          line-height: 26px;
			margin: 0 auto;

          &:before{
            width: 24px;
            height: 13px;
            background-size: cover;
            margin: -6px 0 0 -12px;
          }

          span{
            top: 50%;
            margin-top: -13px;
            left: 100%;
            right: auto;
            padding-top: 0;
            padding-left: 10px;
            transform: none;
          }

        }
      }
    }
  }
  .b-index-carousel{
    &.b-index-carousel_custom{
      .b-product-section__wrapper{
        p{
          display: block;
          max-height: 120px;
        }
      }
    }
    .b-product-section__wrapper{
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      h2{
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 26px;
      }

      p{
        display: none;
      }
    }
  }

  .b-products__section{
    margin-top: 0;
    padding-bottom: 30px;

    .b-wrapper{
      width: 95%;
    }
    .b-products__section_wrapper{
      padding: 10px 0 0;
      margin: 0 0 30px;
    }
  }

  .b-index-sort__section{
    margin-top: 0;

    .b-index-sort__call{
      right: -2.5%;
    }
  }

  .b-product{
    width: 100%;
    margin: 0 0 10px;

    &.news{
      .b-product__main{
        background: none;
        color: rgba(0, 0, 0, 0.9);
      }
    }

    &.financial_literacy{
      .b-product__pic{
        box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.4);

        .b-product__basket{
          right: 0;
          width: auto;
          height: auto;
          left: 0;
          top: 0;
          box-shadow: none;

          &:before{
            width: 33px;
            height: 45px;
          }
        }
      }
    }

    .b-product__tags,
    .b-product__date{
      font-size: 11px !important;
    }

    .b-product__text {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      max-height: 280px;

      h4{
        font-size: 23px;
        line-height: 27px;
      }
    }

    .b-product__pic{
      box-shadow: -5px 20px 30px rgba(0, 0, 0, 0.4);

      &.b-product__pic_simple{
        box-shadow: none;
      }

      .b-product__pic_pic{
        opacity: 0;
      }
      .b-product__price{
        opacity: 1;
      }
    }

    .b-product__tags li a{
      color: rgba(255,255,255, .4);
    }

    .b-product__bg{
      opacity: .5;
    }

    .b-product__main{
      background: #56675f;
      color: rgba(255, 255, 255, 0.9);
    }

    .b-product__text{
      h4{
        .b-link {
          color: #fff;

          .b-link__text{
            box-shadow: -10px 33px 45px rgba(0, 0, 0, 0.5);
            background: #21A038;
            border-bottom-color: transparent;
          }
        }
      }
    }

  }

  .b-specs__section{
    padding: 0;

    .b-wrapper{
      padding: 0;
    }

    h2{
      position: absolute;
      top: 31px;
      left: 0;
      font-weight: normal;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 15px;
      white-space: nowrap;
      opacity: .3;
      z-index: 3;
    }

    .b-specs{
      border-radius: 0;
      box-shadow: none;
      padding: 77px 15px;
      margin: 0;

      h4{
        font-size: 23px;
        line-height: 27px;
      }

      .b-link{
        left: 15px;
      }
    }

    .b-specs__carousel{
      margin: 0 -5.5%;
      padding: 0;
      overflow: hidden;


      .slick-arrow{
        top: 16px;
        transform: scale(0.82);

        &.slick-prev{
          right: 50px;
          left: auto;
        }
        &.slick-next{
          right: 0;
        }
      }


    }
  }
}
