.s-ecosystem-menu {
  position: relative;
  z-index: 2;
  margin-right: 15px;
}

.s-ecosystem-menu__toggle {
  display: block;
  height: 40px;
  width: 40px;
  background: url(../i/new/ecoSystem/ecosystem_icon.svg) no-repeat 50%/cover;
  font-size: 0;
  border: 0 none;

}

.s-ecosystem-menu__toggle_active {
  background-image: url(../i/new/ecoSystem/ecosystem_icon_active.svg)
}

.s-ecosystem-menu__body {
  display: none;
  position: absolute;
  left: -20px;
  top: calc(100% + 8px);
  box-sizing: border-box;
  padding: 32px 20px 12px;
  background: #fff;
  box-shadow: 0 3px 28px rgba(73,78,83,.15);
  border-radius: 10px
}

.s-ecosystem-menu__list {
  display: flex;
  width: 264px;
  flex-wrap: wrap
}

.s-ecosystem-menu__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px 20px;
  padding-bottom: 20px;
  text-decoration: none;
  border-bottom: 0 !important;
}

.s-ecosystem-menu__item._more {
  font-size: 12px;
  margin: 12px 0 0;
  width: 100%;
  text-align: center;
  color: #353850 !important;
}

.s-ecosystem-menu__logo {
  height: 48px;
  width: 48px
}

.s-ecosystem-menu__label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 12px;
  color: #494e53;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
}