.b-razv-top{
  min-height: 600px;

  .b-main-title{
    width: 775px;
    margin: auto;
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;

  }

  .b-text__wrapper{
    width: 490px;
  }
}
.b-razv-scroll{
  margin-top: -60px;
  position: relative;
  min-height: 265px;
  z-index: 11;

  &.fixed{
    .b-razv-scroll__wrapper{
      position: fixed;
      top: -160px;
    }
  }
  &.visibility{
    .b-razv-scroll__wrapper{
      opacity: 0;
      visibility: hidden;
    }
  }

  .b-razv-scroll__wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 15;
    transition: opacity .25s, visibility .25s;
  }
}

.b-razv-links{
  position: relative;
  background: #fff;
  border-radius: $inputBorderRadius;
  box-shadow: $boxShadow;
  text-align: left;

  li{
    float: left;
    vertical-align: top;
    width: 20%;

    &:last-child{
      a{
        border-right: none;
      }
    }
  }

  a{
    display: block;
    border-bottom: none;
    height: 265px;
    padding: 175px 20px 0;
    box-sizing: border-box;
    border-right: $border;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      left: 50%;
      transition: opacity .25s;
    }

    &:hover:before{
      opacity: .7;
    }

    &.b-razv-links_1:before{
      width: 118px;
      height: 131px;
      margin-left: -59px;
      top: 21px;
      background: url("../i/new/pages/razv/i-razv-link-1.png") no-repeat;
    }
    &.b-razv-links_2:before{
      width: 135px;
      height: 93px;
      margin-left: -67px;
      top: 41px;
      background: url("../i/new/pages/razv/i-razv-link-2.png") no-repeat;
    }
    &.b-razv-links_3:before{
      width: 126px;
      height: 91px;
      margin-left: -63px;
      top: 41px;
      background: url("../i/new/pages/razv/i-razv-link-3.png") no-repeat;
    }
    &.b-razv-links_4:before{
      width: 71px;
      height: 122px;
      margin-left: -36px;
      top: 23px;
      background: url("../i/new/pages/razv/i-razv-link-4.png") no-repeat;
    }
    &.b-razv-links_5:before{
      width: 162px;
      height: 129px;
      margin-left: -81px;
      top: 12px;
      background: url("../i/new/pages/razv/i-razv-link-5.png") no-repeat;
    }

  }
}

.b-razv-how-works{
  position: relative;
  text-align: center;
  padding: 65px 0 50px;

  .b-text__wrapper{
    margin: 0;

    ul{
      overflow: hidden;
      li{
        float: left;
        vertical-align: top;
        width: 33.333%;
        box-sizing: border-box;
        margin-left: 0;
        text-align: left;
        position: relative;
        padding-left: 39px;
        padding-right: 50px;
        list-style: none;

        &:before{
          content: "";
          position: absolute;
          left: 0;
          top: -1px;
          width: 30px;
          height: 30px;
          box-sizing: border-box;
          border-radius: 50%;
          border: $borderWhite;
          background: url("../i/new/i-ul-text-white.svg") no-repeat center;
          background-size: 15px 10px;
          font-size: 19px;
          line-height: 27px;
          text-align: center;
          display: block;
        }
      }
    }
  }
}
.b-razv-how-works__steps{
  position: relative;
  padding-top: 8px;
  margin-bottom: 30px;
  counter-reset: razv-steps;

  .b-razv-how-works__steps_item{
    float: left;
    vertical-align: top;
    width: 33.333%;
    box-sizing: border-box;
    padding-right: 4.333%;
    position: relative;
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;
  }

  .b-razv-how-works__steps_icon{
    display: inline-block;
    vertical-align: top;
    width: 228px;
    height: 228px;
    border-radius: 50%;
    border: $borderWhite;
    margin-bottom: 15px;
    position: relative;

    &:after{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:before{
      counter-increment: razv-steps;
      content: counters(razv-steps,".");
      width: 51px;
      height: 50px;
      line-height: 47px;
      position: absolute;
      left: 0;
      text-align: center;
      top: -8px;
      background: url("../i/new/pages/razv/i-steps-count.png") no-repeat;
    }

    &.b-razv-how-works__steps_icon-1:after{
      width: 110px;
      height: 144px;
      margin: -72px 0 0 -55px;
      background: url("../i/new/pages/razv/i-razv-steps-1.png") no-repeat;
    }
    &.b-razv-how-works__steps_icon-2:after{
      width: 168px;
      height: 89px;
      margin: -44px 0 0 -84px;
      background: url("../i/new/pages/razv/i-razv-steps-2.png") no-repeat;
    }
    &.b-razv-how-works__steps_icon-3:after{
      width: 135px;
      height: 149px;
      margin: -74px 0 0 -67px;
      background: url("../i/new/pages/razv/i-razv-steps-3.png") no-repeat;
    }
  }

  .b-razv-how-works__steps_text{
    text-align: left;
    width: 280px;
    display: block;
  }
}

.b-razv-features__box{
  position: relative;
  padding: 65px 0 50px;
  text-align: center;
}

.b-razv-features{
  position: relative;
  font-size: $biggerFontSize;
  line-height: $biggerFontLineHeight;

  li{
    float: left;
    vertical-align: top;
    width: 33.333%;
    position: relative;
    border-top: $border;
    padding-top: 170px;
    margin-bottom: 22px;
    box-sizing: border-box;

    &:before{
      content: "";
      position: absolute;
      top: 35px;
      left: 40%;
    }

    &.b-razv-features_1:before{
      width: 145px;
      height: 101px;
      margin-left: -72px;
      margin-top: 3px;
      background: url("../i/new/pages/razv/i-razv-features-1.png") no-repeat;
    }
    &.b-razv-features_2:before{
      width: 107px;
      height: 88px;
      margin-left: -53px;
      margin-top: 11px;
      background: url("../i/new/pages/razv/i-razv-features-2.png") no-repeat;
    }
    &.b-razv-features_3:before{
      width: 141px;
      height: 106px;
      margin-left: -70px;
      margin-top: -4px;
      background: url("../i/new/pages/razv/i-razv-features-3.png") no-repeat;
    }
    &.b-razv-features_4:before{
      width: 90px;
      height: 109px;
      margin-left: -45px;
      background: url("../i/new/pages/razv/i-razv-features-4.png") no-repeat;
    }
    &.b-razv-features_5:before{
      width: 142px;
      height: 108px;
      margin-left: -71px;
      background: url("../i/new/pages/razv/i-razv-features-5.png") no-repeat;
    }
    &.b-razv-features_6:before{
      width: 113px;
      height: 117px;
      margin-left: -56px;
      background: url("../i/new/pages/razv/i-razv-features-6.png") no-repeat;
    }

    .b-razv-features__text,
    .b-razv-features__faq{
      text-align: left;
      width: 280px;

      sup{
        color: $linkColor;
      }
    }
    .b-razv-features__faq{
      margin-top: 11px;
      color: $greyColor;
      font-size: 14px;
      line-height: 25px;
    }
  }
}

.b-razv-links__bottom{
  padding: 65px 0 107px;
  text-align: center;
}
.b-footnote{
  position: relative;
  font-size: 11px;
  line-height: 14px;
  color: rgba(255,255,255, .5);
  background: $linkColor;
  padding: 45px 0 50px;

  p{
    margin-bottom: 15px;
    box-sizing: border-box;
    padding-right: 35px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .b-wrapper{
    column-count: 3;
    -moz-column-count: 3;

    p{
      break-inside: avoid-column;
      -webkit-backface-visibility: hidden;
    }
  }
}

.b-accumulate__box{
  padding: 0 0 100px;
  position: relative;
  box-sizing: border-box;

  .b-accumulate__title{
    padding-top: 80px;
    text-align: center;
    width: 680px;
    margin: auto;
  }

  .b-wrapper{
    z-index: 4;
  }
}

.b-accumulate__line{
  position: absolute;
  height: 1px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  z-index: 2;

  &.b-accumulate__line_1{
    top: 50%;
    border-top: 1px dashed $yellowColor;
    opacity: 0;
    transition: opacity .25s;

    &.hide-text{

    }

    &.show{
      opacity: 1;
    }

    .b-accumulate__line_point{
      background: $yellowColor;

      &:before{
        background: url("../i/new/pages/razv/incident.svg") no-repeat;
      }
    }
  }

  &.b-accumulate__line_2{
    top: 400px;
    border-top: 1px dashed $linkColor;
    will-change: scroll-position, transform;

    .b-accumulate__line_point{
      background: $linkColor;

      &:before{
        background: url("../i/new/pages/razv/finish.svg") no-repeat;
      }
    }
  }

  .b-accumulate__line_point{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin-top: -33px;
    margin-bottom: 10px;
    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      margin: -20px 0 0 -20px;
    }
  }

  .b-accumulate__line_text{
    width: 165px;
    margin-left: 4px;

    sup{
      color: $linkColor;
    }
  }
}

.b-accumulate{
  position: relative;
  height: 190px;
  text-align: center;
  will-change: height;

  &.middle{
    .b-accumulate__column{
      .b-accumulate__text-bottom{
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.last{
    .b-accumulate__column{
      .b-accumulate__text-bottom{
        span{
          display: none;

          &:last-child{
            display: block;
          }
        }
      }
    }
  }

  .b-accumulate__column{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -116px;
    width: 232px;
    box-sizing: border-box;
    text-align: left;

    &:before{
      content: "";
      position: absolute;
      top: 190px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .b-accumulate__text-top{
      position: absolute;
      bottom: 19px;
      left: 17px;
      right: 15px;
      z-index: 4;
    }

    .b-accumulate__text-bottom{
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin-top: 57px;
      padding: 14px 17px;
      box-sizing: border-box;
      visibility: hidden;
      opacity: 0;
      transition: opacity .25s, visibility .25s;

      span:last-child{
        display: none;
      }
    }

    .b-accumulate__price{
      position: absolute;
      height: 54px;
      left: 0;
      right: 0;
      top: 100%;
      padding: 0 17px;
      box-sizing: border-box;
      font-weight: bold;
      font-family: $sbFontBold;
      font-size: 25px;
      line-height: 54px;
    }

    .b-accumulate__column_bg{
      display: block;
      height: 190px;
      position: relative;
      z-index: 2;
    }

    &.b-accumulate__column_1{
      margin-left: -236px;

      &:before{
        background: $yellowColor;
        opacity: .5;
      }

      .b-accumulate__price,
      .b-accumulate__text-bottom{
        background: $yellowColor;
      }

      .b-accumulate__column_bg{
        opacity: .5;

        background: -moz-linear-gradient(top, rgba(255,202,74,0) 0%, rgba(255,202,74,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,202,74,0) 0%,rgba(255,202,74,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,202,74,0) 0%,rgba(255,202,74,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffca4a', endColorstr='#FAED00',GradientType=0 ); /* IE6-9 */
      }
    }
    &.b-accumulate__column_2{
      margin-left: 4px;

      &:before{
        background: #009942;
        opacity: .3;
      }

      .b-accumulate__text-bottom{
        background: $linkColor;
        color: #fff;
      }

      .b-accumulate__price{
        background: $linkColor;
        color: #fff;
        
        &:after{
          content: "";
          position: absolute;
          right: 13px;
          top: 50%;
          width: 23px;
          height: 23px;
          margin-top: -12px;
          background: url("../i/new/sber-whois-product_white.svg") no-repeat;
          background-size: cover;
        }
      }

      .b-accumulate__column_bg{
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#009942+0,009942+100&0+0,1+100 */
        background: -moz-linear-gradient(top, rgba(0,153,66,0) 0%, rgba(0,153,66,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,153,66,0) 0%,rgba(0,153,66,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,153,66,0) 0%,rgba(0,153,66,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00009942', endColorstr='#009942',GradientType=0 ); /* IE6-9 */

        opacity: .3;
      }
    }
  }
}

@media only screen and (min-width: 1600px){
  .b-accumulate__box .b-accumulate__title{
    width: 1000px;
  }
  .b-accumulate .b-accumulate__column{
    width: 280px;
    &.b-accumulate__column_1{
      margin-left: -284px;
    }
  }
  .b-accumulate__line .b-accumulate__line_text{
    width: 200px;
  }
}
@media only screen and (max-width: 1199px){
  @import "../variables_media_tablet";

  .b-razv-top{

    .b-main-title{
      width: 80%;
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;

    }
    .b-text__wrapper{
      width: 100%;
    }
  }

  .b-razv-scroll{
    min-height: 220px;
    &.fixed{
      .b-razv-scroll__wrapper{
        top: -110px;
      }
    }
  }
  .b-razv-links a{
    padding: 130px 10px 0;
    font-size: 13px;
    line-height: 20px;
    height: 220px;
    overflow: hidden;

    &:before{
      transform: scale(.7) translate(0, -30px);

    }
  }
  .b-razv-how-works__steps{
    .b-razv-how-works__steps_item{
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;
    }
    .b-razv-how-works__steps_text{
      width: 100%;
    }
    .b-razv-how-works__steps_icon{
      width: 180px;
      height: 180px;

      &:after{
        transform: scale(.7);
      }
      &:before{
        width: 40px;
        height: 40px;
        background-size: cover;
        top: -7px;
        line-height: 38px;
      }
    }
  }
  .b-razv-features{
    font-size: $biggerFontSize;
    line-height: $biggerFontLineHeight;

    li{
      padding-top: 135px;

      &:before{
        transform: scale(.75) translate(0, -20px);
      }

      .b-razv-features__text,
      .b-razv-features__faq{
        width: 100%;
        box-sizing: border-box;
        padding-right: 10%;
      }

      .b-razv-features__faq{
        line-height: $smallerFontLineHeight;
        font-size: $smallerFontSize;
      }
    }
  }
}
@media only screen and (max-width: 767px){
  @import "../variables_media_mobile";

  .b-razv-top{

    .b-main-title{
      width: 100%;
      font-size: $biggerFontSize;
      line-height: $biggerFontLineHeight;

    }
  }

  .b-razv-top{
    box-sizing: border-box;
    padding-bottom: 280px;
  }

  .b-razv-scroll{
    min-height: 320px;
    margin-top: -250px;

    .b-razv-scroll__open{
      position: absolute;
      right: 0;
      top: 0;
      height: 49px;
      width: 20px;
      z-index: 5;
      border-bottom: none;
      display: none;

      &:before{
        content: "";
        position: absolute;
        right: 50%;
        top: 50%;
        margin: -3px 0 0 -8px;
        width: 16px;
        height: 7px;
        background: url(../i/new/i-accordion.svg) no-repeat;
        transform: rotate(180deg);
        transition: transform .25s;
      }
    }

    &.fixed{

      &.open{
        .b-razv-scroll__open{
          &:before{
            transform: rotate(0);
          }
        }
        .b-razv-scroll__wrapper .b-razv-links li{
          display: block;

          &:first-child{
            a{
              border-bottom: $border;
            }
          }
        }
      }

      .b-razv-scroll__open{
        display: block;
      }
      .b-razv-scroll__wrapper{
        top: 0;

        .b-razv-links{
          margin-left: 40px;
          border-top-left-radius: 0;
          border-top-right-radius: 0;

          li{
            display: none;

            &:first-child{
              display: block;
              a{
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
  .b-razv-links{
    box-shadow: $boxShadow2;

    li{
      float: none;
      width: 100%;

      &:last-child{
        a{
          border-bottom: none;
        }
      }
    }

    a{
      font-size: $regularFontSize;
      line-height: $regularFontLineHeight;
      color: $linkColor;
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 12px 14px;
      height: auto;

      &:before{
        display: none;
      }
    }
  }
  .b-accumulate__box{
    height: auto !important;
    padding-bottom: 200px;
    .b-accumulate__title{
      width: 100%;
      padding-top: 20px;
      text-align: left;
    }
  }

  .b-accumulate{
    height: 500px;
    .b-accumulate__column{
      width: 150px;
      font-size: 13px;
      line-height: 20px;

      &.b-accumulate__column_1{
        margin-left: -154px;
        max-height: 250px !important;
      }

      .b-accumulate__text-bottom{
        visibility: visible;
        opacity: 1;
        padding: 10px 7px;
        margin-top: 46px;
      }

      .b-accumulate__text-top{
        bottom: 15px;
        left: 7px;
        right: 7px;
      }

      .b-accumulate__price{
        font-size: 15px;
        padding: 0 7px;
        height: 42px;
        line-height: 40px;
      }
    }
  }
  .b-accumulate__line{
    .b-accumulate__line_text{
      font-size: 13px;
      line-height: 20px;
    }

    &.b-accumulate__line_1{
      top: 380px !important;
      visibility: visible;
      opacity: 1;

      .b-wrapper{
        display: none;
      }
    }
    &.b-accumulate__line_2{
      top: auto !important;
      bottom: 199px;
    }
  }

  .b-razv-how-works{
    padding-top: 0;
    h3{
      position: absolute;
      top: 31px;
      left: 0;
      right: 0;
      text-align: left;
    }

    .b-text__wrapper ul li{
      float: none;
      width: 100%;
      padding-right: 0;
    }
  }
  .b-razv-how-works__steps{
    padding-top: 80px;

    .slick-arrow{
      top: 145px;
      transform: scale(0.82) translate(0, 0);

      &.slick-prev{
        left: -10px;
      }
      &.slick-next{
        right: -10px;
      }
    }

    .b-razv-how-works__steps_item{
      font-size: $regularFontSize;
      line-height: $regularFontLineHeight;
      padding-right: 0;
      outline: none;

      .b-razv-how-works__steps_text{
        width: 60%;
        text-align: center;
        margin: auto;
      }
    }
  }
  .b-razv-features__box{
    padding: 30px 0;
  }
  .b-razv-features{
    font-size: 0;
    line-height: 0;

    li{
      float: none;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      font-size: $regularFontSize;
      line-height: $regularFontLineHeight;


      .b-razv-features__faq{
        font-size: $smallerFontSize;
        line-height: $smallerFontLineHeight;
      }
    }
  }
  .b-razv-links__bottom{
    padding: 30px 0 60px;

    h2{
      text-align: left;
    }
  }
  .b-footnote{
    padding: 25px 0 30px;
    font-size: 9px;
    line-height: 11px;

    .b-wrapper{
      column-count: 2;
      -moz-column-count: 2;
      width: 100%;
      padding-left: 20px;

      p{
        padding-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}