@import "variables";
.partners{
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding-bottom: 60px;
  @media only screen and (max-width: 767px){
    padding-bottom: 30px;
  }
  &-head{
    font-size: 30px;
    font-family: $sbFont !important;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
    padding-left: 45px;
    padding-right: 45px;
    margin-top: -25px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @media only screen and (max-width: 767px){
      margin-top: 0;
    }
  }
  &-flex{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    @media only screen and (max-width: 767px){
      flex-direction: column;
    }
    &-el{
      padding: 0 20px;
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      &:first-child{
        padding-left: 0;
      }
      &:last-child{

        padding-right: 0;
      }
      @media only screen and (max-width: 767px){
        width: 100%;
        margin-bottom: 30px;
        padding: 0 20px !important;
      }
      .img{
        height: 40px;
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border-bottom: 0 none;
        img{
          width: 100%;
          max-height: 100%;
          height: 23px;
        }
      }
      .title{
        font-size: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 50px;
        line-height: 1.3;
        color: #38783a;
        @media only screen and (max-width: 767px){
          font-size: 20px;
          padding-bottom: 10px;
          height: auto;
        }
      }
      .desc{
        font-size: 13px;
        line-height: 1.3;
        color: #38783a;
        @media only screen and (max-width: 767px){
          font-size: 16px;
        }
      }
    }
  }
}