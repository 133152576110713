.btns_sub{
  &_el{
    background: #21A038;
    color: #fff;
    padding: 10px 15px;
    margin-right: 10px;
    border: 0 none;
    border-radius: 5px;
    cursor: pointer;
    transition: .5s;
    &.active,
    &:hover{
      background: #388b58;
    }
  }
}

.subHtml:not(.subHtml_0){
  display: none;
  .js-top-result-fix.b-bg.b-bg__top{
    display: none;
  }
}