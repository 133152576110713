.b-header.white,
.b-header.green-day {
  a{
    color: #fff !important;
  }
  .b-header__search_i:before {
    background: url(../i/new/header/i-search-white.svg) no-repeat 0 0;
    @media screen and (max-width: 767px) {
      background: url(../i/new/header/i-search.svg) no-repeat 0 0;
    }
  }
  .b-header__logo {
    background: url(../i/new/header/logo-white.svg) no-repeat 0;
  }
  .b-header-main__wrapper a{
    color: inherit !important;
  }
  .b-top-menu a{
    @media screen and (max-width: 767px) {
      color: inherit !important;
    }
  }
}
.greenDay {
  width: 100%;
  overflow: hidden;
  &-img {
    padding-top: 70px;
    background: #191b18;
    img {
      width: 100%;
      height: auto;
      @media screen and (max-width: 767px) {
        margin-left: 20px;
        width: 600px;
        position: relative;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}

.flex {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  &-el {
    display: block;
    margin-top: 70px;
    text-decoration: none;
    border: 0 none;
    width: 100%;
    position: relative;
    @media screen and (max-width: 767px) {
      padding: 0 20px;
      margin-top: 35px;
    }
    img {
      width: 100%;
      max-width: 980px;
      &.hd {
        display: block;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      &.xs {
        display: none;
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
    }
    //a{
    //  height: 56px;
    //  width: 220px;
    //  border-radius: 50px;
    //  border: 0 none;
    //  color: #fff;
    //  display: flex;
    //  justify-content: center;
    //  align-items: center;
    //  position: absolute;
    //  bottom: 50px;
    //  left: 70px;
    //  background-color: #21A038;
    //  @media screen and (max-width: 1000px){
    //    bottom: 35px;
    //    left: 50px;
    //  }
    //  @media screen and (max-width: 767px){
    //    width: 80%;
    //    left: 50%;
    //    bottom: 35px;
    //    transform: translate(-50%);
    //  }
    //  &.white{
    //    background: #fff;
    //    color: #21A038;
    //    @media screen and (max-width: 767px){
    //      color: #fff;
    //      background: #21A038;
    //    }
    //  }
    //}
  }
}

.star {
  width: 100%;
  max-width: 1050px;
  margin: 70px auto;
  color: #4b4b4b;
  font-family: $sbFont !important;
  font-size: 13px;
  font-weight: 400;
  @media screen and (max-width: 767px) {
    margin: 35px auto;
    padding: 0 20px;
    font-size: 20px;
  }
}
